import { DatePicker } from '../../form';
import { TooltipWrapper } from '../../display';
import { formatDateApi, formatDate, convertApiToDate } from '../../functions';
import { Button, SVGIcon } from '../../custom-essentials';

export function applyTemplate(formik){
    const blItems = [];
    const templateItems = formik.values.selectedTemplate.obj.templateItems;

    let index = 1;
    templateItems.forEach(ti => {
        const bookInfo = ti.bookObj;
        blItems.push({
            bookId: bookInfo.id,
            title: bookInfo.title,
            author: bookInfo.author,
            dateAssigned: '',
            dateCompleted: '',
            sortOrder: index++
        });
    });

    const newValues = { ...formik.values };
    newValues.bookListItems = blItems;
    newValues.selectedTemplate = { value: -1, label: 'Please select a template...'};

    formik.setValues(newValues);
    return;
}

export function modifyBookList(formik, bookItem, mode){
    if(!bookItem) return;
    const blItems = [ ...formik.values.bookListItems ];

    if(mode === 'add'){
        blItems.push({
            bookId: parseInt(bookItem.id),
            title: bookItem.title,
            author: bookItem.author,
            dateAssigned: '',
            dateCompleted: '',
            sortOrder: blItems.length + 1
        });
    } else {
        const currentIndex = blItems.findIndex(i => parseInt(i.bookId) === parseInt(bookItem.bookId));
        if(mode === 'shiftUp'){
            if(currentIndex === 0) return;
    
            blItems[currentIndex].sortOrder--;
            blItems[currentIndex - 1].sortOrder++;
        } else if(mode === 'shiftDown'){
            if(currentIndex >= blItems.length - 1) return;
    
            blItems[currentIndex].sortOrder++;
            blItems[currentIndex + 1].sortOrder--;
        } else if(mode === 'delete'){
            blItems.splice(currentIndex, 1);
        }
    }


    blItems.sort((a, b) => parseInt(a.sortOrder) - parseInt(b.sortOrder));
    formik.setFieldValue('bookListItems', blItems);
}

export function renderBli(bli, formik, positionIndex, duplicateBookMap){
    const assignedDate = bli.dateAssigned ? convertApiToDate(bli.dateAssigned) : '';
    const completedDate = bli.dateCompleted ? convertApiToDate(bli.dateCompleted) : '';
    const shouldWarn = ((completedDate !== '' && (assignedDate === '' || assignedDate > completedDate)) ||
        (assignedDate !== '' && isNaN(new Date(assignedDate))) ||
        (completedDate !== '' && isNaN(new Date(completedDate))));
    const datesWarning = shouldWarn ?
        <div className="text-mpLRed">
            Date completed cannot be before date assigned.
        </div>
        : null;
    const stagedToRemove = formik.values.stagedToRemoveIndex === positionIndex;
    const currentIndex = positionIndex;
    
    const duplicateInfo = duplicateBookMap[parseInt(bli.bookId)];
    let duplicateIndex = 0;
    const duplicateWarning = duplicateInfo ? (
        <>
            <TooltipWrapper
                tooltipText={
                    <>
                        <div>One or more students in this group was already assigned this book!</div>

                        {duplicateInfo.map(di => {
                            const dateAssignedText = di.dateAssigned ? (
                                <div>
                                    <b>Assigned:</b> {formatDate(di.dateAssigned)}
                                </div>
                            ) : <div><b>Assigned:</b> <span className="text-mpOrange">Never</span></div>;
                            const dateCompletedText = di.dateCompleted ? (
                                <div>
                                    <b>Completed:</b> {formatDate(di.dateCompleted)}
                                </div>
                            ): <div><b>Completed:</b> <span className="text-mpOrange">Never</span></div>;;
                            return (
                                <div key={`duplicate-item-${currentIndex}-${duplicateIndex++}`}>
                                    <br/>
                                    <div><b>Student:</b> {di.student}</div>
                                    {dateAssignedText}
                                    {dateCompletedText}
                                </div>
                            );
                        })}
                    </>
                }
            >
                <SVGIcon fileName="exclamation-red"/>
            </TooltipWrapper>
        </>
    ) : null;
    return (
        <div key={`learning-plans-book-id-${bli.bookId}`}>
            {/* BOOK TITLE */}
            <div className="flex flex-row gap-x-4">
                <div className="grid grid-cols-1 gap-y-2 w-full">
                    <div className="flex flex-row gap-x-4">
                        <h4><u>{bli.title}</u></h4>&emsp;{duplicateWarning}
                    </div>
                </div>
            </div>
            {/* MAIN DATES/ICON ROW */}
            <div className="flex flex-row gap-x-4">
                {/* CALENDARS AND DATES COLUMN */}
                <div className="grid grid-cols-1 gap-y-2 w-3/4">
                    {/* CALENDARS AND DATES ROW WRAPPER */}
                    <div className="flex flex-row gap-x-4">
                        {/* DATE ASSIGNED */}
                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                            <h5>Date Assigned</h5>
                            <div className="flex flex-row gap-x-2">
                                <DatePicker
                                    id="learning-plans-date-assigned"
                                    name="dateAssigned"
                                    value={bli.dateAssigned}
                                    onChange={(e) => {
                                        const newValues = { ...formik.values };
                                        newValues.bookListItems[currentIndex].dateAssigned = e.target.value;
                                        formik.setValues(newValues);
                                    }}
                                />
                                <div className="ml-4">
                                    <TooltipWrapper
                                        tooltipText="Use today's date"
                                    >
                                        <Button
                                            className="mr-2"
                                            color="lte-mpLBlue"
                                            variant="icon"
                                            // Disable any non-blank to avoid accidental modification
                                            disabled={bli.dateAssigned !== ''}
                                            onClick={() => {
                                                const newDate = formatDateApi(new Date());
                                                const newValues = { ...formik.values };
                                                newValues.bookListItems[currentIndex].dateAssigned = newDate;
                                                formik.setValues(newValues);
                                            }}
                                        >
                                            <SVGIcon fileName="calendar"/>
                                        </Button>
                                    </TooltipWrapper>
                                </div>
                                <TooltipWrapper
                                    tooltipText="Remove date"
                                >
                                    <Button
                                        color="lte-mpLRed"
                                        variant="icon"
                                        onClick={() => {
                                            const newDate = '';
                                            const newValues = { ...formik.values };
                                            newValues.bookListItems[currentIndex].dateAssigned = newDate;
                                            formik.setValues(newValues);
                                        }}
                                    >
                                        <SVGIcon fileName="eraser"/>
                                    </Button>
                                </TooltipWrapper>
                            </div>
                        </div>
                        {/* DATE COMPLETED */}
                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                            <h5>Date Completed</h5>
                            <div className="flex flex-row gap-x-2">
                                <DatePicker
                                    id="learning-plans-date-completed"
                                    name="dateCompleted"
                                    value={bli.dateCompleted}
                                    onChange={(e) => {
                                        const newValues = { ...formik.values };
                                        newValues.bookListItems[currentIndex].dateCompleted = e.target.value;
                                        formik.setValues(newValues);
                                    }}
                                    />
                                <div className="ml-4">
                                    <TooltipWrapper
                                        tooltipText="Use today's date"
                                    >
                                        <Button
                                            color="lte-mpLBlue"
                                            variant="icon"
                                            // Disable any non-blank to avoid accidental modification
                                            disabled={bli.dateCompleted !== ''}
                                            onClick={() => {
                                                const newDate = formatDateApi(new Date());
                                                const newValues = { ...formik.values };
                                                newValues.bookListItems[currentIndex].dateCompleted = newDate;
                                                formik.setValues(newValues);
                                            }}
                                            style={{ marginRight: "10px" }}
                                        >
                                            <SVGIcon fileName="calendar"/>
                                        </Button>
                                    </TooltipWrapper>
                                </div>
                                <TooltipWrapper
                                    tooltipText="Remove date"
                                >
                                    <Button
                                        color="lte-mpLRed"
                                        variant="icon"
                                        onClick={() => {
                                            const newDate = '';
                                            const newValues = { ...formik.values };
                                            newValues.bookListItems[currentIndex].dateCompleted = newDate;
                                            formik.setValues(newValues);
                                        }}
                                    >
                                        <SVGIcon fileName="eraser"/>
                                    </Button>
                                </TooltipWrapper>
                            </div>
                        </div>
                    </div>
                </div>
                {/* MODIFICATION BUTTONS (up/down, delete) */}
                <div className="grid grid-cols-1 gap-y-2 w-1/4 ml-4">
                    {/* ROW WRAPPER */}
                    <div className="flex flex-row gap-x-1 items-center">
                        {/* UP/DOWN */}
                        <div className="grid grid-cols-1 gap-y-1">
                            <TooltipWrapper
                                tooltipText="Shift book up"
                            >
                                <Button
                                    color="lte-mpYellow"
                                    variant="icon"
                                    disabled={currentIndex === 0}
                                    onClick={() => modifyBookList(formik, bli, 'shiftUp')}
                                >
                                    <SVGIcon fileName="arrow-up"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText="Shift book down"
                            >
                                <Button
                                    color="lte-mpYellow"
                                    variant="icon"
                                    disabled={currentIndex >= formik.values.bookListItems.length - 1}
                                    onClick={() => modifyBookList(formik, bli, 'shiftDown')}
                                >
                                    <SVGIcon fileName="arrow-down"/>
                                </Button>
                            </TooltipWrapper>
                        </div>

                        <div className="flex items-center">
                            <div className="flex flex-row gap-x-2 ml-1 items-center">
                                { stagedToRemove ? 
                                    <>
                                        <TooltipWrapper
                                            tooltipText="Cancel remove"
                                        >
                                            <Button
                                                color="lte-mpLBlue"
                                                variant="icon"
                                                onClick={() => formik.setFieldValue('stagedToRemoveIndex', -1)}
                                            >
                                                <SVGIcon fileName="undo"/>
                                            </Button>
                                        </TooltipWrapper>
                                        <TooltipWrapper
                                            tooltipText="Confirm remove"
                                        >
                                            <Button
                                                color="lte-mpLRed"
                                                variant="icon"
                                                onClick={() => {
                                                    modifyBookList(formik, bli, 'delete');
                                                    formik.setFieldValue('stagedToRemoveIndex', -1);
                                                }}
                                            >
                                                <SVGIcon fileName="trash"/>
                                            </Button>
                                        </TooltipWrapper>
                                    </>
                                    : 
                                    <TooltipWrapper
                                        tooltipText="Remove book"
                                    >
                                        <Button
                                            color="lte-mpLRed"
                                            variant="icon"
                                            onClick={() => formik.setFieldValue('stagedToRemoveIndex', currentIndex)}
                                        >
                                            <SVGIcon fileName="trash"/>
                                        </Button>
                                    </TooltipWrapper>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row no-mar">
                {datesWarning}
            </div>
            <br/>
        </div>
    )
}