import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { Check, DatePicker, DateRangeSelector, SelectSingle, checkResponses } from '../../../../components/form';
import { formatDateApi, getDateObject, convertApiToDate, getymd } from '../../../../components/functions';
import { Socket } from '../../../../components/ws';
import { generateStandard } from './helpers';

import {
    fetchRpCentersAll,
    fetchAdminUsersAll,
    fetchStudentsAll,
    fetchAppointmentsDaterangeStudent,
    fetchBookListItemsStudentDaterangeCompleted,
    fetchBooksAll
} from '../../../../actions';
import {
    fetchGraphicND
} from '../../../../actions-nd';

const pageTitle = 'Report Generator';

const reportTypeOptions = [
    { value: 'standard', label: 'Standard PR' }
];

const start = new Date();
start.setMonth(start.getMonth() - 1);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

function ReportGenerator(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [drsValid, setDrsValid] = useState(true); // dateRangeSelector
    const [apiError, setApiError] = useState(false);

    // DATA
    const [studentOptions, setStudentOptions] = useState([]);
    const [instructors, setInstructors] = useState([]);
    const [logoData, setLogoData] = useState(null);

    const [centers, setCenters] = useState([{ value: -1, label: 'Loading centers...' }]);

    const { fetchAppointmentsDaterangeStudent, fetchRpCentersAll, fetchAdminUsersAll, fetchStudentsAll,
        fetchBookListItemsStudentDaterangeCompleted, fetchBooksAll } = props;

    const handleSubmit = useCallback((values, actions) => {
        (async function submit(){
            if(loading || !drsValid || !formRef.current?.values) return;
            if(mounted.current) setLoading(true);
    
            const { startDate, endDate, selectedStudent, selectedReportType } = values;
            const { setStatus, setSubmitting } = actions;
            
            if(selectedStudent.value === -1){
                setStatus('Please select a valid student');
                if(mounted.current){
                    setSubmitting(false);
                    setLoading(false);
                }
                return;
            } else if(selectedReportType.value === -1){
                setStatus('Please select a report type');
                if(mounted.current){
                    setSubmitting(false);
                    setLoading(false);
                }
                return;
            }
            
            const instructorMap = {};
            instructors.forEach(i => instructorMap[i.id] = `${i.first_name} ${i.last_name}`);

            const centerMap = {};
            centers.forEach(c => centerMap[parseInt(c.id)] = c.name);
    
            const startDateObj = getDateObject(convertApiToDate(startDate));
            const endDateObj = getDateObject(convertApiToDate(endDate));
            const appointmentsRes = await fetchAppointmentsDaterangeStudent({
                startDate: startDateObj.raw,
                endDate: endDateObj.raw,
                student: selectedStudent.value
            });
            const bookListItemsRes = await fetchBookListItemsStudentDaterangeCompleted({
                startDate: startDate,
                endDate: endDate,
                student: selectedStudent.value
            });
            const booksRes = await fetchBooksAll();
            const isApiError = checkResponses(appointmentsRes, bookListItemsRes, booksRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAppointmentsData = appointmentsRes.data || [];
            const newBookListItems = bookListItemsRes.data || [];
            const newBooks = booksRes.data || [];

            const newAppointments = newAppointmentsData.appointments || [];
            const newAssignments = newAppointmentsData.assignments || [];
            const appointmentAssignmentMap = {};
            newAssignments.forEach(a => {
                const appointmentId = parseInt(a.appointment_id);
                if(!appointmentAssignmentMap[appointmentId]) appointmentAssignmentMap[appointmentId] = [];
                appointmentAssignmentMap[appointmentId].push(a);
            })

            const bookMap = {};
            newBooks.forEach(b => {
                bookMap[parseInt(b.id)] = `${b.title} (${b.author})`;
            });
            newBookListItems.forEach(bli => {
                bli.bookName = bookMap[parseInt(bli.book_id)] || `Unknown book (${bli.book_id})`;
            });
            const dateToBliMap = {};
            newBookListItems.forEach(bli => {
                const [year, month, date] = getymd(new Date(bli.date_completed));
                const dateKey = `${year}-${month}-${date}`;
                if(!dateToBliMap[dateKey]) dateToBliMap[dateKey] = [];
                dateToBliMap[dateKey].push(bli.bookName);
            });

            newAppointments.forEach(a => {
                a.centerName = centerMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;

                a.assignedInstructors = appointmentAssignmentMap[parseInt(a.id)] || [];
                a.assignedInstructorNames = '';
                a.assignedInstructors.forEach(ai => {
                    const instructorName = (instructorMap[ai.instructor] || `Unknown admin user (ID: ${ai.instructor})`) + ', ';
                    a.assignedInstructorNames += (instructorName);
                })
                const instructorNames = a.assignedInstructorNames;
                if(instructorNames) a.assignedInstructorNames = instructorNames.slice(0, instructorNames.length - 2);

                const [year, month, date] = getymd(new Date(a.date_time));
                const dateKey = `${year}-${month}-${date}`;
                a.booksCompleted = dateToBliMap[dateKey] || [];
            });

            if(selectedReportType.value === 'standard'){
                if(!newAppointmentsData.appointments?.length){
                    setStatus('No appointments were found.');
                    if(mounted.current){
                        setSubmitting(false);
                        setLoading(false);
                    }
                    return;
                }
                await generateStandard(values, newAppointments, logoData,
                    values.quickDownload, values.quickDownloadDate);
            }
    
            if(mounted.current){
                setLoading(false);
            }
        })();
    }, [loading, setLoading, formRef, drsValid, instructors, centers, logoData, fetchAppointmentsDaterangeStudent,
        fetchBookListItemsStudentDaterangeCompleted, fetchBooksAll]);
    useEffect(() => {
        async function init(){
            setLoading(true);
            const centersRes = await fetchRpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const instructorsRes = await fetchAdminUsersAll();
            const logoRes = await fetchGraphicND({ fileName: 'RP Long Light@4x.png' });
            const isApiError = checkResponses(centersRes, studentsRes, instructorsRes, logoRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }
            
            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newInstructors = instructorsRes.data || [];
            
            const newStudentOptions = newStudents.filter(s => {
                return parseInt(s.rp_active) === 1 && parseInt(s.is_rp_student) === 1 && parseInt(s.is_lead) === 0;
            }).map(s => {
                return {
                    value: s.user_id,
                    label: `${s.first_name} ${s.last_name}`,
                    obj: s
                };
            });

            if(mounted.current){
                setStudentOptions(newStudentOptions);
                setCenters(newCenters);
                setInstructors(newInstructors);
                setLogoData(logoRes.data || null);
                setLoading(false);
                setHasLoaded(true);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            {hasLoaded &&
                <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        selectedStudent: { value: -1, label: 'Please select...' },
                        selectedReportType: { value: -1, label: 'Please select...' },
                        quickDownload: false,
                        quickDownloadDate: formatDateApi(new Date())
                    }}
                    innerRef={formRef}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <>
                            <h3>Report Generator</h3>
                            <br/>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <DateRangeSelector
                                        id="report-generator-manager-drs"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="report-generator-selectedStudent"
                                        name="selectedStudent"
                                        label="Student"
                                        value={formik.values.selectedStudent}
                                        onChange={formik.handleChange}
                                        options={studentOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                    <SelectSingle
                                        id="report-generator-selectedReportType"
                                        name="selectedReportType"
                                        label="Report Type"
                                        value={formik.values.selectedReportType}
                                        onChange={formik.handleChange}
                                        options={reportTypeOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 self-start">
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={formik.handleSubmit}
                                        disabled={formik.values.selectedStudent.value === -1 ||
                                            formik.values.selectedReportType.value === -1}
                                    >
                                        Generate Report
                                    </Button>
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2">
                                    <TooltipWrapper
                                        tooltipText="Download report without opening a popup window"
                                    >
                                        <Check
                                            id="report-generator-quickDownload"
                                            name="quickDownload"
                                            label={
                                                <div className="mp-text-mpLBlue">
                                                    Quick Download
                                                </div>
                                            }
                                            color="mpDBlue"
                                            checked={formik.values.quickDownload}
                                            onChange={formik.handleChange}
                                        />
                                    </TooltipWrapper>
                                </div>
                                {formik.values.quickDownload ? 
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <DatePicker
                                            id="report-generator-quickDownloadDate"
                                            name="quickDownloadDate"
                                            label="File Name Date"
                                            value={formik.values.quickDownloadDate}
                                            onChange={formik.handleChange}
                                            disabled={loading}
                                        />
                                    </div> : null
                                }
                            </div>

                            
                            <br/>
                            
                            {formik.status && !formik.isSubmitting ? 
                                <div className="text-mpLRed mr-8">
                                    {formik.status}
                                </div> : null
                            }
                            { apiError ? <div className="text-mpLRed">{apiError}</div>
                                : null }
                        </>
                    )}
                </Formik>
                </div>
            }
            <Socket
                refreshData={() => null}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchRpCentersAll,
    fetchAdminUsersAll,
    fetchStudentsAll,
    fetchAppointmentsDaterangeStudent,
    fetchBookListItemsStudentDaterangeCompleted,
    fetchBooksAll
})(ReportGenerator);