import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { getColumnsContracts } from '../table/getColumns';
import { Table } from '../../../../../components/table';
import { ContractsModal } from '../../../../../components/modal';

const defaultSorted = [{ dataField: 'studentName', order: 'asc' }];

function Component(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [modalMode, setModalMode] = useState(null);
    const [selectedContract, setSelectedContract] = useState(null);

    const { contracts, refreshData } = props;

    const handleShowModal = useCallback((mode, contract) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedContract(contract);
        }
    }, [mounted])
    const onSubmitCallback = useCallback((changes = false) =>{
        if(mounted.current){
            setModalMode(null);
            setSelectedContract(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    const columns = useMemo(() => getColumnsContracts(handleShowModal), [handleShowModal]);

    return (
        <div>
            {modalMode && 
                <ContractsModal
                    mode={modalMode}
                    selectedContract={selectedContract}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="contracts"
                data={contracts}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No contracts were found"
            />
        </div>
    );
}

export default connect(null, {

})(Component);