import React from 'react';
import { formatDate } from '../../../../../components/functions';

import { Button, Label, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper } from '../../../../../components/display';

const noneTag = <div className="text-mpOrange">None</div>;
export function getColumnsContracts(handleShowModal){
    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
            formatter: (row) => {
                const studentName = row.studentName;
                if(row.isPrimary){
                    const check = (
                        <TooltipWrapper
                            tooltipText={`This is ${studentName}'s primary contract`}
                        >
                            <i className="fa fa-check text-mpGreen"/>
                        </TooltipWrapper>
                    )
                    return (
                        <div className="flex flex-row">
                            {studentName}&nbsp;{check}
                        </div>
                    );
                }
                else return studentName;
            }
        },
        {
            dataField: 'type',
            text: 'Contract Type',
            sort: true,
            formatter: (row) => {
                const type = row.type;
                switch(type){
                    case 'Writing':
                        return (
                            <Label color="mpGreen">
                                Writing
                            </Label>
                        );
                    case 'One-on-One':
                        return (
                            <Label color="mpPurple">
                                One-on-One
                            </Label>
                        );
                    case 'Reading':
                        return (
                            <Label color="mpOrange">
                                Reading
                            </Label>
                        );
                    default:
                        return (
                            <Label color="mpLGrey">
                                {type}
                            </Label>
                        );
                }
            }
        },
        {
            dataField: 'start_date',
            text: 'Contract Start',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;
            },
            formatter: (row) => formatDate(row.start_date)
        },
        {
            dataField: 'end_date',
            text: 'Contract End',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                
                const aSortBy = parseInt(rowA.expires) === 0 ? 0 : aDate;
                const bSortBy = parseInt(rowB.expires) === 0 ? 0 : bDate;
                return (aSortBy - bSortBy) * swap;
            },
            formatter: (row) => {
                if(parseInt(row.expires) === 0){
                    return(
                        <Label color="mpYellow">
                            Never
                        </Label>
                    )
                } else return formatDate(row.end_date);
            }
        },
        { 
            dataField: 'payment_per_cycle',
            text: 'Payment Per Cycle',
            sort: true,
            formatter: (row) => `$${row.payment_per_cycle}`
        },
        {
            dataField: 'paymentStatus',
            text: 'Payment Status',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                // Sort 'extra' contracts last (asc) or first (desc)
                const swap = order === 'asc' ? 1 : -1;
                const aIsExtra = rowA.type === 'Extra' ? 1 : 0;
                const bIsExtra = rowB.type === 'Extra' ? 1 : 0;
                if(aIsExtra || bIsExtra) return (aIsExtra - bIsExtra) * swap;

                // Negative is underpaid. Sort these first (asc) or last (desc)
                const aBalanceDiff = rowA.balancePaid - rowA.balanceOwed;
                const bBalanceDiff = rowB.balancePaid - rowB.balanceOwed;
                return (aBalanceDiff - bBalanceDiff) * swap;
            },
            formatter: (row) => {
                const balanceDiff = row.balancePaid - row.balanceOwed;
                let paymentLabel = null;
                if(row.type === 'Extra') {
                    paymentLabel = (
                        <Label color="mpPurple">
                            NA - extra
                        </Label>
                    );
                } else if(balanceDiff < 0){
                    paymentLabel = (
                        <Label color="mpLRed">
                            Under (${Math.abs(balanceDiff)})
                        </Label>
                    );
                } else if(balanceDiff > 0){
                    paymentLabel = (
                        <Label color="mpYellow">
                            Over (${Math.abs(balanceDiff)})
                        </Label>
                    );
                } else {
                    paymentLabel = (
                        <Label color="mpGreen">
                            Matching
                        </Label>
                    );
                }
                const expectedText = row.type === 'Extra' ? <div>Expected: NA</div> : <div>Expected: ${row.balanceOwed}</div>;
                const paidText = <div>Paid: ${row.balancePaid}</div>;
                const diffText = row.balanceOwed === row.balancePaid ? null : <div>Difference: ${row.balanceOwed - row.balancePaid}</div>
                let index = 0;
                return(
                    <TooltipWrapper
                        tooltipText={
                            <div>
                                {expectedText}
                                {paidText}
                                {diffText}
                                { row.relevantPayments?.length !== 0 ? (
                                        <>
                                            <br/>
                                            Summary:
                                            {row.relevantPayments.map(rp => {
                                                return (
                                                    <div key={`c-${row.id}-p-${rp.id}-${index++}`}>
                                                        {formatDate(rp.date)}: ${rp.amount}
                                                    </div>
                                                )
                                            })}
                                        </>
                                    ) : null
                                }
                            </div>
                        }
                    >
                        {paymentLabel}
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'notes',
            text: 'Notes',
            headerStyle: () => ({ width: "30%" }),
            formatter: (row) => row.notes || noneTag
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                return (b - a) * swap;
            },
            formatter: (row) => {
                if(parseInt(row.active) === 1) return <Label color="mpGreen">Active</Label>;
                else return <Label color="mpLRed">Inactive</Label>;
            }
        }, 
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2 whitespace-nobreak">
                        <TooltipWrapper
                            tooltipText={`Edit contract`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete contract`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}

export function getColumnsPayments(handleShowPaymentModal, handleShowContractModal){
    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
        },
        {
            dataField: 'date',
            text: 'Date Paid',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                
                return (aDate - bDate) * swap;
            },
            formatter: (row) => formatDate(row.studentName)
        }, {
            dataField: 'method',
            text: 'Method',
            sort: true,
            formatter: (row) => {
                const method = row.method;
                if(method === 'Credit'){
                    return (
                        <Label color="mpOrange">
                            Credit
                        </Label>
                    );
                } else if(method === 'Venmo') {
                    return (
                        <Label color="mpLBlue">
                            Venmo
                        </Label>
                    );
                } else if(method === 'Zelle') {
                    return (
                        <Label color="mpPurple">
                            Zelle
                        </Label>
                    );
                } else {
                    return(
                        <Label color="mpLGrey">
                            {method}
                        </Label>
                    );
                }
            }
        }, {
            dataField: 'amount',
            text: 'Amount',
            sort: true,
            formatter: (row) => `$${row.amount}`
        }, {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => row.notes || noneTag
        }, {
            dataField: 'contractDetails',
            text: 'Contract',
            formatter: (row) => {
                const contractDetails = row.contractDetails;
                if(!contractDetails.id) return noneTag; // No relevant contract exists (only happens if contract was deleted)
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div>
                                <div>Type: {contractDetails.type}</div>
                                <div>Start: {formatDate(contractDetails.start_date)}</div>
                                <div>End: {parseInt(contractDetails.expires) ? formatDate(contractDetails.end_date) : 'Never'}</div>
                                <div>Payment Freq.: {contractDetails.payment_frequency}</div>
                                <div>Amount Per Payment: ${contractDetails.amount_per_payment}</div>
                                <div>Notes: {contractDetails.notes}</div>
                            </div>
                        }
                    >
                        <Button
                            color="lte-mpLBlue"
                            onClick={() => handleShowContractModal('edit', contractDetails)}
                        >
                            <SVGIcon fileName="contract"/>
                        </Button>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'actions',
            text:'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2 whitespace-nobreak">
                        <TooltipWrapper
                            tooltipText={`Edit payment`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowPaymentModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete payment`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLRed"
                                onClick={() => handleShowPaymentModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}

export function getColumnsWarnings(handleShowModalSpecialCreate){
    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true
        }, {
            dataField: 'warningType',
            text: 'Warning Type',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? 1 : -1;
                
                let rowAPriority = 0;
                if(rowA.lowMinutes) rowAPriority += 4;
                if(rowA.endingSoon) rowAPriority += 3;
                if(rowA.contractExpired) rowAPriority += 2;
                if(rowA.noContract) rowAPriority += 1;

                let rowBPriority = 0;
                if(rowB.lowMinutes) rowBPriority += 4;
                if(rowB.endingSoon) rowBPriority += 3;
                if(rowB.contractExpired) rowBPriority += 2;
                if(rowB.noContract) rowBPriority += 1;
                return swap * (rowAPriority - rowBPriority);
            },
            formatter: (row) => {
                let noContract = null;
                let contractExpired = null;
                let lowMinutes = null;
                let endingSoon = null;
                if(row.noContract){
                    noContract = (
                        <Label color="mpLRed">
                            No Contract
                        </Label>
                    );
                }
                if(row.contractExpired){
                    contractExpired = (
                        <Label color="mpYellow">
                            Expired Contract
                        </Label>
                    );
                }
                if(row.endingSoon){
                    endingSoon = (
                        <Label color="mpLBlue">
                            Ending Soon
                        </Label>
                    );
                }
                if(row.lowMinutes){
                    lowMinutes = (
                        <Label color="mpOrange">
                            Low hours
                        </Label>
                    );
                }
                return (
                    <>
                        {noContract}
                        {contractExpired}
                        {endingSoon}
                        {lowMinutes}
                    </>
                );
            }
        }, {
            dataField: 'message',
            text: 'Message',
            formatter: (row) => {
                let noContract = null;
                let contractExpired = null;
                let lowMinutes = null;
                let endingSoon = null;
                const contract = row.currentContract || {};
                if(row.noContract){
                    noContract = <div>Student is currently active but does not have an assigned contract.</div>;
                }
                if(row.contractExpired){
                    contractExpired = (
                        <div>
                            Student is currently active but has an expired contract. Contract expired on {formatDate(contract.end_date)}.
                        </div>
                    )
                }
                if(row.endingSoon){
                    endingSoon = (
                        <div>
                            Contract is ending within the next month on ${formatDate(contract.end_date)}.
                        </div>
                    );
                }
                if(row.lowMinutes){
                    lowMinutes = (
                        <div>
                            {(parseInt(contract.minutes_remaining) / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })} hour(s) remaining.
                        </div>
                    );
                }

                return (
                    <>
                        {noContract}
                        {contractExpired}
                        {endingSoon}
                        {lowMinutes}
                    </>
                );
            },
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? 1 : -1;
                
                return swap * (rowA.messageSortIndex - rowB.messageSortIndex)
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <TooltipWrapper
                        tooltipText={`Add contract for ${row.first_name} ${row.last_name}`}
                    >
                        <Button
                            variant="lte-mpLBlue"
                            onClick={() => handleShowModalSpecialCreate('create-special', row)}
                        >
                            + New Contract
                        </Button>
                    </TooltipWrapper>
                );
            }
        },
    ];
}