import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import BookListBodyFooter from './BookListBF';
import { checkResponse } from '../../form';
import { formatDateApi, formatDate } from '../../functions';

import {
    fetchBookListsId,
    fetchCycleGroupsIds,
    fetchBookListTemplatesAll,
    fetchBooksAll,
    fetchBookListsStudents,
    fetchStudentsUserIds,
    createBookList,
    updateBookList,
    deleteBookList,
} from '../../../actions';

function BookListModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [attemptingClose, setAttemptingClose] = useState(false);
    
    const [bookList, setBookList] = useState({});
    const [student, setStudent] = useState({});
    const [selectedGroupOption, setSelectedGroupOption] = useState({});
    const [bookListItems, setBookListItems] = useState([]);
    const [books, setBooks] = useState([]);
    const [bookOptions, setBookOptions] = useState([]);
    const [duplicateBookMap, setDuplicateBookMap] = useState({});
    const [templateOptions, setTemplateOptions] = useState([]);

    const { mode, selectedBookList, selectedGroup, selectedStudent, onSubmitCallback, fetchBookListsId,
        fetchCycleGroupsIds, fetchBookListTemplatesAll, fetchBooksAll,
        fetchBookListsStudents, fetchStudentsUserIds, createBookList, updateBookList, deleteBookList } = props;

    useEffect(() => {
        async function init(){           
            const groupsRes = await fetchCycleGroupsIds({ ids: [selectedGroup.value] });
            const newGroup = groupsRes.data?.groups?.[0] || {};
            const newGroupOption = newGroup.id ?
                { value: newGroup.id, label: newGroup.name }
                : { value: -1, label: `Unknown group (ID ${selectedGroup.value})` };
            const newGroupMembers = (groupsRes.data?.groupMembers || []).filter(m => {
                return parseInt(m.group_id) === parseInt(newGroup.id);
            }).map(m => {
                return m.student;
            });

            const booksRes = await fetchBooksAll();
            const newBooks = booksRes.data || [];
            const bookMap = {};
            newBooks.forEach(b => bookMap[b.id] = b);

            const templatesRes = await fetchBookListTemplatesAll();
            const newTemplates = (templatesRes.data?.templates || []).filter(t => parseInt(t.active) === 1);
            const newTemplateItems = templatesRes.data?.templateItems || [];

            const exceptedBooks = [];
            const bookItemMap = {};
            newTemplateItems.forEach(ti => {
                const templateId = parseInt(ti.book_list_template_id);
                const bookId = parseInt(ti.book_id);
                exceptedBooks.push(bookId);

                ti.bookObj = bookMap[bookId] || {};
                if(!bookItemMap[templateId]) bookItemMap[templateId] = [];
                bookItemMap[templateId].push(ti);
            });
            newTemplates.forEach(t => {
                t.templateItems = bookItemMap[parseInt(t.id)] || [];
            });
            const newTemplateOptions = newTemplates.map(t => {
                return { value: t.id, label: `${t.name} (Created ${formatDate(t.date_created)})`, obj: t };
            });

        // Will use this to determine if a book has already been assigned to a student, and if so, when.
            const bookListRes = await fetchBookListsStudents({ studentIds: [...newGroupMembers] });
            const studentsRes = await fetchStudentsUserIds({ userIds: [...newGroupMembers] });
            const newBookLists = bookListRes.data?.bookLists || [];
            const newBookListItems = bookListRes.data?.bookListItems || [];
            const newStudents = studentsRes.data || [];
            const currentStudent = newStudents.find(s => s.user_id === selectedStudent.value) || {};
            if(!Object.keys(currentStudent).length && mounted.current){
                setApiError('Unable to load student info. Please close this form and try again.');
                return;
            }

            const studentMap = {};
            newStudents.forEach(s => studentMap[s.user_id] = `${s.first_name} ${s.last_name}`);

            const bookListToStudentMap = {};
            newBookLists.forEach(bl => {
                const bookListId = parseInt(bl.id);
                if(bookListId === parseInt(selectedBookList?.value)) return; // Don't want to report duplicates for for items on the currently selected book list!

                bookListToStudentMap[bookListId] = studentMap[bl.student];
            });

            const newDuplicateBookMap = {};
            newBookListItems.forEach(bli => {
                const bookListId = parseInt(bli.book_list_id);
                const bookId = parseInt(bli.book_id);

                const relevantStudentName = bookListToStudentMap[bookListId];
                if(!relevantStudentName) return;
                const bookListItemMatch = {
                    student: relevantStudentName,
                    dateAssigned: bli.date_assigned,
                    dateCompleted: bli.date_completed
                };

                if(!newDuplicateBookMap[bookId]) newDuplicateBookMap[bookId] = [];
                newDuplicateBookMap[bookId].push(bookListItemMatch);
            });
        // end section

            if(mode !== 'create'){
                const bookListsRes = await fetchBookListsId({ id: selectedBookList.id });

                const newBookList = bookListsRes.data?.bookLists?.[0] || {};
                const newBookListItems = bookListsRes.data?.bookListItems || [];

                const formattedBookListItems = newBookListItems.sort((a, b) => {
                    return parseInt(a.sort_order) - parseInt(b.sort_order);
                }).map(bli => {
                    exceptedBooks.push(bli.book_id);
                    const relevantBook = bookMap[parseInt(bli.book_id)] || {};
                    return {
                        bookId: parseInt(bli.book_id),
                        title: relevantBook.title,
                        author: relevantBook.author,
                        dateAssigned: bli.date_assigned ? formatDateApi(bli.date_assigned) : '',
                        dateCompleted: bli.date_completed ? formatDateApi(bli.date_completed) : '',
                        sortOrder: parseInt(bli.sort_order)
                    };
                });

                if(mounted.current){
                    setBookList(newBookList);
                    setBookListItems(formattedBookListItems);
                }
            }

            const filteredBooks = newBooks.filter(b => {
                const isValid = parseInt(b.active) === 1 || exceptedBooks.includes(b.id);
                return isValid
            });

            const newBookOptions = [ { value: -1, label: 'None Selected' },
                ...filteredBooks.map(b => {
                    const bookId = parseInt(b.id);
                    const bookOption = ({ value: bookId, label: `${b.title} (${b.author})`, obj: b });
                    return bookOption;
                }
            )];
            
            if(mounted.current){
                setStudent(currentStudent);
                setSelectedGroupOption(newGroupOption);
                setBooks(filteredBooks);
                setBookOptions(newBookOptions);
                setTemplateOptions(newTemplateOptions);
                setDuplicateBookMap(newDuplicateBookMap);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes, force = false) => {
        if(!attemptingClose && !force && mode !== 'delete'){
            setAttemptingClose(true);
            return;
        }
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, setAttemptingClose, mode]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const { name, notes, active, bookListItems } = values;
                const bookListParams = {
                    name,
                    studentId: student.user_id,
                    notes,
                    active: active ? 1 : 0
                };
                const bookListItemParams = [];

                bookListItems.forEach(bli => {
                    bookListItemParams.push({
                        bookId: bli.bookId,
                        dateAssigned: bli.dateAssigned || null,
                        dateCompleted: bli.dateCompleted || null,
                        sortOrder: bli.sortOrder
                    });
                })
                
                if(mode === 'create'){
                    response = await createBookList({
                        bookList: bookListParams,
                        bookListItems: bookListItemParams
                    });
                } else if(mode === 'edit') {
                    bookListParams.id = bookList.id;
                    response = await updateBookList({
                        bookList: bookListParams,
                        bookListItems: bookListItemParams
                    });
                }
            } else if(mode === 'delete') {
                response = await deleteBookList({ id: bookList.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [mode, handleClose, student, bookList, createBookList, updateBookList, deleteBookList]);

    return (
        <Modal className="w-2/3" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Book List Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <BookListBodyFooter
                    mode={mode}
                    apiError={apiError}
                    selectedGroupOption={selectedGroupOption}
                    selectedStudent={student}
                    bookList={bookList}
                    bookListItems={bookListItems}
                    templateOptions={templateOptions}
                    duplicateBookMap={duplicateBookMap}
                    books={books}
                    bookOptions={bookOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchBookListsId,
    fetchCycleGroupsIds,
    fetchBookListTemplatesAll,
    fetchBooksAll,
    fetchBookListsStudents,
    fetchStudentsUserIds,
    createBookList,
    updateBookList,
    deleteBookList
})(BookListModal);