import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import { checkResponse } from '../../form';
import { formatDate } from '../../functions';
import PaymentsBodyFooter from './PaymentsBF';

import {
    fetchStudentsActive,
    fetchContractsIds,
    fetchPaymentsIds,
    createPayment,
    updatePayment,
    deletePayment
} from '../../../actions';

function PaymentsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // Default null to match 'undefined' from not passing selectedAppointment to begin with
    const [students, setStudents] = useState([]);
    const [payment, setPayment] = useState({});
    const [contractOption, setContractOption] = useState(null);

    const { mode, selectedPayment, onSubmitCallback, fetchPaymentsIds, fetchContractsIds, fetchStudentsActive,
        createPayment, updatePayment, deletePayment } = props;

    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsActive();
            const newStudents = studentsRes.data || [];
            
            if(mode !== 'create'){
                const paymentRes = await fetchPaymentsIds({ ids: [selectedPayment.id] });
                const contractRes = await fetchContractsIds({ ids: [selectedPayment.contract] });
                const newPayment = paymentRes.data?.[0] || {};
                const newContract = contractRes.data?.[0] || {};

                const relStudent = newStudents.find(s => s.user_id === newContract.student) || {};
                const studentName = `${relStudent.first_name} ${relStudent.last_name}`
                    || `Unknown student (UID: ${newContract.student})`;
                const newContractOption = {
                    value: parseInt(newContract.id),
                    label: `*${formatDate(newContract.start_date)} to ${formatDate(newContract.end_date)}: ${studentName}`
                };

                if(mounted.current){
                    setPayment(newPayment);
                    setContractOption(newContractOption);
                }
            }

            if(mounted.current){
                setStudents(newStudents);
                setLoaded(true);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current) setSubmitting(true);

            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const paymentObject = {
                    date: values.date,
                    contract: values.contract.value,
                    method: values.method.value,
                    amount: values.amount,
                    notes: values.notes
                };

                if(mode === 'create'){
                    response = await createPayment(paymentObject);
                } else if(mode === 'edit') {
                    paymentObject.id = payment.id;
                    response = await updatePayment(paymentObject);
                }
            } else if(mode === 'delete') {
                response = await deletePayment({ id: payment.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, payment, createPayment, updatePayment, deletePayment]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Payment Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <PaymentsBodyFooter
                    mode={mode}
                    students={students}
                    contractOption={contractOption}
                    selectedPayment={payment}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsActive,
    fetchContractsIds,
    fetchPaymentsIds,
    createPayment,
    updatePayment,
    deletePayment
})(PaymentsModal);