import { getymd } from '.';

export function processRelInfo(relInfo, centers, employees, mapsGiven = []){
    const newRelInfo = {...relInfo};

    let employeeMap = {};
    if(mapsGiven.includes('employees')) employeeMap = employees;
    else employees.forEach(i => employeeMap[i.id] = `${i.first_name} ${i.last_name}`);
    
    let centerMap = {};
    if(mapsGiven.includes('centers')) centerMap = centers;
    else centers.forEach(c => centerMap[c.id] = c.name);

    if(newRelInfo.assignments && newRelInfo.appointments){
        const assignmentMap = {};
        newRelInfo.assignments.forEach(a => {
            const aptId = a.appointment_id;
            if(!assignmentMap[aptId]) assignmentMap[aptId] = [];
            assignmentMap[aptId].push(a.instructor);
        });

        newRelInfo.appointments.forEach(a => {
            a.centerName = centerMap[a.center] || `Unknown center (ID: ${a.center})`;
            a.instructorNames = '';

            const assignedInstructors = assignmentMap[parseInt(a.id)] || [];
            for(let instructor of assignedInstructors){
                a.instructorNames += (employeeMap[instructor] || `Unknown author (ID: ${instructor})`) + ', ';
            }
            a.instructorNames = a.instructorNames.slice(0, a.instructorNames.length - 2);
        });
    }
    newRelInfo.combinedNotes = [];
    if(newRelInfo.appointments){
        const notesMap = {};
        
        newRelInfo.appointments.forEach(a => {
            if(!a.session_notes.trim() && !a.internal_notes.trim()) return;
            // Get notes
            const [year, month, date] = getymd(a.date_time);
            const dateKey = `${year}_${month}_${date}`;
            if(!notesMap[dateKey]) notesMap[dateKey] = { sessionNotes: ``, internalNotes: ``, reports: `` }
            notesMap[dateKey].sessionNotes += a.session_notes.trim();
            notesMap[dateKey].internalNotes += a.internal_notes.trim();
    
            // Add instructor names
            if(notesMap[dateKey].internalNotes){
                notesMap[dateKey].internalNotes += ` (${a.instructorNames || 'No instructors assigned'})`;
            } else if(notesMap[dateKey].sessionNotes){
                notesMap[dateKey].sessionNotes += ` (${a.instructorNames || 'No instructors assigned'})`;
            }
        });
    
        let keyIndex = 0;
        const combinedNotes = Object.keys(notesMap).map(dateKey => {
            const notesGroup = notesMap[dateKey];
            const [year, month, date] = dateKey.split('_');
            notesGroup.date = new Date(year, parseInt(month) - 1, date);
            notesGroup.key = `rel-info-combined-notes-${keyIndex++}`;
            return notesGroup;
        }).sort((a, b) => b.date - a.date);
    
        newRelInfo.combinedNotes = combinedNotes || [];
    };
    if(newRelInfo.flags){
        newRelInfo.flags.forEach(f => {
            f.centerName = centerMap[f.center] || `Unknown center (ID: ${f.center})`;
            f.createdByName = employeeMap[f.created_by] || `Unknown user (ID: ${f.created_by})`;
            if(f.updated_by) f.updatedByName = employeeMap[f.updated_by] || `Unknown user (ID: ${f.updated_by})`;
        });
    }
    if(newRelInfo.student){
        const centerId = newRelInfo.student.mp_primary_center;
        newRelInfo.student.primaryCenterName = centerMap[centerId] || `Unknown center (ID: ${centerId})`;
    }

    return newRelInfo;
}