import {
    drawText,
    drawTextMulti,
    drawVSpace
} from './index';

function doesIntExist(value){
    return (value || value === 0) && !isNaN(value);
}
export function drawItems(reportPdf, currentPage, itemsToDraw, defaultFontObj, coords, retVal = 0){
    ////////// 0 - Check that necessary arguments are passed
    if(!reportPdf || !defaultFontObj || !coords){
        console.error(`drawItems function call is missing arguments ${!reportPdf && 'reportPdf'}${!defaultFontObj && 'defaultFontObj'}${!coords && 'coords'}`);
        return;
    }
    // The only value here that will change is yPos. The rest are static
    let { xMin, xMax, yPos, yMin, yMax } = coords;
    const isXMin = doesIntExist(xMin);
    const isXMax = doesIntExist(xMax);
    const isYPos = doesIntExist(yPos);
    const isYMin = doesIntExist(yMin);
    const isYMax = doesIntExist(yMax);
    if(!isXMax || !isXMax || !isYPos || !isYMin || !isYMax){
        console.error(`drawItems function call is missing coords arguments ${!isXMin && 'xMin'}${!isXMax && 'xMax'}${!isYPos && 'yPos'}${!isYMin && 'yMin'}${!isYMax && 'yMax'}`);
        return;
    }

    ////////// 1 - Append heights
    for(let item of itemsToDraw){
        // Get heights
        item.coords = item.coords || { ...coords };
        if(item.shiftYPos !== false) item.shiftYPos = true;
        if(item.type === 'text') drawText(currentPage, item, defaultFontObj, true);
        else if(item.type === 'textMulti') drawTextMulti(currentPage, item, defaultFontObj, true);
        else if(item.type === 'vspace') drawVSpace(item, true);
    }

    ////////// 2 - Create appended items (new array since items may need to be processed in groups)
    const yPosInitial = yPos;
    let newItemList = []; // Stores page blocks before inserting into main array
    let yFalseHoldingSet = []; // Holds groups of items with shiftYPos = false until the largest height is found
    // Handles the edge case where shiftYPos = false and item is at the end. Height may be ignored when trying to draw end of page
    // If consecutive items have shiftYPos false at the end of the array, need to find the largest height value
    let yShiftFalseLargestHeight = 0;
    // Ensures yFalseHoldingSet gets cleared if last item has shiftYPos = false. Will not render since it has no type property
    itemsToDraw.push({ height: 0, shiftYPos: true }); 
    for(let item of itemsToDraw){
        const height = item.height;

        // See comment above variable declaration
        if(!item.shiftYPos){
            yShiftFalseLargestHeight = Math.max(yShiftFalseLargestHeight, height);
            yFalseHoldingSet.push(item);
        }
        // This will trigger once we reach the end of a series of items with shiftYPos = false
        if(item.shiftYPos && yFalseHoldingSet.length){
            for(let yItem of yFalseHoldingSet) yItem.coords = { xMin, xMax, yPos };

            // Add new items to render
            yPos -= yShiftFalseLargestHeight;
            newItemList.push(...yFalseHoldingSet);
            yFalseHoldingSet = [];
            yShiftFalseLargestHeight = 0;
        }
        
        if(item.shiftYPos){
            // Add new item to render
            item.coords = { xMin, xMax, yPos };
            if(item.type) newItemList.push(item);
            
            if(item.shiftYPos) yPos -= height;
        }
    }

    ////////// 3 - If a new page is needed, adjust the height position of each item
    if(yPos < yMin){
        currentPage = reportPdf.addPage();
        const totalHeight = yPosInitial - yPos;
        yPos = yMax - totalHeight;

        const yDiff = yMax - yPosInitial;
        for(let item of newItemList){
            item.coords.yPos += yDiff;
        }
    }

    ////////// 4 - Draw items
    for(let item of newItemList){
        if(item.type === 'text') drawText(currentPage, item, defaultFontObj);
        else if(item.type === 'textMulti') drawTextMulti(currentPage, item, defaultFontObj);
        else if(item.type === 'vspace') drawVSpace(item);
    }

    coords.yPos = yPos;
    if(retVal) return yPos;
    return { currentPage, yPos };
}