import { basicPath } from '../basicPath';

export const fetchAutoScheduleItemsAll = (params) => async (dispatch) => {
    const path = '/rp/auto-schedule-items/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAutoScheduleItemsCenter = (params) => async (dispatch) => {
    const path = '/rp/auto-schedule-items/center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAutoScheduleItemsIds = (params) => async (dispatch) => {
    const path = '/rp/auto-schedule-items/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createAutoScheduleItem = (params) => async (dispatch) => {
    const path = '/rp/auto-schedule-items';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateAutoScheduleItem = (params) => async (dispatch) => {
    const path = '/rp/auto-schedule-items';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAutoScheduleItem = (params) => async (dispatch) => {
    const path = '/rp/auto-schedule-items';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}