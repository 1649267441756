import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { formatDate, formatTime } from "../../../../../components/functions";
import { TooltipWrapper, tableColors } from '../../../../../components/display';

export function getColumns(handleShowModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    const noneTag = <div className="text-mpOrange">None</div>;
    return [
        {
            dataField: 'name',
            text: 'Group Name',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                if(a < b) return -1 * swap;
                else if(a > b) return 1 * swap;
                else return 0;
            }
        },
        {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            formatter: (row) =>{
                const centerName = row.centerName;
                let color = '';
                if(colorMap[centerName]) color = colorMap[centerName];
                else {
                    colorMap[centerName] = colors[(colorIndex++) % 5];
                    color = colorMap[centerName];
                }
                return (
                    <Label color={color}>
                        {centerName}
                    </Label>
                );
            }
        },
        {
            dataField: 'memberNames',
            text: 'Students',
        },
        {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => row.notes || noneTag
        },
        {
            dataField: 'schedulingInfo',
            text: 'Scheduling Info',
            formatter: (row) => {
                let dowString = '';
                if(parseInt(row.sundays) === 1) dowString += 'Su, ';
                if(parseInt(row.mondays) === 1) dowString += 'M, ';
                if(parseInt(row.tuesdays) === 1) dowString += 'Tu, ';
                if(parseInt(row.wednesdays) === 1) dowString += 'W, ';
                if(parseInt(row.thursdays) === 1) dowString += 'Th, ';
                if(parseInt(row.fridays) === 1) dowString += 'F, ';
                if(parseInt(row.saturday) === 1) dowString += 'Sa, ';
                if(dowString) dowString = dowString.slice(0, dowString.length - 2);
                return(
                    <div className="col no-pad">
                        <div>Dates: {formatDate(row.start_date)} to {formatDate(row.end_date)}</div>
                        <div>Time: {formatTime(row.time)}</div>
                        <div>Days: {dowString}</div>
                    </div>
                );
            }
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                return (b - a) * swap;
            },
            formatter: (row) => {
                if(parseInt(row.active) === 1) return <Label color="mpGreen">Active</Label>;
                else return <Label color="mpLRed">Inactive</Label>;
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2 whitespace-nowrap">
                        <TooltipWrapper
                            tooltipText={`Edit group`}
                        >
                             <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete group`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}