import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import StudentsBodyFooter from './StudentsBF';
import { getContractOption } from './helpers';
import { checkResponse } from '../../form';

import {
    fetchStudentsUserIds,
    fetchLeadsRpPermissions,
    fetchMembersRpPermissions,
    fetchContractsStudentIds,
    updateStudentAttributes,
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function StudentForm(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [attemptingClose, setAttemptingClose] = useState(false);
    const [closureSubmitting, setClosureSubmitting] = useState(false); // Prevents form from closing while submitting (only forms with accidental closure prevention)
    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [student, setStudent] = useState({});
    const [parentOptions, setParentOptions] = useState([]);
    const [contractOptions, setContractOptions] = useState([]);

    const { selectedStudent, onSubmitCallback, fetchStudentsUserIds, fetchLeadsRpPermissions, fetchMembersRpPermissions,
        fetchContractsStudentIds, updateStudentAttributes } = props;

    useEffect(() => {
        async function init(){
            const studentRes = await fetchStudentsUserIds({ userIds: [selectedStudent.user_id] });
            const parentsRes = await fetchMembersRpPermissions({ permissions: 'Parent' });
            const leadsRes = await fetchLeadsRpPermissions({ permissions: 'Parent' });
            const contractsRes = await fetchContractsStudentIds({ studentIds: [selectedStudent.user_id] });
            
            const newStudent = studentRes.data?.[0] || {};
            const newParents = parentsRes.data || [];
            const newLeads = leadsRes.data || [];
            const newContracts = contractsRes.data || [];

            const newParentsFiltered = newParents.filter(p => parseInt(p.rp_active) === 1);
            const newLeadsFiltered = newLeads.filter(l => parseInt(l.rp_active) === 1);

            const newParentOptions = [{ value: -1, label: 'None selected' },
                ...newLeadsFiltered.map(p => ({ value: p.id, label: `${p.first_name} ${p.last_name} (Lead)` })),
                ...newParentsFiltered.map(p => ({ value: p.id, label: `${p.first_name} ${p.last_name}` }))];
            const newContractOptions = [{ value: -1, label: 'None selected' },
                ...newContracts.map(c => getContractOption(c))];

            if(mounted.current){
                setStudent(newStudent);
                setParentOptions(newParentOptions);
                setContractOptions(newContractOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes, force = false) => {
        if(!force){
            if(changes !== true) changes = false;
            if(closureSubmitting) return;
            if(!attemptingClose){
                setAttemptingClose(true);
                return;
            }
        }
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, closureSubmitting]);

    const handleSubmit = useCallback((values, actions) => {
        (async function submit(){
            const { setStatus, setSubmitting } = actions;
            if(mounted.current){
                setSubmitting(true);
                setClosureSubmitting(true);
            }
            const v = values;

            const studentParams = {
                userId: student.user_id,
                parent: v.parent.value,
                grade: v.grade.value,
                schoolYear: v.schoolYear.value,
                school: v.school,
                teacher: v.teacher,
                address: v.address,
                parentNotes: v.parentNotes,
                firstGuardianNotes: v.firstGuardianNotes,
                secondGuardianNotes: v.secondGuardianNotes,
                specialNotes: v.specialNotes,
                specialNotes2: v.specialNotes2,
                driveFolderLink1Name: v.driveFolderLink1Name,
                driveFolderLink1: v.driveFolderLink1,
                driveFolderLink2Name: v.driveFolderLink2Name,
                driveFolderLink2: v.driveFolderLink2,
                driveFolderLink3Name: v.driveFolderLink3Name,
                driveFolderLink3: v.driveFolderLink3,
                driveFolderLink4Name: v.driveFolderLink4Name,
                driveFolderLink4: v.driveFolderLink4,
            };

            const response = await updateStudentAttributes(studentParams);

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            };
            
            if(mounted.current){
                setSubmitting(false);
                setClosureSubmitting(false);
                setSubmitted(true);
            }
            setTimeout(() => handleClose(true, true), 1000);
        })()
    }, [handleClose, updateStudentAttributes, student]);

    return (
        <Modal className="w-1/2" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>
                    Student Form ({student.first_name ?
                        `${student.first_name} ${student.last_name}` : 
                        `${selectedStudent.first_name} ${selectedStudent.last_name}`})
                </h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <StudentsBodyFooter
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    selectedStudent={student}
                    parentOptions={parentOptions}
                    contractOptions={contractOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsUserIds,
    fetchLeadsRpPermissions,
    fetchMembersRpPermissions,
    fetchContractsStudentIds,
    updateStudentAttributes,
})(StudentForm);