import { basicPath } from '../basicPath';

export const fetchContractsDaterangeCenter = (params) => async (dispatch) => {
    const path = '/rp/contracts/daterange-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchContractsIds = (params) => async (dispatch) => {
    const path = '/rp/contracts/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchContractsActive = (params) => async (dispatch) => {
    const path = '/rp/contracts/active';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchContractsActiveStudents = (params) => async (dispatch) => {
    const path = '/rp/contracts/students-active';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchContractsStudentIds = (params) => async (dispatch) => {
    const path = '/rp/contracts/student-ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchContractsStudentNames = (params) => async (dispatch) => {
    const path = '/rp/contracts/student-names';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createContract = (params) => async (dispatch) => {
    const path = '/rp/contracts';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateContract = (params) => async (dispatch) => {
    const path = '/rp/contracts';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteContract = (params) => async (dispatch) => {
    const path = '/rp/contracts';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}