import React, { useState, useEffect, useRef } from "react";

import { Table } from '../../../../../components/table';
import { getStudentColumns } from '../table/getColumns';
import { StudentModal } from '../../../../../components/modal';

const studentsDefaultSorted = { dataField: 'name', order: 'asc' };

export default function StudentTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { studentData, refreshData } = props;

    // MODAL
    const [showModal, setShowModal] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    function handleShowStudentModal(student){
        setShowModal('student');
        setSelectedStudent(student);
    }
    
    // END MODAL
    const studentColumns = getStudentColumns(handleShowStudentModal);
    function onSubmitCallback(changes = true){
        if(changes) refreshData();
        setShowModal(null);
        setSelectedStudent(null);
    }

    return(
        <>
            {showModal === 'student' ?
                <StudentModal
                    selectedStudent={selectedStudent}
                    onSubmitCallback={onSubmitCallback}
                /> : null
            }
            <Table
                tableName="members-students"
                data={studentData}
                columns={studentColumns}
                defaultSorted={studentsDefaultSorted}
                rowsPerPageInitial={20}
                rowsPerPageList={[10, 20, 40]}
                emptyMessage="No students were found"
            />
        </>
    );
}