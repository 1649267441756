import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, EllipsisLoader, ModalLoading, ModalProcessing } from '../../custom-essentials';
import { validationSchema, renderBody } from './helpers';
import { formatDateTime } from '../../functions';

function BookListBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, apiError, selectedStudent, selectedGroupOption, bookList, bookListItems, templateOptions, bookOptions, duplicateBookMap,
        loaded, submitted, handleClose, handleSubmit, attemptingClose, setAttemptingClose } = props;

    const [deleteFirstConfirm, setDeleteFirstConfirm] = useState(false);

    if(apiError){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4 className="text-mpLRed">
                        {apiError}
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this book list?</h4>
                            <div className="grid grid-cols-1">
                                <div><b>Group:</b> {selectedGroupOption.label}</div>
                                <div><b>Student:</b> {selectedStudent.first_name} {selectedStudent.last_name}</div>
                                <div><b>Book List Name:</b> {bookList.name}</div>
                                <div><b>Created:</b> {formatDateTime(bookList.date_created)}</div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">

                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-[10pt]">
                                        {formik.status}
                                    </div> : null
                                }
                                { deleteFirstConfirm ?
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={formik.handleSubmit}
                                            >
                                            Confirm Delete
                                        </Button>
                                        <Button
                                            color="lte-mpLBlue"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setDeleteFirstConfirm(false)}
                                        >
                                            Back
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setDeleteFirstConfirm(true)}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                }
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                    stagedToRemoveIndex: -1,
                    selectedGroup: selectedGroupOption, // Included so that the formik object has reference to it
                    selectedStudent: selectedStudent, // Included so that the formik object has reference to it
                    selectedTemplate: { value: -1, label: 'Please select a template...'},
                    notes: bookList.notes || '',
                    name: bookList.name || '',
                    bookListItems,
                    active: parseInt(bookList.active) === 0 ? false : true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    attemptingClose ? (
                        <ModalBodyFooter>
                            <ModalBodyFooter.Body>
                                <h4>
                                    Are you sure you would like to close the form?
                                </h4>
                            </ModalBodyFooter.Body>
                            <ModalBodyFooter.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                    >
                                        Close Form
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setAttemptingClose(false)}
                                    >
                                        Back to Form
                                    </Button>
                                </div>
                            </ModalBodyFooter.Footer>
                        </ModalBodyFooter>
                    ) : renderBody(formik, handleClose, bookOptions, templateOptions, duplicateBookMap)
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(BookListBodyFooter);