import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import CycleGroupsBodyFooter from './CycleGroupsBF';
import { checkResponse } from '../../form';

import {
    fetchCycleGroupsIds,
    fetchRpCentersAll,
    fetchStudentsAll,
    createCycleGroup,
    updateCycleGroup,
    deleteCycleGroup,
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function CycleGroupsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [group, setGroup] = useState({});
    const [centerOptions, setCenterOptions] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);

    const { mode, selectedGroup, onSubmitCallback, fetchCycleGroupsIds, fetchRpCentersAll, 
        fetchStudentsAll, createCycleGroup, updateCycleGroup, deleteCycleGroup } = props;

    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsAll();
            const newStudents = studentsRes.data || [];

            const centersRes = await fetchRpCentersAll();
            const newCenters = centersRes.data || [];
            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));

            const exceptedStudents = [];
            if(mode !== 'create'){
                const groupsRes = await fetchCycleGroupsIds({ ids: [selectedGroup.id] });
                const newGroup = groupsRes.data?.groups?.[0] || [];
                const newGroupMembers = groupsRes.data?.groupMembers || [];

                let memberNames = '';
                newGroupMembers.forEach(m => {
                    exceptedStudents.push(m.student);

                    const studentObj = newStudents.find(s => s.user_id === m.student);
                    m.studentName = studentObj ? `${studentObj.first_name} ${studentObj.last_name}` :
                        `Unknown student (UID ${studentObj.student})`;
                    memberNames += `${m.studentName}, `;
                });
                newGroup.memberNames = memberNames.slice(0, memberNames.length - 2);
                newGroup.members = newGroupMembers;

                newGroup.centerName = newCenterOptions.find(c => parseInt(c.value) === parseInt(newGroup.center))?.label ||
                    `Unknown center (ID ${newGroup.center})`;

                if(mounted.current) setGroup(newGroup);
            }

            const newStudentOptions = newStudents.filter(ns => {
                return (parseInt(ns.is_rp_student) === 1 && parseInt(ns.rp_active)) || exceptedStudents.includes(ns.user_id);
            }).map(ns => ({ value: ns.user_id, label: `${ns.first_name} ${ns.last_name}` }));

            if(mounted.current){
                setStudentOptions(newStudentOptions);
                setCenterOptions(newCenterOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const groupMembers = [];
                values.selectedStudents.forEach(s => {
                    groupMembers.push({
                        student: s.value
                    });
                });
                const groupParams = {
                    name: values.groupName,
                    center: values.center.value,
                    notes: values.notes,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    time: values.time.raw,
                    sundays: values.sundays ? 1 : 0,
                    mondays: values.mondays ? 1 : 0,
                    tuesdays: values.tuesdays ? 1 : 0,
                    wednesdays: values.wednesdays ? 1 : 0,
                    thursdays: values.thursdays ? 1 : 0,
                    fridays: values.fridays ? 1 : 0,
                    saturdays: values.saturdays ? 1 : 0,
                    active: values.active ? 1 : 0,
                    groupMembers
                };
                
                if(mode === 'create'){
                    response = await createCycleGroup(groupParams);
                } else if(mode === 'edit') {
                    groupParams.id = group.id;
                    response = await updateCycleGroup(groupParams);
                }
            } else if(mode === 'delete') {
                response = await deleteCycleGroup({ id: group.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, group, createCycleGroup, updateCycleGroup, deleteCycleGroup]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Group Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <CycleGroupsBodyFooter
                    mode={mode}
                    selectedGroup={group}
                    studentOptions={studentOptions}
                    centerOptions={centerOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchCycleGroupsIds,
    fetchRpCentersAll,
    fetchStudentsAll,
    createCycleGroup,
    updateCycleGroup,
    deleteCycleGroup,
})(CycleGroupsModal);