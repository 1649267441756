import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { BooksModal } from '../../../../components/modal';
import { getColumns } from './table/getColumns';

const defaultSorted = { dataField: 'default_sort_order', order: 'asc' };

function BooksTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { books, refreshData } = props;

    const [modalMode, setModalMode] = useState(null);
    const [selectedBook, setSelectedBook] = useState(null);

    const handleShowModal = useCallback((mode, book) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedBook(book);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedBook(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    const columns = useMemo(() => {
        return getColumns(handleShowModal);
    }, [handleShowModal]);

    return (
        <div>
            {modalMode &&
                <BooksModal
                    mode={modalMode}
                    selectedBook={selectedBook}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="books"
                data={books}
                columns={columns}
                defaultSorted={defaultSorted}
                rowsPerPageInitial={50}
                emptyMessage="No books were found"
            />
        </div>
    );
}

export default connect(null, {

})(BooksTable);