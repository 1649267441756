import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button, ModalBodyFooter, EllipsisLoader, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { getTimeObject, formatDateApi, formatDateFull, formatTime } from '../../functions';
import { DatePicker, SelectSingle, FormikControl, TimePicker, Check } from '../../form';
import { TooltipWrapper } from '../../display';

import {
    validationSchema, getStudentHeader, getInitStudentOption, getInitCenterOption,
    durationOptions, getInitDurationOption, statusOptions, getInitStatusOption,
    getInitGroupOption, getGroupHeader, ContractFields
} from './helpers';

function AppointmentsModalBF(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedCenter, selectedDate, centerOptions, selectedAppointment, groupOptions,
        studentOptions, parents, loaded, submitted, handleClose, handleSubmit, forceDelete } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        {mode === 'create' ? 'Created' : mode === 'edit' ?
                        'Updated' : mode === 'delete' ? 'Deleted' : 'Unexpected mode...'} successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        const sa = selectedAppointment;
        const student = studentOptions.find(s => s.obj?.user_id === sa.student)?.obj || {};
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    deleteLinked: false
                }}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this appointment?</h4>
                            <div className="grid grid-cols-1">
                                <div>
                                    <b>Student: </b>{student ? `${student.first_name} ${student.last_name}` :
                                    `Unknown student (UID: ${sa.student})`}
                                </div>
                                <div><b>Time: </b>{formatDateFull(sa.date)} at {formatTime(sa.time)}</div>
                                <div><b>Center: </b>{sa.centerName}</div>
                                <div><b>Contract:</b> {selectedAppointment.contractName}</div>
                            </div>

                            <br/>

                            <div className="flex flex-row">
                                <div className="grid grid-cols-1">
                                    <TooltipWrapper
                                        tooltipText={<div>If checked, all appointments with the same date, time, and group will be deleted.</div>}
                                        placement="bottom"
                                    >
                                        <Check
                                            id="appointments-delete-linked"
                                            name="deleteLinked"
                                            label={<div className="text-mpLBlue">Delete linked appointments</div>}
                                            color="mpDBlue"
                                            checked={formik.values.deleteLinked}
                                            onChange={formik.handleChange}
                                        />
                                    </TooltipWrapper>
                                </div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed" style={{ fontSize: "8pt", marginRight: "2rem" }}>
                                        {formik.status}
                                    </div> : null
                                }
                                {formik.status ?
                                    <TooltipWrapper
                                        tooltipText="Deletes the appointment without updating hours, overriding most errors."
                                    >
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={() => forceDelete(formik)}
                                        >
                                            Force Delete
                                        </Button>
                                    </TooltipWrapper>
                                : null}
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    date: formatDateApi(selectedAppointment.date || selectedDate.date || new Date()),
                    time: getTimeObject(parseInt(selectedAppointment.time || 840)),
                    center: getInitCenterOption(selectedAppointment.center || selectedCenter.value, centerOptions),
                    group: getInitGroupOption(parseInt(selectedAppointment.group_id), groupOptions),
                    student: getInitStudentOption(selectedAppointment.group_id, selectedAppointment.student, studentOptions),
                    selectedContracts: {},
                    duration: getInitDurationOption(selectedAppointment.duration),
                    status: getInitStatusOption(selectedAppointment.status),
                    specialNotes: selectedAppointment.special_notes || '',
                    specialNotes2: selectedAppointment.special_notes_2 || '',
                    nWeeks: 1,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="w-1/2 grid grid-cols-1 gap-y-2">
                                    <h4>Appointment Date</h4>
                                    <DatePicker
                                        id="appointment-form-datepicker"
                                        name="date"
                                        value={formik.values.date}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.date ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.date}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="w-5/12 grid grid-cols-1 gap-y-2">
                                    <TooltipWrapper
                                        tooltipText="Must be an increment of 30 minutes"
                                    >
                                        <h4 className="text-mpLBlue">Time</h4>
                                    </TooltipWrapper>
                                    <TimePicker
                                        id="appointment-form-time"
                                        name="time"
                                        value={formik.values.time?.formatted24 || ''}
                                        onChange={formik.handleChange}
                                        step={900} // 15 min
                                    />
                                    {formik.errors.time ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.time}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            {mode === 'create' ? 
                                <>
                                    <div className="flex flex-row">
                                        <div className="w-full grid grid-cols-1 gap-y-2">
                                            {getGroupHeader(formik)}
                                            <SelectSingle
                                                id="appointments-group"
                                                name="group"
                                                disabled={mode !== 'create'}
                                                value={formik.values.group}
                                                onChange={(e) => {
                                                    const newValues = { ...formik.values };
                                                    const group = e.target.value;
                                                    newValues.group = group;
                                                    newValues.student = { value: -1, label: 'Please select...' };
        
                                                    formik.setValues(newValues);
                                                }}
                                                options={groupOptions}
                                            />
                                            {formik.errors.group ? (
                                                <ErrorMessage color="mpLRed">
                                                        {formik.errors.group}
                                                </ErrorMessage>
                                            ) : null}
                                            {mode !== 'create' && (
                                                <div className="text-mpOrange" style={{ fontSize: "8pt" }} >
                                                    Field disabled. Create a new appointment if you would like to select a different group.
                                                </div>
                                            )}
                                        </div>
                                    </div>
        
                                    <br/>
        
                                    <div className="flex flex-row">
                                        <div className="w-full grid grid-cols-1 gap-y-2">
                                            {getStudentHeader(formik.values.student.obj, parents)}
                                            <SelectSingle
                                                id="appointments-student"
                                                name="student"
                                                disabled={mode !== 'create' || formik.values.group.value === -1}
                                                value={formik.values.student}
                                                onChange={(e) => {
                                                    const group = formik.values.group;
                                                    const student = e.target.value;
                                                    const studentObj = student.obj;
                                                    const newValues = { ...formik.values };
                                                    newValues.student = student;
        
                                                    if(student.value !== 'all'){
                                                        newValues.specialNotes = studentObj.mp_special_notes || '';
                                                        newValues.specialNotes2 = studentObj.mp_special_notes_2 || '';
                                                    } else {
                                                        newValues.specialNotes = '';
                                                        newValues.specialNotes2 = '';
                                                    }
        
                                                    const selectedContracts = {};
                                                    const studentsToRender = student.value === 'all' ? group.obj.studentList : [studentObj];
                                                    studentsToRender.forEach(s => {
                                                        selectedContracts[s.user_id] = s.contractOptions[0] || { value: -1, label: 'No active contracts found' }
                                                    });
                                                    newValues.selectedContracts = selectedContracts;
        
                                                    formik.setValues(newValues);
                                                }}
                                                options={formik.values.group?.obj?.studentOptions || [{ value: -1, label: 'Please select a group first...' }]}
                                            />
                                            {formik.values.group.value === -1 ? 
                                                <div className="text-mpOrange">Please select a group first.</div>
                                                : null
                                            }
                                            {formik.errors.student ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.student}
                                                </ErrorMessage>
                                            ) : null}
                                            {mode !== 'create' && (
                                                <div className="text-mpOrange" style={{ fontSize: "8pt" }} >
                                                    Field disabled. Create a new appointment if you would like to select a different student.
                                                </div>
                                            )}
                                        </div>
                                    </div>
        
                                    <br/>
        
                                    <ContractFields formik={formik}/>
                                </> : 
                                <>
                                    <div className="flex flex-row">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <div>
                                                <b>Student:</b> {selectedAppointment.studentName}
                                            </div>
                                            <div>
                                                <b>Contract:</b> {selectedAppointment.contractName}
                                            </div>
                                            <div className="text-mpOrange" style={{ fontSize: "8pt" }}>
                                                Student and contract cannot be modified after an appointment is created.
                                            </div>
                                        </div>
                                    </div>


                                    <br/>
                                </>
                            }

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="w-1/2 grid grid-cols-1 gap-y-2">
                                    <h4>Center</h4>
                                    <SelectSingle
                                        id="appointments-center"
                                        name="center"
                                        value={formik.values.center}
                                        onChange={formik.handleChange}
                                        options={centerOptions}                                        
                                    />
                                    {formik.errors.center ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.center}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="w-1/2 grid grid-cols-1 gap-y-2">
                                    <h4>Duration</h4>
                                    <SelectSingle
                                        id="appointments-duration"
                                        name="duration"
                                        value={formik.values.duration}
                                        onChange={formik.handleChange}
                                        options={durationOptions}
                                    />
                                    {formik.errors.duration ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.duration}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row">
                                <div className="w-full grid grid-cols-1 gap-y-2">
                                    <TooltipWrapper
                                        tooltipText={
                                            <>
                                                <div>Scheduled, In Progress, Completed: Hours charged</div>
                                                <br/>
                                                <div>
                                                    Missed (hours charged) &amp; Cancelled (not charged):
                                                    Some filters can be used to remove these entries
                                                </div>
                                                <br/>
                                                <div>
                                                    Not Charged: Session completed, but due to an issue,
                                                    the hours were refunded
                                                </div>
                                            </>
                                        }
                                    >
                                        <h4 className="text-mpLBlue">Status</h4>
                                    </TooltipWrapper>
                                    <SelectSingle
                                        id="appointments-status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        options={statusOptions}
                                    />
                                    {formik.errors.status ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.status}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row">
                                <div className="w-full grid grid-cols-1 gap-y-2">
                                    <TooltipWrapper
                                        tooltipText={`This will ONLY update the "special notes" (red !) for the selected appointment.`}
                                    >
                                        <h4 className="text-mpLBlue">Special Notes</h4>
                                    </TooltipWrapper>
                                    <FormikControl
                                        id="appointments-special-notes"
                                        as="textarea"
                                        name="specialNotes"
                                        value={formik.values.specialNotes}
                                        onChange={formik.handleChange}
                                        disabled={formik.values.student.value === 'all'}
                                        placeholder="This will only update the special notes for this session. To adjust future notes for all of a student's future sessions, please update the student's profile."
                                    />
                                    {formik.values.student.value === 'all' ? 
                                        <div className="text-mpOrange">
                                            Disabled if "all" students are selected. Each student's account notes will be used for their respective appointment.
                                        </div>
                                        : null
                                    }
                                    {formik.errors.specialNotes ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.specialNotes}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row">
                                <div className="w-full grid grid-columns-1 gap-y-2">
                                    <TooltipWrapper
                                        tooltipText={`This will ONLY update the "special notes 2" (blue !) for the selected appointment.`}
                                    >
                                        <h4 className="text-mpLBlue">Special Notes 2</h4>
                                    </TooltipWrapper>
                                    <FormikControl
                                        id="appointments-special-notes-2"
                                        as="textarea"
                                        name="specialNotes2"
                                        value={formik.values.specialNotes2}
                                        onChange={formik.handleChange}
                                        disabled={formik.values.student.value === 'all'}
                                        placeholder="This will only update the special notes 2 for this session. To adjust future notes for all of a student's future sessions, please update the student's profile."
                                    />
                                    {formik.values.student.value === 'all' ? 
                                        <div className="text-mpOrange">
                                            Disabled if "all" students are selected. Each student's account notes will be used for their respective appointment.
                                        </div>
                                        : null
                                    }
                                    {formik.errors.specialNotes2 ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.specialNotes2}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            {mode === 'create' ?
                                (<>
                                    <br/>

                                    <div className="flex flex-row">
                                        <div className="w-full grid grid-columns-1 gap-y-2">
                                            <TooltipWrapper
                                                tooltipText={
                                                    <>
                                                        <div>Use this to schedule multiple recurring appointments at once.</div>
                                                        <br/>
                                                        <div>"1" will schedule the selected date/time only.</div>
                                                        <br/>
                                                        <div>"2" will schedule the selected date/time AND the same day/time next week.</div>
                                                    </>
                                                }
                                            >
                                                <h4 className="text-mpLBlue">Number of Weeks to Schedule</h4>
                                            </TooltipWrapper>
                                            <FormikControl
                                                id="appointments-nWeeks"
                                                name="nWeeks"
                                                value={formik.values.nWeeks}
                                                onChange={formik.handleChange}
                                                disabled={mode !== 'create'}
                                                placeholder="Max 15"
                                            />
                                            {formik.errors.nWeeks ? (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.nWeeks}
                                                </ErrorMessage>
                                            ) : null}
                                        </div>
                                    </div>
                                </>) : null}
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed mr-8">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <div>
                                        <span className="mr-8"><EllipsisLoader/></span>
                                        Processing...
                                    </div> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-8">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    onClick={handleClose}
                                    disabled={formik.isSubmitting}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(AppointmentsModalBF);