export function drawVSpace(item, appendHeight = false){
    const { afterMargin = 0, coords } = item;
    let { yPos } = coords;

    if(appendHeight){
        item.height = afterMargin;
        return;
    }

    yPos -= afterMargin;
    return yPos;
}