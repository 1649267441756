import { basicPath } from '../basicPath';

export const fetchBookListTemplatesAll = (params) => async (dispatch) => {
    const path = '/rp/book-list-templates/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchBookListTemplatesFull = (params) => async (dispatch) => {
    const path = '/rp/book-list-templates/full';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchBookListTemplatesId = (params) => async (dispatch) => {
    const path = '/rp/book-list-templates/id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createBookListTemplate = (params) => async (dispatch) => {
    const path = '/rp/book-list-templates';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateBookListTemplate = (params) => async (dispatch) => {
    const path = '/rp/book-list-templates';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteBookListTemplate = (params) => async (dispatch) => {
    const path = '/rp/book-list-templates';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}