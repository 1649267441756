import React from 'react';
import * as Yup from 'yup';

import { ModalBodyFooter, Button, ListGroup, SVGIcon, ModalProcessing } from '../../custom-essentials';
import { DatePicker, Check, renderSubmittingDefault } from '../../form';
import { TooltipWrapper } from '../../display';
import { convertApiToDate, formatDateFull, formatDateApi, checkLessonDatesValid } from '../../functions';

export const validationSchema = Yup.object().shape({
    stampsAmount: Yup.number()
        .typeError('Stamps Amount must be a number')
        .min(0, 'Min 0')
        .max(100, 'Max 100')
        .required('Stamps Amount is required (it can be 0!)'),
    stampsNotes: Yup.string()
        .max(300, 'Max 300 characters'),
    sessionNotes: Yup.string()
        .max(1000, 'Max 1,000 characters'),
    homeworkNotes: Yup.string()
        .max(500, 'Max 500 characters'),
    internalNotes: Yup.string()
        .max(1000, 'Max 1,000 characters'),
    vocabPages: Yup.string()
        .max(100, 'Max 100 characters'),
    status: Yup.mixed().test(
        'statusIsValid',
        'Please select a status',
        (value) => value.value !== -1
    ),
    flagUserValid: Yup.mixed().when(['flagUser', 'createFlag'],
        ([flagUser, createFlag]) => {
            const isValid = !createFlag || flagUser.value !== -1;
            const errorText = `Flag User is required if "Create Flag" is checked.`
            if(isValid) return Yup.mixed().test('checkFlagCenter', '', () => true);
            else return Yup.mixed().test('checkFlagCenter', errorText, () => false);
        }
    ),
    flagCenterValid: Yup.mixed().when(['flagCenter', 'createFlag'],
        ([flagCenter, createFlag]) => {
            const isValid = !createFlag || flagCenter.value !== -1;
            const errorText = `Tag is required if "Create Flag" is checked.`
            if(isValid) return Yup.mixed().test('checkFlagCenter', '', () => true);
            else return Yup.mixed().test('checkFlagCenter', errorText, () => false);
        }
    ),
    flagPriorityValid: Yup.mixed().when(['flagPriority', 'createFlag'],
        ([flagPriority, createFlag]) => {
            const isValid = !createFlag || flagPriority.value !== -1;
            const errorText = `Priority is required if "Create Flag" is checked.`
            if(isValid) return Yup.mixed().test('checkflagPriority', '', () => true);
            else return Yup.mixed().test('checkflagPriority', errorText, () => false);
        }
    ),
    flagNotes: Yup.string()
        .min(0, 'Min length 0')
        .max(200, 'Max length 200')
});


export const radioOptions = [1, 2, 3, 4].map(i => ({ value: i, label: i }));

export function renderRecommendedStamps(values){
    const { participation, answerAccuracy, depthOfThinking } = values;
    let recommendedAmount = participation + 1 + answerAccuracy + 1 + depthOfThinking + 1;

    const today = formatDateApi(new Date());
    const bookCompletedToday = values.bookListOptions.some(blo => {
        return (blo.obj.items.some(bli => bli.dateCompleted === today));
    });
    if(bookCompletedToday) recommendedAmount += 30;
    return(
        <div className="grid grid-cols-1 gap-y-2 w-full" style={{ fontSize: "8pt" }}>
            <div>Participation: {participation} = {participation + 1} stamps&nbsp;&nbsp;</div>
            <div>Answer Accuracy: {answerAccuracy} = {answerAccuracy + 1} stamps&nbsp;&nbsp;</div>
            <div>Depth of Thinking: {depthOfThinking} = {depthOfThinking + 1} stamps&nbsp;&nbsp;</div>
            {bookCompletedToday ?  <div>Book Completed: 30 stamps&nbsp;&nbsp;</div> : null}
            <div>Total Stamps Recommended: {recommendedAmount}</div>
        </div>
    )
}

export function getSelectedInstructors(assignments, instructorOptions){
    return assignments
        .map(a => {
            const defaultOpt = { value: a.instructor, label: `Unknown instructor (ID: ${a.instructor})` };
            return instructorOptions.find(i => a.instructor === i.value) || defaultOpt;
        })
        .sort((a, b) => {
            if(a.label < b.label) return -1;
            else if(a.label > b.label) return 1;
            return 0;
        });
}

export const statusOptions = ['Scheduled', 'In Progress', 'Completed', 'Missed', 'Cancelled', 'Not Charged']
    .map(i => ({ value: i, label: i }));

export function getInitSelectedStatus(status){
    if(['Scheduled', 'In Progress', 'Completed', 'Missed', 'Cancelled', 'Not Charged'].includes(status)){
        return { value: status, label: status };
    } else {
        return { value: -1, label: `Unknown Status ${status}`}
    }
}

export const flagTags = ['No Tag', 'Lesson', 'LC', 'Assessment'].map(i => ({ value: i, label: i }));

export function getCenterOptions(centers){
    return centers.map(c => ({ value: parseInt(c.id), label: c.name }));
}

export function getInitSelectedCenter(center, centers){
    const relCenter = centers.find(c => parseInt(c.id) === parseInt(center));
    if(relCenter) return { value: relCenter.id, label: relCenter.name };
    else return { value: -1, label: `Unknown Center (ID ${center})`};
}

export const flagPriorityOptions = ['Low', 'Important', 'Urgent'].map(i => ({ value: i, label: i }));

export function renderSubmitting(submissionStatus, setSubmitting){
    return (
        <ModalBodyFooter>
            <ModalBodyFooter.Body>
                <h4>
                    {submissionStatus.completed ? 
                        <div className="grid grid-cols-1 gap-y-2 w-full">
                            <div>
                                One or more errors occurred. Click "Back" to return to the previous form and try again.
                            </div>
                            <br/>
                            <div>
                                Please take a screenshot of this page and the previous one for debugging purposes.
                            </div>
                        </div>
                    :
                        <ModalProcessing/>
                    }
                </h4>

                <br/>
                
                <div className="grid grid-cols-1 gap-y-2 w-full">
                    {renderSubmittingDefault(submissionStatus)}
                </div>
            </ModalBodyFooter.Body>
            <ModalBodyFooter.Footer>
                <Button
                    color="lte-mpLRed"
                    disabled={!submissionStatus.completed}
                    onClick={() => setSubmitting(false)}
                >
                    Back
                </Button>
            </ModalBodyFooter.Footer>
        </ModalBodyFooter>
    );
}

export function renderRelevantNotes(combinedNotes){
    const notesToShow = combinedNotes.length ? (
        <div style={{ maxHeight: "45rem", overflowY: "auto" }}>
            <ListGroup>
                {combinedNotes.map(cn => {
                    const sessionNotes = cn.sessionNotes ?
                    <div>
                        <b>Session Notes: </b>
                        <span className="text-mpBlue">{cn.sessionNotes}</span>
                    </div> : null;
                    const internalNotes = cn.internalNotes ?
                    <div>
                        <b>Internal Notes: </b>
                        <span className="text-mpGreen">{cn.internalNotes}</span>
                    </div> : null;
                    return (
                        <div key={cn.key}>
                            <h4>{formatDateFull(cn.date)}</h4>
                            {sessionNotes}
                            {internalNotes}
                        </div>
                    );
                })}
            </ListGroup>
        </div>
    ) : (
        <div className="grid grid-cols-1 w-full gap-y-2">
            <h5 className="text-mpOrange">None</h5>
        </div>
    );
    return(
        <>
            <h4>Relevant Notes (Last 3 Months)</h4>
            {notesToShow}
        </>
    );
}

export function renderPastFlags(flags){
    let index = 0;
    return(
        <>
            <h4>Past Flags (3 months)</h4>
            <div className="text-mpOrange">
                Please ensure you are not creating a redundant flag!
            </div>
            <div className="max-h-[128] overflow-y-auto">
                <ListGroup>
                    {flags.map(flag => {
                        const color = flag.status === 'Completed' ? 'text-mpGreen' : 'text-mpOrange';
                        return (
                            <div key={`appointments-past-flags-${index++}`}>
                                <h4 className={color}>{formatDateFull(flag.date_created)} ({flag.status})</h4>
                                <div>
                                    <b>User: </b>
                                    {flag.userName}
                                </div>
                                <div>
                                    <b>Updated: </b>
                                    {flag.date_updated ? 
                                        <span>{formatDateFull(flag.date_updated)}</span> :
                                        <span className="text-mpOrange">Never</span>
                                    }
                                </div>
                                <div>
                                    <b>Tag: </b>
                                    {flag.tag ? <span>{flag.tag}</span> : <span className="text-mpOrange">None</span>}
                                </div>
                                <div>
                                    <b>Notes: </b>
                                    {flag.notes ? <span>{flag.notes}</span> : <span className="text-mpOrange">None</span>}
                                </div>
                                <div>
                                    <b>Admin Notes: </b>
                                    {flag.admin_notes ? <span>{flag.admin_notes}</span> : <span className="text-mpOrange">None</span>}
                                </div>
                            </div>
                        );
                    })}
                </ListGroup>
            </div>
        </>
    );
}

export function renderBLFields(formik){
    const selectedBookListOption = formik.values.selectedBookList || {};
    const selectedBLOIndex = formik.values.bookListOptions.findIndex(blo => blo.value === selectedBookListOption.value);
    const blItems = selectedBookListOption.obj?.items || [];

    if(!blItems?.length) return <div className="text-mpOrange">No active items</div>;
    let currentIndex = 0;

    return(
        <div className="max-h-[40rem] overflow-y-auto">
            <div className="flex flex-row gap-x-4">
                <TooltipWrapper
                    tooltipText={"Hides lessons that were marked as completed when the form was opened."}
                >
                    <Check
                        id="details-hideCompleted"
                        name="hideCompleted"
                        label={
                            <div className="text-mpLBlue">
                                Hide Completed
                            </div>
                        }
                        textPosition="after"
                        color="mpDBlue"
                        checked={formik.values.hideCompleted}
                        onChange={formik.handleChange}
                    />
                </TooltipWrapper>
            </div>
            <br/>
            {blItems.filter(bli => {
                const shouldShow = !(formik.values.hideCompleted && bli.date_completed);
                return shouldShow;
            }).map(bli => {
                const assignedDate = bli.dateAssigned ? convertApiToDate(bli.dateAssigned) : '';
                const completedDate = bli.dateCompleted ? convertApiToDate(bli.dateCompleted) : '';
                const shouldWarn = !(checkLessonDatesValid(assignedDate, completedDate));
                const datesWarning = shouldWarn ?
                    <div className="text-mpOrange">
                        Date completed should not be before date assigned.
                    </div>
                : null;
        
                return (
                    <div key={`details-learning-plan-item-${currentIndex++}`}>
                        <h5><u>{bli.name}</u></h5>
                        <div className="flex flex-row gap-x-4">
                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                <h5>Assigned</h5>
                                <div className="flex flex-row">
                                    <DatePicker
                                        id="learning-plans-date-assigned"
                                        name="dateAssigned"
                                        value={bli.dateAssigned}
                                        onChange={() => null}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                <h5>Completed</h5>
                                <div className="flex flex-row gap-x-4">
                                    <DatePicker
                                        id="learning-plans-date-completed"
                                        name="dateCompleted"
                                        value={bli.dateCompleted}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            const newBookListOption = { ...selectedBookListOption };
                                            newBookListOption.obj.items[bli.orderIndex].dateCompleted = e.target.value;
                                            newValues.bookListOptions[selectedBLOIndex] = newBookListOption;
                                            formik.setValues(newValues);
                                        }}
                                    />                      
                                </div>
                            </div>
                            <div className="flex flex-row gap-x-2 items-center">
                                <div>
                                    <TooltipWrapper tooltipText="Use today's date">
                                        <Button
                                            color="lte-mpLBlue"
                                            variant="icon"
                                            onClick={() => {
                                                const newDate = formatDateApi(new Date());
                                                const newValues = { ...formik.values };
                                                const newBookListOption = { ...selectedBookListOption };
                                                newBookListOption.obj.items[bli.orderIndex].dateCompleted = newDate;
                                                newValues.bookListOptions[selectedBLOIndex] = newBookListOption;
                                                formik.setValues(newValues);
                                            }}
                                        >
                                            <SVGIcon fileName="calendar"/>
                                        </Button>
                                    </TooltipWrapper>
                                </div>
                                <div>
                                    <TooltipWrapper tooltipText="Remove date">
                                        <Button
                                            color="lte-mpLRed"
                                            variant="icon"
                                            onClick={() => {
                                                const newDate = '';
                                                const newValues = { ...formik.values };
                                                const newBookListOption = { ...selectedBookListOption };
                                                newBookListOption.obj.items[bli.orderIndex].dateCompleted = newDate;
                                                newValues.bookListOptions[selectedBLOIndex] = newBookListOption;
                                                formik.setValues(newValues);
                                            }}
                                        >
                                            <SVGIcon fileName="eraser"/>
                                        </Button>
                                    </TooltipWrapper>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-x-4">
                            {datesWarning}
                        </div>

                        <br/>
                    </div>
                );
            })}
        </div>
    )
}