import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { SelectSingle, FormikControl, Check, checkResponses } from '../../../../components/form';
import { CycleGroupModal } from '../../../../components/modal';
import { CSVExport } from '../../../../components/export';
import CycleGroupsTable from './CycleGroupsTable';
import { Socket } from '../../../../components/ws';

import {
    fetchRpCentersAll,
    fetchStudentsAll,
    fetchCycleGroupsAll
} from '../../../../actions';

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'studentName', label: 'Student Name' },
    { value: 'center', label: 'Center' },
    { value: 'notes', label: 'Notes' },
];

function CycleGroups(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);

    // DATA
    const [groups, setGroups] = useState([]);
    const [students, setStudents] = useState([]);

    const [filteredGroups, setFilteredGroups] = useState([]);
    const [centers, setCenters] = useState([{ value: -1, label: 'Loading centers...' }]);

    const [modalMode, setModalMode] = useState(null);

    const { fetchCycleGroupsAll, fetchRpCentersAll, fetchStudentsAll } = props;

    const filterGroups = useCallback((unfilteredGroups, filterType, filterQuery, hideInactive) => {
        filterQuery = filterQuery.replace(/ /g, '').toLowerCase();
        const checkGroupName = (g) => g.name.toLowerCase().replace(/ /g, '').includes(filterQuery);
        const checkStudent = (g) => g.memberNames.toLowerCase().replace(/ /g, '').includes(filterQuery);
        const checkCenters = (g) => g.centerName.replace(/ /g, '').toLowerCase().includes(filterQuery);
        const checkNotes = (g) => g.notes.replace(/ /g, '').toLowerCase().includes(filterQuery)

        const newFilteredGroups = unfilteredGroups.filter(g => {
            switch(filterType.value){
                case 'all':
                    return checkGroupName(g) || checkStudent(g) || checkCenters(g) || checkNotes(g);
                case 'groupName':
                    return checkGroupName(g);
                case 'studentName':
                    return checkStudent(g);
                case 'center':
                    return checkCenters(g);
                case 'notes':
                    return checkNotes(g);
                default:
                    return false;
            }
        }).filter(g => {
            if(hideInactive === false) return true;
            else return parseInt(g.active) === 1;
        });
        
        setFilteredGroups(newFilteredGroups);
    }, []);
    const refreshData = useCallback((newStudents = students, newCenters = centers) => {
        (async function refresh(){
            if(loading || !formRef.current?.values) return;
            if(mounted.current) setLoading(true);
    
            const { filterQuery, filterType, hideInactive } = formRef.current.values;
    
            const studentMap = {};
            newStudents.forEach(s => studentMap[s.user_id] = `${s.first_name} ${s.last_name}`);

            const centerMap = {};
            newCenters.forEach(c => centerMap[parseInt(c.id)] = c.name);
    
            const groupsRes = await fetchCycleGroupsAll();
            const isApiError = checkResponses(groupsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newGroups = groupsRes.data?.groups || [];
            const newGroupMembers = groupsRes.data?.groupMembers || [];

            const groupMap = {};
            newGroups.forEach(g => {
                groupMap[parseInt(g.id)] = {
                    ...g,
                    centerName: centerMap[parseInt(g.center)],
                    members: [],
                    memberNames: ''
                };
            });
            newGroupMembers.forEach(m => {
                const groupId = m.group_id;
                const studentId = m.student;
                const groupItem = groupMap[parseInt(groupId)];
                if(!groupItem?.members) return;

                const studentName = studentMap[studentId] || `Unknown student (UID ${studentId})`;
                groupItem.members.push({
                    id: studentId,
                    name: studentName
                });
                groupItem.memberNames += `${studentName}, `;
            });
            const groupList = Object.values(groupMap).map(g => g);
            groupList.forEach(g => g.memberNames = g.memberNames.slice(0, g.memberNames.length - 2))

    
            if(mounted.current){
                setGroups(groupList);
                filterGroups(groupList, filterType, filterQuery, hideInactive);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, fetchCycleGroupsAll, filterGroups, formRef, centers, students]);
    useEffect(() => {
        async function init(){
            setLoading(true);
            const centersRes = await fetchRpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const isApiError = checkResponses(centersRes, studentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }
            
            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];

            if(mounted.current){
                setStudents(newStudents);
                setCenters(newCenters);
                setLoading(false);
                setHasLoaded(true);
                refreshData(newStudents, newCenters);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    return (
        <div className="page-box">
            <BrowserTabTitle>Group Manager</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            { modalMode && // 'create' only
                <CycleGroupModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {hasLoaded &&
                <div className="card">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            filterType: { value: 'all', label: 'All' },
                            filterQuery: '',
                            activeKey: 'pending',
                            hideInactive: true
                        }}
                        innerRef={formRef}
                        onSubmit={() => refreshData(students, centers)}
                    >
                        {formik => (
                            <>
                                <h3>Group Manager</h3>
                                <br/>
                                <div className="flex flex-row gap-x-4">
                                    <div className="self-center">
                                        <Button
                                            color="lte-mpTeal"
                                            onClick={formik.handleSubmit}
                                        >
                                            Refresh
                                        </Button>
                                    </div>
                                    <div className="self-center">
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => setModalMode('create')}
                                        >
                                            + Add Group
                                        </Button>
                                    </div>
                                    <div className="items-center ml-auto">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    <div>
                                                        What gets exported?
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        All groups that are currently filtered
                                                        ({filteredGroups.length} items).
                                                        This depends on the tab that is selected.
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <CSVExport
                                                title="Groups"
                                                label="Export Groups to CSV"
                                                data={filteredGroups}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>
                                
                                <br/>
                                
                                { apiError ? <div className="text-mpLRed">{apiError}</div> : 
                                    <>
                                        <hr/>
                                        <br/>

                                        <h3>Filter Results</h3>
                                        <br/>
                                        <div className="flex flex-row gap-x-4">
                                            <div className="w-1/4">
                                                <SelectSingle
                                                    id="group-manager-searchType"
                                                    name="filterType"
                                                    label="Filter by"
                                                    value={formik.values.filterType}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        filterGroups(groups, e.target.value, formik.values.filterQuery, formik.values.hideInactive);
                                                    }}
                                                    options={filterTypeOptions}
                                                />
                                            </div>
                                            <div className="w-5/12 flex-end">
                                                <FormikControl
                                                    id="group-manager-searchQuery"
                                                    name="filterQuery"
                                                    placeholder="Enter a filter query..."
                                                    value={formik.values.filterQuery}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        filterGroups(groups, formik.values.filterType, e.target.value, formik.values.hideInactive);
                                                    }}
                                                />
                                            </div>
                                            <div className="flex-end">
                                                <Check
                                                    id="assessments-hideInactive"
                                                    name="hideInactive"
                                                    label="Hide Inactive Groups"
                                                    color="mpDBlue"
                                                    checked={formik.values.hideInactive}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        filterGroups(groups, formik.values.filterType, formik.values.filterQuery, e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <CycleGroupsTable
                                            refreshData={refreshData}
                                            groups={filteredGroups}
                                        />
                                    </>
                                }
                                
                            </>
                        )}
                    </Formik>
                </div>
            }
            <Socket
                refreshData={refreshData}
                page="Group Manager"
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchRpCentersAll,
    fetchStudentsAll,
    fetchCycleGroupsAll
})(CycleGroups);