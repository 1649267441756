import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl, Check } from '../../form';
import { renderPhone } from '../../display';
import { validationSchema, getInitCenterOption, mpPermissionsOptions,
    rpPermissionsOptions, getInitPermissionsOption } from './helpers';

function EmployeesBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedEmployee, mpCenterOptions, rpCenterOptions, loaded, passwordReset, resetSuccess, showPass, setShowPass,
        submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        const employeeName = `${selectedEmployee.first_name} ${selectedEmployee.last_name}`;
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                    confirmName: ''
                }}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>
                                Delete this employee?
                            </h4>
                            <div className="text-mpOrange">
                                All appointments and actions will be permanently unlinked.
                            </div>
                            <br/>
                            <h4 className="text-mpOrange">
                                This cannot be undone.
                            </h4>
                            <div className="h-2 clear-both"/>
                            <div className="grid grid-cols-1">
                                <div>Name: {employeeName}</div>
                                <div>Email: {selectedEmployee.email}</div>
                                <div>Phone: {renderPhone(selectedEmployee.phone)}</div>
                                <div>MP Permissions: {selectedEmployee.mp_permissions}</div>
                                <div>RP Permissions: {selectedEmployee.rp_permissions}</div>
                            </div>

                            <br/>
                            
                            <h4>Enter this user's name to proceed:</h4>
                            <div className="grid grid-cols-1">
                                <FormikControl
                                    id="delete-member-confirmName"
                                    name="confirmName"
                                    value={formik.values.confirmName}
                                    onChange={formik.handleChange}
                                    placeholder={employeeName}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-xs">
                                        {formik.status}
                                    </div> : null
                                }
                                { !formik.values.firstConfirm ? (
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            onClick={() => formik.setFieldValue('firstConfirm', true)}
                                            disabled={formik.values.confirmName !== employeeName}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div className="flex flex-row gap-x-2 mr-auto">
                                            <Button
                                                color="hol-mpLRed"
                                                disabled={formik.isSubmitting || formik.values.confirmName !== employeeName}
                                                onClick={(e) => {
                                                    formik.setFieldValue('firstConfirm', false);
                                                    formik.handleSubmit(e)
                                                }}
                                            >
                                                Confirm Delete
                                            </Button>
                                            <Button
                                                color="lte-mpLBlue"
                                                disabled={formik.isSubmitting}
                                                onClick={() => {
                                                    formik.setStatus('');
                                                    formik.setFieldValue('firstConfirm', false);
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        <div
                                            className="text-mpOrange d-flex align-items-center"
                                        >
                                            Are you sure?
                                        </div>
                                    </>
                                )}
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    email: selectedEmployee.email || '',
                    receiveEmails: parseInt(selectedEmployee.receive_emails) === 1 ? true : false,
                    firstName: selectedEmployee.first_name || '',
                    lastName: selectedEmployee.last_name || '',
                    phone: selectedEmployee.phone || '',
                    receiveTexts: parseInt(selectedEmployee.receive_texts) === 1 ? true : false,
                    mpPrimaryCenter: getInitCenterOption(selectedEmployee.mp_primary_center, mpCenterOptions),
                    rpPrimaryCenter: getInitCenterOption(selectedEmployee.rp_primary_center, rpCenterOptions),
                    mpPermissions: getInitPermissionsOption(selectedEmployee.mp_permissions, mpPermissionsOptions),
                    rpPermissions: getInitPermissionsOption(selectedEmployee.rp_permissions, rpPermissionsOptions),
                    verified: parseInt(selectedEmployee.verified) === 1 ? true : false,
                    accountActive: parseInt(selectedEmployee.account_active) === 0 ? false : true,
                    passwordReset: '',
                    passwordConfirm: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>First Name</h4>
                                    <FormikControl
                                        id="employees-first-name"
                                        name="firstName"
                                        value={formik.values.firstName}
                                        onChange={formik.handleChange}
                                        placeholder="Max 30 characters"
                                    />
                                    {formik.errors.firstName ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.firstName}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Last Name</h4>
                                    <FormikControl
                                        id="employees-first-name"
                                        name="lastName"
                                        value={formik.values.lastName}
                                        onChange={formik.handleChange}
                                        placeholder="Max 30 characters"
                                    />
                                    {formik.errors.lastName ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.lastName}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <h4>Email</h4>
                                    <FormikControl
                                        id="employees-email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        placeholder="riley@mathplextutoring.com"
                                    />
                                    {formik.errors.email ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.email}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                    <h4>Phone</h4>
                                    <FormikControl
                                        id="employees-phone"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        placeholder="9493294040"
                                    />
                                    {formik.errors.phone ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.phone}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>MP Primary Center</h4>
                                    <SelectSingle
                                        id="employees-mpCenter"
                                        name="mpPrimaryCenter"
                                        value={formik.values.mpPrimaryCenter}
                                        onChange={formik.handleChange}
                                        options={mpCenterOptions}
                                        placeholder="Max 30 characters"
                                    />
                                    {formik.errors.mpPrimaryCenter ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.mpPrimaryCenter}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>MP Permissions</h4>
                                    <SelectSingle
                                        id="employees-mpPermissions"
                                        name="mpPermissions"
                                        value={formik.values.mpPermissions}
                                        onChange={formik.handleChange}
                                        options={mpPermissionsOptions}
                                    />
                                    {formik.errors.mpPermissions ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.mpPermissions}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>RP Primary Center</h4>
                                    <SelectSingle
                                        id="employees-rpCenter"
                                        name="rpPrimaryCenter"
                                        value={formik.values.rpPrimaryCenter}
                                        onChange={formik.handleChange}
                                        options={rpCenterOptions}
                                        placeholder="Max 30 characters"
                                    />
                                    {formik.errors.rpPrimaryCenter ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.rpPrimaryCenter}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>RP Permissions</h4>
                                    <SelectSingle
                                        id="employees-rpPermissions"
                                        name="rpPermissions"
                                        value={formik.values.rpPermissions}
                                        onChange={formik.handleChange}
                                        options={rpPermissionsOptions}
                                    />
                                    {formik.errors.rpPermissions ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.rpPermissions}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="flex flex-row gap-x-4">
                                    <Check
                                        id="employees-receive-emails"
                                        name="receiveEmails"
                                        label="Receive Emails"
                                        color="mpDBlue"
                                        checked={formik.values.receiveEmails}
                                        onChange={formik.handleChange}
                                    />
                                    <Check
                                        id="employees-receive-texts"
                                        name="receiveTexts"
                                        label="Receive Texts"
                                        color="mpDBlue"
                                        checked={formik.values.receiveTexts}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                {mode === 'create' ? null : 
                                    <div className="flex flex-row gap-x-4">
                                        <Check
                                            id="employees-verified"
                                            name="verified"
                                            label="Verified"
                                            color="mpDBlue"
                                            checked={formik.values.verified}
                                            onChange={formik.handleChange}
                                        />
                                        <Check
                                            id="employees-active"
                                            name="accountActive"
                                            label="Active"
                                            color="mpDBlue"
                                            checked={formik.values.accountActive}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                }
                            </div>

                            {mode !== 'create' ? 
                                <>

                                    <br/>
                                    <hr/>
                                    <br/>

                                    {showPass ? 
                                        <>
                                            <h6
                                                className="text-mpLBlue table cursor-pointer"
                                                onClick={() => {
                                                    const newValues = formik.values;
                                                    newValues.passwordReset = '';
                                                    newValues.passwordConfirm = '';
                                                    formik.setValues(newValues);
                                                    setShowPass(false);
                                                }}
                                            >
                                                Hide Password Fields
                                            </h6>
                                            <br/>
                                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                                <h4>Reset Password</h4>
                                                <FormikControl
                                                    id="employees-password-reset"
                                                    name="passwordReset"
                                                    type="password"
                                                    value={formik.values.passwordReset}
                                                    onChange={formik.handleChange}
                                                    // type="password"
                                                    placeholder="One lower case letter, one upper case letter, one number"
                                                />
                                                {formik.errors.passwordReset ? (
                                                    <ErrorMessage color="mpLRed">
                                                        {formik.errors.passwordReset}
                                                    </ErrorMessage>
                                                ) : null}

                                                <br/>

                                                <h4>Confirm Password</h4>
                                                <FormikControl
                                                    id="employees-password-reset"
                                                    name="passwordConfirm"
                                                    type="password"
                                                    value={formik.values.passwordConfirm}
                                                    // type="password"
                                                    onChange={formik.handleChange}
                                                />
                                                {formik.errors.passwordConfirm ? (
                                                    <ErrorMessage color="mpLRed">
                                                        {formik.errors.passwordConfirm}
                                                    </ErrorMessage>
                                                ) : null}

                                                {resetSuccess ? 
                                                    <>
                                                        <div className="text-mpGreen">Password reset successfully!</div>
                                                        <div className="h-4 clear-both"/>
                                                    </>
                                                : null}

                                                <div className="flex flex-row ml-auto">
                                                    <Button
                                                        color="lte-mpLBlue"
                                                        disabled={resetSuccess || formik.isSubmitting || formik.errors.passwordReset ||
                                                            formik.errors.passwordConfirm || (formik.values.passwordReset !== formik.values.passwordConfirm)}
                                                        onClick={() => passwordReset(formik)}
                                                    >
                                                        Reset
                                                    </Button>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <h5
                                                className="text-mpLBlue table cursor-pointer"
                                                onClick={() => setShowPass(true)}
                                            >
                                                Show Password Fields
                                            </h5>
                                        </>
                                    }
                                </> : null
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(EmployeesBodyFooter);