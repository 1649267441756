import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Label } from '../../../../components/custom-essentials';
import { Table } from '../../../../components/table';
import { AppointmentModal, DetailsModal, StampsModal } from '../../../../components/modal';
import { Wait } from '../../../../components/wait';
import { formatTime } from '../../../../components/functions';
import { getColumns } from './table/getColumns';
import { checkResponse } from '../../../../components/form';

import {
    updateAppointmentStatus
} from '../../../../actions';

const defaultSorted = [{ dataField: 'time', order: 'asc' }];

async function startTimer(nextTime, setNextTime, mounted){
    while(true){
        const now = new Date();
        
        const hours = now.getHours() + (now.getMinutes() < 30 ? 0 : 1);
        const minutes = now.getMinutes() < 30 ? 30 : 0;
        const newNextTime = hours * 60 + minutes * 1;
        
        if(mounted.current){
            if(newNextTime !== nextTime){
                nextTime = newNextTime;
                setNextTime(newNextTime);
            }
        }
        else break;

        await Wait(30000);
    }
}

function AppointmentsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { legacyView, selectedDate, students, centerOptions, selectedCenter,
        appointments, splitAppointments, refreshData, updateAppointmentStatus, permissions } = props;

    const [loading, setLoading] = useState(false);
    const [nextTime, setNextTime] = useState((new Date()).getHours() * 60 + (new Date()).getMinutes() * 1);
    const [modalMode, setModalMode] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState(null);

    useEffect(() => {
        startTimer(nextTime, setNextTime, mounted);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleShowModal = useCallback((mode, appointment) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedAppointment(appointment);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedAppointment(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);
    const handleStatusButtonClick = useCallback((appointment, newStatus) => {
        (async function(){
            if(loading) return;
            setLoading(true);
    
            const updateParams = {
                id: appointment.id,
                status: newStatus
            };
            const response = await updateAppointmentStatus(updateParams);
            checkResponse(response, mounted, () => null);
    
            if(mounted.current){
                refreshData();
                setLoading(false);
            }
        })()
    }, [loading, updateAppointmentStatus, refreshData]);

    const columns = useMemo(() => {
        return getColumns(loading, handleShowModal, handleStatusButtonClick, selectedCenter, nextTime, permissions);
    }, [loading, handleShowModal, handleStatusButtonClick, selectedCenter, nextTime, permissions]);

    return (
        <div>
            {modalMode === 'appointment-edit' && 
                <AppointmentModal
                    mode="edit"
                    students={students}
                    selectedDate={selectedDate}
                    centerOptions={centerOptions}
                    selectedAppointment={selectedAppointment}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {modalMode === 'appointment-delete' && 
                <AppointmentModal
                    mode="delete"
                    students={students}
                    selectedAppointment={selectedAppointment}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {modalMode === 'details' &&
                <DetailsModal
                    selectedAppointment={selectedAppointment}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {modalMode === 'stamps' &&
                <StampsModal
                    selectedAppointment={selectedAppointment}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {!appointments.length ?
                <>
                    <br/>
                    <h5 className="text-mpOrange">No appointments found with the given criteria.</h5>
                </>
                :
                <>
                    <h5>Next time block: <Label color="mpBlue">{formatTime(nextTime)}</Label></h5>
                    <br/>
                    {/* {legacyView ? legacyTables : newTable} */}
                    {legacyView ?
                        <>
                            {Object.entries(splitAppointments).map(([timeKey, groupAppointmentObj]) => {
                                timeKey = parseInt(timeKey);
                                return(
                                    <div key={`sched-apt-table-${timeKey}`}>
                                        <h2>{formatTime(timeKey)}</h2>
                                        {Object.entries(groupAppointmentObj).map(([groupId, groupObj]) => {
                                            const { groupName, appointments } = groupObj;
                                            return (
                                                <div key={`sched-apt-table${timeKey}-${groupId}`}>
                                                    <h4>{groupName}</h4>
                                                    <div className="h-4 clear-both"/>
                                                    <Table
                                                        tableName={`scheduling-appointments-${timeKey}`}
                                                        data={appointments}
                                                        columns={columns}
                                                        defaultSorted={defaultSorted}
                                                        rowsPerPageInitial={100}
                                                        emptyMessage="No appointments were found"       
                                                    />
                                                    <br/>
                                                </div>
                                            );
                                        })}
                                        <br/>
                                        <br/>
                                    </div>
                                )
                            })}
                        </> : <Table
                            tableName="scheduling-appointments"
                            data={appointments}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            rowsPerPageInitial={100}
                            emptyMessage="No appointments were found"
                        />
                    }
                </>
            }
        </div>
    );
}

export default connect(null, {
    updateAppointmentStatus
})(AppointmentsTable);