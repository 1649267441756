import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl, Check } from '../../form';
import { validationSchema, difficultyOptions, getInitDifficulty, themeOptions, getInitTheme, 
    typeOptions, getInitType, renderMeta } from './helpers';

import { TooltipWrapper } from '../../display';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function BooksBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedBook, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this book?</h4>
                            <div className="grid grid-cols-1">
                                <div>Title: {selectedBook.title}</div>
                                <div>Author: {selectedBook.author}</div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed text-[8pt] mr-4">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    title: selectedBook.title || '',
                    author: selectedBook.author || '',
                    difficulty: getInitDifficulty(selectedBook.difficulty),
                    theme: getInitTheme(selectedBook.theme),
                    type: getInitType(selectedBook.type),
                    notes: selectedBook.notes || '',
                    contentWarning: selectedBook.content_warning || '',
                    active: parseInt(selectedBook.active) === 0 ? false : true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Title</h4>
                                    <FormikControl
                                        id="books-title"
                                        name="title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        placeholder="Max. 200 characters."
                                    />
                                    {formik.errors.title ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.title}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Author</h4>
                                    <FormikControl
                                        id="books-author"
                                        name="author"
                                        value={formik.values.author}
                                        onChange={formik.handleChange}
                                        placeholder="Max. 200 characters."
                                    />
                                    {formik.errors.author ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.author}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Difficulty</h4>
                                    <SelectSingle
                                        id="books-difficulty"
                                        name="difficulty"
                                        value={formik.values.difficulty}
                                        onChange={formik.handleChange}
                                        options={difficultyOptions}
                                    />
                                    {formik.errors.difficulty ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.difficulty}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Theme</h4>
                                    <SelectSingle
                                        id="books-theme"
                                        name="theme"
                                        value={formik.values.theme}
                                        onChange={formik.handleChange}
                                        options={themeOptions}
                                    />
                                    {formik.errors.theme ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.theme}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Type</h4>
                                    <SelectSingle
                                        id="books-type"
                                        name="type"
                                        value={formik.values.type}
                                        onChange={formik.handleChange}
                                        options={typeOptions}
                                    />
                                    {formik.errors.type ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.type}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2">
                                <h4>Book Notes</h4>
                                <FormikControl
                                    id="books-notes"
                                    name="notes"
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    as="textarea"
                                    placeholder="Max. 500 characters."
                                />
                                {formik.errors.notes ? (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.notes}
                                    </ErrorMessage>
                                ) : null}
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2">
                                <h4>Content Warning</h4>
                                <FormikControl
                                    id="books-contentWarning"
                                    name="contentWarning"
                                    value={formik.values.contentWarning}
                                    onChange={formik.handleChange}
                                    placeholder="Max. 100 characters."
                                />
                                {formik.errors.contentWarning ? (
                                    <ErrorMessage color="mpLRed">
                                        {formik.errors.contentWarning}
                                    </ErrorMessage>
                                ) : null}
                            </div>

                            <br/>

                            <div className="grid grid-cols-1 gap-y-2">
                                <div className="row no-mar">
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                Inactive books are hidden by default.
                                            </div>
                                        }
                                    >
                                        <Check
                                            id="books-active"
                                            name="active"
                                            color="mpDBlue"
                                            label={<div className="text-mpLBlue">Active</div>}
                                            checked={formik.values.active}
                                            onChange={formik.handleChange}
                                        />
                                    </TooltipWrapper>
                                </div>
                            </div>

                            {renderMeta(selectedBook)}
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-4">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(BooksBodyFooter);