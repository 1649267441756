import { TooltipWrapper } from '../../display';
import { Button, SVGIcon } from '../../custom-essentials';

export function modifyBookListTemplate(formik, bookListTemplateItem, mode){
    if(!bookListTemplateItem) return;
    const blItems = [ ...formik.values.bookListTemplateItems ];

    if(mode === 'add'){
        blItems.push({
            id: parseInt(bookListTemplateItem.id),
            dateAssigned: '',
            dateCompleted: '',
            title: bookListTemplateItem.title,
            author: bookListTemplateItem.author,
            sortOrder: blItems.length + 1
        });
    } else {
        const currentIndex = blItems.findIndex(i => parseInt(i.id) === parseInt(bookListTemplateItem.id));
        if(mode === 'shiftUp'){
            if(currentIndex === 0) return;
    
            blItems[currentIndex].sortOrder--;
            blItems[currentIndex - 1].sortOrder++;
        } else if(mode === 'shiftDown'){
            if(currentIndex >= blItems.length - 1) return;
    
            blItems[currentIndex].sortOrder++;
            blItems[currentIndex + 1].sortOrder--;
        } else if(mode === 'delete'){
            blItems.splice(currentIndex, 1);
        }
    }


    blItems.sort((a, b) => parseInt(a.sortOrder) - parseInt(b.sortOrder));
    formik.setFieldValue('bookListTemplateItems', blItems);
}

export function renderBli(bli, formik, positionIndex){
    const stagedToRemove = formik.values.stagedToRemoveIndex === positionIndex;
    const currentIndex = positionIndex;
    return (
        <div key={`learning-plans-book-id-${bli.id}`}>
            <div className="flex flex-row gap-x-2">
                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                    <h4><u>{currentIndex + 1}: {bli.title}</u></h4>
                </div>
                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                    <div className="flex flex-row gap-x-2">
                        <h4>{currentIndex + 1}:</h4>&emsp;
                        <TooltipWrapper
                            tooltipText="Shift book up"
                        >
                            <Button
                                color="lte-mpYellow"
                                variant="icon"
                                disabled={currentIndex === 0}
                                onClick={() => modifyBookListTemplate(formik, bli, 'shiftUp')}
                            >
                                <SVGIcon fileName="arrow-up"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText="Shift book down"
                        >
                            <Button
                                color="lte-mpYellow"
                                variant="icon"
                                disabled={currentIndex >= formik.values.bookListTemplateItems.length - 1}
                                onClick={() => modifyBookListTemplate(formik, bli, 'shiftDown')}
                            >
                                <SVGIcon fileName="arrow-down"/>
                            </Button>
                        </TooltipWrapper>

                        { stagedToRemove ? 
                        <>
                            <TooltipWrapper
                                tooltipText="Cancel remove"
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => formik.setFieldValue('stagedToRemoveIndex', -1)}
                                >
                                    <SVGIcon fileName="undo"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText="Confirm remove"
                            >
                                <Button
                                    color="lte-mpLRed"
                                    variant="icon"
                                    onClick={() => {
                                        modifyBookListTemplate(formik, bli, 'delete');
                                        formik.setFieldValue('stagedToRemoveIndex', -1);
                                    }}
                                >
                                    <SVGIcon fileName="trash"/>
                                </Button>
                            </TooltipWrapper>
                        </>
                        : 
                        <TooltipWrapper
                            tooltipText="Remove book"
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => formik.setFieldValue('stagedToRemoveIndex', currentIndex)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>}
                    </div>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}