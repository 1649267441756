import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { TimeSheetsTable } from './timesheetsTable/TimeSheetsTable';
import { PayPeriodSelector } from './PayPeriodSelector/PayPeriodSelector';
import { DateRangeSelector, checkResponses, SelectSingle } from '../../../../components/form';
import { formatDateApi, getPayPeriod } from '../../../../components/functions';
import { InstructorAttendanceModal } from '../../../../components/modal';
import { TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import { getHours, getHoursFromFormatted } from './timesheetsTable/getHours';

import {
    fetchAdminUsersAll,
    fetchInstructorAttendanceDaterangeUserIds
} from '../../../../actions';

const [startDate, endDate] = getPayPeriod(new Date());
const startApi = formatDateApi(startDate);
const endApi = formatDateApi(endDate);

function TimesheetTables(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // DateRangeSelector
    const [valid, setValid] = useState(true);
    // Data
    const [employeeMap, setEmployeeMap] = useState({});
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [attendances, setAttendances] = useState({});
    const [attendancesAsList, setAttendancesAsList] = useState({});
    const [showList, setShowList] = useState([]);

    // Modal
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    // Initialize showList
    useEffect(() => {
        let oldShowList = JSON.parse(localStorage.getItem('admin_timesheets_showList'));
        if(!oldShowList || oldShowList === 'null') oldShowList = [];
        let newShowList = [];

        // If duplicates somehow got into the list, delete the list from storage
        // and reset
        for(let id of oldShowList){
            if(newShowList.includes(id)){
                newShowList = [];
                localStorage.removeItem('admin_timesheets_showList');
                break;
            }
            newShowList.push(id);
        }
        setShowList(newShowList);
    }, [])
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    // showList determines which tables to render
    const setShowListWrapper = useCallback((id, mode) => {
        let newShowList = [...showList];
        if(mode === 'add'){
            newShowList.push(id);
        } else if(mode === 'remove') {
            newShowList.splice(newShowList.indexOf(id), 1);
        }

        localStorage.setItem('admin_timesheets_showList', JSON.stringify(newShowList));
        if(mounted.current) setShowList(newShowList);
    }, [showList]);

    const { refreshCount, setRefreshCount, fetchAdminUsersAll, fetchInstructorAttendanceDaterangeUserIds } = props;

    const refreshData = useCallback((isOrigin = false) => {
        (async function refresh(){
            if(loading || !formRef.current.values || !valid) return;
            setLoading(true);
    
            function sortAttendances(attendances){
                const sorted = {};
                attendances.forEach(att => {
                    const userId = att.user_id;
                    if(!sorted[userId]) sorted[userId] = [];
                    sorted[userId].push(att)
                });
            
                return sorted;
            }
    
            const { startDate, endDate } = formRef.current.values;
    
            const employeesRes = await fetchAdminUsersAll();
            const isApiError = checkResponses(employeesRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newEmployees = employeesRes.data || [];
            const newEmployeeOptions = newEmployees.filter(e => {
                return e.rp_permissions !== 'None' && parseInt(e.account_active) === 1;
            }).map(e => ({ value: e.id, label: `${e.first_name} ${e.last_name}`}));

            const newEmployeeMap = {};
            // Last, first is used for sorting. First, last is used to display
            newEmployees.forEach(u => {
                const isInactive = parseInt(u.account_active) === 0;
                const activeText = isInactive ? ' (Inactive)' : '';
                newEmployeeMap[u.id] = {
                    byFirst: `${u.first_name} ${u.last_name}${activeText}`,
                    byLast: `${u.last_name} ${u.first_name}`,
                    isInactive
                }
            });
            const employeeIds = newEmployees.map(e => e.id);
            
            const attendancesRes = await fetchInstructorAttendanceDaterangeUserIds({
                startDate: startDate,
                endDate: endDate,
                userIds: employeeIds
            });
            const isApiError2 = checkResponses(attendancesRes);
            if(isApiError2){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAttendances = attendancesRes.data || [];
            const sortedData = sortAttendances(newAttendances);
            if(mounted.current){
                if(isOrigin){
                    const { checkIn, attendanceHistory, timesheetTables } = refreshCount;
                    setRefreshCount({
                        checkIn: checkIn + 1,
                        attendanceHistory: attendanceHistory + 1,
                        timesheetTables,
                    });
                }
                setEmployeeOptions(newEmployeeOptions);
                setEmployeeMap(newEmployeeMap);
                setAttendances(sortedData);
                setAttendancesAsList(newAttendances);
                setLoading(false);
            }
        })();
    }, [refreshCount, setRefreshCount, fetchAdminUsersAll, fetchInstructorAttendanceDaterangeUserIds,
        formRef, valid, loading, setLoading]);
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshCount.timesheetTables]);
    function handleShowModal(mode, item){
        setShowModal(true);
        setModalMode(mode);
        setSelectedItem(item);
    }
    function onSubmitCallback(changes = true){
        if(changes) refreshData(true);
        setShowModal(false);
        setModalMode(null);
        setSelectedItem(null);
    }

    return (
        <div>
            <h2>Employee Timesheets ({attendancesAsList.length})</h2>
            <br/>
            <Formik
                enableReinitialize
                initialValues={{
                    startDate: startApi,
                    endDate: endApi,
                    selectedInstructor: { value: -1, label: 'Select an instructor...' },
                    payPeriod: { value: -1, label: 'Select a pay period...' }
                }}
                onSubmit={() => refreshData()}
                innerRef={formRef}
            >
                {formik => (
                    <>
                        <div className="flex flex-row gap-x-4 items-center">
                            <div className="grid grid-cols-1 gap-y-2">
                                <DateRangeSelector
                                    id="attendanceHistory-drs-1"
                                    startName="startDate"
                                    endName="endDate"
                                    startLabel="Start Date"
                                    endLabel="End Date"
                                    startValue={formik.values.startDate}
                                    endValue={formik.values.endDate}
                                    defaultValid={true}
                                    onStartChange={formik.handleChange}
                                    onEndChange={formik.handleChange}
                                    onChangeValidation={setValid}
                                />
                            </div>
                            <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                <PayPeriodSelector
                                    formik={formik}
                                    refreshData={() => refreshData(true)}
                                    setValid={setValid}
                                    id="attendanceHistory-payPeriodSelector"
                                />
                            </div>
                            <div>
                                <Button
                                    color="lte-mpTeal"
                                    onClick={formik.handleSubmit}
                                >
                                    Search
                                </Button>
                            </div>
                            <div style={{ alignSelf: "center" }}>
                                {getHoursFromFormatted(attendances)}
                            </div>
                            <div className="flex ml-auto">
                                <TooltipWrapper
                                    tooltipText={
                                        <div>
                                            <div>
                                                What gets exported?
                                            </div>
                                            <br/>
                                            <div>
                                                All attendances that are currently filtered ({attendancesAsList.length} items).
                                            </div>
                                        </div>
                                    }
                                >
                                    <CSVExport
                                        title="Timesheets"
                                        label="Export timesheets to CSV"
                                        data={attendancesAsList}
                                    />
                                </TooltipWrapper>
                            </div>
                        </div>

                        <br/>

                        <h4>Add Attendance</h4>
                        <div className="flex flex-row gap-x-4 items-center">
                            <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                <SelectSingle
                                    id="attendanceHistory-selectedInstructor"
                                    name="selectedInstructor"
                                    value={formik.values.selectedInstructor}
                                    onChange={formik.handleChange}
                                    options={employeeOptions}
                                />
                            </div>
                            <div>
                                <Button
                                    color="lte-mpLBlue"
                                    disabled={(function(){
                                        const selectedInstructorId = formik.values.selectedInstructor.value;
                                        const isSelected = selectedInstructorId === -1;
                                        const relAttendances = attendances[selectedInstructorId] || [];
                                        const selectedIsSignedIn = relAttendances.some(a => !a.time_out);

                                        return isSelected || selectedIsSignedIn;
                                    })()}
                                    onClick={() => handleShowModal('create', formik.values.selectedInstructor.value)}
                                >
                                    + Add Attendance
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </Formik>

            <br/>
            <hr/>
            <br/>

            { apiError ? <div className="text-mpLRed">{apiError}</div> : 
            Object.keys(attendances).sort((a, b) => {
                if(employeeMap[a]?.byLast < employeeMap[b]?.byLast) return -1;
                else if (employeeMap[a]?.byLast > employeeMap[b]?.byLast) return 1;
                return 0;
            }).map(userId => {
                const relAttendances = attendances[userId];
                const isSignedIn = relAttendances.some(a => !a.time_out);
                const shouldShow = showList.includes(userId);
                const isInactive = employeeMap[userId]?.isInactive;
                return (
                    <div key={`attendance-group=${userId}`}>
                        <div className="flex flex-row gap-x-4 items-center">
                            {shouldShow ? 
                                <h2
                                    className="cursor-pointer text-mpDBlue self-start"
                                    onClick={() => setShowListWrapper(userId, 'remove')}
                                >
                                    {employeeMap[userId]?.byFirst || `Unknown user (ID: ${userId})`} ({relAttendances.length})
                                </h2> : 
                                <h2
                                    className="cursor-pointer text-mpLBlue self-start"
                                    onClick={() => setShowListWrapper(userId, 'add')}
                                >
                                    {employeeMap[userId]?.byFirst || `Unknown user (ID: ${userId})`} ({relAttendances.length})
                                </h2>
                            }
                            <div>{getHours(relAttendances)}</div>
                            {isInactive ? null : 
                                <Button
                                    color="lte-mpLBlue"
                                    disabled={isSignedIn}
                                    onClick={() => handleShowModal('create', userId)}
                                >
                                    + Add Attendance
                                </Button>
                            }
                        </div>
                        { shouldShow &&
                            <>
                                <br/>
                                <TimeSheetsTable
                                    data={relAttendances}
                                    handleShowModal={handleShowModal}
                                />
                            </>
                        }
                        <div className="h-8 clear-both"/>
                        <hr/>
                        <div className="h-8 clear-both"/>
                    </div>
                );
            })} { showModal &&
                <InstructorAttendanceModal
                    mode={modalMode}
                    selectedItem={selectedItem}
                    onSubmitCallback={onSubmitCallback}
                />
            }
        </div>
    );
}

export default connect(null, {
    fetchAdminUsersAll,
    fetchInstructorAttendanceDaterangeUserIds
})(TimesheetTables);