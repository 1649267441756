import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { formatTime, getAlerts, formatDateFull } from '../../../../../components/functions';
import { renderStatus, renderPhone, TooltipWrapper, tableColors } from '../../../../../components/display';

export function getColumns(loading, handleShowModal, handleStatusButtonClick, selectedCenter, nextTime, permissions){
    const isAll = selectedCenter === 'all';
    const statusSortObject = {
        'Not Charged': 1,
        'Missed': 2,
        'Cancelled': 3,
        'Scheduled': 4,
        'In Progress': 5,
        'Completed': 6
    };

    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};
    const columns = [
        {
            dataField: 'student',
            text: 'Student',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? 1 : -1;
                if(rowA.studentName < rowB.studentName) return -1 * swap;
                else if(rowA.studentName > rowB.studentName) return 1 * swap;
                else return 0;
            },
            formatter: (row) => {
                const student = row.studentInfo || {};
                const parent = row.parentObject || {};
                const firstGuardianContent = student.first_guardian_notes ? (
                    <>
                        <br/>
                        <u>Additional Contacts</u>
                        <div>First Guardian Notes: {student.first_guardian_notes}</div>
                    </>
                ) : null;
                const secondGuardianContent = student.second_guardian_notes ? (
                    <>
                        <br/>
                        <div>Second Guardian Notes: {student.second_guardian_notes}</div>
                    </>
                ) : null;
                return (
                    <TooltipWrapper
                        placement="right"
                        tooltipText={
                            <>
                                <u>School Info</u>
                                <div>School: {student.school || 'Not Specified'}</div>
                                <div>Teacher: {student.mp_teacher || 'Not Specified'}</div>
                                <div>Grade: {student.grade || 'Not Specified'}</div>
                                <div>School Year: {student.school_year || 'Not Specified'}</div>
            
                                <br/>
            
                                <u>Account Info</u>
                                <div>Weight: {student.mp_weight}</div>
                                <div>Next Month Begins:</div>
                                <div>{formatDateFull(student.mp_next_month_start)}</div>
                                <div>
                                    Hours This Month:&nbsp;
                                    {(student.mp_time_left_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>
                                    Hours Next Month:&nbsp;
                                    {(student.mp_time_left_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>
                                    Reserve Hours Left:&nbsp;
                                    {(student.mp_reserve_time_left / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
                                <div>
                                    Default Hours:&nbsp;
                                    {(student.mp_default_time_per_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                                </div>
            
                                <br/>
            
                                <u>Student Contact:</u>
                                <div>Phone: {renderPhone(student.phone)}</div>
                                <div>Email: {student.email}</div>
            
                                <br/>
            
                                <u>Parent Contact:</u>
                                <div>Name: {parent.first_name} {parent.last_name}</div>
                                <div>Phone: {renderPhone(parent.phone)}</div>
                                <div>Email: {parent.email}</div>
            
                                {firstGuardianContent}
                                {secondGuardianContent}
                            </>
                        }
                    >
                        <div className="mtext-mpLBlue">{student.first_name} {student.last_name}</div>
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'start',
            text: 'Start Time',
            sort: true,
            formatter: (row) => {
                const start = row.start;
                const timeFormatted = formatTime(start);
                if(parseInt(start) === nextTime){
                    return <Label color="mpBlue">{timeFormatted}</Label>;
                }
                return timeFormatted;
            }
        },
        {
            dataField: 'end',
            text: 'End Time',
            sort: true,
            formatter: (row) => {
                const end = row.end
                const timeFormatted = formatTime(end);
                if(parseInt(end) === nextTime){
                    return <Label color="mpBlue">{timeFormatted}</Label>;
                }
                return timeFormatted;
            }
        },
        {
            dataField: 'instructorNames',
            text: 'Instructors',
        },
        {
            dataField: 'groupName',
            text: 'Group',
            sort: true
        },
        {
            dataField: 'alerts',
            text: 'Alerts',
            formatter: (row) => getAlerts(row)
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                return (statusSortObject[a] - statusSortObject[b]) * swap;
            },
            formatter: (row) => renderStatus(row)
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                const si = row.studentInfo || {};
                const studentName = `${si.first_name} ${si.last_name}`;
                const name1 = si.rp_drive_folder_link_1_name || 'Unnamed';
                const driveLink1 = si.rp_drive_folder_link_1 ? (
                    <div className="mr-2">
                        <TooltipWrapper
                            tooltipText={`View ${studentName}'s drive folder (${name1})`}
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                onClick={() => window.open(si.rp_drive_folder_link_1, '_blank')}
                            >
                                <SVGIcon fileName="external-link"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                ) : null;
                const name2 = si.rp_drive_folder_link_2_name || 'Unnamed';
                const driveLink2 = si.rp_drive_folder_link_2 ? (
                    <div className="mr-2">
                        <TooltipWrapper
                            tooltipText={`View ${studentName}'s drive folder (${name2})`}
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                onClick={() => window.open(si.rp_drive_folder_link_2, '_blank')}
                            >
                                <SVGIcon fileName="external-link"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                ) : null;
                const name3 = si.rp_drive_folder_link_3_name || 'Unnamed';
                const driveLink3 = si.rp_drive_folder_link_3 ? (
                    <div className="mr-2">
                        <TooltipWrapper
                            tooltipText={`View ${studentName}'s drive folder (${name3})`}
                        >
                            <Button
                                color="lte-mpEGreen"
                                variant="icon"
                                // onClick={() => window.open(si.rp_drive_folder_link_3, '_blank')}
                                onClick={() => null}
                            >
                                <a href={si.rp_drive_folder_link_3} target="blank" rel="noopener noreferrer">
                                    <SVGIcon fileName="external-link"/>
                                </a>
                            </Button>
                        </TooltipWrapper>
                    </div>
                ) : null;
                const name4 = si.rp_drive_folder_link_4_name || 'Unnamed';
                const driveLink4 = si.rp_drive_folder_link_4 ? (
                    <TooltipWrapper
                        tooltipText={`View ${studentName}'s drive folder (${name4})`}
                    >
                        <Button
                            color="lte-mpEGreen"
                            variant="icon"
                            onClick={() => window.open(si.rp_drive_folder_link_4, '_blank')}
                        >
                            <SVGIcon fileName="external-link"/>
                        </Button>
                    </TooltipWrapper>
                ) : null;

                const driveLinks2To4 = driveLink2 || driveLink3 || driveLink4 ? (
                    <>
                        {driveLink2 ?
                            (
                                <TooltipWrapper
                                    tooltipText={`Open ${studentName}'s folder (${name2})`}
                                >
                                    <Button
                                        color="lte-mpEGreen"
                                        variant="icon"
                                        onClick={() => window.open(si.rp_drive_folder_link_2, '_blank')}
                                    >
                                        <SVGIcon fileName="external-link"/>
                                    </Button>
                                </TooltipWrapper>
                            ) : null
                        }
                        {driveLink3 ?
                            (
                                <TooltipWrapper
                                    tooltipText={`Open ${studentName}'s folder (${name3})`}
                                >
                                    <Button
                                        color="lte-mpEGreen"
                                        variant="icon"
                                        onClick={() => window.open(si.rp_drive_folder_link_3, '_blank')}
                                    >
                                        <SVGIcon fileName="external-link"/>
                                    </Button>
                                </TooltipWrapper>
                            ) : null
                        }
                        {driveLink4 ?
                            (
                                <TooltipWrapper
                                    tooltipText={`Open ${studentName}'s folder (${name4})`}
                                >
                                    <Button
                                        color="lte-mpEGreen"
                                        variant="icon"
                                        onClick={() => window.open(si.rp_drive_folder_link_4, '_blank')}
                                    >
                                        <SVGIcon fileName="external-link"/>
                                    </Button>
                                </TooltipWrapper>
                            ) : null
                        }
                    </>
                ) : null;

                return (
                    <>
                        <div className="flex flex-row gap-x-2 ml-2">
                            <TooltipWrapper
                                tooltipText={`Edit this appointment`}
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowModal('appointment-edit', row)}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="pencil"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Update appointment details`}
                            >
                                <Button
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowModal('details', row)}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="plus"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Give stamps for this appointment`}
                            >
                                <Button
                                    color={row.stamps_given ? 'lte-mpDBlue' : 'lte-mpPurple'}
                                    variant="icon"
                                    onClick={() => handleShowModal('stamps', row)}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="star"/>
                                </Button>
                            </TooltipWrapper>
                            { ['Root', 'Admin', 'Lead Instructor'].includes(permissions) && 
                                <TooltipWrapper
                                    tooltipText={`Delete this appointment`}
                                >
                                    <Button
                                        color="lte-mpLRed"
                                        variant="icon"
                                        onClick={() => handleShowModal('appointment-delete', row)}
                                        disabled={loading}
                                    >
                                        <SVGIcon fileName="trash"/>
                                    </Button>
                                </TooltipWrapper>
                            }
                        </div>
                        <div className="flex flex-row gap-x-2 mt-2">
                            <TooltipWrapper
                                tooltipText={`Mark appointment as "In Progress"`}
                            >
                                <Button
                                    color="hol-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleStatusButtonClick(row, 'In Progress')}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="ellipsis"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Mark appointment as "Completed"`}
                            >
                                <Button
                                    color="hol-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleStatusButtonClick(row, 'Completed')}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="check"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Mark appointment as "Missed"`}
                            >
                                <Button
                                    color="hol-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleStatusButtonClick(row, 'Missed')}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="question"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Mark appointment as "Cancelled"`}
                            >
                                <Button
                                    color="hol-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleStatusButtonClick(row, 'Cancelled')}
                                    disabled={loading}
                                >
                                    <SVGIcon fileName="times"/>
                                </Button>
                            </TooltipWrapper>
                        </div>
                        <div className="flex flex-row ml-2 gap-x-2 mt-2">
                            {driveLink1 ?
                                (
                                    <TooltipWrapper
                                        tooltipText={`Open ${studentName}'s folder (${name1})`}
                                    >
                                        <Button
                                            color="lte-mpEGreen"
                                            variant="icon"
                                            onClick={() => window.open(si.rp_drive_folder_link_1, '_blank')}
                                        >
                                            <SVGIcon fileName="external-link"/>
                                        </Button>
                                    </TooltipWrapper>
                                ) : null
                            }
                            {driveLinks2To4}
                        </div>
                    </>
                );
            }
        }
    ];

    if(isAll){
        columns.splice(6, 0, {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(rowA.center) - parseInt(rowB.center)) * swap;
            },
            formatter: (row) => {
                const center = parseInt(row.center);
                let centerColor = '';
                if(!colorMap[center])  colorMap[center] = colors[(colorIndex++) % 5];
                centerColor = colorMap[center];
                return (
                    <div className="mr-2">
                        <Label color={centerColor}>
                            {row.centerName}
                        </Label>
                    </div>
                );
            }
        });
    }
    return columns;
}