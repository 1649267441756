import { basicPath } from '../basicPath';

export const fetchBookListItemsStudentDaterangeCompleted = (params) => async (dispatch) => {
    const path = '/rp/book-list-items/student-daterange-completed';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const updateBookListItems = (params) => async (dispatch) => {
    const path = '/rp/book-list-items';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}