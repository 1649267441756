import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { toAbsoluteUrl } from '../../functions';
import { Button } from '../../custom-essentials';
import MenuItem from './components/MenuItem';
import MenuDropdown from './components/MenuDropdown';

import {

} from '../../../actions';

function NavBar(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { permissions } = props;

    const [activeItem, setActiveItem] = useState(null);
    const setActiveWrapper = useCallback((e, newActiveItem) => {
        if(mounted.current){
            if(activeItem === newActiveItem) setActiveItem(null);
            else setActiveItem(newActiveItem);
            e.stopPropagation();
        }
    }, [activeItem, setActiveItem]);

    const menuContents = useMemo(() => {
        const newMenuContents = {
            appointments: [],
            students: [],
            curriculum: [],
            accounts: [],
            admin: []
        };

        const isRoot = permissions === 'Root';
        const isAdmin = permissions === 'Admin';
        const isLead = permissions === 'Lead Instructor';
        const isInstructor = permissions === 'Instructor';

        if(isRoot || isAdmin || isLead || isInstructor){
            newMenuContents.appointments.push({ label: "Scheduling", to: "/appointments/scheduling", order: 1 });
            newMenuContents.appointments.push({ label: "Appointment Search", to: "/appointments/appointment-search", order: 2 });

            newMenuContents.students.push({ label: "Stamps Logs", to: "/students/stamps-logs", order: 5 });

            newMenuContents.admin.push({ label: "Timesheets", to: "/admin/timesheets", order: 2 });
        }
        if(isRoot || isAdmin || isLead){
            newMenuContents.appointments.push({ label: "Auto Scheduler", to: "/appointments/auto-scheduler", order: 3 });

            newMenuContents.students.push({ label: "Flag Manager", to: "/students/flag-manager", order: 1 });
            newMenuContents.students.push({ label: "Group Manager", to: "/students/group-manager", order: 2 });
            newMenuContents.students.push({ label: "Contracts and Payments", to: "/students/contracts-payments", order: 3 });
            newMenuContents.students.push({ label: "Report Generator", to: "/students/report-generator", order: 4 });

            newMenuContents.curriculum.push({ label: "Books", to: "/curriculum/book-manager", order: 1 });
            newMenuContents.curriculum.push({ label: "Book Lists", to: "/curriculum/book-list-manager", order: 2 });
            
            newMenuContents.accounts.push({ label: "Employees", to: "/accounts/employees", order: 1 });
            newMenuContents.accounts.push({ label: "Members", to: "/accounts/members", order: 2 });

            newMenuContents.admin.push({ label: "Text Logs", to: "/admin/text-logs", order: 1 });
        }
        if(isRoot || isAdmin){
            newMenuContents.curriculum.push({ label: "Book List Templates", to: "/curriculum/book-list-template-manager", order: 3 });
        }
        if(isRoot || isAdmin || isLead || isInstructor){
        }

        return newMenuContents;
    }, [permissions])

    return (
        <div>
            {/* <div className="fixed inset-0 pointer-events-auto" onClick={() => setActiveItem('')}/> */}
            <div className="sticky top-0 w-screen h-16 bg-black flex z-[2000]">
                {/* Main NavBar */}
                <div className="flex flex-row gap-x-5 w-screen" onClick={() => setActiveItem('')}>
                    <div className="relative">
                        <img
                            alt="logo"
                            className="h-12 mt-2 mx-4"
                            src={toAbsoluteUrl("/media/logos/darklong.png")}
                        />
                    </div>
                    <div className="flex">
                        <MenuItem
                            label="Home"
                            to="/home"
                        />
                    </div>
                    <div className={`flex ${!menuContents.appointments.length ? 'hidden' : ''}`}>
                        <MenuDropdown
                            label="Appointments"
                            items={menuContents.appointments}
                            active={activeItem === 'appointments'}
                            handleClick={(e) => setActiveWrapper(e, 'appointments')}
                        />
                    </div>
                    <div className={`flex ${!menuContents.students.length ? 'hidden' : ''}`}>
                        <MenuDropdown
                            label="Students"
                            items={menuContents.students}
                            active={activeItem === 'students'}
                            handleClick={(e) => setActiveWrapper(e, 'students')}
                        />
                    </div>
                    <div className={`flex ${!menuContents.curriculum.length ? 'hidden' : ''}`}>
                        <MenuDropdown
                            label="Curriculum"
                            items={menuContents.curriculum}
                            active={activeItem === 'curriculum'}
                            handleClick={(e) => setActiveWrapper(e, 'curriculum')}
                        />
                    </div>
                    <div className={`flex ${!menuContents.accounts.length ? 'hidden' : ''}`}>
                        <MenuDropdown
                            label="Accounts"
                            items={menuContents.accounts}
                            active={activeItem === 'accounts'}
                            handleClick={(e) => setActiveWrapper(e, 'accounts')}
                        />
                    </div>
                    <div className={`flex ${!menuContents.admin.length ? 'hidden' : ''}`}>
                        <MenuDropdown
                            label="Admin"
                            items={menuContents.admin}
                            active={activeItem === 'admin'}
                            handleClick={(e) => setActiveWrapper(e, 'admin')}
                        />
                    </div>
                </div>
                <div className="mr-8 self-center shrink-0">
                    <Button
                        color="hol-mpLRed"
                        onClick={() => null}
                    >
                        <Link to="/logout">
                            Log Out
                        </Link>
                    </Button>
                </div>
            </div>
            {/* Post-Navbar Spacer
            <div className="pb-12 bg-mpLYellow-5"/> */}
        </div>
    );
}

export default connect(null, {

})(NavBar);