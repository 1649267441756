import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    selectedStatus: Yup.mixed().test(
        'statusIsSelected',
        'Select a valid status',
        (value) => value?.value !== -1
    ),
    adminNotes: Yup.string()
        .max(200, 'Max 200 characters'),
});

export const statusOptions = ['Pending', 'Approved', 'Denied'].map(i => ({ value: i, label: i }));
export function getInitStatusOption(status){
    if(!status) return statusOptions[0];
    return statusOptions.find(o => o.value === status) || { value: -1, label: `Unknown status (${status})`};
}