import React from 'react';

import { Button, Label, SVGIcon } from '../../../../../components/custom-essentials';
import { formatDateTime } from '../../../../../components/functions';
import { TooltipWrapper } from '../../../../../components/display';

export function getColumns(handleShowModal){
    return [
        {
            dataField: 'name',
            text: 'Template Name',
            sort: true
        },
        {
            dataField: 'titleAuthorList',
            text: 'Books',
            formatter: (row) => {
                return (
                    <div>
                        {row.templateItems.map(b => {
                            const bookObj = b.bookObj || {};
                            return (
                                <div key={`book-template-item-${row.id}-${bookObj.id}`}>
                                    {bookObj.title} ({bookObj.author})
                                </div>
                            );
                        })}
                    </div>
                );
            }
        },
        {
            dataField: 'date_created',
            text: 'Creation Date',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                const updated = row.date_updated ? `Updated: ${formatDateTime(row.date_updated)}` : null;
                return(
                    <>
                        <div>Created: {formatDateTime(row.date_created)}</div>
                        <div>{updated}</div>
                    </>
                );
            }
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                return (parseInt(a) - parseInt(b)) * swap;
            },
            formatter: (row) => {
                const isActive = parseInt(row.active);
                if(isActive) return <Label color="mpGreen">Yes</Label>;
                else return <Label color="mpLRed">No</Label>;
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2 whitespace-nowrap">
                        <TooltipWrapper
                            tooltipText={`Edit this assessment template`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete this assessment template`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}