import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { getColumnsPayments } from '../table/getColumns';
import { Table } from '../../../../../components/table';
import { ContractsModal, PaymentsModal } from '../../../../../components/modal';

import {

} from '../../../../../actions';

const defaultSorted = [{ dataField: 'date', order: 'desc' }];

function PaymentsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [paymentModalMode, setPaymentModalMode] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [contractModalMode, setContractModalMode] = useState(null);
    const [selectedContract, setSelectedContract] = useState(null);

    const { payments, refreshData } = props;

    const handleShowPaymentModal = useCallback((mode, payment) => {
        if(mounted.current){
            setPaymentModalMode(mode);
            setSelectedPayment(payment);
        }
    }, [mounted]);
    const handleShowContractModal = useCallback((mode, contract) => {
        if(mounted.current){
            setContractModalMode(mode);
            setSelectedContract(contract);
        }
    }, [mounted]);
    function onSubmitCallback(changes = false){
        if(mounted.current){
            setPaymentModalMode(null);
            setSelectedPayment(null);
            setContractModalMode(null);
            setSelectedContract(null);
            if(changes) refreshData();
        }
    }

    const columns = useMemo(() => getColumnsPayments(handleShowPaymentModal, handleShowContractModal),
        [handleShowPaymentModal, handleShowContractModal]);

    return (
        <div>
            {paymentModalMode && 
                <PaymentsModal
                    mode={paymentModalMode}
                    selectedPayment={selectedPayment}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {contractModalMode &&
                <ContractsModal
                    mode={contractModalMode}
                    selectedContract={selectedContract}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="payments"
                data={payments}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No payments were found"
            />
        </div>
    );
}

export default connect(null, {

})(PaymentsTable);