import React from 'react';

import { Button, SVGIcon } from '../../../../../components/custom-essentials';
import { formatDateTime } from "../../../../../components/functions";
import { TooltipWrapper, tableColors } from '../../../../../components/display';

export function getColumns(handleShowModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    const priorityMap = {
        'Urgent': 1,
        'Important': 2,
        'Low': 3
    };

    const noneTag = <div className="text-mpOrange">None</div>
    return [
        {
            dataField: 'date_created',
            text: 'Date Created',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                return(
                    <TooltipWrapper
                        tooltipText={row.createdByName}
                    >
                        <div className="text-mpLBlue">{formatDateTime(row.date_created)}</div>
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            formatter: (row) =>{
                const centerName = row.centerName;
                let color = '';
                if(colorMap[centerName]) color = colorMap[centerName];
                else {
                    colorMap[centerName] = colors[(colorIndex++) % 5];
                    color = colorMap[centerName];
                }
                return (
                    <span className={`label label-light-${color} label-inline font-weight-bold`}>
                        {centerName}
                    </span>
                );
            }
        },
        {
            dataField: 'priority',
            text: 'Priority',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aPriority = priorityMap[a];
                const bPriority = priorityMap[b];
                return (aPriority - bPriority) * swap;
            },
            formatter: (row) => {
                let color = '';
                const priority = row.priority;
                if(priority === 'Urgent') color = 'mpLRed';
                else if(priority === 'Important') color = 'mpCYellow';
                else if(priority === 'Low') color = 'mpLBlue';
                else color = 'mpLGrey';
                return (
                    <span className={`label label-light-${color} label-inline font-weight-bold`}>
                        {priority}
                    </span>
                );
            }
        },
        {
            dataField: 'userName',
            text: 'User',
            sort: true
        },
        {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => {
                return (
                    <>
                        <div className="flex flex-row"><b>General:</b>&nbsp;{row.notes || noneTag}</div>
                        <div className="flex flex-row"><b>Followup:</b>&nbsp;{row.followup_notes || noneTag}</div>
                    </>
                );
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
        },
        {
            dataField: 'date_updated',
            text: 'Date Updated',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                const dateUpdated = row.date_updated;
                if(!dateUpdated) return <div className="text-mpOrange">Never</div>;
                return(
                    <TooltipWrapper
                        tooltipText={row.updatedByName}
                    >
                        <div className="text-mpLBlue">{formatDateTime(dateUpdated)}</div>
                    </TooltipWrapper>
                );
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2 whitespace-nobreak">
                        <TooltipWrapper
                            tooltipText={`Edit flag`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete flag`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}