import { basicPath } from '../basicPath';

export const fetchMemberRequestsAll = (params) => async (dispatch) => {
    const path = '/rp/member-requests/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchMemberRequestsIds = (params) => async (dispatch) => {
    const path = '/rp/member-requests/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const updateMemberRequest = (params) => async (dispatch) => {
    const path = '/rp/member-requests';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteMemberRequest = (params) => async (dispatch) => {
    const path = '/rp/member-requests';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}