import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, ModalProcessing, ModalLoading, ErrorMessage } from '../../custom-essentials';
import { formatDate, formatDateApi } from '../../functions';
import { TooltipWrapper } from '../../display';
import { DateRangeSelector, SelectSingle, FormikControl, Check } from '../../form';
import { validationSchema, getInitStudentOption, typeOptions,
    getInitTypeOption, renderSubmitting } from './helpers';

const startApi = formatDateApi(new Date());
const endDate = new Date();
endDate.setMonth(endDate.getMonth() + 6);
endDate.setDate(endDate.getDate() - 1);
const endApi = formatDateApi(endDate);

function ContractsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, submissionStatus, selectedContract, selectedStudent,
        studentOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        const sc = selectedContract;
        return (
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                }}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? renderSubmitting(submissionStatus, formik.setSubmitting) : 
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this contract?</h4>
                            <div className="text-mpLRed">
                                All linked payments will be deleted
                            </div>
                            <br/>
                            <h4 className="text-mpOrange">
                                This cannot be undone.
                            </h4>
                            <div className="h-2 clear-both"/>
                            <div className="col">
                                <div>
                                    {sc.studentName}
                                </div>
                                <div>
                                    {formatDate(sc.start_date)} to&nbsp;
                                    {formatDate(sc.end_date)}
                                </div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed font-[8pt] mr-8">
                                        {formik.status}
                                    </div> : null
                                }
                                { !formik.values.firstConfirm ? (
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            onClick={() => formik.setFieldValue('firstConfirm', true)}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div className="mr-auto">
                                            <Button
                                                color="hol-mpLRed"
                                                disabled={formik.isSubmitting}
                                                onClick={(e) => {
                                                    formik.setFieldValue('firstConfirm', false);
                                                    formik.handleSubmit(e)
                                                }}
                                                className="mr-2"
                                            >
                                                Confirm Delete
                                            </Button>
                                            <Button
                                                color="lte-mpLBlue"
                                                disabled={formik.isSubmitting}
                                                onClick={() => {
                                                    formik.setStatus('');
                                                    formik.setFieldValue('firstConfirm', false);
                                                }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                        <div
                                            className="text-mpOrange flex flex-row items-center ml-4"
                                        >
                                            Are you sure?
                                        </div>
                                    </>
                                )
                                }
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    student: getInitStudentOption(selectedContract.student || selectedStudent.user_id, studentOptions),
                    type: getInitTypeOption(selectedContract.type),
                    hoursPerCycle: isNaN(selectedContract.hours_per_cycle) ? 8 : selectedContract.hours_per_cycle,
                    currentCycle: isNaN(selectedContract.current_cycle) ? 1 : selectedContract.current_cycle,
                    hoursRemaining: isNaN(selectedContract.minutes_remaining) ? 8 :
                        (selectedContract.minutes_remaining / 60).toLocaleString(undefined, { maximumFractionDigits: 1 }),
                    startDate: formatDateApi(selectedContract.start_date || startApi),
                    endDate: formatDateApi(selectedContract.end_date || endApi),
                    initialPayment: selectedContract.initial_payment || 0,
                    // Need the first part since if selectedContract.amount_per_payment === 0, evaluates to false and sets amount to 300
                    paymentPerCycle: isNaN(selectedContract?.payment_per_cycle) ? 300 : selectedContract.payment_per_cycle,
                    lockHoursPerCycle: !['create', 'create-special'].includes(mode),
                    notes: selectedContract.notes || '',
                    showWarnings: parseInt(selectedContract.show_warnings) === 0 ? false : true,
                    hasIncreasedCycle: ['create', 'create-special'].includes(mode),
                    active: parseInt(selectedContract.active) === 0 ? false : true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? renderSubmitting(submissionStatus, formik.setSubmitting) : 
                        <ModalBodyFooter>
                            <ModalBodyFooter.Body>
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Student</h4>
                                        <SelectSingle
                                            id="contracts-student"
                                            name="student"
                                            disabled={!['create', 'create-special'].includes(mode)}
                                            value={formik.values.student}
                                            onChange={formik.handleChange}
                                            options={studentOptions}
                                        />
                                        {!['create', 'create-special'].includes(mode) ? (
                                            <div className="text-mpOrange">
                                                Student cannot be modified for existing contracts
                                            </div>
                                        ) : null}
                                        {formik.errors.student ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.student}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Contract Type</h4>
                                        <SelectSingle
                                            id="contracts-type"
                                            name="type"
                                            value={formik.values.type}
                                            onChange={formik.handleChange}
                                            options={typeOptions}
                                        />
                                        {formik.errors.type ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.type}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Hours Per Cycle</h4>
                                        <FormikControl
                                            id="contracts-hoursPerCycle"
                                            name="hoursPerCycle"
                                            value={formik.values.hoursPerCycle}
                                            disabled={formik.values.lockHoursPerCycle}
                                            onChange={formik.handleChange}
                                            placeholder="Min: 1, Max: 100"
                                        />
                                        {formik.errors.hoursPerCycle ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.hoursPerCycle}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2"/>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <TooltipWrapper
                                            tooltipText={<div>Locks the "Hours Per Cycle" field to prevent accidental changes.</div>}
                                            placement="bottom"
                                        >
                                            <Check
                                                id="contracts-lock-hours-per-cycle"
                                                name="lockHoursPerCycle"
                                                color="mpDBlue"
                                                label={<div className="text-mpLBlue text-[10pt]">Lock Hours Per Cycle</div>}
                                                checked={formik.values.lockHoursPerCycle}
                                                onChange={formik.handleChange}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                        <h4>Current Cycle</h4>
                                        <FormikControl
                                            id="contracts-currentCycle"
                                            name="currentCycle"
                                            value={formik.values.currentCycle}
                                            onChange={formik.handleChange}
                                            placeholder="Min: 1, Max: 100"
                                        />
                                        {formik.errors.currentCycle ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.currentCycle}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                        <h4>Hours Remaining</h4>
                                        <FormikControl
                                            id="contracts-hoursRemaining"
                                            name="hoursRemaining"
                                            value={formik.values.hoursRemaining}
                                            onChange={formik.handleChange}
                                            placeholder="Min: 1, Max: 100"
                                        />
                                        {formik.errors.hoursRemaining ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.hoursRemaining}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4 items-end">
                                        <TooltipWrapper
                                            tooltipText={
                                                <>
                                                    <div>
                                                        Increase "Current Cycle" by 1 and increase "Hours Remaining" by "Hours Per Cycle."
                                                        Intended to be used when a student pays for their next cycle
                                                    </div>

                                                    <br/>

                                                    <div>
                                                        To prevent unintended changes, this button can only be used once each time the form is opened.
                                                    </div>
                                                </>
                                            }
                                        >
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => {
                                                    const newValues = {...formik.values};
                                                    newValues.currentCycle++;
                                                    newValues.hoursRemaining = parseInt(newValues.hoursRemaining) + parseInt(newValues.hoursPerCycle);
                                                    newValues.hasIncreasedCycle = true;
                                                    formik.setValues(newValues);
                                                }}
                                                disabled={formik.values.hasIncreasedCycle || isNaN(formik.values.hoursRemaining) || isNaN(formik.values.hoursPerCycle)}
                                            >
                                                Cycle Up
                                            </Button>
                                        </TooltipWrapper>
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <h4>Contract Start and End</h4>
                                        <DateRangeSelector
                                            id="contract-form-drs"
                                            startName="startDate"
                                            endName="endDate"
                                            startLabel="Start Date"
                                            endLabel="End Date"
                                            startValue={formik.values.startDate}
                                            endValue={formik.values.endDate}
                                            defaultValid={true}
                                            onStartChange={formik.handleChange}
                                            onEndChange={formik.handleChange}
                                            useCustomValidation={true}
                                        />
                                        {formik.errors.drsValid ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.drsValid}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <TooltipWrapper
                                            tooltipText={
                                                <>
                                                    <div>
                                                        Optional. Initial payment made at the start of the contract.
                                                        This will be added to the contract's expected total.
                                                    </div>
                                                </>
                                            }
                                        >
                                            <h4 className="text-mpLBlue">Initial Payment</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="contracts-initial-payment"
                                            name="initialPayment"
                                            value={formik.values.initialPayment}
                                            onChange={formik.handleChange}
                                            placeholder="Min: 0, Max: 30000"
                                        />
                                        {formik.errors.initialPayment ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.initialPayment}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <TooltipWrapper
                                            tooltipText={
                                                <>
                                                    <div>The price of one cycle.</div>
                                                    <br/>
                                                    <div>
                                                        For 6 month up front payments, this should be the total payment divided by 6.
                                                        If the total payment doesn't divide evenly by 6, use the Initial Payment field
                                                        to apply the remainder of the sum.
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        Eg $1805 = 6 payments of $300 (Price Per Cycle), plus $5 (Initial Payment)
                                                    </div>
                                                </>
                                            }
                                        >
                                            <h4 className="text-mpLBlue">Payment Per Cycle</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="contracts-amount-per-payment"
                                            name="paymentPerCycle"
                                            value={formik.values.paymentPerCycle}
                                            onChange={formik.handleChange}
                                            placeholder="Min: 0, Max: 30000"
                                        />
                                        {formik.errors.paymentPerCycle ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.paymentPerCycle}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Notes</h4>
                                        <FormikControl
                                            id="contracts-notes"
                                            as="textarea"
                                            name="notes"
                                            value={formik.values.notes}
                                            onChange={formik.handleChange}
                                            placeholder="Max. 1000 characters."
                                        />
                                        {formik.errors.notes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.notes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <Check
                                            id="contracts-show-warnings"
                                            name="showWarnings"
                                            color="mpDBlue"
                                            label="Show warnings when sessions are running low or when the contract is expiring within the next month"
                                            checked={formik.values.showWarnings}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <Check
                                            id="contracts-active"
                                            name="active"
                                            color="mpDBlue"
                                            label="Contract Active"
                                            checked={formik.values.active}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                            </ModalBodyFooter.Body>
                            <ModalBodyFooter.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                        (
                                            <div className="text-mpLRed">
                                                One or more fields needs to be corrected.
                                            </div>
                                        ) : null
                                    }
                                    {formik.isSubmitting ?
                                        <ModalProcessing/> : null
                                    }
                                    {formik.status && !formik.isSubmitting ? 
                                        <div className="text-mpLRed mr-4">
                                            {formik.status}
                                        </div> : null
                                    }
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                        disabled={formik.isSubmitting}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={formik.handleSubmit}
                                        disabled={formik.isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </ModalBodyFooter.Footer>
                        </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(ContractsBodyFooter);