import React, { useState } from "react";
// import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { SelectSingle } from '../../components/form';
// import { toAbsoluteUrl } from '../../components/functions';
import { VersionHistory } from '../../components/display';
import { Socket } from '../../components/ws';
import { BrowserTabTitle } from '../../components/display';

const versionOptions = [
    { value: 'Admin 4.1', label: 'Admin 4.1'},
    { value: 'Admin 4.0', label: 'Admin 4.0'},
];

const pageTitle = 'Home';

function Home(){
    const [viewVersion, setViewVersion] = useState(versionOptions[0]);

    return (
        <div className="page-box">
            <BrowserTabTitle content={pageTitle}/>
            <div className="card">
                <h2>Version History</h2>

                <div className="max-w-md mt-4">
                    <SelectSingle
                        id="home-view-version"
                        name="viewVersion"
                        value={viewVersion}
                        options={versionOptions}
                        onChange={(e) => setViewVersion(e.target.value)}
                    />
                </div>

                <br/>

                <VersionHistory
                    version={viewVersion.value}
                />
            </div>

            {/* <Socket
                refreshData={() => null}
                page={pageTitle}
                setVersion={props.setVersion}
            /> */}
        </div>
    );
};

export default Home;