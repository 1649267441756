import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, SVGIcon, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { TooltipWrapper } from '../../display';
import { SelectSingle, FormikControl } from '../../form';
import { validationSchema, getInitParent, gradeOptions, getInitGradeOption,
    getSchoolYearOptions, getInitSchoolYearOption } from './helpers';

const today = new Date();
today.setHours(0, 0, 0, 0);

function StudentsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { selectedStudent, attemptingClose, setAttemptingClose, parentOptions,
        loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    showContactInfo: true,
                    showSchedulerInfo: false,
                    showExtraInfo: false,

                    parent: getInitParent(selectedStudent.parent, parentOptions),
                    grade: getInitGradeOption(selectedStudent.grade),
                    schoolYear: getInitSchoolYearOption(selectedStudent.school_year),
                    school: selectedStudent.school || '',
                    teacher: selectedStudent.rp_teacher || '',
                    address: selectedStudent.address || '',
                    parentNotes: selectedStudent.parent_notes || '',
                    firstGuardianNotes: selectedStudent.first_guardian_notes || '',
                    secondGuardianNotes: selectedStudent.second_guardian_notes || '',
                    specialNotes: selectedStudent.rp_special_notes || '',
                    specialNotes2: selectedStudent.rp_special_notes_2 || '',
                    driveFolderLink1Name: selectedStudent.rp_drive_folder_link_1_name || '',
                    driveFolderLink1: selectedStudent.rp_drive_folder_link_1 || '',
                    driveFolderLink2Name: selectedStudent.rp_drive_folder_link_2_name || '',
                    driveFolderLink2: selectedStudent.rp_drive_folder_link_2 || '',
                    driveFolderLink3Name: selectedStudent.rp_drive_folder_link_3_name || '',
                    driveFolderLink3: selectedStudent.rp_drive_folder_link_3 || '',
                    driveFolderLink4Name: selectedStudent.rp_drive_folder_link_4_name || '',
                    driveFolderLink4: selectedStudent.rp_drive_folder_link_4 || ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    attemptingClose ? 
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>
                                Are you sure you would like to close the form?
                            </h4>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                <Button
                                    color="lte-mpLRed"
                                    onClick={handleClose}
                                >
                                    Close Form
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={() => setAttemptingClose(false)}
                                >
                                    Back to Form
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                    :
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
{/* CONTACT INFO */}
                            <div className="grid grid-cols-1 gap-y-2 table cursor-pointer">
                                <h2
                                    className={formik.values.showContactInfo ? 'text-mpLBlue' : 'text-mpDBlue'}
                                    onClick={() => formik.setFieldValue('showContactInfo', !formik.values.showContactInfo)}
                                >
                                    <u>Contact</u>
                                </h2>
                            </div>
                            <br/>
    {/* SCHOOL */}
                            {formik.values.showContactInfo && 
                            <>
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Parent</h4>
                                        <SelectSingle
                                            id="student-form-parent"
                                            name="parent"
                                            value={formik.values.parent}
                                            onChange={formik.handleChange}
                                            options={parentOptions}
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>Grade</h4>
                                        <SelectSingle
                                            id="student-form-grade"
                                            name="grade"
                                            value={formik.values.grade}
                                            onChange={formik.handleChange}
                                            options={gradeOptions}
                                        />
                                        {formik.errors.grade ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.grade}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/4">
                                        <h4>School Year</h4>
                                        <SelectSingle
                                            id="student-form-school-year"
                                            name="schoolYear"
                                            value={formik.values.schoolYear}
                                            onChange={formik.handleChange}
                                            options={getSchoolYearOptions()}
                                        />
                                        {formik.errors.schoolYear ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.schoolYear}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                        <h4>School</h4>
                                        <FormikControl
                                            id="student-form-school"
                                            name="school"
                                            placeholder="School? Max 100 characters."
                                            value={formik.values.school}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.school ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.school}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                        <h4>English Teacher</h4>
                                        <FormikControl
                                            id="student-form-teacher"
                                            name="teacher"
                                            placeholder="Teacher? Max 50 characters."
                                            value={formik.values.teacher}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.teacher ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.teacher}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>
    {/* CONTACTS */}
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Address</h4>
                                        <FormikControl
                                            id="student-form-address"
                                            name="address"
                                            placeholder="Home address? Max 200 characters."
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.address ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.address}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Parent Notes</h4>
                                        <FormikControl
                                            id="student-form-parent-notes"
                                            name="parentNotes"
                                            placeholder="Parent Notes? Max 500 characters."
                                            value={formik.values.parentNotes}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.parentNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.parentNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>First Guardian Notes</h4>
                                        <FormikControl
                                            id="student-form-first-guardian-notes"
                                            name="firstGuardianNotes"
                                            placeholder="First guardian? Max 500 characters."
                                            value={formik.values.firstGuardianNotes}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.firstGuardianNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.firstGuardianNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Second Guardian Notes</h4>
                                        <FormikControl
                                            id="student-form-second-guardian-notes"
                                            name="secondGuardianNotes"
                                            placeholder="Second guardian? Max 500 characters."
                                            value={formik.values.secondGuardianNotes}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.secondGuardianNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.secondGuardianNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                                <br/>
                            </>
                            }

                            <hr/>
{/* SCHEDULING INFO */}
                            <div className="col">
                                <div className="h-2 clear-both"/>
                                <h2
                                    className={`table cursor-pointer ${formik.values.showSchedulerInfo ? 'text-mpLBlue' : 'text-mpDBlue'}`}
                                    onClick={() => formik.setFieldValue('showSchedulerInfo', !formik.values.showSchedulerInfo)}
                                >
                                    <u>Misc.</u>
                                </h2>
                            </div>
                            <br/>
                            {formik.values.showSchedulerInfo && 
                            <>
    {/* DRIVE FOLDERS */}
        {/* DRIVE FOLDER - 1 */}
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                        <h4>Drive Link 1 Name</h4>
                                        <FormikControl
                                            id="student-form-driveFolderLink1Name"
                                            name="driveFolderLink1Name"
                                            placeholder="Drive link name? Max 30 characters"
                                            value={formik.values.driveFolderLink1Name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.driveFolderLink1Name ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.driveFolderLink1Name}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                        <h4>Drive Link 1</h4>
                                        <FormikControl
                                            id="student-form-driveFolderLink1"
                                            name="driveFolderLink1"
                                            placeholder="Drive link? Max 300 characters"
                                            value={formik.values.driveFolderLink1}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.driveFolderLink1 ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.driveFolderLink1}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>
        {/* DRIVE FOLDER - 2 */}
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                        <h4>Drive Link 2 Name</h4>
                                        <FormikControl
                                            id="student-form-driveFolderLink2Name"
                                            name="driveFolderLink2Name"
                                            placeholder="Drive link name? Max 30 characters"
                                            value={formik.values.driveFolderLink2Name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.driveFolderLink2Name ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.driveFolderLink2Name}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                        <h4>Drive Link 2</h4>
                                        <FormikControl
                                            id="student-form-driveFolderLink2"
                                            name="driveFolderLink2"
                                            placeholder="Drive link? Max 300 characters"
                                            value={formik.values.driveFolderLink2}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.driveFolderLink2 ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.driveFolderLink2}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>
        {/* DRIVE FOLDER - 3 */}
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                        <h4>Drive Link 3 Name</h4>
                                        <FormikControl
                                            id="student-form-driveFolderLink3Name"
                                            name="driveFolderLink3Name"
                                            placeholder="Drive link name? Max 30 characters"
                                            value={formik.values.driveFolderLink3Name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.driveFolderLink3Name ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.driveFolderLink3Name}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                        <h4>Drive Link 3</h4>
                                        <FormikControl
                                            id="student-form-driveFolderLink3"
                                            name="driveFolderLink3"
                                            placeholder="Drive link? Max 300 characters"
                                            value={formik.values.driveFolderLink3}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.driveFolderLink3 ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.driveFolderLink3}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>
        {/* DRIVE FOLDER - 4 */}
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                        <h4>Drive Link 4 Name</h4>
                                        <FormikControl
                                            id="student-form-driveFolderLink4Name"
                                            name="driveFolderLink4Name"
                                            placeholder="Drive link name? Max 30 characters"
                                            value={formik.values.driveFolderLink4Name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.driveFolderLink4Name ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.driveFolderLink4Name}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                        <h4>Drive Link 4</h4>
                                        <FormikControl
                                            id="student-form-driveFolderLink4"
                                            name="driveFolderLink4"
                                            placeholder="Drive link? Max 300 characters"
                                            value={formik.values.driveFolderLink4}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.driveFolderLink4 ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.driveFolderLink4}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

    {/* GENERAL CONFIG - Special Notes */}
                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <TooltipWrapper
                                            tooltipText={<div>The default value that will appear as "Special Notes"
                                            on all booked appointments. Changing this value will not affect
                                            existing appointments. The icon for "Special Notes" is
                                            <SVGIcon fileName="exclamation-red"/></div>}
                                        >
                                            <h4 className="text-mpLBlue">Special Notes</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="student-special-notes"
                                            name="specialNotes"
                                            placeholder="This will update the special notes (red !) for this student's future appointments. Max 300 characters"
                                            value={formik.values.specialNotes}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.specialNotes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.specialNotes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <TooltipWrapper
                                            tooltipText={<div>The default value that will appear as "Special Notes 2"
                                            on all booked appointments. Changing this value will not affect
                                            existing appointments. The icon for "Special Notes" is
                                            <SVGIcon fileName="exclamation-blue"/>. This field is
                                            intended to be used for items that need to stand out from the traiditional
                                            "Special Notes"</div>}
                                        >
                                            <h4 className="text-mpLBlue">Special Notes 2</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="student-special-notes-2"
                                            name="specialNotes2"
                                            placeholder="This will update the special notes 2 (blue !) for this student's future appointments. Max 300 characters"
                                            value={formik.values.specialNotes2}
                                            onChange={formik.handleChange}
                                            as="textarea"
                                        />
                                        {formik.errors.specialNotes2 ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.specialNotes2}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                            </>
                            }
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-4">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(StudentsBodyFooter);