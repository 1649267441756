import React from 'react';

const versionHistory = {
    "Admin 4.1": (
        <>
            <h5>v4.1.1</h5>
            <h6>Release Date: 4/6/23</h6>
            <ul>
                <li>Scheduling: Fixed an issue with appointments from the wrong day showing up</li>
            </ul>

            <h5>v4.1.0</h5>
            <h6>Release Date: 4/6/23</h6>
            <ul>
                <li>Changes and New Features</li>
                <ul>
                    <li>Scheduling: Added "All Centers" viewing mode</li>
                    <li>Scheduling: Added general Stamps Form</li>
                    <li>Auto Scheduler page added</li>
                    <li>Timesheets added payperiod selector</li>
                </ul>
                <li>Fixes</li>
                <ul>
                    <li>Fixed Contract Form Cycle Up issue causing hours to be added incorrectly sometimes (7 + 8 = 78)</li>
                    <li>Report Generator fixed an issue with the page crashing if tabs or newlines were included in notes</li>
                    <li>Member/Lead forms fixed issues with account creation</li>
                </ul>
            </ul>
        </>
    ),
    "Admin 4.0": (
        <>
            <h5>v4.0.0</h5>
            <h6>Release Date: 2/17/23</h6>
            <ul>
                <li>Rewrite of visual components.</li>
            </ul>
        </>
    ),
};

export function VersionHistory(props){
    return versionHistory[props.version] || <h4>Unknown version</h4>
}