import { basicPath } from '../basicPath';

export const fetchStudentsAll = (params) => async (dispatch) => {
    const path = '/rp/students/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchStudentsActive = (params) => async (dispatch) => {
    const path = '/rp/students/active';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchStudentsEssential = (params) => async (dispatch) => {
    const path = '/rp/students/essential';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchStudentsCenter = (params) => async (dispatch) => {
    const path = '/rp/students/center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchStudentsUserIds = (params) => async (dispatch) => {
    const path = '/rp/students/user-ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const updateStudentAttributes = (params) => async (dispatch) => {
    const path = '/rp/students/attributes';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateStudentBinder = (params) => async (dispatch) => {
    const path = '/rp/students/binder';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateStudentContract = (params) => async (dispatch) => {
    const path = '/rp/students/contract';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateStudentStamps = (params) => async (dispatch) => {
    const path = '/rp/students/stamps';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateStudentHold = (params) => async (dispatch) => {
    const path = '/rp/students/holds';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}