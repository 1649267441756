import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
    userName: null,
    userId: null,
    userEmail: null,
    sessionId: null,
    permissions: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserInfo(state, action){
            if(!action.payload?.accessToken || !action.payload?.refreshToken) return initialState;
            
            const accessDeobbed = jwt_decode(action.payload.accessToken);
            const refreshDeobbed = jwt_decode(action.payload.refreshToken);

            // Mismatched permissions indicate an unexpected error.
            if(accessDeobbed.rpPermissions !== refreshDeobbed.rpPermissions) return initialState;

            cookies.set('rToken', action.payload.refreshToken, { path: '/' });
            cookies.set('aToken', action.payload.accessToken, { path: '/' });

            state.userName = refreshDeobbed.userName;
            state.userId = accessDeobbed.userId;
            state.userEmail = refreshDeobbed.userEmail;
            state.permissions = accessDeobbed.rpPermissions;
            state.sessionId = accessDeobbed.aSessionId;
        },
        clearUserInfo(state, action){
            cookies.remove('rToken', { path: '/' });
            cookies.remove('aToken', { path: '/' });

            for(let key of Object.keys(initialState)) state[key] = initialState[key];
        }
    }
});