import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { DateRangeSelector, SelectSingle, FormikControl, Switch, checkResponses } from '../../../../components/form';
import { formatDateApi } from '../../../../components/functions';
import { ContractsModal } from '../../../../components/modal';
import { TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import { appendContractBalances } from './functions';
import ContractsTable from './contracts/ContractsTable';

import {
    fetchContractsDaterangeCenter,
    fetchStudentsAll
} from '../../../../actions';

const start = new Date();
start.setMonth(start.getMonth() - 6);
const startApi = formatDateApi(start);
const end = new Date();
end.setMonth(end.getMonth() + 6);
const endApi = formatDateApi(end);

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'studentName', label: 'Student Name' },
    { value: 'type', label: 'Type' },
    { value: 'notes', label: 'Notes' },
];

function Contracts(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const { centerOptions, loading, setLoading, setNContracts,
        fetchContractsDaterangeCenter, fetchStudentsAll } = props;

    const [hasLoaded, setHasLoaded] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [contracts, setContracts] = useState([]);
    const [students, setStudents] = useState([]);
    const [filteredContracts, setFilteredContracts] = useState([]);
    const [monthlyExpected, setMonthlyExpected] = useState(0);
    // Form
    const [drsValid, setDrsValid] = useState(true);
    // Modal
    const [modalMode, setModalMode] = useState(null);
    function onSubmitCallback(changes = false){
        setModalMode(null);
        if(changes) refreshData();
    }

    const filterContracts = useCallback((unfilteredContracts, filterType, filterQuery,
    activeOnly, activeStudentsOnly, extra, overUnderOnly, monthlyOnly, newStudents) => {
        filterQuery = filterQuery.replace(/ /g, '').toLowerCase();
        const checkStudentNames = (c) => c.studentName.replace(/ /g, '').toLowerCase().includes(filterQuery);
        const checkType = (c) => c.type.toLowerCase().includes(filterQuery);
        const checkNotes = (c) => c.notes.toLowerCase().includes(filterQuery);

        let newContracts = unfilteredContracts.filter(c => {
            switch(filterType.value){
                case 'all':
                    return checkStudentNames(c) || checkType(c) || checkNotes(c);
                case 'studentName':
                    return checkStudentNames(c);
                case 'type':
                    return checkType(c);
                case 'notes':
                    return checkNotes(c);
                default:
                    return false;
            }
        });

        if(activeOnly && activeOnly !== 'false'){
            newContracts = newContracts.filter(c => parseInt(c.active) === 1);
        }
        if(activeStudentsOnly && activeStudentsOnly !== 'false'){
            const studentMap = {};
            newStudents.forEach(s => studentMap[s.user_id] = parseInt(s.rp_active) === 1 ? true : false);

            newContracts = newContracts.filter(c => studentMap[c.student]);
        }
        if(!extra || extra === 'false'){
            newContracts = newContracts.filter(c => c.type !== 'Extra');
        }
        if(overUnderOnly && overUnderOnly !== 'false'){
            newContracts = newContracts.filter(c => c.balanceOwed !== c.balancePaid);
        }
        if(monthlyOnly && monthlyOnly !== 'false'){
            newContracts = newContracts.filter(c => c.payment_frequency === 'Monthly');
        }

        let newMonthlyExpected = 0;
        newContracts.forEach(c => {
            if(c.payment_frequency === 'Monthly' && c.type !== 'Extra'){
                newMonthlyExpected += parseInt(c.amount_per_payment);
            }
        });
        setMonthlyExpected(newMonthlyExpected);

        if(mounted.current){
            setFilteredContracts(newContracts);
            setHasLoaded(true);
        }
    }, [mounted]);


    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading.contracts || !drsValid || !formRef.current?.values) return;
            if(mounted.current) setLoading({ ...loading, contracts: true });
            const { startDate, endDate, selectedCenter, filterType, filterQuery, activeOnly,
                activeStudentsOnly, extra, overUnderOnly, monthlyOnly } = formRef.current.values;
    
            // Get contracts and students
            const contractsRes = await fetchContractsDaterangeCenter({
                startDate: startDate,
                endDate: endDate,
                center: selectedCenter.value
            });
            const studentsRes = await fetchStudentsAll();
            const isApiError = checkResponses(contractsRes, studentsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading({ ...loading, contracts: false });
                    setHasLoaded(true);
                }
                return;
            } else setApiError(false);

            const newContracts = contractsRes.data.contracts || [];
            const newPayments = contractsRes.data.payments || [];
            const newStudents = studentsRes.data || [];
            
            const studentToNameMap = {};
            newStudents.forEach(s => {
                s.studentName = `${s.first_name} ${s.last_name}`;
                studentToNameMap[s.user_id] = s.studentName;
            });

            const contractMap = {};
            newStudents.forEach(s => contractMap[parseInt(s.rp_primary_contract)] = s.user_id);
            newContracts.forEach(c => {
                if(contractMap[parseInt(c.id)]) c.isPrimary = true;
                else c.isPrimary = false;
            });
            
            const newContractsAppended = appendContractBalances(newContracts, newPayments).map(c => {
                c.studentName = studentToNameMap[c.student] || `Unknown student (UID: ${c.student})`;
                return c;
            });
    
            if(mounted.current){
                setContracts(newContractsAppended);
                setNContracts(newContractsAppended.length);
                setStudents(newStudents);
                filterContracts(newContracts, filterType, filterQuery, activeOnly,
                    activeStudentsOnly, extra, overUnderOnly, monthlyOnly, newStudents);
                setLoading({ ...loading, contracts: false });
            }
        })()
    }, [loading, drsValid, setLoading, formRef, filterContracts, setNContracts,
        fetchContractsDaterangeCenter, fetchStudentsAll]);
    useEffect(() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {modalMode && 
                <ContractsModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Formik
                enableReinitialize
                initialValues={{
                    startDate: startApi,
                    endDate: endApi,
                    selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                    filterType: { value: 'all', label: 'All' },
                    filterQuery: '',
                    activeOnly: true,
                    activeStudentsOnly: true,
                    extra: true,
                    overUnderOnly: false,
                    monthlyOnly: false,
                }}
                innerRef={formRef}
                onSubmit={refreshData}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit}>
                        <h3>Contracts ({contracts.length})</h3>
                        <br/>
                        <div className="flex flex-row gap-x-4">
                            <div>
                                <DateRangeSelector
                                    id="contracts-drs-1"
                                    startName="startDate"
                                    endName="endDate"
                                    startLabel="Start Date"
                                    endLabel="End Date"
                                    startValue={formik.values.startDate}
                                    endValue={formik.values.endDate}
                                    defaultValid={true}
                                    onStartChange={formik.handleChange}
                                    onEndChange={formik.handleChange}
                                    onChangeValidation={setDrsValid}
                                />
                            </div>
                            <div className="w-1/4">
                                <SelectSingle
                                    id="contracts-selectedCenter"
                                    name="selectedCenter"
                                    label="Center"
                                    value={formik.values.selectedCenter}
                                    onChange={formik.handleChange}
                                    options={centerOptions}
                                />
                            </div>
                            <div className="self-start">
                                <Button
                                    color="lte-mpTeal"
                                    onClick={formik.handleSubmit}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>

                        <br/>
                        
                        {apiError ? <div className="text-mpLRed">{apiError}</div> : 
                            <>
                                <hr/>
                                <br/>

                                <h3>Filter Results ({filteredContracts.length})</h3>
                                <br/>
                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="w-1/4 self-start">
                                        <SelectSingle
                                            id="contracts-searchType"
                                            name="filterType"
                                            label="Filter by"
                                            value={formik.values.filterType}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterContracts(contracts, e.target.value, formik.values.filterQuery,
                                                    formik.values.activeOnly,
                                                    formik.values.activeStudentsOnly,
                                                    formik.values.extra, formik.values.overUnderOnly,
                                                    formik.values.monthlyOnly, students);
                                            }}
                                            options={filterTypeOptions}
                                        />
                                    </div>
                                    <div className="w-1/3">
                                        <FormikControl
                                            id="flag-manager-searchQuery"
                                            name="filterQuery"
                                            placeholder="Enter a filter query..."
                                            value={formik.values.filterQuery}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterContracts(contracts, formik.values.filterType, e.target.value,
                                                    formik.values.activeOnly,
                                                    formik.values.activeStudentsOnly,
                                                    formik.values.extra, formik.values.overUnderOnly,
                                                    formik.values.monthlyOnly, students);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => setModalMode('create')}
                                        >
                                            + New Contract
                                        </Button>
                                    </div>
                                    <div className="ml-auto">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    <div>
                                                        What gets exported?
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        All contracts that are currently selected ({filteredContracts.length} items).
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <CSVExport
                                                title="Contracts"
                                                label="Export Contracts to CSV"
                                                data={filteredContracts}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>
                                <br/>
                                <div className="flex flex-row self-center gap-x-4">
                                    <div>
                                        <Switch
                                            name="activeOnly"
                                            label={
                                                <TooltipWrapper
                                                    tooltipText={`Only show active contracts`}
                                                >
                                                    <div className="text-mpLBlue">Active Contracts Only</div>
                                                </TooltipWrapper>
                                            }
                                            color="mpTeal"
                                            textPosition="after"
                                            checked={formik.values.activeOnly}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterContracts(contracts, formik.values.filterType,
                                                    formik.values.filterQuery, e.target.value,
                                                    formik.values.activeStudentsOnly, formik.values.extra,
                                                    formik.values.overUnderOnly, formik.values.monthlyOnly, students);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Switch
                                            name="activeStudentsOnly"
                                            label={
                                                <TooltipWrapper
                                                    tooltipText={`Only show contracts linked to an active student account`}
                                                >
                                                    <div className="text-mpLBlue">Active Students Only</div>
                                                </TooltipWrapper>
                                            }
                                            color="mpTeal"
                                            textPosition="after"
                                            checked={formik.values.activeStudentsOnly}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterContracts(contracts, formik.values.filterType,
                                                    formik.values.filterQuery, formik.values.activeOnly, e.target.value,
                                                    formik.values.extra, formik.values.overUnderOnly,
                                                    formik.values.monthlyOnly, students);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Switch
                                            name="extra"
                                            label={
                                                <TooltipWrapper
                                                    tooltipText={`Show contracts of the "Extra" type`}
                                                >
                                                    <div className="text-mpLBlue">Show Extra</div>
                                                </TooltipWrapper>
                                            }
                                            color="mpTeal"
                                            textPosition="after"
                                            checked={formik.values.extra}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterContracts(contracts, formik.values.filterType,
                                                    formik.values.filterQuery, formik.values.activeOnly,
                                                    formik.values.activeStudentsOnly, e.target.value, formik.values.overUnderOnly,
                                                    formik.values.monthlyOnly, students);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Switch
                                            name="overUnderOnly"
                                            label={
                                                <TooltipWrapper
                                                    tooltipText={`Hide payments with the "Matching" payment status`}
                                                >
                                                    <div className="text-mpLBlue">Hide Matching</div>
                                                </TooltipWrapper>
                                            }
                                            color="mpTeal"
                                            textPosition="after"
                                            checked={formik.values.overUnderOnly}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterContracts(contracts, formik.values.filterType,
                                                    formik.values.filterQuery, formik.values.activeOnly,
                                                    formik.values.activeStudentsOnly, formik.values.extra,
                                                    e.target.value, formik.values.monthlyOnly, students);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Switch
                                            name="monthlyOnly"
                                            label={
                                                <TooltipWrapper
                                                    tooltipText={`Only display contracts with the "Monthly" payment frequency`}
                                                >
                                                    <div className="text-mpLBlue">Monthly Only</div>
                                                </TooltipWrapper>
                                            }
                                            color="mpTeal"
                                            textPosition="after"
                                            checked={formik.values.monthlyOnly}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                filterContracts(contracts, formik.values.filterType,
                                                    formik.values.filterQuery, formik.values.activeOnly,
                                                    formik.values.activeStudentsOnly, formik.values.extra,
                                                    formik.values.overUnderOnly, e.target.value, students);
                                            }}
                                        />
                                    </div>
                                </div>
                                {monthlyExpected !== 0 && 
                                    <>
                                        <br/>
                                        <hr/>
                                        <br/>
                                        <h3>Expected Monthly Income: ${monthlyExpected}</h3>
                                    </>
                                }
                            </>
                        }
                        
                    </form>
                )}
            </Formik>

            <br/>

            {apiError ? null : hasLoaded && 
                <ContractsTable
                    contracts={filteredContracts}
                    refreshData={refreshData}
                />
            }
        </div>
    );
}

export default connect(null, {
    fetchContractsDaterangeCenter,
    fetchStudentsAll
})(Contracts);