import { basicPath } from '../basicPath';

export const fetchInstructorAttendanceIds = (params) => async (dispatch) => {
    const path = '/rp/instructor-attendance/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchInstructorAttendanceLatestId = (params) => async (dispatch) => {
    const path = '/rp/instructor-attendance/latest-id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchInstructorAttendanceCurrentId = (params) => async (dispatch) => {
    const path = '/rp/instructor-attendance/current-id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchInstructorAttendanceDaterangeUserIds = (params) => async (dispatch) => {
    const path = '/rp/instructor-attendance/daterange-user-ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const signIn = () => async (dispatch) => {
    const path = '/rp/instructor-attendance/sign-in';
    const type = 'post';
    const params = {};
    return await basicPath(dispatch, params, path, type);
}

export const signOut = () => async (dispatch) => {
    const path = '/rp/instructor-attendance/sign-out';
    const type = 'put';
    const params = {};
    return await basicPath(dispatch, params, path, type);
}

export const createAttendance = (params) => async (dispatch) => {
    const path = '/rp/instructor-attendance/in-out';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateAttendance = (params) => async (dispatch) => {
    const path = '/rp/instructor-attendance/in-out';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAttendance = (params) => async (dispatch) => {
    const path = '/rp/instructor-attendance';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}