import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import BooksTable from './BooksTable';
import { Button } from "../../../../components/custom-essentials";
import { LoadingOverlay, BrowserTabTitle } from "../../../../components/display";
import { FormikControl, Switch, checkResponses, SelectSingle } from "../../../../components/form";
import { BooksModal } from '../../../../components/modal';
import { Socket } from '../../../../components/ws';

import { 
    fetchBooksAll,
} from '../../../../actions';
import { Wait } from "../../../../components/wait";

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'title', label: 'Title' },
    { value: 'author', label: 'Author' },
    { value: 'difficulty', label: 'Difficulty' },
    { value: 'theme', label: 'Theme' },
    { value: 'type', label: 'Type' }
];

function BookManager(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [loading, setLoading] = useState(false);
    const [firstSearch, setFirstSearch] = useState(false);
    const [apiError, setApiError] = useState(false);

    const [filteredBooks, setFilteredBooks] = useState([]);
    const [books, setBooks] = useState([]);

    const [modalMode, setModalMode] = useState(null);

    const { fetchBooksAll } = props;

    const filterData = useCallback((filterQuery, filterType, showInactive, newBooks) => {
        if(showInactive && showInactive !== true && showInactive !== 'true') showInactive = false;
        const formattedFQ = filterQuery.toLowerCase().trim().replace(/ /g, '');
        
        const checkTitle = (book) => book.title.toLowerCase().replace(/ /g, '').includes(formattedFQ);
        const checkAuthor = (book) => book.author.toLowerCase().replace(/ /g, '').includes(formattedFQ);
        const checkDifficulty  = (book) => book.difficulty.toLowerCase().replace(/ /g, '').includes(formattedFQ);
        const checkTheme = (book) => book.theme.toLowerCase().replace(/ /g, '').includes(formattedFQ);
        const checkType = (book) => book.type.toLowerCase().replace(/ /g, '').includes(formattedFQ);

        const newFilteredBooks = newBooks.filter(l => {
            if(!(showInactive || parseInt(l.active) === 1)) return false;
            switch(filterType.value){
                case 'all':
                    return checkTitle(l) || checkAuthor(l) || checkDifficulty(l) || checkTheme(l) || checkType(l);
                case 'title':
                    return checkTitle(l);
                case 'author':
                    return checkAuthor(l);
                case 'difficulty':
                    return checkDifficulty(l);
                case 'theme':
                    return checkTheme(l);
                case 'type':
                    return checkType(l);
                default:
                    return false;
            }
        });

        if(mounted.current) setFilteredBooks(newFilteredBooks);
    }, []);
    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);

            const booksRes = await fetchBooksAll();
            const isApiError = checkResponses(booksRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newBooks = booksRes.data || [];

            if(mounted.current){
                setBooks(newBooks);
                filterData(formRef?.current?.values?.filterTerm, formRef?.current?.values?.filterType,
                    formRef?.current?.values?.showInactive, newBooks);
                setFirstSearch(true);

                await Wait(500);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, fetchBooksAll, filterData]);
    useEffect(() => {
        (async function init(){
            await refreshData();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    return (
        <div className="page-box">
            <BrowserTabTitle>Book Manager</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            { modalMode && 
                <BooksModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        filterTerm: '',
                        filterType: filterTypeOptions[0],
                        showInactive: false
                    }}
                    innerRef={formRef}
                >
                    {formik => (
                        <>
                            <h3>Books ({filteredBooks.length})</h3>
                            <br/>
                            <div className="flex flex-row gap-x-4">
                                <div className="flex-end w-1/6">
                                    <SelectSingle
                                        id="book-manager-filterType"
                                        name="filterType"
                                        label="Filter by"
                                        value={formik.values.filterType}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(formik.values.filterTerm, e.target.value, formik.values.showInactive, books);
                                        }}
                                        options={filterTypeOptions}
                                    />
                                </div>
                                <div className="flex-end w-1/3">
                                    <FormikControl
                                        id="book-manager-filterTerm"
                                        name="filterTerm"
                                        placeholder="Search for books..."
                                        value={formik.values.filterTerm}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(e.target.value, formik.values.filterType, formik.values.showInactive, books);
                                        }}
                                    />
                                </div>
                                <div className="self-center">
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={() => refreshData()}
                                    >
                                        Refresh
                                    </Button>
                                </div>
                                <div className="self-center">
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setModalMode('create')}
                                    >
                                        + Add Book
                                    </Button>
                                </div>
                                <div>
                                    <Switch
                                        id="book-manager-show-inactive"
                                        name="showInactive"
                                        label="Show Inactive"
                                        color="mpDBlue"
                                        checked={formik.values.showInactive}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(formik.values.filterTerm, formik.values.filterType, e.target.value, books);
                                        }}
                                    />
                                </div>
                            </div>

                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                                <>
                                    <br/>
                                    <hr/>
                                    <br/>

                                    { firstSearch &&
                                        <BooksTable
                                            books={filteredBooks}
                                            refreshData={refreshData}
                                        />
                                    }
                                </>
                            }
                        </>
                    )}
                </Formik>
            </div>
            {/* <Socket
                refreshData={() => null}
                page="Book Manager"
                setVersion={props.setVersion}
            /> */}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchBooksAll
})(BookManager)