import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Modal, ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, TimePicker, Check } from '../../form';
import { getTimeObject } from '../../functions';
import { validationSchema, dowOptions, getInitCenterOption, getInitGroupOption, getInitContractOption,
    getInitDoWOption, durationOptions, getInitDuratonOption, getInitStudentOption } from './helpers';

function AutoScheduleItemsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, autoScheduleItem, groupOptions, centerOptions,
        loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <Modal.Body>
                    <ModalLoading/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <Modal.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <h4>Delete this auto schedule item?</h4>
                            <div>Student: {autoScheduleItem.studentName}</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    group: getInitGroupOption(autoScheduleItem.group_id, groupOptions),
                    student: getInitStudentOption(autoScheduleItem.group_id, autoScheduleItem.student, groupOptions),
                    contract: getInitContractOption(autoScheduleItem.group_id, autoScheduleItem.student, autoScheduleItem.contract, groupOptions),
                    center: getInitCenterOption(autoScheduleItem.center, centerOptions),
                    dow: getInitDoWOption(autoScheduleItem.dow),
                    time: getTimeObject(parseInt(autoScheduleItem.time) === 0 ? 0 : autoScheduleItem.time || 840),
                    duration: getInitDuratonOption(parseInt(autoScheduleItem.duration) === 0 ? 0 : autoScheduleItem.duration || 60),
                    active: parseInt(autoScheduleItem.active) === 0 ? false : true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <Modal.Body>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <h4>Group</h4>
                                    <SelectSingle
                                        id="asi-group"
                                        name="group"
                                        value={formik.values.group}
                                        options={groupOptions}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.group = e.target.value;
                                            newValues.student = { value: -1, label: 'Please select...' };
                                            newValues.contract = { value: -1, label: 'Please select...' };

                                            formik.setValues(newValues);
                                        }}
                                        placeholder="Required. Max 20 characters."
                                    />
                                    {formik.errors.group && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.group}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <h4>Student</h4>
                                    <SelectSingle
                                        id="asi-student"
                                        name="student"
                                        value={formik.values.student}
                                        options={formik.values.group?.obj?.studentOptions || []}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.student = e.target.value;

                                            formik.setValues(newValues);
                                        }}
                                        placeholder="Required. Max 20 characters."
                                    />
                                    {formik.errors.student && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.student}
                                        </ErrorMessage>
                                    )}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <h4>Contract</h4>
                                    <SelectSingle
                                        id="asi-contract"
                                        name="contract"
                                        value={formik.values.contract}
                                        options={formik.values.student?.obj?.contractOptions || []}
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.contract = e.target.value;

                                            formik.setValues(newValues);
                                        }}
                                        placeholder="Required. Max 20 characters."
                                    />
                                    {formik.errors.contract && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.contract}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <h4>Center</h4>
                                    <SelectSingle
                                        id="asi-center"
                                        name="center"
                                        value={formik.values.center}
                                        options={centerOptions}
                                        onChange={formik.handleChange}
                                        placeholder="Required. Max 20 characters."
                                    />
                                    {formik.errors.center && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.center}
                                        </ErrorMessage>
                                    )}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                    <h4>Day of Week</h4>
                                    <SelectSingle
                                        id="asi-dow"
                                        name="dow"
                                        value={formik.values.dow}
                                        options={dowOptions}
                                        onChange={formik.handleChange}
                                        placeholder="Required. Max 20 characters."
                                    />
                                    {formik.errors.dow && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.dow}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Time</h4>
                                    <TimePicker
                                        id="appointment-form-time"
                                        name="time"
                                        value={formik.values.time?.formatted24 || ''}
                                        onChange={formik.handleChange}
                                        step={1800} // 30 min
                                    />
                                    {formik.errors.time ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.time}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Duration</h4>
                                    <SelectSingle
                                        id="asi-duration"
                                        name="duration"
                                        value={formik.values.duration}
                                        options={durationOptions}
                                        onChange={formik.handleChange}
                                        placeholder="Required. Max 20 characters."
                                    />
                                    {formik.errors.duration && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.duration}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 items-bottom">
                                    <Check
                                        id="asi-active"
                                        name="active"
                                        label="Active"
                                        color="mpLRed"
                                        checked={formik.values.active}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={() => formik.handleSubmit(formik)}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Modal.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(AutoScheduleItemsBodyFooter);