export function appendContractBalances(contracts, payments){
    if(!contracts || !payments) return [{}];

    // Get a list of all relevant payments for each contract for quick lookup
    const contractPaymentMap = {};
    payments.forEach(p => {
        const contractId = parseInt(p.contract);
        if(!contractPaymentMap[contractId]) contractPaymentMap[contractId] = [p];
        else contractPaymentMap[contractId].push(p);
    });

    return contracts.map(c => {
        // Calculate the amount that should be paid
        c.balanceOwed = c.initial_payment + parseInt(c.current_cycle) * parseInt(c.payment_per_cycle);

        // Calculate the amount that has been paid
        const relPayments = contractPaymentMap[parseInt(c.id)] || [];
        let newBalance = 0;
        relPayments.forEach(p => newBalance += parseInt(p.amount));
        c.balancePaid = newBalance;

        c.relevantPayments = relPayments.sort((a, b) => {
            let aDate = new Date(a.date);
            let bDate = new Date(b.date);
            return bDate - aDate;
        });

        return c;
    });
}