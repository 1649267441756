import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Tab, Tabs } from '@mui/material';

import { Button, TabPanel } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { SelectSingle, FormikControl, DateRangeSelector, checkResponses } from '../../../../components/form';
import { formatDateApi } from '../../../../components/functions';
import { FlagModal } from '../../../../components/modal';
import { CSVExport } from '../../../../components/export';
import FlagsTable from './FlagsTable';
import { Socket } from '../../../../components/ws';

import {
    fetchRpCentersAll,
    fetchAdminUsersAll,
    fetchStudentsAll,
    fetchFlagsDaterange
} from '../../../../actions';

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'userName', label: 'User Name' },
    { value: 'instructorName', label: 'Instructor Name' },
    { value: 'center', label: 'Center' },
    { value: 'notes', label: 'Notes' },
];

const start = new Date();
start.setMonth(start.getMonth() - 2);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

function FlagManager(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [drsValid, setDrsValid] = useState(true); // dateRangeSelector
    const [apiError, setApiError] = useState(false);

    // DATA
    const [flags, setFlags] = useState([]);
    const [users, setUsers] = useState([]);

    const [pendingFlagsFiltered, setPendingFlagsFiltered] = useState([]);
    const [completedFlagsFiltered, setCompletedFlagsFiltered] = useState([]);
    const [centers, setCenters] = useState([{ value: -1, label: 'Loading centers...' }]);

    const [modalMode, setModalMode] = useState(null);

    const { fetchFlagsDaterange, fetchRpCentersAll, fetchAdminUsersAll, fetchStudentsAll, } = props;

    const filterFlags = useCallback((unfilteredFlags, filterType, filterQuery) => {
        filterQuery = filterQuery.replace(/ /g, '').toLowerCase();
        const checkUser = (flag) => flag.userName.toLowerCase().replace(/ /g, '').includes(filterQuery);
        const checkInstructors = (flag) => {
            return (
                flag.createdByName.replace(/ /g, '').toLowerCase().includes(filterQuery) ||
                flag.updatedByName.replace(/ /g, '').toLowerCase().includes(filterQuery)
            );
        }
        const checkNotes = (flag) => {
            return (
                flag.notes.replace(/ /g, '').toLowerCase().includes(filterQuery) || 
                flag.admin_notes.replace(/ /g, '').toLowerCase().includes(filterQuery)
            );
        }
        const checkCenters = (flag) => flag.centerName.replace(/ /g, '').toLowerCase().includes(filterQuery);

        const newFlags = unfilteredFlags.filter(f => {
            switch(filterType.value){
                case 'all':
                    return checkUser(f) || checkInstructors(f) || checkNotes(f) || checkCenters(f);
                case 'userName':
                    return checkUser(f);
                case 'instructorName':
                    return checkInstructors(f);
                case 'center':
                    return checkCenters(f);
                case 'notes':
                    return checkNotes(f);
                default:
                    return false;
            }
        });

        // Sort by type and insert into proper state array
        const newPendingFlags = newFlags.filter(f => f.status === 'Pending' );
        const newCompletedFlags = newFlags.filter(f => f.status === 'Completed');
        
        setPendingFlagsFiltered(newPendingFlags);
        setCompletedFlagsFiltered(newCompletedFlags);
    }, []);
    const refreshData = useCallback((newUsers = users, newCenters = centers) => {
        (async function refresh(){
            if(loading || !drsValid || !formRef.current?.values) return;
            if(mounted.current) setLoading(true);
    
            const { filterQuery, filterType, startDate, endDate } = formRef.current.values;
            
            const userMap = {};
            newUsers.forEach(u => userMap[u.id] = `${u.first_name} ${u.last_name}`);

            const centerMap = {};
            newCenters.forEach(c => centerMap[parseInt(c.id)] = c.name);
    
            const flagsRes = await fetchFlagsDaterange({
                startDate: startDate,
                endDate: endDate
            });
            const isApiError = checkResponses(flagsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newFlags = flagsRes.data || [];
            const flagsAppended = newFlags.map(f => {
                const relUserName = userMap[f.user];
                f.userName = relUserName || `Unknown user (UID: ${f.user})`;

                const createdByInstructorName = userMap[f.created_by];
                f.createdByName = createdByInstructorName || `Unknown admin user (ID: ${f.created_by})`;

                const updatedByInstructorName = f.updated_by ? userMap[f.updated_by] : 'None';
                f.updatedByName = updatedByInstructorName || `Unknown admin user (ID: ${f.updated_by})`;

                f.centerName = centerMap[parseInt(f.center)] || `Uknown center (ID: ${f.center})`;

                return f;
            });
    
            if(mounted.current){
                setFlags(flagsAppended);
                filterFlags(flagsAppended, filterType, filterQuery);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, filterFlags, formRef, drsValid, users, centers, fetchFlagsDaterange]);
    useEffect(() => {
        async function init(){
            setLoading(true);
            const centersRes = await fetchRpCentersAll();
            const instructorsRes = await fetchAdminUsersAll();
            const studentsRes = await fetchStudentsAll();
            const isApiError = checkResponses(centersRes, instructorsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }
            
            const newCenters = centersRes.data || [];
            const newInstructors = instructorsRes.data || [];
            const newStudents = studentsRes.data || [];
            newStudents.forEach(s => s.id = s.user_id)
            const newUsers = [...newInstructors, ...newStudents];

            if(mounted.current){
                setCenters(newCenters);
                setUsers(newUsers);
                setLoading(false);
                setHasLoaded(true);
                refreshData(newUsers, newCenters);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    return (
        <div className="page-box">
            <BrowserTabTitle>Flag Manager</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            { modalMode && // 'create' only
                <FlagModal
                    mode={modalMode}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {hasLoaded &&
                <div className="card">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            startDate: startApi,
                            endDate: endApi,
                            filterType: { value: 'all', label: 'All' },
                            filterQuery: '',
                            tabActiveKey: 0 // 0 = pending, 1 = completed
                        }}
                        innerRef={formRef}
                        onSubmit={() => refreshData(users)}
                    >
                        {formik => (
                            <form onSubmit={formik.handleSubmit}>
                                <h3>Flags</h3>
                                <br/>
                                <div className="flex flex-row gap-x-4 items-center">
                                    <div>
                                        <DateRangeSelector
                                            id="flag-manager-drs"
                                            startName="startDate"
                                            endName="endDate"
                                            startLabel="Start Date"
                                            endLabel="End Date"
                                            startValue={formik.values.startDate}
                                            endValue={formik.values.endDate}
                                            defaultValid={true}
                                            onStartChange={formik.handleChange}
                                            onEndChange={formik.handleChange}
                                            onChangeValidation={setDrsValid}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            color="lte-mpTeal"
                                            onClick={formik.handleSubmit}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                    <div>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={() => setModalMode('create')}
                                        >
                                            + Add Flag
                                        </Button>
                                    </div>
                                    <div className="ml-auto">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    <div>
                                                        What gets exported?
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        All flags that are currently filtered
                                                        ({formik.values.tabActiveKey === 0 ?
                                                            pendingFlagsFiltered.length : completedFlagsFiltered.length} items).
                                                        This depends on the tab that is selected.
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <CSVExport
                                                title="Flags"
                                                label="Export Flags to CSV"
                                                data={formik.values.tabActiveKey === 0 ? pendingFlagsFiltered : completedFlagsFiltered}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>
                                
                                <br/>
                                
                                { apiError ? <div className="text-mpLRed">{apiError}</div> : 
                                    <>
                                        <hr/>
                                        <br/>

                                        <h3>Filter Results</h3>
                                        <br/>
                                        <div className="flex flex-row gap-x-4">
                                            <div className="w-1/6">
                                                <SelectSingle
                                                    id="flag-manager-searchType"
                                                    name="filterType"
                                                    label="Filter by"
                                                    value={formik.values.filterType}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        filterFlags(flags, e.target.value, formik.values.filterQuery);
                                                    }}
                                                    options={filterTypeOptions}
                                                />
                                            </div>
                                            <div className="flex-end w-1/3">
                                                <FormikControl
                                                    id="flag-manager-searchQuery"
                                                    name="filterQuery"
                                                    placeholder="Enter a filter query..."
                                                    value={formik.values.filterQuery}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        filterFlags(flags, formik.values.filterType, e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <br/>
                                        <Tabs
                                            id="flags-tabs"
                                            value={formik.values.tabActiveKey}
                                            onChange={(e, newValue) => formik.setFieldValue('tabActiveKey', newValue)}
                                        >
                                            <Tab label={`Pending (${pendingFlagsFiltered.length})`}/>
                                            <Tab label={`Completed (${completedFlagsFiltered.length})`} />
                                        </Tabs>
                                        <TabPanel activeKey={formik.values.tabActiveKey} index={0}>
                                            <FlagsTable
                                                refreshData={refreshData}
                                                flags={pendingFlagsFiltered}
                                            />
                                        </TabPanel>
                                        <TabPanel activeKey={formik.values.tabActiveKey} index={1}>
                                            <FlagsTable
                                                refreshData={refreshData}
                                                flags={completedFlagsFiltered}
                                            />
                                        </TabPanel>
                                    </>
                                }
                                
                            </form>
                        )}
                    </Formik>
                </div>
            }
            <Socket
                refreshData={refreshData}
                page="Flag Manager"
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchRpCentersAll,
    fetchAdminUsersAll,
    fetchStudentsAll,
    fetchFlagsDaterange
})(FlagManager);