import React from 'react';

import { Button, Label, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper } from '../../../../../components/display';

export function getColumns(handleShowModal){
    const noneTag = <span className="text-mpOrange">None</span>;
    return [
        {
            dataField: 'title',
            text: 'Title',
            sort: true
        },
        {
            dataField: 'author',
            text: 'Author',
            sort: true
        },
        {
            dataField: 'difficulty',
            text: 'Difficulty',
            sort: true
        },
        {
            dataField: 'theme',
            text: 'Theme',
            sort: true
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true
        },
        {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => {
                const notes = row.notes;
                const contentWarning = row.content_warning;

                const notesContent = notes || noneTag;

                if(contentWarning){
                    return (
                        <>
                            <div>{notesContent}</div>
                            <TooltipWrapper
                                displayContent={<i className="fas fa-exclamation text-mpLRed"/>}
                                tooltipText={contentWarning}
                            />
                        </>
                    );
                } else {
                    return notesContent;
                }
            }
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                return (parseInt(a) - parseInt(b)) * swap;
            },
            formatter: (row) => {
                const isActive = parseInt(row.active);
                if(isActive) return <Label color="mpGreen">Yes</Label>;
                else return <Label color="mpLRed">No</Label>;
            }
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2 whitespace-nowrap">
                        <TooltipWrapper
                            tooltipText={`Edit this lesson`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete this lesson`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}