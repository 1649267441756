import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import AppointmentModalBodyFooter from './AppointmentsModalBF';
import { checkResponse } from '../../form';
import { formatDate, getymd, getymdhms } from '../../functions';

import {
    fetchRpCentersAll,
    fetchCycleGroupsAll,
    fetchStudentsAll,
    fetchContractsActive,
    fetchMembersRpPermissions,
    fetchAppointmentsIds,
    createAppointments,
    updateAppointmentCore,
    deleteAppointment,
    deleteAppointmentForce
} from '../../../actions';

function AppointmentModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // Default null to match 'undefined' from not passing selectedAppointment to begin with
    const [appointment, setAppointment] = useState({});
    const [parents, setParents] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [studentOptions, setStudentOptions] = useState([]);

    const { mode, fetchRpCentersAll, fetchStudentsAll, fetchContractsActive, fetchCycleGroupsAll,
        fetchMembersRpPermissions, fetchAppointmentsIds, createAppointments,
        updateAppointmentCore, deleteAppointment, deleteAppointmentForce,
        selectedDate, selectedCenter, selectedAppointment, onSubmitCallback } = props;

    useEffect(() => {
        async function init(){
            const centersRes = await fetchRpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const contractsRes = await fetchContractsActive();
            const groupsRes = await fetchCycleGroupsAll();
            const studentUserRes = await fetchMembersRpPermissions({ permissions: 'Student' });
            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newContracts = contractsRes.data || [];
            const newStudentUsers = studentUserRes.data || [];
            const newGroups = groupsRes.data?.groups || [];
            const newGroupMembers = groupsRes.data?.groupMembers || [];

            const newCenterOptions = newCenters.map(c => ({ value: c.id, label: c.name }));

            const studentMap = {};
            newStudents.forEach(s => {
                s.studentName = `${s.first_name} ${s.last_name}`;
                studentMap[s.user_id] = s;
            });

            const contractMap = {};
            newContracts.forEach(c => {
                const studentId = c.student;
                if(!contractMap[studentId]) contractMap[studentId] = [];
                const hoursRemaining = (c.minutes_remaining / 60).toLocaleString(undefined, { maximumFractionDigits: 1 });
                contractMap[studentId].push({
                    value: c.id,
                    label: `${formatDate(c.start_date)} to ${formatDate(c.end_date)}: ${c.type}, ${hoursRemaining} hours left`,
                    obj: c
                });
            });

            const newStudentOptions = newStudents.filter((s) => {
                return (parseInt(s.rp_active) === 1 && parseInt(s.is_rp_student) === 1) || s.user_id === selectedAppointment?.student;
            }).sort((a, b) => {
                if(a.first_name < b.first_name) return -1;
                else if(a.first_name > b.first_name) return 1;
                return 0;
            }).map(s => {
                const relStudent = newStudentUsers.find(su => su.id === s.user_id) || {};
                s.email = relStudent.email;
                s.contractOptions = contractMap[s.user_id] || [];
                return { value: s.user_id, label: `${s.first_name} ${s.last_name}`, obj: s };
            });
            const allStudentOptions = [{ value: 'all', label: 'All' }, ...newStudentOptions];

            const parentIds = [];
            for(let student of newStudents){
                if(!parentIds.includes(student.parent)) parentIds.push(student.parent);
            }
            const parentsRes = await fetchMembersRpPermissions({ permissions: 'Parent' });
            const newParents = parentsRes.data || [];

            const groupMemberMap = {};
            newGroupMembers.forEach(gm => {
                const groupId = parseInt(gm.group_id);
                const studentObj = studentMap[gm.student] || {};
                if(!groupMemberMap[groupId]) groupMemberMap[groupId] = [];
                groupMemberMap[groupId].push(studentObj)
            });
            newGroups.forEach(g => {
                const groupId = parseInt(g.id);
                const studentList = groupMemberMap[groupId] || [];

                const studentExistsMap = {};
                studentList.forEach(s => studentExistsMap[s.user_id] = true);

                let studentNames = '';
                for(let s of studentList){
                    const studentInactive = parseInt(s.rp_active) === 0;
                    if(studentInactive){
                        g.hasInactive = true;
                        studentNames += `${s.first_name} ${s.last_name}*, `
                    } else {
                        studentNames += `${s.first_name} ${s.last_name}, `
                    }
                }

                studentNames = studentNames.slice(0, Math.max(0, studentNames.length - 2));

                const studentOptions = newStudentOptions.filter(s => {
                    return studentExistsMap[s.value];
                });

                g.studentList = studentList;
                g.studentExistsMap = studentExistsMap;
                g.studentNames = studentNames;
                g.studentOptions = [{ value: 'all', label: 'All' }, ...studentOptions];
            });
            const newGroupOptions = newGroups.map(g => ({ value: g.id, label: `${g.name}: ${g.studentNames}`, obj: g }));

            if(mode !== 'create'){
                const appointmentsRes = await fetchAppointmentsIds({ ids: [selectedAppointment.id] });
                const newAppointment = appointmentsRes.data?.appointments?.[0] || {};

                const aptDateTime = new Date(newAppointment.date_time);
                const [year, month, date, hours, minutes] = getymdhms(aptDateTime);
                newAppointment.date = `${year}-${month}-${date} 00:00:00`;
                newAppointment.time = hours * 60 + minutes * 1;

                const relCenter = newCenters.find(c => parseInt(c.id) === parseInt(newAppointment.center)) || {};
                newAppointment.centerName = relCenter.name || `Unable to find center (ID: ${newAppointment.center})`;

                const relStudent = newStudentOptions.find(s => s.value === newAppointment.student) || {};
                newAppointment.studentName = relStudent.label || `Unable to find student (UID: ${newAppointment.student})`;

                const relContract = newContracts.find(c => parseInt(c.id) === parseInt(newAppointment.contract)) || {};
                const hoursRemaining = (relContract.minutes_remaining / 60).toLocaleString(undefined, { maximumFractionDigits: 1 });
                newAppointment.contractName = relContract ? 
                    `${formatDate(relContract.start_date)} to ${formatDate(relContract.end_date)}: ${relContract.type}, ${hoursRemaining} hours left` :
                    `Unknown contract (ID: ${newAppointment.contract})`;
                if(mounted.current) setAppointment(newAppointment);
            }

            if(mounted.current){
                setParents(newParents);
                setCenterOptions(newCenterOptions);
                setGroupOptions(newGroupOptions);
                setStudentOptions(allStudentOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const forceDelete = useCallback((formik) => {
        (async function(){
            const { setStatus, setSubmitting, values } = formik;

            if(mounted.current) setSubmitting(true);

            const response = await deleteAppointmentForce({ id: appointment.id, deleteLinked: values.deleteLinked ? 1 : 0 });
            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        })();
    }, [deleteAppointmentForce, appointment, handleClose])

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current) setSubmitting(true);

            let response = { status: 999 };

            if(mode === 'create'){
                for(let sc of Object.values(values.selectedContracts)){
                    if(sc.value === -1 || !sc.obj){
                        if(mounted.current){
                            setStatus('One or more contracts has not been selected.');
                            setSubmitting(false);
                            return;
                        }
                    }
                }
            }
            
            if(['create', 'edit'].includes(mode)){
                const [year, month, date] = getymd(`${values.date} 00:00:00`);
                const hours = Math.floor(parseInt(values.time.raw) / 60);
                const minutes = parseInt(values.time.raw % 60);
                const dateTime = new Date(`${year}-${month}-${date} ${hours}:${minutes}:00`);

                const appointmentObject = {
                    dateTime,
                    groupId: values.group.value,
                    student: values.student.value,
                    selectedContracts: values.selectedContracts,
                    center: values.center.value,
                    duration: values.duration.value,
                    status: values.status.value,
                    specialNotes: values.specialNotes,
                    specialNotes2: values.specialNotes2,
                    nWeeks: values.nWeeks
                };
                if(mode === 'create'){
                    response = await createAppointments(appointmentObject);
                } else if(mode === 'edit') {
                    appointmentObject.id = appointment.id;
                    response = await updateAppointmentCore(appointmentObject);
                }
            } else if(mode === 'delete') {
                response = await deleteAppointment({ 
                    id: appointment.id,
                    deleteLinked: values.deleteLinked ? 1 : 0
                });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, createAppointments, updateAppointmentCore, deleteAppointment, handleClose, appointment]);

    return (
        <>
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Appointment Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <AppointmentModalBodyFooter
                    mode={mode}
                    selectedDate={selectedDate}
                    selectedCenter={selectedCenter}
                    selectedAppointment={appointment}
                    centerOptions={centerOptions}
                    groupOptions={groupOptions}
                    studentOptions={studentOptions}
                    parents={parents}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    forceDelete={forceDelete}
                />
            </Modal.BodyFooter>
        </Modal>
        </>
    );
}

export default connect(null, {
    fetchRpCentersAll,
    fetchCycleGroupsAll,
    fetchStudentsAll,
    fetchContractsActive,
    fetchMembersRpPermissions,
    fetchAppointmentsIds,
    createAppointments,
    updateAppointmentCore,
    deleteAppointment,
    deleteAppointmentForce
})(AppointmentModal);