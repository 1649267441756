import { basicPath } from '../basicPath';

export const fetchBooksAll = (params) => async (dispatch) => {
    const path = '/rp/books/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchBooksActive = (params) => async (dispatch) => {
    const path = '/rp/books/active';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchBooksId = (params) => async (dispatch) => {
    const path = '/rp/books/id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchBooksTerm = (params) => async (dispatch) => {
    const path = '/rp/books/term';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createBook = (params) => async (dispatch) => {
    const path = '/rp/books';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateBook = (params) => async (dispatch) => {
    const path = '/rp/books';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteBook = (params) => async (dispatch) => {
    const path = '/rp/books';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}