import React, { useRef, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { getColumns } from './table/getColumns';

const defaultSorted = { dataField: 'custom', order: 'asc' };

function BookListTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { selectedBookList } = props;

    const columns = useMemo(() => {
        return getColumns();
    }, []);

    return (
        <div>
            <Table
                tableName="bookLists"
                data={selectedBookList.obj.items}
                columns={columns}
                defaultSorted={defaultSorted}
                rowsPerPageInitial={50}
                emptyMessage="No books were found"
            />
        </div>
    );
}

export default connect(null, {

})(BookListTable);