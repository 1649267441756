import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl } from '../../form';
import { validationSchema, getInitUserOption,
    getInitCenterOption, statusOptions, getInitStatusOption,
    priorityOptions, getInitPriorityOption, renderMeta } from './helpers';
import { formatDate } from '../../functions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function FlagsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedFlag, userOptions, centerOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        const noneTag = <span className="text-mpOrange">None</span>;
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this flag?</h4>
                            <br/>
                            <div className="grid grid-cols-1">
                                <div>User: {selectedFlag.userName}</div>
                                <div>Center: {selectedFlag.centerName}</div>
                                <div>Notes: {selectedFlag.notes || noneTag}</div>
                                <div>Followup Notes: {selectedFlag.followup_notes || noneTag}</div>
                                <div>Status: {selectedFlag.status}</div>
                                <div>Created: {formatDate(selectedFlag.date_created)}</div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-8 text-[10pt]">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    user: getInitUserOption(selectedFlag.user, userOptions),
                    center: getInitCenterOption(selectedFlag.center, centerOptions),
                    notes: selectedFlag.notes || '',
                    followupNotes: selectedFlag.followup_notes || '',
                    priority: getInitPriorityOption(selectedFlag.priority, priorityOptions),
                    status: getInitStatusOption(selectedFlag.status)
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>User</h4>
                                    <SelectSingle
                                        id="flags-user"
                                        name="user"
                                        value={formik.values.user}
                                        onChange={formik.handleChange}
                                        options={userOptions}
                                    />
                                    <div>* = Employee</div>
                                    {formik.errors.user ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.user}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Priority</h4>
                                    <SelectSingle
                                        id="flags-priority"
                                        name="priority"
                                        value={formik.values.priority}
                                        onChange={formik.handleChange}
                                        options={priorityOptions}
                                    />
                                    {formik.errors.priority ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.priority}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Center</h4>
                                    <SelectSingle
                                        id="flags-center"
                                        name="center"
                                        value={formik.values.center}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                    {formik.errors.center ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.center}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Notes</h4>
                                    <FormikControl
                                        id="flags-notes"
                                        name="notes"
                                        value={formik.values.notes}
                                        onChange={formik.handleChange}
                                        as="textarea"
                                        placeholder="Extra comments about the flag in case the tag doesn't suffice. Max 200 characters."
                                    />
                                    {formik.errors.notes ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.notes}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Followup Notes</h4>
                                    <FormikControl
                                        id="flags-followup-notes"
                                        name="followupNotes"
                                        value={formik.values.followupNotes}
                                        onChange={formik.handleChange}
                                        as="textarea"
                                        placeholder="Notes to be left by the person handling the flag. Max 200 characters."
                                    />
                                    {formik.errors.followupNotes ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.followupNotes}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Status</h4>
                                    <SelectSingle
                                        id="flags-status"
                                        name="status"
                                        value={formik.values.status}
                                        onChange={formik.handleChange}
                                        options={statusOptions}
                                    />
                                    {formik.errors.status ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.status}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            {renderMeta(selectedFlag)}
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed mr-2">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-8">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                                {mode === 'edit' ?     
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={(e) => {
                                            formik.setFieldValue('status', { value: 'Completed', label: 'Completed' });
                                            formik.handleSubmit(e);
                                        }}
                                        disabled={formik.isSubmitting}
                                    >
                                        Mark Completed &amp; Submit
                                    </Button>
                                    : null
                                }
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(FlagsBodyFooter);