import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, ModalLoading, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, SelectMulti, FormikControl, Check, RadioSet } from '../../form';
import { TooltipWrapper } from '../../display';
import { validationSchema, getSelectedInstructors, radioOptions,
    statusOptions, getInitSelectedStatus, getCenterOptions,
    getInitSelectedCenter, flagPriorityOptions, renderSubmitting, renderRelevantNotes,
    renderBLFields, renderPastFlags, renderRecommendedStamps } from './helpers';
    import { formatDateFull, formatTime } from '../../functions';
import { renderChanges } from '../stamps/helpers';
import { priorityOptions } from '../flags/helpers';

function DetailsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { loaded, attemptingClose, setAttemptingClose, centers, stampParams,
        instructorOptions, selectedAppointment, relInfo, flags, bookListOptions,
        relevantAssignments, submitted, submissionStatus, handleClose, handleSubmit } = props;

    useEffect(() => {
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    instructors: getSelectedInstructors(relevantAssignments, instructorOptions),
                    bookListOptions,
                    selectedBookList: bookListOptions[0] || { value: -1, label: 'No book lists found', obj: {} },
                    vocabPages: selectedAppointment.vocab_pages || 0,
                    participation: selectedAppointment.participation ? parseInt(selectedAppointment.participation) : 4,
                    answerAccuracy: selectedAppointment.answer_accuracy ? parseInt(selectedAppointment.answer_accuracy) : 4,
                    depthOfThinking: selectedAppointment.depth_of_thinking ? parseInt(selectedAppointment.depth_of_thinking) : 4,
                    stampsAmount: 0,
                    stampsGiven: parseInt(selectedAppointment.stamps_given) === 1 ? true : false,
                    stampsNotes: '',
                    sessionNotes: selectedAppointment.session_notes,
                    homeworkNotes: selectedAppointment.homework_notes || '',
                    internalNotes: selectedAppointment.internal_notes,
                    status: getInitSelectedStatus(selectedAppointment.status),
                    createFlag: false,
                    flagTag: { value: -1, label: 'Please select...' },
                    flagCenter: getInitSelectedCenter(selectedAppointment.center, centers),
                    flagCenterUnlock: false,
                    flagUser: { value: -1, label: 'Please select...'},
                    flagNotes: '',
                    flagPriority: flagPriorityOptions[1],
                    flagFollowupNotes: '',
                    hideCompleted: true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? 
                        renderSubmitting(submissionStatus, formik.setSubmitting) :
                    attemptingClose ? (
                        <ModalBodyFooter>
                            <ModalBodyFooter.Body>
                                <h4>
                                    Are you sure you would like to close the form?
                                </h4>
                            </ModalBodyFooter.Body>
                            <ModalBodyFooter.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                    >
                                        Close Form
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setAttemptingClose(false)}
                                    >
                                        Back to Form
                                    </Button>
                                </div>
                            </ModalBodyFooter.Footer>
                        </ModalBodyFooter>
                    ) :
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                        >

                            <h3>Scheduling</h3>
                            <br/>

                        {/* SECTION 1 */}
                            <div className="flex flex-row gap-x-4 items-start">
                            {/* S1 COLUMN 1 */}
                                <div className="grid grid-columns-1 w-7/12">
                                    <h4>Appointment Info</h4>
                                    <div className="font-sm ml-4">
                                        <div><b>Student:</b> {selectedAppointment.studentName}</div>
                                        <div><b>Time:</b>
                                            &nbsp;{formatDateFull(selectedAppointment.date)}
                                            &nbsp;from {formatTime(selectedAppointment.time)}
                                            &nbsp;to {formatTime(parseInt(selectedAppointment.time) +
                                                parseInt(selectedAppointment.duration))}
                                        </div>
                                        <div><b>Center:</b> {selectedAppointment.centerName}</div>
                                    </div>
                                </div>
                            {/* END S1 COLUMN 1 */}
                            {/* S1 COLUMN 2 */}
                                <div className="grid grid-columns-1 w-5/12">
                                    <h4>Instructors</h4>
                                    <SelectMulti
                                        id="details-instructors"
                                        name="instructors"
                                        onChange={(e) => {
                                            if(e.target.value.length <= 5) formik.handleChange(e);
                                        }}
                                        value={formik.values.instructors}
                                        options={instructorOptions}
                                    />
                                    {formik.values.instructors.length >= 5 ? 
                                        <>
                                            <br/>
                                            <div className="text-mpOrange">
                                                Maximum 5 instructors
                                            </div>
                                        </> : null
                                    }

                                    <br/>

                                    <h4>Status</h4>
                                    <SelectSingle
                                        id="details-status"
                                        name="status"
                                        onChange={formik.handleChange}
                                        value={formik.values.status}
                                        options={statusOptions}
                                    />
                                    {formik.errors.status && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.status}
                                        </ErrorMessage>
                                    )}
                                </div>
                            {/* END S1 COLUMN 2 */}
                            </div>
                        {/* END SECTION 1 */}

                            <br/>
                            <hr/>
                            <br/>

                            <h2>Work Completed</h2>
                            <br/>

                        {/* SECTION 2 */}
                            <div className="flex flex-row gap-x-4 items-start">
                            {/* S2 COLUMN 1 */}
                                <div className="grid grid-cols-1 w-1/2 gap-y-2">
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 w-full gap-y-2">
                                            <h4>Active Book Lists</h4>
                                            <SelectSingle
                                                id="details-selected-lp"
                                                name="selectedBookList"
                                                onChange={formik.handleChange}
                                                value={formik.values.selectedBookList}
                                                options={bookListOptions}
                                            />
                                            {renderBLFields(formik)}
                                        </div>
                                    </div>
                                </div>
                            {/* END S2 COLUMN 1 */}
                            {/* S2 COLUMN 2 */}
                                <div className="grid grid-cols-1 w-1/2 gap-y-2">
                                    <div className="grid grid-cols-1 w-full gap-y-2">
                                        <h4>Vocab Pages</h4>
                                        <FormikControl
                                            id="details-vocab-pages"
                                            name="vocabPages"
                                            onChange={formik.handleChange}
                                            value={formik.values.vocabPages}
                                            placeholder="Max. 100 characters"
                                        />
                                        {formik.errors.vocabPages && (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.vocabPages}
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <div className="grid grid-cols-1 w-full gap-y-2">
                                        <h4>Participation</h4>
                                        <RadioSet
                                            name="participation"
                                            onChange={formik.handleChange}
                                            value={formik.values.participation}
                                            options={radioOptions}
                                            orientation={"horizontal"}
                                        />
                                    </div>

                                    <div className="grid grid-cols-1 w-full gap-y-2">
                                        <h4>Accuracy of Answers</h4>
                                        <RadioSet
                                            name="answerAccuracy"
                                            onChange={formik.handleChange}
                                            value={formik.values.answerAccuracy}
                                            options={radioOptions}
                                            orientation={"horizontal"}
                                        />
                                    </div>

                                    <div className="grid grid-cols-1 w-full gap-y-2">
                                        <h4>Depth of Thinking</h4>
                                        <RadioSet
                                            name="depthOfThinking"
                                            onChange={formik.handleChange}
                                            value={formik.values.depthOfThinking}
                                            options={radioOptions}
                                            orientation={"horizontal"}
                                        />
                                    </div>

                                    <div className="h-2 clear-both"/>
                                    <hr/>
                                    <div className="h-2 clear-both"/>

                                    <div className="row no-mar">
                                        <div className="col-6">
                                            <TooltipWrapper
                                                tooltipText={
                                                    <>
                                                        <div>Heads up! This will give stamps on <u>each</u> form submission.</div>
                                                        <br/>
                                                        <div>If you are just updating other fields, leave this at 0.</div>
                                                    </>
                                                }
                                            >
                                                <h4 className="text-mpLBlue">Stamps To Give*</h4>
                                            </TooltipWrapper>
                                            <FormikControl
                                                id="details-form-stamps"
                                                name="stampsAmount"
                                                value={formik.values.stampsAmount}
                                                onChange={formik.handleChange}
                                                placeholder="Min 0, Max 100"
                                            />
                                            {formik.errors.stampsAmount && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.stampsAmount}
                                                </ErrorMessage>
                                            )}
                                            <Check
                                                id="details-form-stamps-given-bool"
                                                name="stampsGiven"
                                                color="mpDBlue"
                                                checked={formik.values.stampsGiven}
                                                onChange={formik.handleChange}
                                                label={<TooltipWrapper
                                                    tooltipText={
                                                        <>
                                                            <div>
                                                                Have stamps been given for this appointment?
                                                            </div>
                                                            <br/>
                                                            <div>
                                                                Will be marked as true if the form is submitted and "Stamps to Give" is not zero.
                                                            </div>
                                                                <br/>
                                                            <div>
                                                                When this is false, the stamps button appears purple on the
                                                                appointments table. When true, it appears light blue.
                                                            </div>
                                                        </>
                                                    }
                                                >
                                                    <div className="text-mpLBlue">Stamps Given?</div>
                                                </TooltipWrapper>}
                                            />
                                        </div>
                                        <div className="col-6">
                                            {renderRecommendedStamps(formik.values)}
                                        </div>
                                    </div>

                                    {parseInt(formik.values.nPages) + parseInt(formik.values.nLessons) !== 0 && 
                                        parseInt(formik.values.stampsAmount) === 0 &&
                                        !formik.values.stampsGiven ? (
                                            <div className="row no-mar">
                                                <div className="col text-mpOrange">
                                                    Warning! Student has completed work, but has not yet received stamps!
                                                    Are you sure "Stamps To Give" should be 0?
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            <TooltipWrapper
                                                tooltipText="Notes will be added to the Stamps Log entry"
                                            >
                                                <h4 className="text-mpLBlue">Stamps Notes</h4>
                                            </TooltipWrapper>
                                            <FormikControl
                                                id="stamps-notes"
                                                name="stampsNotes"
                                                value={formik.values.stampsNotes}
                                                onChange={formik.handleChange}
                                                placeholder="Max. 100 characters"
                                            />
                                            {formik.errors.stampsNotes && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.stampsNotes}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            {/* The arguments needed to be adjusted since this function is
                                                adopted from the StampsModal helpers */}
                                            {renderChanges({ object: selectedAppointment.studentObject },
                                                { value: 'Give Stamps' },
                                                formik.values.stampsAmount, !formik.errors.stampsAmount,
                                                stampParams)}
                                        </div>
                                    </div>
                                </div>

                            {/* END S2 COLUMN 2 */}
                            </div>
                        {/* END SECTION 2 */}

                            <br/>
                            <hr/>
                            <br/>

                            <h2>Notes</h2>
                            <br/>

                        {/* SECTION 3 */}
                            <div className="flex flex-row gap-x-4 items-start">
                            {/* S3 COLUMN 1 */}
                                <div className="w-1/2 grid grid-cols-1 gap-y-2">
                                    <div className="w-full grid grid-cols-1 gap-y-2">
                                        <TooltipWrapper
                                            tooltipText="Session notes can be viewed by the parent."
                                        >
                                            <h4 className="text-mpLBlue">Session Notes</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="details-session-notes"
                                            name="sessionNotes"
                                            onChange={formik.handleChange}
                                            value={formik.values.sessionNotes}
                                            as="textarea"
                                            placeholder="Notes to be shared with parents. Max 1,000 characters"
                                        />
                                        {formik.errors.sessionNotes && (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.sessionNotes}
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <br/>

                                    <div className="w-full grid grid-cols-1 gap-y-2">
                                        <TooltipWrapper
                                            tooltipText="Homework Notes can be viewed by the parent."
                                        >
                                            <h4 className="text-mpLBlue">Homework Notes</h4>
                                        </TooltipWrapper>
                                        <FormikControl
                                            id="details-homeworkNotes"
                                            name="homeworkNotes"
                                            onChange={formik.handleChange}
                                            value={formik.values.homeworkNotes}
                                            as="textarea"
                                            placeholder="Max 500 characters"
                                        />
                                        {formik.errors.homeworkNotes && (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.homeworkNotes}
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <br/>

                                    <div className="w-full grid grid-cols-1 gap-y-2">
                                        <h4>Internal Notes</h4>
                                        <FormikControl
                                            id="details-internal-notes"
                                            name="internalNotes"
                                            onChange={formik.handleChange}
                                            value={formik.values.internalNotes}
                                            as="textarea"
                                            placeholder="Any additional notes for the student's session. Will not be shared with parents. Max 1,000 characters"
                                        />
                                        {formik.errors.internalNotes && (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.internalNotes}
                                            </ErrorMessage>
                                        )}
                                    </div>

                                    <br/>
                                </div>
                            {/* END S3 COLUMN 1 */}
                            {/* S3 COLUMN 2 */}
                                <div className="w-1/2 grid grid-cols-1 gap-y-2">
                                    <div className="w-full grid grid-cols-1 gap-y-2">
                                        {renderRelevantNotes(relInfo.combinedNotes)}
                                    </div>
                                </div>
                            {/* END S3 COLUMN 3 */}
                            </div>
                        {/* END SECTION 3 */}

                            <br/>
                            <hr/>
                            <br/>
                        
                            <h2>Flags</h2>
                        {/* SECTION 4 */}
                            <div className="flex flex-row gap-x-4 items-start">
                            {/* S4 COLUMN 1 */}
                                <div className="w-1/2 grid grid-cols-1 gap-y-2">
                                    <div className="w-full grid grid-cols-1 gap-y-2 justify-self-start">
                                        <Check
                                            id="details-create-flag"
                                            name="createFlag"
                                            label="Create Flag"
                                            color="mpDBlue"
                                            checked={formik.values.createFlag}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    {formik.values.createFlag && (
                                        <div className="w-full grid grid-cols-1 gap-y-2">
                                            <h4>User</h4>
                                            <SelectSingle
                                                id="details-flagUser"
                                                name="flagUser"
                                                onChange={formik.handleChange}
                                                value={formik.values.flagUser}
                                                options={instructorOptions}
                                            />
                                            {formik.errors.flagUserValid && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.flagUserValid}
                                                </ErrorMessage>
                                            )}

                                            <br/>

                                            <h4>Center</h4>
                                            <SelectSingle
                                                id="details-flag-center"
                                                name="flagCenter"
                                                disabled={!formik.values.flagCenterUnlock}
                                                onChange={formik.handleChange}
                                                value={formik.values.flagCenter}
                                                options={getCenterOptions(centers)}
                                            />
                                            <div className="text-mpOrange">
                                                Center defaults to the center that this appointment was at.
                                            </div>
                                            {formik.errors.flagCenterValid && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.flagCenterValid}
                                                </ErrorMessage>
                                            )}
                                            <Check
                                                id="details-flag-center-unlocked"
                                                name="flagCenterUnlock"
                                                label="Change Center"
                                                color="mpDBlue"
                                                checked={formik.values.flagCenterUnlock}
                                                onChange={formik.handleChange}
                                            />

                                            <br/>

                                            <h4>Priority</h4>
                                            <SelectSingle
                                                id="details-flag-priority"
                                                name="flagPriority"
                                                onChange={formik.handleChange}
                                                value={formik.values.flagPriority}
                                                options={priorityOptions}
                                            />
                                            {formik.errors.flagPriorityValid && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.flagPriorityValid}
                                                </ErrorMessage>
                                            )}

                                            <br/>

                                            <h4>Flag Notes</h4>
                                            <FormikControl
                                                as="textarea"
                                                id="details-flag-notes"
                                                name="flagNotes"
                                                onChange={formik.handleChange}
                                                value={formik.values.flagNotes}
                                                placeholder="Extra comments about the flag in case the tag doesn't suffice. Max 200 characters"
                                            />
                                            {formik.errors.flagNotes && (
                                                <ErrorMessage color="mpLRed">
                                                    {formik.errors.flagNotes}
                                                </ErrorMessage>
                                            )}
                                        </div>
                                    )}
                                </div>
                            {/* END S4 COLUMN 1 */}
                            {/* S4 COLUMN 2 */}
                                <div className="w-1/2 grid grid-cols-1 gap-y-2">
                                    {formik.values.createFlag && renderPastFlags(flags)}
                                </div>
                            {/* END S4 COLUMN 2 */}
                            </div>
                        {/* END SECTION 4 */}
                            
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-4">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={(e) => {
                                        formik.setFieldValue('status', { value: 'Completed', label: 'Completed' });
                                        formik.handleSubmit(e);
                                    }}
                                    disabled={formik.isSubmitting}
                                >
                                    Mark Completed &amp; Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(DetailsBodyFooter);