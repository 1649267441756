import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { BookListTemplateModal } from '../../../../components/modal';
import { getColumns } from './table/getColumns';

const defaultSorted = { dataField: 'date_created', order: 'desc' };

function TemplatesTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { bookListTemplates, refreshData, searchTerm } = props;

    const [modalMode, setModalMode] = useState(null);
    const [selectedBookListTemplate, setSelectedBookListTemplate] = useState(null);

    const handleShowModal = useCallback((mode, bookListTemplate) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedBookListTemplate(bookListTemplate);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedBookListTemplate(null);
            if(changes) refreshData(searchTerm);
        }
    }, [mounted, refreshData, searchTerm]);

    const columns = useMemo(() => {
        return getColumns(handleShowModal);
    }, [handleShowModal]);

    return (
        <div>
            {modalMode &&
                <BookListTemplateModal
                    mode={modalMode}
                    selectedBookListTemplate={selectedBookListTemplate}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="bookListTemplates"
                data={bookListTemplates}
                columns={columns}
                defaultSorted={defaultSorted}
                rowsPerPageInitial={50}
                emptyMessage="No book list templates were found"
            />
        </div>
    );
}

export default connect(null, {

})(TemplatesTable);