import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from "formik";

import TemplatesTable from './TemplatesTable';
import { Button } from '../../../../components/custom-essentials';
import { FormikControl, checkResponses, Switch } from '../../../../components/form';
import { BookListTemplateModal } from "../../../../components/modal";
import { LoadingOverlay, BrowserTabTitle } from "../../../../components/display";
import { Socket } from '../../../../components/ws';

import {
    fetchBookListTemplatesAll,
    fetchBooksAll
} from '../../../../actions';

function TemplateManager(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    });
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [bookListTemplates, setBookListTemplates] = useState([]);
    const [filteredBookListTemplates, setFilteredBookListTemplates] = useState([]);
    // Modal
    const [modalMode, setModalMode] = useState(null);
    const [selectedBookListTemplate, setSelectedBookListTemplate] = useState(null);
    
    // const urlParams = new URLSearchParams(window.location.search);

    const filterData = useCallback((newBookListTemplates = bookListTemplates, filterQuery, showInactive) => {
        const formattedFQ = filterQuery.toLowerCase().trim().replace(/ /g, '');
        showInactive = [true, 'true'].includes(showInactive);
        
        function checkDoesContain(text){ return text.toString().toLowerCase().replace(/ /g, '').trim().includes(formattedFQ) };
        const cdc = (text) => checkDoesContain(text);
        const newFilteredBookListTemplates = newBookListTemplates.filter(blt => {
            if(!showInactive && parseInt(blt.active) === 0) return false;
            return cdc(blt.name) || cdc(blt.titleString) || cdc(blt.authorString) || cdc(blt.notes);
        })

        if(mounted.current) setFilteredBookListTemplates(newFilteredBookListTemplates);
    }, [setFilteredBookListTemplates, bookListTemplates, mounted]);

    const { fetchBookListTemplatesAll, fetchBooksAll } = props;
    const refreshData = useCallback((filterQuery = '', showInactive = false) => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);
            const bookListTemplatesRes = await fetchBookListTemplatesAll();
            const booksRes = await fetchBooksAll();
            const isApiError = checkResponses(bookListTemplatesRes, booksRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            
            const newBookListTemplates = bookListTemplatesRes.data?.templates || [];
            const newBookListTemplateItems = bookListTemplatesRes.data?.templateItems || [];
            const newBooks = booksRes.data || [];

            const bookMap = {};
            newBooks.forEach(b => bookMap[parseInt(b.id)] = b);

            const bookItemMap = {};
            newBookListTemplateItems.forEach(blti => {
                const templateId = parseInt(blti.book_list_template_id);
                const bookId = parseInt(blti.book_id);
                blti.bookObj = bookMap[bookId] || {};
                if(!bookItemMap[templateId]) bookItemMap[templateId] = [];
                bookItemMap[templateId].push(blti);
            });
            newBookListTemplates.forEach(blt => {
                blt.templateItems = bookItemMap[parseInt(blt.id)] || [];
                blt.titleString = '';
                blt.authorString = '';
                blt.titleAuthorString = '';
                blt.templateItems.forEach(blti => {
                    blt.titleString += blti.bookObj.title;
                    blt.authorString += blti.bookObj.author;
                    blt.titleAuthorString += `${blti.bookObj.title} (${blti.bookObj.author}), `;
                });
                if(blt.titleAuthorString.length){
                    blt.titleAuthorString = blt.titleAuthorString.slice(0, blt.titleAuthorString.length - 2);
                }
            });
    
            if(mounted.current){
                setBookListTemplates(newBookListTemplates);
                filterData(newBookListTemplates, filterQuery, showInactive);
                setLoading(false);
            }
        })();
    }, [fetchBookListTemplatesAll, fetchBooksAll, loading, mounted, setBookListTemplates, setLoading, filterData]);
    useEffect(() => {
        (async function init(){
            await refreshData('', false);
            setHasLoaded(true);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function onSubmitCallback(filterQuery, changes = true){
        setModalMode(null);
        setSelectedBookListTemplate(null);
        if(changes) refreshData(filterQuery);
    }

    return (
        <div className="page-box">
            <BrowserTabTitle>Book List Template Manager</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <h2>Book List Templates ({filteredBookListTemplates.length})</h2>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={{
                        filterQuery: '',
                        showInactive: false
                    }}
                    handleSubmit={() => null}
                >
                    {formik => (
                        <>
                            <div className="flex flex-row gap-x-4">
                                <div className="w-5/12 self-end">
                                    <FormikControl
                                        id="template-manager-query-1"
                                        name="filterQuery"
                                        placeholder="Filter book list templates..."
                                        value={formik.values.filterQuery}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(bookListTemplates, e.target.value, formik.values.showInactive);
                                        }}
                                    />
                                </div>
                                <div className="self-end">
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setModalMode('create')}
                                    >
                                        + New Template
                                    </Button>
                                </div>
                                <div>
                                    <Switch
                                        id="template-manager-show-inactive"
                                        name="showInactive"
                                        label="Show Inactive"
                                        color="mpDBlue"
                                        checked={formik.values.showInactive}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(bookListTemplates, formik.values.filterQuery, e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            {modalMode && <BookListTemplateModal
                                mode={modalMode}
                                selectedBookListTemplate={selectedBookListTemplate}
                                onSubmitCallback={(changes) => onSubmitCallback(formik.values.filterQuery, changes)}
                            />}
                            {/* <Socket
                                refreshData={() => refreshData(formik.values.filterQuery)}
                                page="Book List Template Manager"
                                setVersion={props.setVersion}
                            /> */}

                            <br/>

                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                                hasLoaded && 
                                <TemplatesTable
                                    bookListTemplates={filteredBookListTemplates}
                                    refreshData={() => refreshData(formik.values.filterQuery)}
                                />
                            }
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchBookListTemplatesAll,
    fetchBooksAll
})(TemplateManager);