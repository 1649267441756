import app from '../api/app';
import Cookies from 'universal-cookie';
import DOMPurify from 'dompurify';

import { formatStrings } from '../components/functions';
import { Wait } from '../components/wait';

import { clearUserInfo } from '../../src/app/store';

export { login, signout, forceSignout, requestPasswordReset, resetPassword } from './paths/auth';

// paths
export { fetchStampParams } from './paths/_stampParams';
export { fetchAdminUsersAll, fetchAdminUsersActive, fetchAdminUsersIds,
    createAdminUser, updateAdminUser, updateAdminUserPassword, deleteAdminUser } from './paths/adminUsers';
export { fetchAppointmentsDateCenter, fetchAppointmentsDaterange, fetchAppointmentsDaterangeStudent,
    fetchAppointmentsDaterangeCenterStudentName, fetchAppointmentsIds, createAppointments,
    createAppointmentsBulk, updateAppointmentCore, updateAppointmentDetails, updateAppointmentStatus,
    updateAppointmentInstructors, updateAppointmentStampsGiven, deleteAppointment,
    deleteAppointmentForce } from './paths/appointments';
export { fetchAutoScheduleItemsAll, fetchAutoScheduleItemsCenter, fetchAutoScheduleItemsIds,
    createAutoScheduleItem, updateAutoScheduleItem, deleteAutoScheduleItem } from './paths/autoScheduleItems';
export { fetchBooksActive, fetchBooksAll, fetchBooksId, fetchBooksTerm, createBook,
    updateBook, deleteBook } from './paths/books';
export { fetchBookListsId, fetchBookListsStudents,
    createBookList, updateBookList, deleteBookList } from './paths/bookLists';
export { fetchBookListItemsStudentDaterangeCompleted, updateBookListItems } from './paths/bookListItems';
export { fetchBookListTemplatesAll, fetchBookListTemplatesFull, fetchBookListTemplatesId,
    createBookListTemplate, updateBookListTemplate, deleteBookListTemplate } from './paths/bookListTemplates';
export { fetchMpCentersAll, fetchRpCentersAll } from './paths/centers';
export { fetchContractsDaterangeCenter, fetchContractsActiveStudents, fetchContractsActive,
    fetchContractsIds, fetchContractsStudentIds, fetchContractsStudentNames, createContract,
    updateContract, deleteContract } from './paths/contracts';
export { fetchFlagsDaterange, fetchFlagsIds, fetchFlagsStatus, createFlag,
    updateFlag, deleteFlag } from './paths/flags';
export { fetchCycleGroupsAll, fetchCycleGroupsActive, fetchCycleGroupsIds, fetchCycleGroupsStudents,
    createCycleGroup, updateCycleGroup, deleteCycleGroup } from './paths/cycleGroups';
export { fetchInstructorAppointmentAssignments } from './paths/instructorAppointmentAssignments';
export { fetchInstructorAttendanceDaterangeUserIds, fetchInstructorAttendanceIds,
    fetchInstructorAttendanceLatestId, fetchInstructorAttendanceCurrentId,
    signIn, signOut, createAttendance, updateAttendance, deleteAttendance } from './paths/instructorAttendance';
export { fetchLeadsAll, fetchLeadsIds, fetchLeadsRpPermissions, createLead,
    updateLead, convertLead, deleteLead } from './paths/leadUsers' ;
export { fetchMembersAll, fetchMembersIds, fetchMembersMpPermissions, fetchMembersRpPermissions,
    createMember, updateMember, updateMemberPassword, resendMemberWelcome,
    deleteMember } from './paths/memberUsers';
export { fetchMemberRequestsAll, fetchMemberRequestsIds, updateMemberRequest,
    deleteMemberRequest } from './paths/memberRequests';
export { fetchPaymentsDaterangeCenter, fetchPaymentsIds,
    createPayment, updatePayment, deletePayment } from './paths/payments';
export { fetchServerTime, fetchServerDatabase } from './paths/server';
export { fetchStampsLogsDaterange, fetchStampsLogsIds, createStampsLog,
    updateStampsLog, deleteStampsLog } from './paths/stampsLogs';
export { fetchStudentsAll, fetchStudentsActive, fetchStudentsEssential, fetchStudentsCenter,
    fetchStudentsUserIds, updateStudentAttributes, updateStudentBinder, updateStudentStamps,
    updateStudentContract, updateStudentHold } from './paths/students';
export { fetchTextLogs } from './paths/textLogs';
    export { fetchRelevantInfoMemberDaterangeStudentName } from './paths/xRelevantInfo';

// localPaths
export { setNMemberRequests } from './localPaths/notifications';
export { updateConnections } from './localPaths/socket';

const cookies = new Cookies();

// Attempts the specified action with the access token currently stored in the broswer cookie.
// If the access token has expired, the refresh token is used in an attempt to get a new access token.
// If the refresh token has expired, the user will be signed out.
export const attemptAction = async (action, dispatch, params = {}) => {
    // Sanitize and escape ' for SQL use
    const sanitizedParams = JSON.parse(DOMPurify.sanitize(JSON.stringify(formatStrings(params))));

    // Attempt action with currently stored acces token
    const aToken = cookies.get('aToken');
    sanitizedParams.aToken = aToken;

    let response = { status: 999 };
    if(aToken){
        try {
            response = await action(sanitizedParams);
        } catch(e) {
            console.error(e);    
        } finally {
            if(!response || !response.status) return response || {};

            // 401 will be used for expired or otherwise invalid tokens
            if([401, 999].includes(response.status)){
                let tokenResponse = await refreshToken();

                // Try one more time if the first request failed
                // This should hopefully limit premature signouts
                if(!tokenResponse?.data?.message || tokenResponse?.status === 401){
                    await Wait(500);
                    tokenResponse = await refreshToken();
                }

                // Token response status should either be 200, 401, or 500
                if(!tokenResponse || tokenResponse.status === 500) return tokenResponse;

                // 401 should only be be returned if the refresh token has expired
                if(tokenResponse.status === 401){
                    dispatch(clearUserInfo());
                    return response;
                }
                
                // If new token received, retry action
                const aToken = tokenResponse.data?.aToken;
                if(!aToken) return {};

                cookies.set('aToken', tokenResponse.data.aToken, { path: '/' });
                sanitizedParams.aToken = aToken;
                
                // return await action(sanitizedParams);
                const response2 = await action(sanitizedParams);
                return response2;
            } else {
                return response || {};
            }
        }
    } else {
        dispatch(clearUserInfo());
        return response;
    }
}

const refreshToken = async () => {
    const rToken = cookies.get('rToken');
    if(!rToken) return {};

    let response = null;
    try {
        response = await app.post(`/adminAuth/request-new-token`, {
            rToken,
            portal: 'rp'
        });
    } catch(e) {
        response = e.response;
        console.error(e);
    } finally {}

    return response || {};
}