import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import MemberRequestsBodyFooter from './MemberRequestsBF';
import { checkResponse } from '../../form';

import {
    fetchMemberRequestsIds,
    fetchStudentsAll,
    updateMemberRequest,
    deleteMemberRequest,
} from '../../../actions';

function MemberRequests(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [memberRequest, setMemberRequest] = useState({});

    const { mode, selectedRequest, onSubmitCallback, fetchMemberRequestsIds, 
        fetchStudentsAll, updateMemberRequest, deleteMemberRequest } = props;

    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsAll();
            const newStudents = studentsRes.data || [];

            const memberRequestRes = await fetchMemberRequestsIds({ ids: [selectedRequest?.id] });
            const newMemberRequest = memberRequestRes.data?.[0] || {};

            const matchedStudent = newStudents.find(s => s.user_id === newMemberRequest.student) || {};
            const name = matchedStudent.first_name || matchedStudent.last_name ? 
                `${matchedStudent.first_name} ${matchedStudent.last_name}` : `Unknown Student (UID: ${selectedRequest?.student})`;
            newMemberRequest.studentName = name;

            if(mounted.current) setMemberRequest(newMemberRequest);

            if(mounted.current){
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(mode === 'edit'){
                const memberRequestParams = {
                    id: memberRequest.id,
                    status: values.selectedStatus.value,
                    adminNotes: values.adminNotes
                };
                response = await updateMemberRequest(memberRequestParams);
            } else if(mode === 'delete') {
                response = await deleteMemberRequest({ id: memberRequest.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, memberRequest, updateMemberRequest, deleteMemberRequest]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Member Request Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <MemberRequestsBodyFooter
                    mode={mode}
                    memberRequest={memberRequest}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchMemberRequestsIds,
    fetchStudentsAll,
    updateMemberRequest,
    deleteMemberRequest,
})(MemberRequests);