import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import { convertApiToDate, formatDateTimeApi } from '../../functions';
import { checkResponse } from '../../form';
import InstructorAttendanceBodyFooter from './InstructorAttendanceBF';

import {
    fetchAdminUsersIds,
    fetchInstructorAttendanceIds,
    createAttendance,
    updateAttendance,
    deleteAttendance
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function InstructorAttendanceModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [attendance, setAttendance] = useState({});
    const [userName, setUserName] = useState('Loading...');

    const { mode, createAttendance, fetchAdminUsersIds, fetchInstructorAttendanceIds,
        updateAttendance, deleteAttendance, selectedItem, onSubmitCallback } = props;

    useEffect(() => {
        async function init(){
            if(mode !== 'create'){
                const attendanceRes = await fetchInstructorAttendanceIds({ ids: [selectedItem.id] })
                const newAttendance = attendanceRes.data?.[0] || {};
                setAttendance(newAttendance);
            }
            const userId = mode === 'create' ? selectedItem : selectedItem.user_id;
            const userRes = await fetchAdminUsersIds({ ids: [userId] });
            const user = userRes.data?.[0] || {};

            const userName = user ? `${user.first_name} ${user.last_name}` : `Unknown user (ID ${userId})`;
            
            if(mounted.current){
                setUserName(userName);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const timeInDate = convertApiToDate(values.selectedDate);
                const timeInRaw = values.timeIn.raw;
                timeInDate.setHours(Math.floor(timeInRaw / 60), timeInRaw % 60, 0, 0);
                const timeOutDate = convertApiToDate(values.selectedDate);
                const timeOutRaw = values.timeOut.raw;
                timeOutDate.setHours(Math.floor(timeOutRaw / 60), timeOutRaw % 60, 0, 0);

                const attendanceParams = {
                    // userId gets conditionally depending on the mode
                    timeIn: formatDateTimeApi(timeInDate),
                    timeOut: values.timeOutNull ? null : formatDateTimeApi(timeOutDate),
                };
                
                if(mode === 'create'){
                    attendanceParams.userId = selectedItem;
                    response = await createAttendance(attendanceParams);
                } else if(mode === 'edit') {
                    attendanceParams.id = attendance.id;
                    response = await updateAttendance(attendanceParams);
                }
            } else if(mode === 'delete') {
                response = await deleteAttendance({ id: attendance.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, selectedItem, attendance, createAttendance, updateAttendance, deleteAttendance,]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Attendance Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <InstructorAttendanceBodyFooter
                    mode={mode}
                    selectedAttendance={attendance}
                    userName={userName}
                    today={initDate}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchAdminUsersIds,
    fetchInstructorAttendanceIds,
    createAttendance,
    updateAttendance,
    deleteAttendance
})(InstructorAttendanceModal);