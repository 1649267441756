import React, { useState, useRef, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { formatDateApi } from '../../../../components/functions';
import { Button } from '../../../../components/custom-essentials';
import { DateRangeSelector, checkResponses } from '../../../../components/form';
import { LoadingOverlay, TooltipWrapper, BrowserTabTitle } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import TextLogsTable from './textLogs/TextLogsTable';
import { Socket } from '../../../../components/ws';

import {
    fetchTextLogs
} from '../../../../actions';

const start = new Date();
start.setDate(start.getDate() - 6);
const startApi = formatDateApi(start);
const endApi = formatDateApi(new Date());

function TextLogs(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [drsValid, setDrsValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);

    // Data
    const [textLogsData, setTextLogsData] = useState([]);

    const { fetchTextLogs } = props;

    const refreshData = useCallback((values) => {
        (async function refresh(){
            if(loading || !drsValid) return;
            if(mounted.current) setLoading(true);
    
            const { startDate, endDate } = values;
    
            const textLogsRes = await fetchTextLogs({
                startDate: startDate,
                endDate: endDate
            });
            const isApiError = checkResponses(textLogsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newTextLogs = textLogsRes.data || [];
    
            if(mounted.current){
                setTextLogsData(newTextLogs);
                setLoading(false);
            }
        })();
    }, [loading, drsValid, fetchTextLogs]);
    useEffect(() => {
        (async function(){
            refreshData({ startDate: startApi, endDate: endApi });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-box">
            <BrowserTabTitle>Text Logs</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <div className="flex flex-row gap-x-4">
                    <Formik
                        enableReinitialize
                        initialValues={{
                            startDate: startApi,
                            endDate: endApi,
                            showAll: false,
                        }}
                        innerRef={formRef}
                        onSubmit={refreshData}
                    >
                        {formik => (
                            <>
                                <div>
                                    <DateRangeSelector
                                        id="assigned-appointments-drs"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                                <div className="self-center">
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={formik.handleSubmit}
                                    >
                                        Search
                                    </Button>
                                </div>
                                <div className="col- d-flex align-items-center" style={{ marginLeft: "auto" }}>
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                <div>
                                                    What gets exported?
                                                </div>
                                                <br/>
                                                <div>
                                                    All text logs that are currently filtered ({textLogsData.length} items).
                                                </div>
                                            </div>
                                        }
                                    >
                                        <CSVExport
                                            title="Text_Logs"
                                            label="Export logs to CSV"
                                            data={textLogsData}
                                        />
                                    </TooltipWrapper>
                                </div>
                            </>
                        )}
                    </Formik>
                </div>

                <br/>

                <h3>Text Log Entries ({textLogsData.length})</h3>


                {apiError ? <div className="text-mpLRed">{apiError}</div> : (
                    <>
                        <br/>
                        <TextLogsTable
                            data={textLogsData}
                        />
                    </>
                )}
            </div>
            <Socket
                refreshData={refreshData}
                page="Text Logs"
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchTextLogs
})(TextLogs);