import * as Yup from 'yup';
import { FormikControl, SelectSingle, Check } from '../../form';

import { ModalBodyFooter, Button, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { renderBli, modifyBookList, applyTemplate } from './helpers2';

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Name is required')
        .max(100, 'Max 100 characters'),
    notes: Yup.string()
        .max(1000, 'Max 1,000 characters'),
});

export function getInitAssessmentOption(assessment, assessmentOptions){
    if(!assessment) return { value: -1, label: 'No Assessment' };
    return assessmentOptions.find(a => a.value === assessment) || { value: -1, label: `Unknown assessment (${assessment})`}
}

function checkFieldsValid(formik){
    let isValid = true;
    const blItems = formik.values.bookListItems;
    for(let i = 0; i < blItems.length; i++){
        const bli = blItems[i];
        const assignedDate = bli.dateAssigned || '';
        const completedDate = bli.dateCompleted || '';
        
        if((completedDate !== '' && (assignedDate === '' || assignedDate > completedDate)) ||
            (assignedDate !== '' && isNaN(new Date(assignedDate))) ||
            (completedDate !== '' && isNaN(new Date(completedDate)))){
            isValid = false;
            break;
        }
    }

    if(!isValid){
        formik.setStatus('One more more fields needs to be corrected.');
        return;
    } else {
        formik.handleSubmit();
    }
}


export function renderBody(formik, handleClose, bookOptions, templateOptions, duplicateBookMap){
    let positionIndex = 0;
    const currentBooks = {};
    currentBooks[-1] = true; // Block out default option
    formik.values.bookListItems.forEach(bli => currentBooks[parseInt(bli.bookId)] = true);
    const newBookOptions = bookOptions.filter(l => !currentBooks[parseInt(l.value)]);

    return (
        <ModalBodyFooter>
            <ModalBodyFooter.Body>
                <div className="flex flex-row gap-x-4">
                    <div className="grid grid-cols-1 gap-y-2 w-full">
                        <h3><u>Book List For:</u> {formik.values.selectedStudent.first_name} {formik.values.selectedStudent.last_name}</h3>
                        <h5>Linked Group: {formik.values.selectedGroup.label}</h5>
                    </div>
                </div>

                <br/>

                <div className="flex flex-row gap-x-4">
                    <div className="grid grid-cols-1 gap-y-2 w-2/3">
                        <h4>Load a Template?</h4>
                        <SelectSingle
                            id="book-list-selectedTemplate"
                            name="selectedTemplate"
                            value={formik.values.selectedTemplate}
                            onChange={formik.handleChange}
                            options={templateOptions}
                        />
                    </div>
                    <div className="grid grid-cols-1 gap-y-2 self-end">
                        <Button
                            color="lte-mpLBlue"
                            disabled={formik.values.selectedTemplate.value === -1}
                            onClick={() => applyTemplate(formik)}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
                <div className="flex flex-row gap-x-4">
                    <div className="grid grid-cols-1 gap-y-2">
                        {formik.values.selectedTemplate.value !== -1 ? 
                            <div className="text-mpOrange">
                                Clicking "Apply" will replace the currently selected books and reset all dates!
                            </div>
                            :
                            null
                        }
                    </div>
                </div>

                <br/>
                <hr/>
                <br/>

                <div className="flex flex-row gap-x-4">
                    <div className="grid grid-cols-1 gap-y-2 w-full">
                        <h4>Book List Name</h4>
                        <FormikControl
                            id="book-list-name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            placeholder="Required. Max 100 characters."
                        />
                        {formik.errors.name && (
                            <ErrorMessage color="mpLRed">
                                {formik.errors.name}
                            </ErrorMessage>
                        )}
                    </div>
                </div>

                <br/>

                <div className="flex flex-row gap-x-4">
                    <div className="grid grid-cols-1 gap-y-2 w-5/6">
                        <h4>Notes</h4>
                        <FormikControl
                            id="book-list-notes"
                            name="notes"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                            placeholder="Max. 1000 characters."
                            as="textarea"
                        />
                        {formik.errors.notes && (
                            <ErrorMessage color="mpLRed">
                                {formik.errors.notes}
                            </ErrorMessage>
                        )}
                    </div>
                    <div className="grid grid-cols-1 gap-y-2 w-1/6 items-end">
                        <Check
                            id="book-list-active"
                            name="active"
                            color="mpDBlue"
                            label={`Active?`}
                            checked={formik.values.active}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>

                <br/>

                <hr/>

                <br/>

                <div className="max-h-[80rem] overflow-y-auto">
                    {formik.values.bookListItems.map(bli => {
                        return renderBli(bli, formik, positionIndex++, duplicateBookMap);
                    })}
                </div>

                <div className="flex flex-row gap-x-4">
                    <div className="grid grid-cols-1 gap-y-2 w-full">
                        <h4>Add Book:</h4>
                        <SelectSingle
                            id="book-list-add-book"
                            name="addBook"
                            value={{ value: -1, label: 'Please select...' }}
                            onChange={(e) => {
                                modifyBookList(formik, e.target.value.obj, 'add')
                            }}
                            options={newBookOptions}
                        />
                    </div>
                </div>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <div className="flex flex-row gap-x-2 flex-wrap">
                        {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                            (
                                <div className="text-mpLRed">
                                    One or more fields needs to be corrected.
                                </div>
                            ) : null
                        }
                        {formik.isSubmitting ?
                            <ModalProcessing/> : null
                        }
                        {formik.status && !formik.isSubmitting ? 
                            <div className="text-mpLRed mr-4">
                                {formik.status}
                            </div> : null
                        }
                        <Button
                            color="lte-mpLRed"
                            disabled={formik.isSubmitting}
                            onClick={() => handleClose(false)}
                        >
                            Close
                        </Button>
                        <Button
                            color="lte-mpLBlue"
                            disabled={formik.isSubmitting}
                            onClick={() => checkFieldsValid(formik)}
                        >
                            Submit
                        </Button>
                    </div>
                </ModalBodyFooter.Footer>
        </ModalBodyFooter>
    );
}