import { basicPath } from '../basicPath';

export const fetchAppointmentsDateCenter = (params) => async (dispatch) => {
    const path = '/rp/appointments/date-center';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAppointmentsDaterange = (params) => async (dispatch) => {
    const path = '/rp/appointments/daterange-only';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAppointmentsDaterangeStudent = (params) => async (dispatch) => {
    const path = '/rp/appointments/daterange-student';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAppointmentsDaterangeCenterStudentName = (params) => async (dispatch) => {
    const path = '/rp/appointments/daterange-center-student-name';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchAppointmentsIds = (params) => async (dispatch) => {
    const path = '/rp/appointments/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createAppointments = (params) => async (dispatch) => {
    const path = '/rp/appointments/single';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const createAppointmentsBulk = (params) => async (dispatch) => {
    const path = '/rp/appointments/bulk';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateAppointmentCore = (params) => async (dispatch) => {
    const path = '/rp/appointments/core';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateAppointmentStatus = (params) => async (dispatch) => {
    const path = '/rp/appointments/status';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateAppointmentDetails = (params) => async (dispatch) => {
    const path = '/rp/appointments/details';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateAppointmentInstructors = (params) => async (dispatch) => {
    const path = '/rp/appointments/instructors';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const updateAppointmentStampsGiven = (params) => async (dispatch) => {
    const path = '/rp/appointments/stamps-given';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAppointment = (params) => async (dispatch) => {
    const path = '/rp/appointments/standard';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}

export const deleteAppointmentForce = (params) => async (dispatch) => {
    const path = '/rp/appointments/force';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}