import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { formatTime, formatDateFull } from '../../../../../components/functions';
import { renderStatus, TooltipWrapper, tableColors } from '../../../../../components/display';

export function getColumns(handleShowAppointmentModal, handleShowDetailsModal, permissions){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    return [
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (bDate - aDate) * swap;
            },
            formatter: (row) => formatDateFull(row.date),
        },
        {
            dataField: 'time',
            text: 'Time',
            sort: true,
            formatter: (row) => {
                const time = row.time;
                return `${formatTime(time)} to ${formatTime(parseInt(time) + parseInt(row.duration))}`;
            }
        },
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true
        },
        {
            dataField: 'groupName',
            text: 'Group Name',
            sort: true
        },
        {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? 1 : -1;
                return (parseInt(rowA.center) - parseInt(rowB.center)) * swap;
            },
            formatter: (row) => {
                const center = parseInt(row.center);
                let centerColor = '';
                if(colorMap[center]) centerColor = colorMap[center];
                else {
                    colorMap[center] = colors[(colorIndex++) % 5];
                    centerColor = colorMap[center];
                }
                return <span className="mr-2"><Label color={centerColor}>{row.centerName}</Label></span>;
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: (row) => renderStatus(row)
        },
        {
            dataField: 'instructorNames',
            text: 'Instructors'
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2 whitespace-nobreak">
                        <TooltipWrapper tooltipText={`Edit appointment information`}>
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowAppointmentModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper tooltipText={`Edit appointment details`}>
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowDetailsModal('edit', row)}
                            >
                                <SVGIcon fileName="plus"/>
                            </Button>
                        </TooltipWrapper>
                        { ['Root', 'Admin', 'Lead Instructor'].includes(permissions) && 
                            <TooltipWrapper tooltipText={`Delete appointment`}>
                                <Button
                                    className="btn btn-icon btn-sm"
                                    color="lte-mpLRed"
                                    variant="icon"
                                    onClick={() => handleShowAppointmentModal('delete', row)}
                                >
                                    <SVGIcon fileName="trash"/>
                                </Button>
                            </TooltipWrapper>
                        }
                    </div>
                );
            }
        }
    ];
}