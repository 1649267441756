import app from '../../api/app';
import { basicPath } from '../basicPath';
import { attemptAction } from '../index';

import { setServerDatabase } from '../../app/store';

export const fetchServerTime = () => async (dispatch) => {
    const path = '/server/server-time';
    const type = 'get';
    const params = {};
    return await basicPath(dispatch, params, path, type);
}

export const fetchServerDatabase = () => async (dispatch) => {
    async function action(params){
        let response = null;
        try {
            response = await app.get(`/server/database`, {
                params
            });
        } catch (e) {
            response = e.response;
            console.log(e);
        } finally {
            dispatch (setServerDatabase({
                database: response.data[0]?.['DATABASE()']
            }));
            return response;
        }
    }
    return await attemptAction(action, dispatch);
}