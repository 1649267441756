import React, { useState, useRef, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { formatDateApi, formatDate } from '../../functions';
import { DatePicker, SelectSingle, FormikControl, Check } from '../../form';
import { validationSchema, defaultContractOption, methodOptions, getInitMethodOption } from './helpers';

import {
    fetchContractsStudentNames,
} from '../../../actions';

const initDate = formatDateApi(new Date());

function PaymentsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, students, contractOption, selectedPayment, loaded,
        submitted, handleClose, handleSubmit, fetchContractsStudentNames } = props;

    const [fetching, setFetching] = useState(false);
    const [contractOptions, setContractOptions] = useState([]);
    const [hasFetched, setHasFetched] = useState(false); // Handles display of 'success!' message

    const fetchContracts = useCallback((searchQuery, activeExtraOnly) => {
        (async function(){
            if(mounted.current) setFetching(true);

            const searchQueryFormatted = searchQuery.toLowerCase();

            const contractsRes = await fetchContractsStudentNames({
                searchQuery: searchQueryFormatted,
                activeExtraOnly: activeExtraOnly ? 1 : 0
            });
            const newContracts = contractsRes.data || [];

            const contractToNameMap = {};
            students.forEach(s => contractToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);

            const newContractOptions = newContracts.sort((a, b) => {
                const aDate = new Date(a.start_date);
                const bDate = new Date(b.start_date);

                return bDate - aDate;
            }).map(c => {
                const studentName = contractToNameMap[c.student] || `Unknown student (UID: ${c.student})`;
                const prefix = parseInt(c.id) === parseInt(contractOption?.value) ? '*' : ''; // To denote the original selection
                return ({ value: c.id, label: `${prefix}${formatDate(c.start_date)} to ${formatDate(c.end_date)}: ${studentName} (${c.type})` });
            });
    
            if(mounted.current){
                setContractOptions(newContractOptions);
                setFetching(false);
                setHasFetched(true);
            }
        })();
    }, [mounted, students, contractOption, fetchContractsStudentNames])

        if(!loaded){
            return(
                <ModalBodyFooter>
                    <ModalBodyFooter.Body>
                        <ModalLoading/>
                    </ModalBodyFooter.Body>
                    <ModalBodyFooter.Footer>
                        <Button
                            color="lte-mpLRed"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </ModalBodyFooter.Footer>
                </ModalBodyFooter>
            );
        } else if(submitted){
            return (
                <ModalBodyFooter>
                    <ModalBodyFooter.Body>
                        <h4>
                            Updated successfully!
                        </h4>
                    </ModalBodyFooter.Body>
                    <ModalBodyFooter.Footer>
                        <Button
                            color="lte-mpLRed"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </ModalBodyFooter.Footer>
                </ModalBodyFooter>
            );
        }  else if(mode === 'delete') {
            return (
                <Formik
                    enableReinitialize
                    initialValues={{}}
                    validateOnChange={true}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <ModalBodyFooter>
                            <ModalBodyFooter.Body>
                                <h4>Delete this payment?</h4>
                                <div className="col">
                                    <div>
                                        Linked Contract: {contractOption.label}
                                    </div>
                                </div>
                            </ModalBodyFooter.Body>
                            <ModalBodyFooter.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    {formik.isSubmitting &&
                                        <ModalProcessing/>
                                    }
                                    {formik.status && !formik.isSubmitting ? 
                                        <div className="text-mpLRed text-[8pt] mr-4">
                                            {formik.status}
                                        </div> : null
                                    }
                                    <Button
                                        color="lte-mpLRed"
                                        disabled={formik.isSubmitting}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="hol-mpLRed"
                                        disabled={formik.isSubmitting}
                                        onClick={formik.handleSubmit}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </ModalBodyFooter.Footer>
                        </ModalBodyFooter>
                    )}
                </Formik>
            );
        } else {
            return(
                <Formik
                    enableReinitialize
                    initialValues={{
                        contractSearchQuery: '',
                        onlyShowActiveAndExtra: true,
                        contract: contractOption || defaultContractOption,
                        date: selectedPayment.date ? formatDateApi(selectedPayment.date) : initDate,
                        method: getInitMethodOption(selectedPayment.method),
                        amount: selectedPayment.amount || 0,
                        notes: selectedPayment.notes || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {formik => (
                        <ModalBodyFooter>
                            <ModalBodyFooter.Body>
                                <div>Reminder: You are currently <u>{mode === 'create' ? 'creating' : 'editing'}</u> a payment.</div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 w-full">
                                        <h4>Contract Search</h4>
                                        <div className="flex flex-row gap-x-4">
                                            <div className="grid grid-cols-1 gap-y-2 w-3/4">
                                                <FormikControl
                                                    id="payment-form-contract-search"
                                                    name="contractSearchQuery"
                                                    value={formik.values.contractSearchQuery}
                                                    onChange={(e) => {
                                                        setHasFetched(false);
                                                        formik.handleChange(e);
                                                    }}
                                                    placeholder="Search Contracts by Students..."
                                                    shouldHandleSubmit
                                                    onSubmit={() => fetchContracts(formik.values.contractSearchQuery, formik.values.onlyShowActiveAndExtra)}
                                                />
                                            </div>
                                            <div className="grid grid-cols-1 gap-y-2">
                                                <Button
                                                    color="lte-mpLBlue"
                                                    onClick={() => fetchContracts(formik.values.contractSearchQuery,
                                                        formik.values.onlyShowActiveAndExtra)}
                                                    disabled={fetching}
                                                >
                                                    Search
                                                </Button>
                                            </div>
                                            {hasFetched ?
                                            <div className="text-mpGreen">
                                                Search successful! View results in the dropdown menu below.
                                            </div>
                                        : null}
                                        </div>
                                        <Check
                                            id="payment-form-only-show-active-extra"
                                            name="onlyShowActiveAndExtra"
                                            label="Only show Active and Extra contracts"
                                            checked={formik.values.onlyShowActiveAndExtra}
                                            color="mpDBlue"
                                            onChange={(e) => {
                                                setHasFetched(false);
                                                formik.handleChange(e);
                                            }}
                                        />

                                        <br/>

                                        <h4>Linked Contract</h4>
                                        <SelectSingle
                                            id="payment-form-contract"
                                            name="contract"
                                            value={formik.values.contract}
                                            onChange={(e) => {
                                                setHasFetched(false);
                                                formik.handleChange(e);
                                            }}
                                            options={contractOptions.length ? contractOptions : [contractOption || defaultContractOption]}
                                        />
                                        <div>* Denotes the original selection (if editing a payment)</div>
                                        {contractOptions.length >= 100 ?
                                            <div className="text-mpLRed">
                                                Only the 100 most recent contracts (ordered by Start Date) are shown.
                                                To get more results, please narrow your search criteria.
                                            </div>
                                        : null}
                                    </div>
                                </div>

                                <br/>
                                <hr/>
                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="grid grid-cols-1 gap-y-2">
                                        <h4>Payment Date</h4>
                                        <DatePicker
                                            id="payment-form-date"
                                            name="date"
                                            value={formik.values.date}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.date ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.date}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4 items-start">
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Method</h4>
                                        <SelectSingle
                                            id="payment-form-method"
                                            name="method"
                                            value={formik.values.method}
                                            onChange={formik.handleChange}
                                            options={methodOptions}
                                        />
                                        {formik.errors.method ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.method}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                    <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                        <h4>Amount</h4>
                                        <FormikControl
                                            id="payment-form-amount"
                                            name="amount"
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                            placeholder="Min: -30000, Max: 30000"
                                        />
                                        {formik.errors.amount ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.amount}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row">
                                    <div className="grid grid-cols-1 gap-y-2 w-full">
                                        <h4>Notes</h4>
                                        <FormikControl
                                            id="payment-form-notes"
                                            name="notes"
                                            value={formik.values.notes}
                                            onChange={formik.handleChange}
                                            placeholder="Max. 500 characters"
                                            as="textarea"
                                        />
                                        {formik.errors.notes ? (
                                            <ErrorMessage color="mpLRed">
                                                {formik.errors.notes}
                                            </ErrorMessage>
                                        ) : null}
                                    </div>
                                </div>
                            </ModalBodyFooter.Body>
                            <ModalBodyFooter.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                        (
                                            <div className="text-mpLRed">
                                                One or more fields needs to be corrected.
                                            </div>
                                        ) : null
                                    }
                                    {formik.isSubmitting ?
                                        <ModalProcessing/> : null
                                    }
                                    {formik.status && !formik.isSubmitting ? 
                                        <div className="text-mpLRed mr-2">
                                            {formik.status}
                                        </div> : null
                                    }
                                    <Button
                                        color="lte-mpLRed"
                                        disabled={formik.isSubmitting}
                                        onClick={handleClose}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => formik.handleSubmit(formik)}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </ModalBodyFooter.Footer>
                        </ModalBodyFooter>
                    )}
                </Formik>
            );
        }
}

export default connect(null, {
    fetchContractsStudentNames,
})(PaymentsBodyFooter);