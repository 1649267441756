import * as Yup from 'yup';
import { convertApiToDate } from '../../functions';

export const validationSchema = Yup.object().shape({
    groupName: Yup.string()
        .required('Group Name is required')
        .max(200, 'Max 200 characters'),
    center: Yup.mixed().test(
        'centerIsSelected',
        'Select a valid center',
        (value) => value?.value !== -1
    ),
    selectedStudents: Yup.mixed().test(
        'studentIsSelected',
        'Select at least one student',
        (value) => value.length > 0
        ).test(
            'studentsAtMostSixSelected',
            'Maximum 6 students',
            (value) => value.length <= 6
        ),
    drsValid: Yup.mixed().when(['startDate', 'endDate'],
        ([startDate, endDate]) => {
            const startDateObject = convertApiToDate(startDate);
            const endDateObject = convertApiToDate(endDate);

            const isValid1 = !isNaN(startDateObject.getTime()) && !isNaN(endDateObject.getTime());
            if(!isValid1) return Yup.mixed().test('drsValid', `At least one date is invalid`, () => false);
            
            const isValid2 = startDateObject < endDateObject;
            if(!isValid2) return Yup.mixed().test('drsValid', `Start Date must come before End Date`, () => false);

            return Yup.mixed().test('drsValid', '', () => true);
        }),
    time: Yup.mixed().test(
        'timeIsValid',
        'Invalid time',
        (value) => !isNaN(value.raw)
        ).test(
            'isQuarterHour',
            'Minutes must be an increment of 15',
            (value) => value.raw % 15 === 0
        ),
    notes: Yup.string()
        .max(5000, 'Max 5,000 characters')
});

export function getInitSelectedStudents(groupMembers, studentOptions){
    const selectedStudents = [];
    if(!groupMembers) return selectedStudents;

    for(let groupMember of groupMembers){
        const studentOption = studentOptions.find(o => o.value === groupMember.student);
        selectedStudents.push(studentOption || { value: -1, label: `Unknown student (UID) ${groupMember.student}`})
    }
    return selectedStudents;
}

export function getInitCenterOption(center, centerOptions){
    if(!center) return { value: -1, label: 'Please select...' };
    return centerOptions.find(c => parseInt(c.value) === parseInt(center)) || { value: -1, label: `Unknown center (ID ${center})` };
}
export function getCenterName(center, centerOptions){
    const relCenter = centerOptions.find(c => parseInt(c.value) === parseInt(center));
    if(!relCenter) return `Unknown center (${center})`;
    else return relCenter.label;
}