import React from 'react';
import * as Yup from 'yup';
import { formatDate } from '../../functions';

export const validationSchema = Yup.object().shape({
    employee: Yup.mixed().test(
        'employeeIsSeleceted',
        'Select a valid employee',
        (value) => value?.value !== -1
    ),
    center: Yup.mixed().test(
        'centerIsSelected',
        'Select a valid center',
        (value) => value?.value !== -1
    ),
    notes: Yup.string()
        .max(200, 'Max 200 characters'),
    followupNotes: Yup.string()
        .max(200, 'Max 200 characters'),
    priority: Yup.mixed().test(
        'priorityIsSelected',
        'Select a valid priority',
        (value) => value?.value !== -1
    ),
    status: Yup.mixed().test(
        'statusIsSelected',
        'Select a valid status',
        (value) => value?.value !== -1
    ),
});

export function getInitUserOption(userId, userOptions){
    if(!userId) return { value: -1, label: 'Please select a user...' };
    return userOptions.find(s => s.value === userId) || { value: -1, label: `Unknown user (UID ${userId})`};
}

export function getInitCenterOption(center, centerOptions){
    if(!center) return { value: -1, label: 'Please select...' };
    return centerOptions.find(c => parseInt(c.value) === parseInt(center)) || { value: -1, label: `Unknown center (ID ${center})` };
}

export const statusOptions = ['Pending', 'Completed'].map(i => ({ value: i, label: i }));
export function getInitStatusOption(status){
    if(!status) return statusOptions[0];
    return statusOptions.find(s => s.value === status) || { value: -1, label: `Invalid status (${status})` };
}

export const priorityOptions = ['Urgent', 'Important', 'Low'].map(i => ({ value: i, label: i }));
export function getInitPriorityOption(priority){
    if(!priority) return priorityOptions[1];
    return priorityOptions.find(s => s.value === priority) || { value: -1, label: `Invalid priority (${priority})` };
}

export function renderMeta(flag){
    let created = flag.date_created ? (
        <div>Created on {formatDate(flag.date_created)} by {flag.createdByName || `Unknown user (${flag.created_by})`}</div>
    ) : null;
    let updated = flag.date_updated ? (
        <div>Updated on {formatDate(flag.date_updated)} by {flag.updatedByName || `Unknown user (${flag.updated_by})`}</div>
    ) : null;

    if(created || updated){
        return(
            <div className="grid grid-cols-1 gap-y-2">
                <br/>
                {created}
                {updated}
            </div>
        )
    } else return null;
}