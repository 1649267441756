import * as Yup from 'yup';

import { formatDate } from '../../functions';

export const validationSchema = (() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return (
        Yup.object().shape({
            teacher: Yup.string()
                .max(50, 'Maximum 50 characters'),
            school: Yup.string()
                .max(100, 'Maximum 100 characters'),
            address: Yup.string()
                .max(200, 'Maximum 200 characters'),
            parentNotes: Yup.string()
                .max(500, 'Maximum 500 characters'),
            firstGuardianNotes: Yup.string()
                .max(500, 'Maximum 500 characters'),
            secondGuardianNotes: Yup.string()
                .max(500, 'Maximum 500 characters'),
            specialNotes: Yup.string()
                .max(300, 'Maximum 300 characters'),
            specialNotes2: Yup.string()
                .max(300, 'Maximum 300 characters'),
            driveFolderLink1Name: Yup.string()
                .max(30, 'Maximum 30 characters'),
            driveFolderLink1: Yup.string()
                .max(300, 'Maximum 300 characters'),
            driveFolderLink2Name: Yup.string()
                .max(30, 'Maximum 30 characters'),
            driveFolderLink2: Yup.string()
                .max(300, 'Maximum 300 characters'),
            driveFolderLink3Name: Yup.string()
                .max(30, 'Maximum 30 characters'),
            driveFolderLink3: Yup.string()
                .max(300, 'Maximum 300 characters'),
            driveFolderLink4Name: Yup.string()
                .max(30, 'Maximum 30 characters'),
            driveFolderLink4: Yup.string()
                .max(300, 'Maximum 300 characters'),
        })
    );
})();
export function getHoldLength(startDate, endDate){
    return Math.floor((new Date(endDate) - new Date(startDate)) / (24 * 60 * 60 * 1000)) + 1;
}

export const subjectOptions = [{ value: '', label: 'None' }, { value: 'Geometry', label: 'Geometry' },
{ value: 'Calculus', label: 'Calculus' }];
export function getInitSubjectOption(subject){
    const option = subjectOptions.find(s => s.value === subject);
    return option || subjectOptions[0];
}

export function getContractOption(contract){
    return {
        value: parseInt(contract.id),
        label: `${contract.type}: ${formatDate(contract.start_date)} to ${formatDate(contract.end_date)}`
    };
}

const defaultOption = { value: -1, label: 'None selected' };
export function getInitParent(parentId, parentOptions){
    const parent = parentOptions.find(p => p.value === parentId);

    return parent || defaultOption;
}
export function getInitContract(contractId, contractOptions){
    const contract = contractOptions.find(c => parseInt(c.value) === parseInt(contractId));

    return contract || defaultOption;
}


export const gradeOptions = [
    { value: -1, label: 'None'},
    { value: 0, label: 'Pre-K/Kindergarten'},
    { value: 1, label: '1'},
    { value: 2, label: '2'},
    { value: 3, label: '3'},
    { value: 4, label: '4'},
    { value: 5, label: '5'},
    { value: 6, label: '6'},
    { value: 7, label: '7'},
    { value: 8, label: '8'},
    { value: 9, label: '9'},
    { value: 10, label: '10'},
    { value: 11, label: '11'},
    { value: 12, label: '12'},
    { value: 13, label: 'HS Grad' }
];
export function getInitGradeOption(grade){
    const gradeOption = gradeOptions.find(g => parseInt(g.value) === parseInt(grade));

    return gradeOption || { value: -1, label: 'Invalid grade...' };
}

export function getSchoolYearOptions(){
    const now = new Date();
    const currentYear = (now.getFullYear()) % 100;

    const yesterYesterYear = (currentYear - 2) % 100;
    const yesterYear = (currentYear - 1) % 100;
    const tomorrowYear = (currentYear + 1) % 100;

    const twoSchoolYearsAgo = `${yesterYesterYear}-${yesterYear}`;
    const lastSchoolYear = `${yesterYear}-${currentYear}`;
    const thisSchoolYear = `${currentYear}-${tomorrowYear}`;

    return [
        { value: '', label: 'None' },
        { value: twoSchoolYearsAgo, label: twoSchoolYearsAgo },
        { value: lastSchoolYear, label: lastSchoolYear },
        { value: thisSchoolYear, label: thisSchoolYear }
    ]
}
export function getInitSchoolYearOption(year){
    const syOptions = getSchoolYearOptions();
    const syOption = syOptions.find(sy => parseInt(sy.value) === parseInt(year));

    return syOption || { value: '', label: 'None' };
}