import { rgb } from 'pdf-lib';
import { generateStringBlocks } from './parseData';

function removeIllegalChars(text, fontObj){
    const allowedCharsMap = {};
    fontObj.getCharacterSet().forEach(c => allowedCharsMap[c] = true);
    if(!allowedCharsMap[9]) text = text.replace(/\t/g, ' ');
    if(!allowedCharsMap[10]) text = text.replace(/\n/g, ' ');
    return text;
}

export function drawText(currentPage, item, defaultFontObj, appendHeight = false){
    let { xMin, xMax, yPos } = item.coords;
    const {
        text = '',
        xOffset = 0,
        size = 0,
        font = defaultFontObj,
        color = rgb(0, 0, 0),
        afterMargin = 0,
        center = false,
        underline = {},
        // shiftYPos = true, // Handled in drawItems
    } = item;
    const fontToUse = item.font || defaultFontObj;
    const filteredText = removeIllegalChars(text, fontToUse);

    let textWidth = fontToUse.widthOfTextAtSize(filteredText, size);
    let textHeight = fontToUse.heightAtSize(size);

    let totalHeight = textHeight + afterMargin;

    let xPosToDraw = xMin + xOffset;
    if(center) xPosToDraw += ((xMax - xMin - textWidth) / 2);

    if(!appendHeight){
        currentPage.drawText(filteredText, {
            x: xPosToDraw,
            y: yPos,
            size: size,
            font: font,
            color
        });
    }

    if(underline.active){
        const { px = 2, py = 3, thickness = 1, color = rgb(0, 0 ,0), opacity = 1 } = underline;
        const lineStartX = xPosToDraw - px;
        const lineEndX = xPosToDraw + textWidth + px;
        if(!appendHeight){
            currentPage.drawLine({
                start: { x: lineStartX, y: yPos - py },
                end: { x: lineEndX, y: yPos - py },
                thickness,
                color,
                opacity,
            });
        }

        yPos -= py;
        totalHeight += py;
    }

    if(appendHeight){
        item.height = totalHeight;
        return;
    }

    yPos -= (textHeight + afterMargin);
    return yPos;
}

// Automatically splits text into lines based on the page width
// Has options to add text before and after the main blocks of text
// Before/After text are expected to be short (less than half the page width)
export function drawTextMulti(currentPage, item, defaultFontObj, appendHeight = false){
    let { xMin, xMax, yPos } = item.coords;
    const {
        text = '',
        addTextBefore = {},
        addTextAfter = {},
        xOffset = 0,
        size = 0,
        font = defaultFontObj,
        color = rgb(0, 0, 0),
        spaceBetween = 0,
        afterMargin = 0,
        // shiftYPos = true, // Handled in drawItems
    } = item;
    const fontToUse = item.font || defaultFontObj;
    const filteredText = removeIllegalChars(text, fontToUse);
    const textMaxWidth = xMax - xMin;

    const beforeText = addTextBefore.text || '';
    const afterText = addTextAfter.text || '';
    const filteredBeforeText = removeIllegalChars(beforeText, fontToUse);
    const filteredAfterText = removeIllegalChars(afterText, fontToUse);

    const stringBlocks = generateStringBlocks(
        fontToUse,
        size,
        filteredText,
        textMaxWidth,
        { beforeText: filteredBeforeText, afterText: filteredAfterText }
    );

    const nBlocks = stringBlocks.length;
    const blockHeight = fontToUse.heightAtSize(size);
    let totalHeight = nBlocks * blockHeight + (nBlocks - 1) * spaceBetween + afterMargin;

    if(appendHeight){
        item.height = totalHeight;
        return;
    }
    
    let index = 0;
    for(let block of stringBlocks){
        let additionalXOffset = 0;
        let xPosToDraw = xMin + xOffset;

        // Draw beforeText if included
        if(index === 0 && filteredBeforeText){
            additionalXOffset = fontToUse.widthOfTextAtSize(filteredBeforeText, size);
            if(!appendHeight){
                currentPage.drawText(filteredBeforeText, {
                    x: xPosToDraw,
                    y: yPos,
                    size,
                    font,
                    color: addTextBefore.color || color
                });
            }
        }

        xPosToDraw += additionalXOffset;
    
        if(!appendHeight){
            currentPage.drawText(block, {
                x: xPosToDraw,
                y: yPos,
                size,
                font,
                color
            });
        }

        // Draw afterText if included
        if(index === stringBlocks.length - 1 && filteredAfterText && !appendHeight){
            const blockExistingWidth = fontToUse.widthOfTextAtSize(block, size);
            currentPage.drawText(filteredAfterText, {
                x: xPosToDraw + blockExistingWidth,
                y: yPos,
                size,
                font,
                color: addTextAfter.color || color
            });
        }

        yPos -= (blockHeight + spaceBetween);
        index++;
    }

    yPos -= afterMargin;
    return yPos;
}