import { basicPath } from '../basicPath';

export const fetchCycleGroupsAll = (params) => async (dispatch) => {
    const path = '/rp/cycle-groups/all';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchCycleGroupsActive = (params) => async (dispatch) => {
    const path = '/rp/cycle-groups/active';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchCycleGroupsIds = (params) => async (dispatch) => {
    const path = '/rp/cycle-groups/ids';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchCycleGroupsStudents = (params) => async (dispatch) => {
    const path = '/rp/cycle-groups/students';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createCycleGroup = (params) => async (dispatch) => {
    const path = '/rp/cycle-groups';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateCycleGroup = (params) => async (dispatch) => {
    const path = '/rp/cycle-groups';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteCycleGroup = (params) => async (dispatch) => {
    const path = '/rp/cycle-groups';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}