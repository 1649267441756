import React from 'react';
import { toAbsoluteUrl } from '../functions';

export function Watermark(props){
    const { database } = props;
    let message = '';
    switch(database){
        case 'MathPlex':
            message = '';
            break;
        case 'MathPlexTest':
            message = 'Heads up! You are working on the test database.';
            break;
        case undefined:
        case null:
            break;
        default:
            message = `Unknown database "${database}"!`;
            break;
    }

    const logo = (
        <img
            src={toAbsoluteUrl("/media/logos/lightshort.png")}
            className="h-12 opacity-70"
            alt="ReadingPlex logo"
        />
    );

    return (
        <div className="fixed text-md text-mpLRed z-[100000] bottom-8 left-8 pointer-events-none">
            <div className="flex flex-row items-center">
                {logo} &nbsp; {message}
            </div>
        </div>
    );
}
  