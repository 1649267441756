import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import ContractModalBodyFooter from './ContractsBF';
import { checkResponse } from '../../form';

import {
    fetchStudentsAll,
    fetchContractsIds,
    createContract,
    updateContract,
    deleteContract
} from '../../../actions';

function ContractsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);
    
    const [contract, setContract] = useState({});
    const [studentOptions, setStudentOptions] = useState([]);
    const [student, setStudent] = useState({});

    // selectedStudent is passed from the 'create-special' mode, activated on the warnings tab of the Contracts page
    // The only difference from 'create' is that the student should be pre-initialized
    const { mode, selectedContract, selectedStudent, onSubmitCallback, fetchContractsIds,
        fetchStudentsAll, createContract, updateContract, deleteContract } = props;

    useEffect(() => {
        async function init(){
            const studentsRes = await fetchStudentsAll();
            // const cycleGroupsRes = await fetchCycleGroupsActive();
            const newStudents = studentsRes.data || [];
            // const newGroups = cycleGroupsRes.data?.groups || [];
            // const newGroupMembers = cycleGroupsRes.data?.members || [];

            // const groupNameMap = {};
            // newGroups.forEach(g => {
            //     groupNameMap[parseInt(g.id)] = g.name;
            // });

            // const studentGroupMap = {};
            // newGroupMembers.forEach(gm => {
            //     const studentId = gm.student;
            //     const groupId = parseInt(gm.group_id);

            //     const groupName = groupNameMap[groupId] || `Unknown group (ID ${groupId})`;
            //     const groupOption = { value: groupId, label: groupName };

            //     if(!studentGroupMap[studentId]) studentGroupMap[studentId] = [];
            //     studentGroupMap[studentId].push(groupOption);
            // });

            const exceptedStudents = [];

            if(!['create', 'create-special'].includes(mode)){
                const contractRes = await fetchContractsIds({ ids: [selectedContract.id] });
                const newContract = contractRes.data?.[0] || {};
                const relStudent = newStudents.find(s => s.user_id === newContract.student);
                newContract.studentName = relStudent ? `${relStudent.first_name} ${relStudent.last_name}` :
                    `Unknown student (UID: ${newContract.student})`;

                exceptedStudents.push(newContract.student);

                if(mounted.current){
                    setContract(newContract);
                }
            }

            if(mode === 'create-special'){
                const newStudent = newStudents.find(s => s.user_id === selectedStudent.user_id) || {};

                exceptedStudents.push(selectedStudent.user_id);

                if(mounted.current) setStudent(newStudent);
            }

            const newStudentOptions = newStudents.filter(s => {
                return parseInt(s.is_rp_student) === 1 || exceptedStudents.includes(s.user_id);
            }).map(s => {
                // const groupOptions = studentGroupMap[s.user_id] ? 
                //     // Options exist
                //     [{ value: -1, label: 'Please select a group...' }, ...studentGroupMap[s.user_id]] :
                //     // No options exist
                //     [{ value: -1, label: 'No groups were found...' }];
                return { value: s.user_id, label: `${s.first_name} ${s.last_name}` }
            });

            if(mounted.current){
                setLoaded(true);
                setStudentOptions(newStudentOptions);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, oneSuccess]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current) setSubmitting(true);
            
            const nss = { errored: false, completed: false };
            if(['create', 'create-special', 'edit'].includes(mode)){
                const contractParams = {
                    student: values.student.value,
                    type: values.type.value,
                    minutesPerCycle: Math.floor(values.hoursPerCycle * 60),
                    currentCycle: values.currentCycle,
                    minutesRemaining: Math.floor(values.hoursRemaining * 60),
                    startDate: values.startDate,
                    endDate: values.endDate,
                    initialPayment: values.initialPayment,
                    paymentPerCycle: values.paymentPerCycle,
                    notes: values.notes,
                    showWarnings: values.showWarnings ? 1 : 0,
                    active: values.active ? 1 : 0
                };
                if(['create', 'create-special'].includes(mode)){
                    nss.createContract = { name: 'Create contract', completed: false, message: '' };
                    setSubmissionStatus({...nss});

                    const ccResponse = await createContract(contractParams);
                    nss.createContract.completed = true;
                    nss.errored = !checkResponse(ccResponse, mounted, (resp) => nss.createContract.message = resp) || nss.errored;
                    setSubmissionStatus({...nss});
                } else if(mode === 'edit') {
                    nss.updateContract = { name: 'Update contract', completed: false, message: '' };
                    setSubmissionStatus({...nss});

                    contractParams.id = contract.id;
                    const ucResponse = await updateContract(contractParams);
                    nss.updateContract.completed = true;
                    nss.errored = !checkResponse(ucResponse, mounted, (resp) => nss.updateContract.message = resp) || nss.errored;
                    setSubmissionStatus({...nss});
                }
            } else if(mode === 'delete') {
                nss.deleteContract = { name: 'Delete contract', completed: false, message: '' };
                setSubmissionStatus({...nss});

                const ucResponse = await deleteContract({ id: contract.id });
                nss.deleteContract.completed = true;
                nss.errored = !checkResponse(ucResponse, mounted, (resp) => nss.deleteContract.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setStatus(`One or more errors occurred during submission.`);
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [handleClose, contract, mode, createContract, updateContract, deleteContract, oneSuccess]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Contract Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <ContractModalBodyFooter
                    mode={mode}
                    selectedStudent={student}
                    selectedContract={contract}
                    studentOptions={studentOptions}
                    loaded={loaded}
                    submitted={submitted}
                    submissionStatus={submissionStatus}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsAll,
    fetchContractsIds,
    createContract,
    updateContract,
    deleteContract
})(ContractsModal);