import React from 'react';

import { Button, SVGIcon, Label } from '../../../../../components/custom-essentials';
import { formatTime } from '../../../../../components/functions';
import { tableColors, TooltipWrapper } from '../../../../../components/display';

export function getColumns(handleShowAutoScheduleItemsModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    return [
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                if(a < b) return -1 * swap;
                else if(a > b) return 1 * swap;
                else return 0;
            }
        },
        {
            dataField: 'dayOfWeek',
            text: 'Day',
            sort: true,
            sortFunc:  (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(rowA.dow) - parseInt(rowB.dow)) * swap
            }
        },
        {
            dataField: 'time',
            text: 'Time',
            sort: true,
            sortFunc:  (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(a) - parseInt(b)) * swap
            },
            formatter: (row) => {
                return formatTime(row.time);
            }
        },
        {
            dataField: 'duration',
            text: 'Duration',
            sort: true,
            sortFunc:  (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(a) - parseInt(b)) * swap
            },
            formatter: (row) => `${row.duration} minutes`
        },
        {
            dataField: 'groupName',
            text: 'Group',
        },
        {
            dataField: 'contractName',
            text: 'Contract',
        },
        {
            dataField: 'centerName',
            text: 'Center',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? -1 : 1;
                return (parseInt(rowA.center) - parseInt(rowB.center)) * swap;
            },
            formatter: (row) => {
                const center = parseInt(row.center);
                let centerColor = '';
                if(colorMap[center]) centerColor = colorMap[center];
                else {
                    colorMap[center] = colors[(colorIndex++) % 5];
                    centerColor = colorMap[center];
                }
                return (
                    <div style={{ marginRight: "0.5rem" }}>
                        <Label color={centerColor}>
                            {row.centerName}
                        </Label>
                    </div>
                );
            }
        },
        {
            dataField: 'active',
            text: 'Active',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? -1 : 1;
                return (b - a) * swap;
            },
            formatter: (row) => {
                if(parseInt(row.active) === 1) return <Label color="mpGreen">Active</Label>;
                else return <Label color="mpLRed">Inactive</Label>;
            }
        }, 
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit auto schedule item`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowAutoScheduleItemsModal('edit', row)}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete auto schedule item`}
                        >
                            <Button
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowAutoScheduleItemsModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}