import React, { useState } from "react";

import { Table } from '../../../../../components/table';
import { getUserColumns } from '../table/getColumns';
import { MemberModal } from '../../../../../components/modal';

const usersDefaultSorted = { dataField: 'name', order: 'asc' };

export default function MemberTable(props){
    const { memberData, refreshData } = props;

    const [memberModalMode, setMemberModalMode] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    function handleShowUserModal(mode, user){
        setMemberModalMode(mode);
        setSelectedMember(user);
    }
    const userColumns = getUserColumns(handleShowUserModal);
    function userOnSubmitCallback(changes = true){
        if(changes) refreshData();
        setMemberModalMode(null);
        setSelectedMember(null);
    }

    return (
        <>
            {memberModalMode && <MemberModal
                mode={memberModalMode}
                selectedMember={selectedMember}
                onSubmitCallback={userOnSubmitCallback}
            />}
            <Table
                tableName="members-users"
                data={memberData}
                columns={userColumns}
                defaultSorted={usersDefaultSorted}
                rowsPerPageInitial={20}
                rowsPerPageList={[10, 20, 40]}
                emptyMessage="No members were found"
            />
        </>
    );
}