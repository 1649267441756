import { formatDateTimeFull } from '../../../../../components/functions';
import { renderPhone } from '../../../../../components/display';

export function getColumns(){
    return [
        {
            dataField: 'timestamp',
            text: 'Timestamp',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;
            },
            formatter: (row) => formatDateTimeFull(row.timestamp),
            headerStyle: () => ({ width: "20%" })
        }, {
            dataField: 'number',
            text: 'Sent To',
            sort: true,
            formatter: (row) => <div className="whitespace-nowrap">{renderPhone(row.number)}</div>,
            headerStyle: () => ({ width: "15%" })
        }, {
            dataField: 'body',
            text: 'Body',
            headerStyle: () => ({ width: "75%" })
        }
    ];
}