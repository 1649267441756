import app from '../../api/app';
import { attemptAction } from '../index';
import DOMPurify from 'dompurify';

import { clearUserInfo, setUserInfo } from '../../app/store';

export const login = (email, password) => async (dispatch) => {
    // Clear in case any stupid old cookies came back to life
    await dispatch ({ type: 'auth/clearUserInfo' });

    let response = null;
    try {
        response = await app.post(`/adminAuth/login`, {
            email,
            password,
            portal: 'rp'
        });
    } catch (e){
        response = e.response;
        console.log(e);
    } finally {
        if(response){
            if(response.status === 201){                
                dispatch(setUserInfo(
                    {
                        refreshToken: response.data.rToken,
                        accessToken: response.data.aToken,
                    }
                ));
    
                return response;
            } else {
                // 401, 404, 500
                return response;
            }
            
        } else {
            return {};
        }
    }
}

export const signout = () => async (dispatch) => {
    async function action(params){
        let response = null;
        const aToken = params.aToken;
        try {
            response = await app.post(`/adminAuth/signout`, {
                aToken,
                portal: 'rp'
            });
        } catch (e) {
            response = e.response;
            console.log(e);
        } finally {
            if(response){
                if(response.status === 201){
                    dispatch (clearUserInfo());
                    dispatch ({ type: 'socket/clearConnections' });
                    return response;
                } else {
                    return response;
                }
            } else {
                return {};
            }
        }
    }
    return await attemptAction(action, dispatch);
}

export const forceSignout = () => async (dispatch) => {
    async function action(params){
        const aToken = params.aToken;
        let response = null;
        try {
            app.post(`/adminAuth/force-signout`, {
                aToken,
                portal: 'rp'
            });
        } catch (e) {
            response = e.response;
            console.log(e);
        } finally {
            dispatch (clearUserInfo());
            dispatch ({ type: 'socket/clearConnections' });
            return response;
        }
    }
    return await attemptAction(action, dispatch);
}

export const requestPasswordReset = (email) => async () => {
    email = DOMPurify.sanitize(email);
    let response = null;
    try {
        response = await app.post(`/adminAuth/request-password-reset`, {
            email,
            portal: 'rp'
        });
    } catch (e) {
        response = e.response;
        console.log(e);
    } finally {
        return response;
    }
}

export const resetPassword = (email, newPass, hash) => async () => {
    newPass = DOMPurify.sanitize(newPass);
    let response = null;
    try {
        response = await app.post(`/adminAuth/reset-password`, {
            email,
            newPass,
            hash,
            portal: 'rp'
        });
    } catch (e) {
        response = e.response;
        console.log(e);
    } finally {
        return response;
    }
}