import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import BooksBodyFooter from './BooksBF';
import { checkResponse } from '../../form';

import {
    fetchBooksId,
    createBook,
    updateBook,
    deleteBook
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function BooksModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [book, setBook] = useState({});

    const { mode, onSubmitCallback, selectedBook, fetchBooksId, createBook, updateBook, deleteBook } = props;

    useEffect(() => {
        async function init(){
            if(mode !== 'create'){
                const booksRes = await fetchBooksId({ id: selectedBook.id });
                const newBook = booksRes.data?.[0] || {};
    
                const bookMeta = [];
                if(newBook.created_by) bookMeta.push(newBook.created_by);
                if(newBook.updated_by) bookMeta.push(newBook.updated_by);

                if(mounted.current) setBook(newBook);
            }

            if(mounted.current) setLoaded(true);
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const bookParams = {
                    title: values.title,
                    author: values.author,
                    difficulty: values.difficulty.value,
                    theme: values.theme.value,
                    type: values.type.value,
                    notes: values.notes,
                    contentWarning: values.contentWarning,
                    active: values.active ? 1 : 0
                };
                
                if(mode === 'create'){
                    response = await createBook(bookParams);
                } else if(mode === 'edit') {
                    bookParams.id = book.id;
                    response = await updateBook(bookParams);
                }
            } else if(mode === 'delete') {
                response = await deleteBook({ id: book.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, book, createBook, updateBook, deleteBook]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Book Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <BooksBodyFooter
                    mode={mode}
                    selectedBook={book}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchBooksId,
    createBook,
    updateBook,
    deleteBook
})(BooksModal);