import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import AutoScheduleItemsModalBodyFooter from './AutoScheduleItemsModalBF';
import { checkResponse } from '../../form';
import { formatDate } from '../../functions';

import {
    fetchStudentsActive,
    fetchContractsActiveStudents,
    fetchRpCentersAll,
    fetchAutoScheduleItemsIds,
    fetchCycleGroupsActive,
    createAutoScheduleItem,
    updateAutoScheduleItem,
    deleteAutoScheduleItem
} from '../../../actions';

function AutoScheduleItemsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [autoScheduleItem, setAutoScheduleItem] = useState({});
    const [groupOptions, setGroupOptions] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);

    const { mode, selectedAutoScheduleItem, onSubmitCallback, fetchRpCentersAll, fetchStudentsActive,
        fetchContractsActiveStudents, fetchCycleGroupsActive, fetchAutoScheduleItemsIds,
        createAutoScheduleItem, updateAutoScheduleItem, deleteAutoScheduleItem } = props;

    useEffect(() => {
        async function init(){           
            const studentsRes = await fetchStudentsActive();
            const centersRes = await fetchRpCentersAll();
            const newStudents = studentsRes.data || [];
            const newCenters = centersRes.data || [];
            const newCenterOptions = newCenters.map(c => ({ value: c.id, label: c.name }));

            // Set up group map for easy lookup
            const groupsRes = await fetchCycleGroupsActive();
            const newGroups = groupsRes.data?.groups || [];
            const newGroupMembers = groupsRes.data?.groupMembers || [];
            const groupMap = {};
            newGroups.forEach(g => {
                g.studentOptions = [];
                groupMap[parseInt(g.id)] = g;
            });
            
            // Append contracts to student option.obj
            const contractsRes = await fetchContractsActiveStudents();
            const newContracts = contractsRes.data || [];
            const contractMap = {};
            newContracts.forEach(c => {
                const studentId = c.student;
                if(!contractMap[studentId]) contractMap[studentId] = [];

                const hoursRemaining = (c.minutes_remaining / 60).toLocaleString(undefined, { maximumFractionDigits: 1 });
                contractMap[studentId].push({
                    value: c.id,
                    label: `${formatDate(c.start_date)} to ${formatDate(c.end_date)}: ${c.type}, ${hoursRemaining} hours left`
                });
            });

            // Set up student option map for quick lookup. Attach contract option list
            const studentOptionMap = {};
            newStudents.forEach(s => {
                const userId = s.user_id;
                s.contractOptions = contractMap[userId] || [];
                studentOptionMap[userId] = ({ value: s.user_id, label: `${s.first_name} ${s.last_name}`, obj: s })
            });
            // Attach list of student options to group option.obj
            newGroupMembers.forEach(gm => {
                const groupId = parseInt(gm.group_id);
                const group = groupMap[groupId];
                const studentOption = studentOptionMap[gm.student] || { value: -1, label: `Unknown student: (UID: ${gm.student})` };
                group.studentOptions.push(studentOption);
            });
            const newGroupOptions = Object.values(groupMap).map(g => ({ value: g.id, label: g.name, obj: g }));

            if(mode !== 'create'){
                const autoScheduleItemRes = await fetchAutoScheduleItemsIds({ ids: [selectedAutoScheduleItem.id] });
                
                const newAutoScheduleItem = autoScheduleItemRes.data?.[0] || {};
                
                if(mounted.current) setAutoScheduleItem(newAutoScheduleItem);
            }
            
            if(mounted.current){
                setLoaded(true);
                setCenterOptions(newCenterOptions);
                setGroupOptions(newGroupOptions);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const autoScheduleItemParams = {
                    groupId: values.group.value,
                    student: values.student.value,
                    contract: values.contract.value,
                    center: values.center.value,
                    dow: values.dow.value,
                    time: values.time.raw,
                    duration: values.duration.value,
                    active: values.active ? 1 : 0
                };
                
                if(mode === 'create'){
                    response = await createAutoScheduleItem(autoScheduleItemParams);
                } else if(mode === 'edit') {
                    autoScheduleItemParams.id = autoScheduleItem.id;
                    response = await updateAutoScheduleItem(autoScheduleItemParams);
                }
            } else if(mode === 'delete') {
                response = await deleteAutoScheduleItem({ id: autoScheduleItem.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, autoScheduleItem, createAutoScheduleItem, updateAutoScheduleItem, deleteAutoScheduleItem]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Auto Schedule Item Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <AutoScheduleItemsModalBodyFooter
                    mode={mode}
                    autoScheduleItem={autoScheduleItem}
                    groupOptions={groupOptions}
                    centerOptions={centerOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchStudentsActive,
    fetchContractsActiveStudents,
    fetchRpCentersAll,
    fetchAutoScheduleItemsIds,
    fetchCycleGroupsActive,
    createAutoScheduleItem,
    updateAutoScheduleItem,
    deleteAutoScheduleItem
})(AutoScheduleItemsModal);