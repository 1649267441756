import { basicPath } from '../basicPath';

export const fetchBookListsId = (params) => async (dispatch) => {
    const path = '/rp/book-lists/id';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const fetchBookListsStudents = (params) => async (dispatch) => {
    const path = '/rp/book-lists/students';
    const type = 'get';
    return await basicPath(dispatch, params, path, type);
}

export const createBookList = (params) => async (dispatch) => {
    const path = '/rp/book-lists';
    const type = 'post';
    return await basicPath(dispatch, params, path, type);
}

export const updateBookList = (params) => async (dispatch) => {
    const path = '/rp/book-lists';
    const type = 'put';
    return await basicPath(dispatch, params, path, type);
}

export const deleteBookList = (params) => async (dispatch) => {
    const path = '/rp/book-lists';
    const type = 'delete';
    return await basicPath(dispatch, params, path, type);
}