import React, { useState, useCallback, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from "formik";

import { SelectSingle, FormikControl, Switch, checkResponses } from '../../../../components/form';
import { filterFormatterLtr } from '../../../../components/functions';
import { Button } from '../../../../components/custom-essentials';
import { Table } from '../../../../components/table';
import { EmployeeModal } from "../../../../components/modal";
import { LoadingOverlay, TooltipWrapper, BrowserTabTitle } from "../../../../components/display";
import { CSVExport } from '../../../../components/export';
import { getColumns } from './table/getColumns';
import { Socket } from '../../../../components/ws';

import {
    fetchAdminUsersAll,
    fetchMpCentersAll,
    fetchRpCentersAll,
} from '../../../../actions';

const filterTypeOptions = [
    { value: 'all', label: 'All' },
    { value: 'id', label: 'UUID' },
    { value: 'name', label: 'Name' },
    { value: 'mpCenter', label: 'MP Center' },
    { value: 'rpCenter', label: 'RP Center' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
    { value: 'mpPermissions', label: 'MP Permissions' },
    { value: 'rpPermissions', label: 'RP Permissions' },
];

const defaultSorted = { dataField: 'name', order: 'asc' };

const initialValues = {
    filterType: filterTypeOptions[0],
    filterQuery: '',
    showInactive: false,
};

function Employees(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    });
    
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    // Modal
    const [modalMode, setModalMode] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    
    // const urlParams = new URLSearchParams(window.location.search);

    const { fetchAdminUsersAll, fetchMpCentersAll, fetchRpCentersAll } = props;

    const filterData = useCallback((users, filterType, filterQuery, showInactive) => {
        const formattedFQ = filterFormatterLtr(filterQuery);
        const checkId = (u) => filterFormatterLtr(u.id).includes(formattedFQ);
        const checkName = (u) => filterFormatterLtr((`${u.first_name}${u.last_name}`)).includes(formattedFQ);
        const checkMPCenter = (u) => filterFormatterLtr(u.mpCenterName).includes(formattedFQ);
        const checkRPCenter = (u) => filterFormatterLtr(u.rpCenterName).includes(formattedFQ);
        const checkEmail = (u) => filterFormatterLtr(u.email).includes(formattedFQ);
        const checkPhone = (u) => filterFormatterLtr(u.phone).includes(formattedFQ);
        const checkMPPermissions = (u) => filterFormatterLtr(u.mp_permissions).includes(formattedFQ);
        const checkRPPermissions = (u) => filterFormatterLtr(u.rp_permissions).includes(formattedFQ);

        const showInactiveTrue = showInactive === 'true' || showInactive === true;

        const newFilteredUsers = users.filter(u => {
            switch(filterType.value){
                case 'all':
                    return checkId(u) || checkName(u) || checkMPCenter(u) || checkRPCenter(u) ||
                    checkEmail(u) || checkPhone(u) || checkMPPermissions(u) || checkRPPermissions(u);
                case 'id':
                    return checkId(u);
                case 'name':
                    return checkName(u);
                case 'mpCenter':
                    return checkMPCenter(u);
                case 'rpCenter':
                    return checkRPCenter(u);
                case 'email':
                    return checkEmail(u);
                case 'phone':
                    return checkPhone(u);
                case 'mpPermissions':
                    return checkMPPermissions(u);
                case 'rpPermissions':
                    return checkRPPermissions(u);
                default:
                    return false;
            }
        })
        .filter(u => showInactiveTrue || parseInt(u.account_active) === 1);

        if(mounted.current) setFilteredUsers(newFilteredUsers);
    }, [setFilteredUsers, mounted]);
    const refreshData = useCallback((formik) => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current) setLoading(true);
            const adminUsersRes = await fetchAdminUsersAll();
            const mpCentersRes = await fetchMpCentersAll();
            const rpCentersRes = await fetchRpCentersAll();
            const isApiError = checkResponses(adminUsersRes, mpCentersRes, rpCentersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newUsers = adminUsersRes.data || [];
            const newMpCenters = mpCentersRes.data || [];
            const newRpCenters = rpCentersRes.data || [];
            
            const mpCenterMap = {};
            mpCenterMap[-1] = 'None';
            const rpCenterMap = {};
            rpCenterMap[-1] = 'None';
            newMpCenters.forEach(c => mpCenterMap[parseInt(c.id)] = c.name);
            newRpCenters.forEach(c => rpCenterMap[parseInt(c.id)] = c.name);
            
            newUsers.forEach(u => {
                u.mpCenterName = mpCenterMap[u.mp_primary_center] || `Unable to find center (ID :${u.mp_primary_center})`;
                u.rpCenterName = rpCenterMap[u.rp_primary_center] || `Unable to find center (ID :${u.rp_primary_center})`;
            });
    
            const { filterType, filterQuery, showInactive } = formik.values;
            if(mounted.current){
                setUsers(newUsers);
                filterData(newUsers, filterType, filterQuery, showInactive);
                setLoading(false);
            }
        })();
    }, [fetchAdminUsersAll, fetchMpCentersAll, fetchRpCentersAll, loading, mounted, setUsers, setLoading, filterData]);
    useEffect(() => {
        refreshData({ values: initialValues });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = getColumns(handleShowModal);
    function handleShowModal(mode, user){
        setModalMode(mode);
        setSelectedEmployee(user);
    }
    function onSubmitCallback(formik, changes = true){
        setModalMode(null);
        setSelectedEmployee(null);
        if(changes) refreshData(formik);
    }

    return (
        <div className="page-box">
            <BrowserTabTitle content="Employees"/>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <h3>Employee Accounts ({filteredUsers.length})</h3>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    handleSubmit={() => null}
                >
                    {formik => (
                        <>
                            <div className="flex flex-row gap-x-4">
                                <div className="w-2/12">
                                    <SelectSingle
                                        id="employees-type-1"
                                        value={formik.values.filterType}
                                        name="filterType"
                                        label="Filter Type"
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(users, e.target.value,
                                                formik.values.filterQuery, formik.values.showInactive);
                                        }}
                                        options={filterTypeOptions}
                                    />
                                </div>
                                <div className="w-3/12 self-end">
                                    <FormikControl
                                        id="employees-query-1"
                                        name="filterQuery"
                                        placeholder="Enter a filter query..."
                                        value={formik.values.filterQuery}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(users, formik.values.filterType,
                                                e.target.value, formik.values.showInactive);
                                        }}
                                    />
                                </div>
                                <div className="self-end">
                                    <Switch
                                        id="employees-show-inactive"
                                        name="showInactive"
                                        label="Show Inactive"
                                        color="mpDBlue"
                                        checked={formik.values.showInactive}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterData(users, formik.values.filterType,
                                                formik.values.filterQuery, e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setModalMode('create')}
                                    >
                                        + Add User
                                    </Button>
                                </div>
                                <div className="align-items-end" style={{ marginLeft: "auto" }}>
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                <div>
                                                    What gets exported?
                                                </div>
                                                <br/>
                                                <div>
                                                    All users that are currently filtered ({filteredUsers.length} items).
                                                </div>
                                            </div>
                                        }
                                    >
                                        <CSVExport
                                            title="Employees"
                                            label="Export users to CSV"
                                            data={filteredUsers}
                                        />
                                    </TooltipWrapper>

                                </div>
                            </div>
                            {modalMode && <EmployeeModal
                                mode={modalMode}
                                selectedEmployee={selectedEmployee}
                                onSubmitCallback={(changes) => onSubmitCallback(formik, changes)}
                            />}
                            <Socket
                                refreshData={() => refreshData(formik)}
                                page="Employees"
                                setVersion={props.setVersion}
                            />
                        </>
                    )}
                </Formik>

                <br/>

                {apiError ? <div className="text-mpLRed">{apiError}</div> :
                    <Table
                        tableName="employees"
                        data={filteredUsers}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        rowsPerPageInitial={50}
                        emptyMessage="No employees were found"
                    />
                }
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchAdminUsersAll,
    fetchMpCentersAll,
    fetchRpCentersAll,
})(Employees);