import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { openPopup, downloadFile, convertApiToDate, formatDate,
    formatDateFull, formatTime, getymd, getymdhms, appendLogo } from '../../../../../components/functions';
// import { overlayCoordinates } from '../../../../../components/functions';
import { drawItems } from '../../../../../components/pdf';

export async function generateStandard(values, newAppointments, logoData, quickDownload, quickDownloadDate){
    const { selectedStudent, startDate, endDate } = values;
    const filteredAppointments = newAppointments.filter(a => {
        return a.status === 'Completed'
    }).sort((a, b) => {
        const aDate = new Date(a.date_time);
        const bDate = new Date(b.date_time);
        if(aDate - bDate === 0){
            return parseInt(a.time) - parseInt(b.time);
        }
        return aDate - bDate;
    });

    const studentObj = selectedStudent.obj;
    const studentName = `${studentObj.first_name} ${studentObj.last_name}`;

    let reportPdf = await PDFDocument.create();
    const helveticaFont = await reportPdf.embedFont(StandardFonts.Helvetica);

    let currentPage = reportPdf.addPage();

// A - TOTAL STAT TRACKERS //
    const allBooksCompleted = [];

// B - MASTER VARIABLES //
    const { width, height } = currentPage.getSize();
    // width 595.28 = 70.03 pt per inch, height 841.89 = 76.55 pt per inch
    // 44 quarter inches (11 inches) tall, 34 quarter inches (8 1/2 inches) wide
    const yMarginQuarterInches = 3;
    const yMin = height * (yMarginQuarterInches/44) + 60; // + 60 so that the logo on the bottom left does not collide with text
    const yMax = height - (height * (yMarginQuarterInches/44));
    const xMarginQuarterInches = 3;
    const xMin = width * (xMarginQuarterInches/34);
    const xMax = width - (width * (xMarginQuarterInches/34));

    let yPos = yMax;
    
// 0 - HEADERS //
    function drawItemsWrapper(items){
        const ycp = drawItems(reportPdf, currentPage, items, helveticaFont, { xMin, xMax, yPos, yMin, yMax });
        yPos = ycp.yPos;
        currentPage = ycp.currentPage;
    }
// 0a - Header: Student
    drawItemsWrapper([{
        type: 'text',
        text: `Progress Report for ${studentName}`,
        size: 20,
        font: helveticaFont,
        afterMargin: 5,
        center: true,
    }]);

// 0b - Header: Dates
    const startDateFormatted = formatDate(convertApiToDate(startDate));
    const endDateFormatted = formatDate(convertApiToDate(endDate));
    drawItemsWrapper([{
        type: 'text',
        text: `${startDateFormatted} to ${endDateFormatted}`,
        size: 15,
        font: helveticaFont,
        afterMargin: 5,
        center: true,
    }]);

// 0c - Header: Appointment Count
    let totalHours = 0;
    filteredAppointments.forEach(a => totalHours += (parseInt(a.duration) / 60));

    drawItemsWrapper([{
        type: 'text',
        text: `${filteredAppointments.length} sessions (${totalHours} hours)`,
        size: 15,
        font: helveticaFont,
        afterMargin: 25,
        center: true,
    }]);

    for(let a of filteredAppointments){
        const itemsToDraw = [];
    // 0a - Total stat tracking
        // 1 - Appointment Header -- Monday, 02/13/2023, 4:00 PM to 5:00 PM
        const [year, month, date, hours, minutes] = getymdhms(new Date(a.date_time));
        const aptDateApi = `${year}-${month}-${date} 00:00:00`;
        const startTime = hours * 60 + minutes * 1;
        const endTime = startTime + parseInt(a.duration);
        const ahSize = 15;
        itemsToDraw.push({
            type: 'text',
            xOffset: 0,
            text: `${formatDateFull(aptDateApi)}, ${formatTime(startTime)} to ${formatTime(endTime)}`,
            size: ahSize,
            afterMargin: 10,
            underline: { active: true },
        });

    // 2 - Scores
        const scoreSize = 12;
        itemsToDraw.push({
            type: 'text',
            xOffset: 10,
            text: `Participation: ${a.participation}/4`,
            size: scoreSize,
            afterMargin: 10,
            shiftYPos: false,
        });

        itemsToDraw.push({
            type: 'text',
            xOffset: 175,
            text: `Answer Accuracy: ${a.answer_accuracy}/4`,
            size: scoreSize,
            shiftYPos: false,
        });

        itemsToDraw.push({
            type: 'text',
            xOffset: 350,
            text: `Depth of Thinking: ${a.depth_of_thinking}/4`,
            size: scoreSize,
            shiftYPos: false,
        });

    // 3 - Books Completed
        allBooksCompleted.push(...a.booksCompleted);

        const lcStringBlocks = a.booksCompleted || [];
        const showLCSection = lcStringBlocks.length ? true : false;

        if(showLCSection){
            // a: header
            const lcHeadSize = 12;
            const lcSize = 12;
            itemsToDraw.push({
                type: 'text',
                xOffset: 10,
                text: `Books Completed:`,
                size: lcHeadSize,
                afterMargin: 8,
            });
            
            // b: body
            for(let block of lcStringBlocks){
                itemsToDraw.push({
                    type: 'text',
                    xOffset: 20,
                    text: block,
                    size: lcSize,
                    afterMargin: 3,
                });
            }

            itemsToDraw.push({
                type: 'vspace',
                a: 1,
                afterMargin: 10,
            });
        }

    // 4 - Notes
        const snText = a.session_notes || 'None';
        itemsToDraw.push({
            type: 'textMulti',
            text: snText,
            size: 12,
            font: helveticaFont,
            xOffset: 10,
            spaceBetween: 5,
            afterMargin: 10,
            color: snText === 'None' ? rgb(225/255, 96/255, 0) : rgb(0, 0, 0), // Orange or black
            addTextBefore: {
                text: 'Session Notes: ',
            }
        });

    // 5 - Instructors
        const aiText = a.assignedInstructorNames || 'None';
        itemsToDraw.push({
            type: 'textMulti',
            text: aiText,
            size: 12,
            font: helveticaFont,
            xOffset: 10,
            spaceBetween: 2,
            afterMargin: 10,
            color: aiText === 'None' ? rgb(225/255, 96/255, 0) : rgb(0, 0, 0), // Orange or black
            addTextBefore: {
                text: 'Instructors: '
            }
        });
    
    // 99 - Others
    // Intra-Book margins
        itemsToDraw.push({
            type: 'vspace',
            afterMargin: 10,
        });

    // Draw everything
    // 1 - Reset page if needed
        drawItemsWrapper(itemsToDraw);
    }

    // 99 - Summary
    currentPage = reportPdf.addPage();
    yPos = yMax;
    const itemsToDraw = [];

    // 99a - Main Header
    const summaryHeaderText = `Progress Report Summary`;
    const summaryHeaderSize = 20;
    itemsToDraw.push({
        type: 'text',
        text: summaryHeaderText,
        size: summaryHeaderSize,
        afterMargin: 25,
        center: true,
    });

    // 99b - Books completed header
    const summaryLcHeaderText = `Books Completed:`;
    const summaryLcHeaderSize = 15;
    itemsToDraw.push({
        type: 'text',
        xOffset: 0,
        text: summaryLcHeaderText,
        size: summaryLcHeaderSize,
        afterMargin: 5,
    });

    // 99c - Books completed list
    const summaryLcSize = 12;
    const summaryLcHeight = helveticaFont.heightAtSize(summaryLcSize);
    if(allBooksCompleted.length){
        for(let book of allBooksCompleted){
            const summaryLcText = book;
            if(yPos - summaryLcHeight < yMin){
                currentPage = reportPdf.addPage();
                yPos = yMax;
            }
            itemsToDraw.push({
                type: 'text',
                xOffset: 15,
                text: summaryLcText,
                size: summaryLcSize,
                afterMargin: 2,
            });
        }
    } else {
        itemsToDraw.push({
            type: 'text',
            xOffset: 15,
            text: 'None',
            color: rgb(225/255, 96/255, 0),
            size: summaryLcSize,
            afterMargin: 2,
        });
    }

    // 99f - Draw items
    drawItemsWrapper(itemsToDraw);

    reportPdf = await appendLogo(reportPdf, logoData);

    // Append copyright text
    const copyrightText = `© ${(new Date()).getFullYear()} ReadingPlex LLC`;
    const copyrightSize = 8;
    const copyrightWidth = helveticaFont.widthOfTextAtSize(copyrightText, copyrightSize);
    const copyrightXPos = xMax - copyrightWidth + 20; // + 20 is a manual adjustment
    const pages = reportPdf.getPages();
    for(let page of pages){
        page.drawText(copyrightText, {
            x: copyrightXPos,
            y: 30,
            size: copyrightSize,
            font: helveticaFont,
        });
    }

    const pdfBytes = await reportPdf.save();
    if(quickDownload){
        const [year, month, date] = getymd(`${quickDownloadDate} 00:00:00`);
        downloadFile(pdfBytes, `${year}-${month}-${date} ${studentName} PR`);
    } else {
        openPopup(pdfBytes);
    }

    return;
}