import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { SelectSingle, FormikControl, Check, checkResponses } from '../../../../components/form';
import { formatDate, getymd } from "../../../../components/functions";
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import AutoScheduleItemsTable from './AutoScheduleItemsTable';
import CreateAppointments from './CreateAppointments';
import { Socket } from '../../../../components/ws';
import { AutoScheduleItemsModal } from '../../../../components/modal';

import {
    fetchServerTime,
    fetchRpCentersAll,
    fetchStudentsAll,
    fetchCycleGroupsActive,
    fetchContractsActive,
    fetchAutoScheduleItemsCenter
} from '../../../../actions';

const pageTitle = 'Auto Scheduler';

function getDoW(n){
    switch(n){
        case 0: return 'Sunday';
        case 1: return 'Monday';
        case 2: return 'Tuesday';
        case 3: return 'Wednesday';
        case 4: return 'Thursday';
        case 5: return 'Friday';
        case 6: return 'Saturday';
        default: return null;
    }
}

function AutoScheduler(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [modalMode, setModalMode] = useState(null);
    // Data
    const [weekOptions, setWeekOptions] = useState([{ value: -1, label: 'Please select...' }]);
    const [studentNameMap, setStudentNameMap] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [groupMap, setGroupMap] = useState({});
    const [contractMap, setContractMap] = useState({});
    const [autoScheduleItems, setAutoScheduleItems] = useState([]);
    const [filteredAutoScheduleItems, setFilteredAutoScheduleItems] = useState([]);

    const { fetchServerTime, fetchRpCentersAll, fetchStudentsAll, fetchCycleGroupsActive,
        fetchContractsActive, fetchAutoScheduleItemsCenter } = props;

    const filterAutoScheduleItems = useCallback((newAutoScheduleItems = autoScheduleItems, filterQuery, showInactive) => {
        const formattedFQ = filterQuery.toLowerCase().trim().replace(/ /g, '');
        
        const showInactiveTrue = showInactive === 'true' || showInactive === true;

        const newFilteredAutoScheduleItems = newAutoScheduleItems
            .filter(a => a.studentName.toLowerCase().replace(/ /g, '').includes(formattedFQ))
            .filter(a => showInactiveTrue || parseInt(a.active) === 1);

        if(mounted.current) setFilteredAutoScheduleItems(newFilteredAutoScheduleItems);
    }, [autoScheduleItems]);
    const refreshData = useCallback((newCenterOptions = centerOptions, newStudentNameMap = studentNameMap,
        newGroupMap = groupMap, newContractMap = contractMap) => {
        (async function refreshData(){
            if(loading || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
    
            const { selectedCenter, filterQuery } = formRef.current.values;
    
            const asiRes = await fetchAutoScheduleItemsCenter({ center: selectedCenter.value });
            const isApiError = checkResponses(asiRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAutoScheduleItems = asiRes.data || [];
    
            const centerMap = {};
            newCenterOptions.forEach(c => centerMap[parseInt(c.value)] = c.label);
            
            newAutoScheduleItems.forEach(a => {
                a.dayOfWeek = getDoW(a.dow);
                a.centerName = centerMap[parseInt(a.center)] || `Unknown center (${a.center})`;
                a.studentName = newStudentNameMap[a.student] || `Unknown student (UID: ${a.student})`;
                a.groupName = newGroupMap[a.group_id] || `Unknown group (${a.group_id})`;
                a.contractName = newContractMap[a.contract] || `Unknown contract (${a.contract})`;
            });
            
            if(mounted.current){
                setAutoScheduleItems(newAutoScheduleItems);
                filterAutoScheduleItems(newAutoScheduleItems, filterQuery, formRef?.current?.values?.showInactive);
                setHasLoaded(true);
                setLoading(false);
            }
        })();
    }, [mounted, loading, centerOptions, studentNameMap, fetchAutoScheduleItemsCenter, filterAutoScheduleItems]);
    useEffect(() => {
        async function init(){
            if(mounted.current) setLoading(true);

            const currentTimeRes = await fetchServerTime();
            const centersRes = await fetchRpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const cycleGroupsRes = await fetchCycleGroupsActive();
            const contractsRes = await fetchContractsActive();
            const isApiError = checkResponses(currentTimeRes, centersRes, studentsRes, cycleGroupsRes, contractsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newCurrentTime = currentTimeRes.data?.[0] || {};
            const now = newCurrentTime.CURRENT_TIMESTAMP;
            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];

            const newWeekOptions = [];
            const lastSunday = new Date(now);
            const daysFromLastSunday = lastSunday.getDay();
            lastSunday.setDate(lastSunday.getDate() - daysFromLastSunday);
            lastSunday.setHours(0, 0, 0 ,0);
            const thisSaturday = new Date(now);
            thisSaturday.setDate(thisSaturday.getDate() + (6 - daysFromLastSunday));
            thisSaturday.setHours(0, 0, 0 ,0);
            
            const inOneMonth = new Date();
            inOneMonth.setMonth(inOneMonth.getMonth() + 1);
            inOneMonth.setHours(0, 0, 0, 0);
            for(let i = 0; i < 5; i++){
                if(thisSaturday >= inOneMonth) continue;
                const [sunY, sunM, sunD] = getymd(lastSunday);

                newWeekOptions.push({
                    value: `${sunY}-${sunM}-${sunD}`,
                    label: `${formatDate(lastSunday)} (Sun) to ${formatDate(thisSaturday)} (Sat)`
                });
                lastSunday.setDate(lastSunday.getDate() + 7);
                thisSaturday.setDate(thisSaturday.getDate() + 7);
            }

            const newCenterOptions = [
                { value: 'all', label: 'All'},
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name })) 
            ];
            const newStudentNameMap = {};
            newStudents.forEach(s => newStudentNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);

            const newGroupMap = {};
            const newGroups = cycleGroupsRes.data?.groups || [];
            newGroups.forEach(g => newGroupMap[g.id] = g.name);

            const newContractMap = {};
            const newContracts = contractsRes.data || [];
            
            newContracts.forEach(c => {
                const hoursRemaining = (c.minutes_remaining / 60).toLocaleString(undefined, { maximumFractionDigits: 1 });
                newContractMap[c.id] = `${formatDate(c.start_date)} to ${formatDate(c.end_date)}: ${c.type} (${hoursRemaining} hours left)`;
            });

            if(mounted.current){
                await setLoading(false);

                setWeekOptions(newWeekOptions);
                setCenterOptions(newCenterOptions);
                setStudentNameMap(newStudentNameMap);
                setGroupMap(newGroupMap);
                setContractMap(newContractMap);

                refreshData(newCenterOptions, newStudentNameMap, newGroupMap, newContractMap);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    // Add student contact and account info to a tooltip.
    return (
        <div className="page-box">
            <BrowserTabTitle>{pageTitle}</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            {modalMode === 'create' ?
                <AutoScheduleItemsModal
                    mode={modalMode}
                    onSubmitCallback={(changes) => onSubmitCallback(changes)}
                /> : null
            }
            <div className="card">
                <h2>Auto Schedule Items ({filteredAutoScheduleItems.length})</h2>
                <br/>
                <Formik
                    enableReinitialize={!hasLoaded}
                    initialValues={{
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                        filterQuery: '',
                        showInactive: false
                    }}
                    innerRef={formRef}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-row gap-x-4 items-end">
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <SelectSingle
                                        id="advancedSearch-center"
                                        label="Center"
                                        name="selectedCenter"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                </div>
                                <div className="flex items-end">
                                    <Button
                                        color="lte-mpTeal"
                                        onClick={() => refreshData()}
                                    >
                                        Search
                                    </Button>
                                </div>
                                <div className="flex items-end">
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setModalMode('create')}
                                    >
                                        + Add Item
                                    </Button>
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-center">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <FormikControl
                                        id="auto-scheduler-filter-query"
                                        name="filterQuery"
                                        placeholder="Search by student name..."
                                        value={formik.values.filterQuery}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterAutoScheduleItems(autoScheduleItems, e.target.value, formik.values.showInactive);
                                        }}
                                    />
                                </div>
                                <div className="self-end">
                                    <Check
                                        id="aut-scheduler-show-inactive"
                                        name="showInactive"
                                        label="Show Inactive"
                                        color="mpLRed"
                                        checked={formik.values.showInactive}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            filterAutoScheduleItems(autoScheduleItems, formik.values.filterQuery, e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="ml-auto">
                                    <TooltipWrapper
                                        tooltipText={
                                            <div>
                                                <div>
                                                    What gets exported?
                                                </div>
                                                <br/>
                                                <div>
                                                    All auto schedule items that have been filtered ({autoScheduleItems.length} items).
                                                </div>
                                            </div>
                                        }
                                    >
                                        <CSVExport
                                            title="Auto Schedule Items"
                                            label="Export Auto Schedule Items to CSV"
                                            data={filteredAutoScheduleItems}
                                        />
                                    </TooltipWrapper>
                                </div>
                            </div>

                            <div className="h-2 clear-both"/>

                            <div className="text-mpOrange">
                                Only appointments displayed in the table below will be created.
                            </div>
                        </form>
                    )}
                </Formik>

                <br/>

                <CreateAppointments
                    hasLoaded={hasLoaded}
                    weekOptions={weekOptions}
                    autoScheduleItems={filteredAutoScheduleItems}
                />

                {
                    autoScheduleItems.length >= 2000 ? 
                    <>
                        <br/>
                        <div className="text-mpLRed">Only the first 2000 results were returned.</div>
                    </>
                    : null
                }
                
                <br/>

                { apiError ? <div className="text-mpLRed">{apiError}</div> :
                    hasLoaded && 
                    <>
                        <AutoScheduleItemsTable
                            hasLoaded={hasLoaded}
                            autoScheduleItems={filteredAutoScheduleItems}
                            refreshData={refreshData}
                        />
                    </>
                }
            </div>

            <Socket
                refreshData={refreshData}
                page={pageTitle}
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchServerTime,
    fetchRpCentersAll,
    fetchStudentsAll,
    fetchCycleGroupsActive,
    fetchContractsActive,
    fetchAutoScheduleItemsCenter
})(AutoScheduler)