import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import FlagsBodyFooter from './FlagsBF';
import { checkResponse } from '../../form';

import {
    fetchFlagsIds,
    fetchRpCentersAll,
    fetchAdminUsersAll,
    fetchStudentsAll,
    createFlag,
    updateFlag,
    deleteFlag,
} from '../../../actions';

const initDate = new Date();
initDate.setHours(0, 0, 0, 0);

function FlagModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    
    const [flag, setFlag] = useState({});
    const [centerOptions, setCenterOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    const { mode, selectedFlag, onSubmitCallback, fetchFlagsIds, fetchRpCentersAll, 
        fetchAdminUsersAll, fetchStudentsAll, createFlag, updateFlag, deleteFlag } = props;

    useEffect(() => {
        async function init(){
            const employeesRes = await fetchAdminUsersAll();
            const studentsRes = await fetchStudentsAll();
            const centersRes = await fetchRpCentersAll();

            const newEmployees = employeesRes.data || [];
            const newStudents = studentsRes.data || [];
            const newCenters = centersRes.data || [];

            newStudents.forEach(s => s.shouldExclude = parseInt(s.is_rp_student) === 0);
            const newUsers = [...newEmployees, ...newStudents].sort((a, b) => {
                const aPerms = a.rp_permissions;
                const bPerms = b.rp_permissions;
                if(aPerms && !bPerms) return -1;
                else if(!aPerms && bPerms) return 1;
                else {
                    const aName = `${a.first_name} ${a.last_name}`;
                    const bName = `${b.first_name} ${b.last_name}`;
                    if(aName < bName) return -1;
                    else if(aName > bName) return 1;
                    else return 0;
                }
            });
            const exceptedIds = [];

            if(mode !== 'create'){
                const flagRes = await fetchFlagsIds({ ids: [selectedFlag.id] });
                const newFlag = flagRes.data?.[0] || {};
                const flagUser = newUsers.find(u => u.id === newFlag.user || u.user_id === newFlag.user);
                exceptedIds.push(newFlag.user);
    
                const flagMeta = [];
                if(newFlag.created_by) flagMeta.push(newFlag.created_by);
                if(newFlag.updated_by) flagMeta.push(newFlag.updated_by);

                const cbInstructor = newEmployees.find(ni => ni.id === newFlag.created_by);
                const ubInstructor = newEmployees.find(ni => ni.id === newFlag.updated_by);

                newFlag.userName = flagUser ? `${flagUser.first_name} ${flagUser.last_name}` :
                    `Unknown user (UID ${newFlag.user})`;
                if(cbInstructor) {
                    newFlag.createdByName = cbInstructor ?
                    `${cbInstructor.first_name} ${cbInstructor.last_name}` :
                    `Unknown admin user (ID: ${newFlag.created_by})`;
                }
                if(ubInstructor) {
                    newFlag.updatedByName = ubInstructor ?
                    `${ubInstructor.first_name} ${ubInstructor.last_name}` :
                    `Unknown admin user (ID: ${newFlag.updated_by})`;
                }
                newFlag.centerName = newCenters.find(c => parseInt(c.id) === parseInt(newFlag.center))?.name ||
                    `Unknown center (ID ${newFlag.center})`;

                if(mounted.current) setFlag(newFlag);
            }

            const newUserOptions = newUsers.filter(u => {
                if(exceptedIds.includes(u.user_id || u.id)) return true;
                
                return (
                    u.rp_permissions !== 'None' &&
                    (parseInt(u.account_active) === 1 || parseInt(u.rp_active) === 1) &&
                    !u.shouldExclude
                );
            }).map(u => {
                let userName = `${u.first_name} ${u.last_name}`;
                if(u.id) userName += '*'
                return {
                    value: u.user_id || u.id,
                    label: userName
                };
            });
            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));

            if(mounted.current){
                setUserOptions(newUserOptions);
                setCenterOptions(newCenterOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes) => {
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const flagParams = {
                    user: values.user.value,
                    notes: values.notes,
                    followupNotes: values.followupNotes,
                    center: values.center.value,
                    priority: values.priority.value,
                    status: values.status.value,
                };
                
                if(mode === 'create'){
                    response = await createFlag(flagParams);
                } else if(mode === 'edit') {
                    flagParams.id = flag.id;
                    response = await updateFlag(flagParams);
                }
            } else if(mode === 'delete') {
                response = await deleteFlag({ id: flag.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true), 1000);
        }
        submit();
    }, [mode, handleClose, flag, createFlag, updateFlag, deleteFlag]);

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Flag Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <FlagsBodyFooter
                    mode={mode}
                    selectedFlag={flag}
                    userOptions={userOptions}
                    centerOptions={centerOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchFlagsIds,
    fetchRpCentersAll,
    fetchAdminUsersAll,
    fetchStudentsAll,
    createFlag,
    updateFlag,
    deleteFlag
})(FlagModal);