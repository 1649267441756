import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import BookListTemplateBodyFooter from './BookListTemplateBF';
import { checkResponse } from '../../form';

import {
    fetchBookListTemplatesId,
    fetchBooksAll,
    createBookListTemplate,
    updateBookListTemplate,
    deleteBookListTemplate,
} from '../../../actions';

function BookListTemplateModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [attemptingClose, setAttemptingClose] = useState(false);
    
    const [bookListTemplate, setBookListTemplate] = useState({});
    const [bookListTemplateItems, setBookListTemplateItems] = useState([]);
    const [books, setBooks] = useState([]);
    const [bookOptions, setBookOptions] = useState([]);

    const { mode, selectedBookListTemplate, onSubmitCallback, fetchBookListTemplatesId,
        fetchBooksAll, createBookListTemplate, updateBookListTemplate, deleteBookListTemplate } = props;

    useEffect(() => {
        async function init(){
            const booksRes = await fetchBooksAll();
            const newBooks = booksRes.data || [];

            const bookMap = {};
            const exceptedBooks = [];

            newBooks.forEach(b => bookMap[b.id] = b)

            if(mode !== 'create'){
                const bookListTemplatesRes = await fetchBookListTemplatesId({ id: selectedBookListTemplate.id });

                const newBookListTemplate = bookListTemplatesRes.data?.templates?.[0] || {};
                const newBookListTemplateItems = bookListTemplatesRes.data?.templateItems || [];

                const formattedBookListTemplateItems = newBookListTemplateItems.sort((a, b) => {
                    return parseInt(a.sort_order) - parseInt(b.sort_order);
                }).map(bli => {
                    exceptedBooks.push(bli.book_id);
                    const relevantBook = bookMap[parseInt(bli.book_id)] || {};
                    return {
                        id: parseInt(bli.book_id),
                        title: relevantBook.title,
                        author: relevantBook.author,
                        sortOrder: parseInt(bli.sort_order)
                    };
                });

                if(mounted.current){
                    setBookListTemplate(newBookListTemplate);
                    setBookListTemplateItems(formattedBookListTemplateItems);
                }
            }

            const filteredBooks = newBooks.filter(b => {
                const isValid = parseInt(b.active) === 1 || exceptedBooks.includes(b.id);
                return isValid
            });

            const newBookOptions = [ { value: -1, label: 'None Selected' },
                ...filteredBooks.map(b => {
                    const bookId = parseInt(b.id);
                    const bookOption = ({ value: bookId, label: `${b.title} (${b.author})`, obj: b });
                    return bookOption;
                }
            )];
            
            if(mounted.current){
                setBooks(filteredBooks);
                setBookOptions(newBookOptions);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleClose = useCallback((changes, force = false) => {
        if(!attemptingClose && !force && mode !== 'delete'){
            setAttemptingClose(true);
            return;
        }
        if(changes !== true) changes = false;
        async function close(){
            await onSubmitCallback(changes);
            if(mounted.current) setShowModal(false);
        }
        close();
    }, [onSubmitCallback, attemptingClose, setAttemptingClose, mode]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;
            
            let response = { status: 999 };
            
            if(['create', 'edit'].includes(mode)){
                const { name, notes, active, bookListTemplateItems } = values;
                const bookListTemplateParams = {
                    name,
                    notes,
                    active: active ? 1 : 0
                };
                const bookListTemplateItemParams = [];

                bookListTemplateItems.forEach(bli => {
                    bookListTemplateItemParams.push({
                        bookId: bli.id,
                        dateAssigned: bli.dateAssigned || null,
                        dateCompleted: bli.dateCompleted || null,
                        sortOrder: bli.sortOrder
                    });
                })
                
                if(mode === 'create'){
                    response = await createBookListTemplate({
                        template: bookListTemplateParams,
                        templateItems: bookListTemplateItemParams
                    });
                } else if(mode === 'edit') {
                    bookListTemplateParams.id = bookListTemplate.id;
                    response = await updateBookListTemplate({
                        template: bookListTemplateParams,
                        templateItems: bookListTemplateItemParams
                    });
                }
            } else if(mode === 'delete') {
                response = await deleteBookListTemplate({ id: bookListTemplate.id });
            }

            const responseValid = checkResponse(response, mounted, setStatus);
            if(!responseValid && mounted.current){
                setSubmitting(false);
                return;
            }
            
            if(mounted.current) setSubmitted(true);
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [mode, handleClose, bookListTemplate, createBookListTemplate, updateBookListTemplate, deleteBookListTemplate]);

    return (
        <Modal className="w-1/2" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h3>Book List Form</h3>
            </Modal.Header>
            <Modal.BodyFooter>
                <BookListTemplateBodyFooter
                    mode={mode}
                    bookListTemplate={bookListTemplate}
                    bookListTemplateItems={bookListTemplateItems}
                    books={books}
                    bookOptions={bookOptions}
                    loaded={loaded}
                    submitted={submitted}
                    handleClose={handleClose}
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchBookListTemplatesId,
    fetchBooksAll,
    createBookListTemplate,
    updateBookListTemplate,
    deleteBookListTemplate
})(BookListTemplateModal);