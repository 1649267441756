import React from 'react';
import * as Yup from 'yup';

import { formatDateFull } from '../../functions';
import { SelectSingle } from '../../form';
import { TooltipWrapper, renderPhone } from '../../display';
import { ErrorMessage } from '../../custom-essentials';

export const validationSchema = Yup.object().shape({
    date: Yup.mixed().test(
        'dateIsValid',
        'Invalid Date',
        (value) => !isNaN(new Date(value).getTime())
    ),
    time: Yup.mixed().test(
        'timeIsValid',
        'Invalid time',
        (value) => !isNaN(value.raw)
        ).test(
            'isQuarterHour',
            'Minutes must be an increment of 15',
            (value) => value.raw % 15 === 0
        ),
    group: Yup.mixed().test(
        'groupIsSelected',
        'Group is required',
        (value) => value.value !== -1
    ),
    student: Yup.mixed().test(
        'studentIsSelected',
        'Student is required',
        (value) => value.value !== -1
    ),
    center: Yup.mixed().test(
        'centerIsSelected',
        'Center is required',
        (value) => value.value !== -1
    ),
    duration: Yup.mixed().test(
        'durationIsSelected',
        'Duration is required',
        (value) => value.value !== -1
    ),
    status: Yup.mixed().test(
        'statusIsSelected',
        'Status is required',
        (value) => value.value !== -1
    ),
    specialNotes: Yup.string()
        .max(300, 'Max 300 characters'),
    specialNotes2: Yup.string()
        .max(300, 'Max 300 characters'),
    nWeeks: Yup.number()
        .typeError('Number of Weeks must be a number')
        .min(0, 'Min 0')
        .max(15, 'Max 15')
        .required('Number of Weeks is required')
        .integer('Number of Weeks must be a whole number'),
});

// Header for the 'Student' field //
export function getStudentHeader(student, parents){
    if(!student || typeof(student) !== 'object' || student.value === 'all') return <h5>Student</h5>
    const parent = parents.find(p => p.id === student.parent) || {};

    const firstGuardianContent = student.first_guardian_notes ? (
        <>
            <br/>
            <u>Additional Contacts</u>
            <div>First Guardian Notes: {student.first_guardian_notes}</div>
        </>
    ) : null;
    const secondGuardianContent = student.second_guardian_notes ? (
        <>
            <br/>
            <div>Second Guardian Notes: {student.second_guardian_notes}</div>
        </>
    ) : null;
    return (
        <TooltipWrapper
            placement="bottom"
            tooltipText={
                <>
                    <u>School Info</u>
                    <div>School: {student.school || 'Not Specified'}</div>
                    <div>Teacher: {student.mp_teacher || 'Not Specified'}</div>
                    <div>Grade: {student.grade || 'Not Specified'}</div>

                    <br/>

                    <u>Account Info</u>
                    <div>Next Month Begins:</div>
                    <div>{formatDateFull(student.mp_next_month_start)}</div>
                    <div>Hours This Month:&nbsp;
                        {(student.mp_time_left_this_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                    </div>
                    <div>Hours Next Month:&nbsp;
                        {(student.mp_time_left_next_month / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                    </div>
                    <div>Reserve Hours Left:&nbsp;
                        {(student.mp_reserve_time_left / 60).toLocaleString(undefined, { maximumFractionDigits: 1 })}
                    </div>

                    <br/>

                    <u>Student Contact:</u>
                    <div>Phone: {renderPhone(student.phone)}</div>
                    <div>Email: {student.email || 'None'}</div>

                    <br/>

                    <u>Parent Contact:</u>
                    <div>Phone: {renderPhone(parent.phone)}</div>
                    <div>Email: {parent.email || 'None'}</div>

                    {firstGuardianContent}
                    {secondGuardianContent}
                </>
            }
        >
            <h4 className="text-mpLBlue">Student</h4>
        </TooltipWrapper>
    );
}

export function getGroupHeader(formik){
    const selectedGroup = formik.values.group;
    if(selectedGroup.obj?.studentNames) {
        return (
            <TooltipWrapper
                tooltipText={`Students: ${selectedGroup.obj.studentNames}`}
            >
                <h4 className="text-mpLBlue">Group</h4>
            </TooltipWrapper>
        );
    } else return <h4>Group</h4>
}

export function getInitGroupOption(group, groupOptions){
    if(!group) return { value: -1, label: 'Please select...' };
    return groupOptions.find(s => s.value === group) || { value: -1, label: `Unknown group (${group})` };
}

export function getInitStudentOption(group, student, studentOptions){
    if(!group) return { value: -1, label: 'Please select a group first...' };
    if(!student) return { value: -1, label: 'Please select...' };
    return studentOptions.find(s => s.value === student) || { value: -1, label: `Unknown student (${student})` };
}

export function getInitCenterOption(center, centerOptions){
    if(!center || center === 'all') return { value: -1, label: 'Please select...' };
    return centerOptions.find(s => s.value === center) || { value: -1, label: `Unknown center (${center})` };
}


export const durationOptions = [30, 60, 90, 120].map(o => ({ value: o, label: `${o} minutes` }));
export function getInitDurationOption(duration){
    if(!duration) return durationOptions[1];
    return durationOptions.find(o => o.value === duration) || { value: -1, label: `Unknown duration (${duration})` };
}

export const statusOptions = ['Scheduled', 'In Progress', 'Completed', 'Missed', 'Cancelled', 'Not Charged']
    .map(i => ({ value: i, label: i }));
export function getInitStatusOption(status){
    if(!status) return statusOptions[0];
    return statusOptions.find(o => o.value === status) || { value: -1, label: `Unknown status (${status})` };
}

export function ContractFields(props){
    const formik = props.formik;
    const { group, student } = formik.values;
    if(group.value === -1 || student.value === -1) return null;
    const studentsToRender = student.value === 'all' ? group.obj.studentList : [student.obj];
    
    return (
        <>
            <hr/>

            <div className="h-3 clear-both"/>

            <TooltipWrapper
                tooltipText="Select the contract for each student that you would like to use to create the appointments"
            >
                <h3 className="text-mpLBlue">Contracts to Charge</h3>
            </TooltipWrapper>

            <br/>
            <br/>

            <div className="row no-mar">
                <div className="col-12">
                    {studentsToRender.map(s => {
                        const userId = s.user_id;
                        return (
                            <div key={`appointments-sc-${userId}`}>
                                <h4>{s.first_name} {s.last_name}</h4>
                                <div style={{ height: "0.5rem", clear: "both" }}/>
                                <SelectSingle
                                    id={`appointments-sc-contract-selector-${userId}`}
                                    name={`selectedContracts_${userId}`}
                                    value={formik.values.selectedContracts[userId]}
                                    onChange={(e) => {
                                        const newValues = { ...formik.values };
                                        const selectedContracts = newValues.selectedContracts;
                                        selectedContracts[userId] = e.target.value;
                                        newValues.selectedContracts = selectedContracts;
                                        formik.setValues(newValues);
                                    }}
                                    options={s.contractOptions}
                                />
                                {formik.values.selectedContracts[userId]?.value === -1 ? (
                                    <ErrorMessage color="mpLRed">
                                            Contract is required
                                    </ErrorMessage>
                                ) : null}

                                <div style={{ height: "2rem", clear: "both" }}/>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div style={{ height: "0.5rem", clear: "both" }}/>

            <hr/>
            <br/>
        </>
    );
}