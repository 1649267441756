import React, { useState, useRef, useCallback, useEffect } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import { getDateObject, formatDate, formatDateApi, convertApiToDate, getymdhms } from "../../../../components/functions";
import { DatePicker, SelectSingle, FormikControl, Check, checkResponses } from "../../../../components/form";
import { AppointmentModal, StampsModal } from "../../../../components/modal";
import AppointmentsTable from "./AppointmentsTable";
import { Socket } from '../../../../components/ws';

import {
    fetchMembersAll,
    fetchStudentsAll,
    fetchRpCentersAll,
    fetchAdminUsersAll,
    fetchCycleGroupsIds,
    fetchFlagsStatus,
    fetchAppointmentsDateCenter
} from '../../../../actions';

const lsSaveValues = [true, 'true'].includes(JSON.parse(localStorage.getItem('admin_scheduling_save_values')));
const defaultDate = lsSaveValues ? formatDateApi(convertApiToDate(localStorage.getItem('admin_scheduling_selected_date'))) : formatDateApi(new Date());
const lsSelectedCenter = lsSaveValues ? JSON.parse(localStorage.getItem('admin_scheduling_selected_center')) : null;
const lsFilterQuery = lsSaveValues ? JSON.parse(localStorage.getItem('admin_scheduling_filter_query')) : null;
const defaultFilterQuery = lsFilterQuery === null ? '' : lsFilterQuery;
const lsHideCM = lsSaveValues ? [true, 'true'].includes(JSON.parse(localStorage.getItem('admin_scheduling_hide_cm'))) : true;
const lsLegacyView = lsSaveValues ? [true, 'true'].includes(JSON.parse(localStorage.getItem('admin_scheduling_legacy_view'))) : true;

function getInitCenterOption(center, centerOptions){
    if(!centerOptions[0]) return { value: -1, label: 'Loading centers...' };
    if(!center || isNaN(center)) return centerOptions[0];
    return centerOptions.find(s => s.value === center) || { value: -1, label: `Unknown center (${center})` };
}

function Scheduling(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();
    
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Data
    const [centerOptions, setCenterOptions] = useState([]);
    const [adminUsers, setAdminUsers] = useState([]);
    const [members, setMembers] = useState([]);
    const [students, setStudents] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [filteredAppointments, setFilteredAppointments] = useState([]);
    const [filteredSplitAppointments, setFilteredSplitAppointments] = useState({});
    const [searchedDate, setSearchedDate] = useState(formatDate(new Date()));
    const [searchedCenter, setSearchedCenter] = useState(null);
    // Modal
    const [modalMode, setModalMode] = useState(null);
    
    const { fetchMembersAll, fetchStudentsAll, fetchRpCentersAll, fetchAdminUsersAll,
        fetchCycleGroupsIds, fetchAppointmentsDateCenter, fetchFlagsStatus } = props;

    const filterAppointments = useCallback((newAppointments, filterQuery, hideCM) => {
        const formattedFQ = filterQuery.toLowerCase().trim().replace(/ /g, '');
        const filteredAppointments = newAppointments.filter(a => {
            return !hideCM || !['Cancelled', 'Missed'].includes(a.status);
        }).filter(a => {
            return a.studentName.toLowerCase().trim().replace(/ /g, '').includes(formattedFQ);
        });

        const splitAppointments = {};
        filteredAppointments.forEach(a => {
            const aptTime = parseInt(a.start);
            const groupId = parseInt(a.group_id);
            if(!splitAppointments[aptTime]) splitAppointments[aptTime] = {};
            if(!splitAppointments[aptTime][groupId]){
                splitAppointments[aptTime][groupId] = { groupName: a.groupName, appointments: [] };
            }
            splitAppointments[aptTime][groupId].appointments.push(a);
        });

        if(mounted.current){
            setFilteredAppointments(filteredAppointments);
            setFilteredSplitAppointments(splitAppointments);
        }
    }, [setFilteredAppointments]);
    const refreshData = useCallback((selectedDate = formRef.current.values.selectedDate,
        selectedCenter = formRef.current.values.selectedCenter, newCenterOptions = centerOptions, 
        newMembers = members, newStudents = students, newAdminUsers = adminUsers,
        filterQuery = formRef.current.values.filterQuery, hideCM = formRef.current.values.hideCM) => {
        (async function refresh(){
            if(loading || isNaN(new Date(selectedDate))) return;
            if(mounted.current) setLoading(true);
            const newSelectedDate = getDateObject(convertApiToDate(selectedDate));
    
            const appointmentsRes = await fetchAppointmentsDateCenter({ date: newSelectedDate.raw, center: selectedCenter.value });
            const pendingFlagsRes = await fetchFlagsStatus({ status: 'Pending' });
            const isApiError = checkResponses(appointmentsRes, pendingFlagsRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAppointmentsData = appointmentsRes.data || [];
            const newFlags = pendingFlagsRes.data || [];
            const newAppointments = newAppointmentsData.appointments || {};
            const newInstructorAssignments = newAppointmentsData.assignments || {};

            const groupIds = [];
            newAppointments.forEach(a => {
                const groupId = parseInt(a.group_id);
                if(!groupIds.includes(groupId)) groupIds.push(groupId);
            });
            const cycleGroupsRes = await fetchCycleGroupsIds({ ids: groupIds });
            const newCycleGroups = cycleGroupsRes.data?.groups;
            
            const userToNameMap = {};
            newAdminUsers.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
            newMembers.forEach(m => userToNameMap[m.id] = `${m.first_name} ${m.last_name}`);
            const appointmentToAssignmentMap = {};
            const appointmentToAssignmentObjectMap = {};
            newInstructorAssignments.forEach(ia => {
                const iaApt = parseInt(ia.appointment_id);
                const instructorName = userToNameMap[ia.instructor] || `Unable to find user (ID ${ia.instructor})`;
                if(appointmentToAssignmentMap[iaApt]){
                    appointmentToAssignmentMap[iaApt] += `, ${instructorName}`;
                    appointmentToAssignmentObjectMap[iaApt].push(ia);
                } else {
                    appointmentToAssignmentMap[iaApt] = instructorName;
                    appointmentToAssignmentObjectMap[iaApt] = [ia];
                }
            });

            // To use when parsing scheduling notes
            const instructorNameToIdMap = {};
            newAdminUsers.forEach(u => {
                const name = `${u.first_name}${u.last_name}`.trim().toLowerCase();
                instructorNameToIdMap[name] = u.id;
            });
            
            const centerToNameMap = {};
            newCenterOptions.forEach(c => centerToNameMap[parseInt(c.value)] = c.label);
            const studentToNameMap = {};
            newStudents.forEach(s => studentToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const studentToObjectMap = {};
            newStudents.forEach(s => {
                studentToObjectMap[s.user_id] = s;
            });
            const memberToObjectMap = {};
            newMembers.forEach(m => {
                memberToObjectMap[m.id] = m;
            });
            const appointmentToFlagMap = {};
            newFlags.forEach(f => {
                f.createdByName = userToNameMap[f.created_by];
                f.updatedByName = userToNameMap[f.updated_by];
                f.centerName = centerToNameMap[f.center];
                // Only append the most recent flag (already sorted by date desc)
                if(!appointmentToFlagMap[f.student]) appointmentToFlagMap[f.student] = f;
            });
            const cycleGroupNameMap = {};
            newCycleGroups.forEach(cg => cycleGroupNameMap[parseInt(cg.id)] = cg.name);
            const appointmentsAppended = newAppointments.map(a => {
                const aptDateTime = new Date(a.date_time);
                const [year, month, date, hours, minutes] = getymdhms(aptDateTime);
                a.date = `${year}-${month}-${date} 00:00:00`;
                a.start = hours * 60 + minutes * 1;

                a.centerName = centerToNameMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;
                a.studentName = studentToNameMap[a.student] || `Unknown student (User ID: ${a.student})`;
                a.groupName = cycleGroupNameMap[parseInt(a.group_id)] || `Unknown group (ID ${a.group_id})`;
                a.instructorNames = appointmentToAssignmentMap[parseInt(a.id)] || 'None';
                a.studentInfo = studentToObjectMap[a.student] || {};
                a.end = parseInt(a.start) + parseInt(a.duration);
                a.parentObject = memberToObjectMap[a.parent] || {};
                a.createdByName = userToNameMap[a.created_by] || `Unknown user (UUID: ${a.created_by})`;
                a.updatedByName = userToNameMap[a.updated_by] || `Unknown user (UUID: ${a.updated_by})`;
                a.pendingFlag = appointmentToFlagMap[a.student] || {};
                return a;
            });
    
            if(mounted.current){
                setAppointments(appointmentsAppended);
                filterAppointments(appointmentsAppended, filterQuery, hideCM);
                setSearchedDate(newSelectedDate);
                setSearchedCenter(selectedCenter.label);
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mounted, loading, adminUsers, centerOptions, members, students]);

    useEffect(() => {
        async function init(){
            const membersRes = await fetchMembersAll();
            const studentsRes = await fetchStudentsAll();
            const centersRes = await fetchRpCentersAll();
            const adminUsersRes = await fetchAdminUsersAll();
            const isApiError = checkResponses(membersRes, studentsRes, centersRes, adminUsersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newMembers = membersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newCenters = centersRes.data || [];
            const newAdminUsers = adminUsersRes.data || [];

            const newCenterOptions = newCenters.map(c => ({ value: parseInt(c.id), label: c.name }));
            newCenterOptions.unshift({ value: 'all', label: 'All Centers' });
            const prevSelectedCenter = newCenterOptions.find(c => c.value === parseInt(lsSelectedCenter));

            if(mounted.current){
                setHasLoaded(true);
                setMembers(newMembers);
                setStudents(newStudents);
                setAdminUsers(newAdminUsers);
                setCenterOptions(newCenterOptions);
                refreshData(defaultDate, prevSelectedCenter || newCenterOptions[0], newCenterOptions,
                newMembers, newStudents, newAdminUsers);
            }
        }
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleShowModal = useCallback((mode) => {
        if(mounted.current) setModalMode(mode);
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current) setModalMode(null);
        if(changes) refreshData();
    }, [mounted, refreshData]);

    // Need some method for booking multiple students at once (mainly for parents with more than 1 student).
    return (
        <div className="page-box">
            <BrowserTabTitle>Scheduling</BrowserTabTitle>
            {!setHasLoaded || loading ? <LoadingOverlay/> : null}
            <div className="card">
                <h3>Appointment Scheduling</h3>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedDate: defaultDate,
                        selectedCenter: getInitCenterOption(lsSelectedCenter, centerOptions) || { value: -1, label: 'Loading centers...' },
                        saveValues: lsSaveValues,
                        filterQuery: defaultFilterQuery,
                        hideCM: lsHideCM,
                        legacyView: lsLegacyView,
                    }}
                    onSubmit={refreshData}
                    innerRef={formRef}
                >
                    {formik => (
                    <>
                        {modalMode === 'appointment' && 
                            <AppointmentModal
                                mode="create"
                                selectedDate={searchedDate}
                                selectedCenter={formik.values.selectedCenter}
                                onSubmitCallback={onSubmitCallback}
                            />
                        }
                        {modalMode === 'stamps' && 
                            <StampsModal
                                selectedDate={searchedDate}
                                selectedCenter={formik.values.selectedCenter}
                                onSubmitCallback={onSubmitCallback}
                            />
                        }
                        {apiError ? <div className="text-mpLRed">{apiError}</div> :
                            hasLoaded &&
                            <>
                                <h4>Appointment Search ({filteredAppointments.length})</h4>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div>
                                        <DatePicker
                                            id="scheduling-select-2"
                                            name="selectedDate"
                                            value={formik.values.selectedDate || formik.initialValues.selectedDate}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_selected_date', e.target.value || '');
                                            }}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className="w-1/4">
                                        <SelectSingle
                                            id="scheduling-control-2"
                                            name="selectedCenter"
                                            value={formik.values.selectedCenter}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_selected_center', JSON.stringify(e.target.value.value));
                                                refreshData(formik.values.selectedDate, e.target.value);
                                            }}
                                            disabled={loading}
                                            options={centerOptions}
                                        />
                                    </div>
                                    <div>
                                        <Check
                                            id="scheduling-save-values-2"
                                            name="saveValues"
                                            label="Save Search Settings"
                                            color="mpDBlue"
                                            checked={formik.values.saveValues}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                localStorage.setItem('admin_scheduling_save_values', JSON.stringify(e.target.value));
                                                if(e.target.value === true){
                                                    localStorage.setItem('admin_scheduling_selected_date',
                                                        isNaN(new Date(formik.values.selectedDate)) ? formatDateApi(new Date()) : formik.values.selectedDate);
                                                    localStorage.setItem('admin_scheduling_selected_center', JSON.stringify(formik.values.selectedCenter.value));
                                                    localStorage.setItem('admin_scheduling_filter_query', JSON.stringify(formik.values.filterQuery));
                                                    localStorage.setItem('admin_scheduling_hide_cm', JSON.stringify(formik.values.hideCM));
                                                    localStorage.setItem('admin_scheduling_legacy_view', JSON.stringify(formik.values.legacyView));
                                                }
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className="flex flex-row gap-x-4">
                                            <Button
                                                color="lte-mpTeal"
                                                onClick={() => refreshData()}
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => handleShowModal('appointment')}
                                            >
                                                Add Appointment
                                            </Button>
                                            <div>
                                                <TooltipWrapper
                                                    tooltipText={
                                                        <div>
                                                            <div>
                                                                What gets exported?
                                                            </div>
                                                            <br/>
                                                            <div>
                                                                All appointments that are shown here ({appointments.length} items).
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <CSVExport
                                                        title="Appointments"
                                                        label="Export Appointments to CSV"
                                                        data={appointments}
                                                    />
                                                </TooltipWrapper>
                                            </div>
                                            {/* <Button
                                                color="lte-mpEGreen"
                                                disabled={!appointments.length}
                                                style={{ marginRight: "2rem" }}
                                                onClick={() => renderAppointmentsAsPDF(appointments, selectedCenter.label, selectedDate.date)}
                                            >
                                                Download Schedule
                                            </Button> */}
                                        </div>
                                    </div>
                                </div>

                                <br/>

                                <div className="flex flex-row gap-x-4">
                                    <div className="w-1/3">
                                        <FormikControl
                                            id="members-query-1"
                                            name="filterQuery"
                                            placeholder="Filter appointments by student name..."
                                            value={formik.values.filterQuery}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_filter_query', JSON.stringify(e.target.value));
                                                filterAppointments(appointments, e.target.value, formik.values.hideCM);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Check
                                            id="scheduling-hidecm"
                                            name="hideCM"
                                            label="Hide Cancelled/Missed"
                                            color="mpDBlue"
                                            checked={formik.values.hideCM}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_hide_cm', JSON.stringify(e.target.value));
                                                filterAppointments(appointments, formik.values.filterQuery, e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Check
                                            id="scheduling-legacy"
                                            name="legacyView"
                                            label="Split Tables by Times and Groups"
                                            color="mpDBlue"
                                            checked={formik.values.legacyView}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                if(formik.values.saveValues) localStorage.setItem('admin_scheduling_legacy_view', JSON.stringify(e.target.value));
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            color="lte-mpPurple"
                                            onClick={() => handleShowModal('stamps')}
                                        >
                                            Give Stamps
                                        </Button>
                                    </div>
                                </div>

                                <br/>
                                <br/>

                                <h4>Showing appointments for {searchedDate.formattedFull} {searchedCenter ? `at ${searchedCenter}` : ``}</h4>

                                 <AppointmentsTable
                                    legacyView={formik.values.legacyView}
                                    refreshData={() => refreshData()}
                                    centerOptions={centerOptions}
                                    selectedCenter={formik.values.selectedCenter.value}
                                    selectedDate={formik.values.selectedDate}
                                    students={students}
                                    appointments={filteredAppointments}
                                    splitAppointments={filteredSplitAppointments}
                                    permissions={props.auth.permissions}
                                />
                            </>
                        }
                    </>
                    )}
                </Formik>
            </div>

            <Socket
                refreshData={refreshData}
                page="Scheduling"
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchMembersAll,
    fetchStudentsAll,
    fetchRpCentersAll,
    fetchAdminUsersAll,
    fetchCycleGroupsIds,
    fetchFlagsStatus,
    fetchAppointmentsDateCenter,
})(Scheduling);