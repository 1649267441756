import React from 'react';

import { formatDate } from '../../../../../components/functions';

export function getColumns(){
    const neverTag = <div className="text-mpOrange">Never</div>;
    return [
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            formatter: (row) => {
                return row.bookObj.title;
            }
        },
        {
            dataField: 'author',
            text: 'Author',
            sort: true,
            formatter: (row) => {
                return row.bookObj.author;
            }
        },
        {
            dataField: 'difficulty',
            text: 'Difficulty',
            sort: true,
            formatter: (row) => {
                return row.bookObj.difficulty;
            }
        },
        {
            dataField: 'theme',
            text: 'Theme',
            sort: true,
            formatter: (row) => {
                return row.bookObj.theme;
            }
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true,
            formatter: (row) => {
                return row.bookObj.type;
            }
        },
        {
            dataField: 'date_assigned',
            text: 'Date Assigned',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                if(!row.date_assigned) return neverTag;
                return <div>{formatDate(row.date_assigned)}</div>;
            }
        },
        {
            dataField: 'date_completed',
            text: 'Date Completed',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (aDate - bDate) * swap;                
            },
            formatter: (row) => {
                if(!row.date_completed) return neverTag;
                return <div>{formatDate(row.date_completed)}</div>;
            }
        },
    ];
}