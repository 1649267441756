import React from 'react';
import * as Yup from 'yup';
import { formatDateTime } from '../../functions';

export const validationSchema = Yup.object().shape({
    title: Yup.string()
        .max(200, 'Max 200 characteres')
        .required('Book title is required'),
    author: Yup.string()
        .max(200, 'Max 200 characteres')
        .required('Author is required'),
    difficulty: Yup.mixed().test(
        'bookDifficultyIsSelected',
        'Select a book difficulty',
        (value) => value?.value !== -1
    ),
    theme: Yup.mixed().test(
        'bookThemeIsSelected',
        'Select a book theme',
        (value) => value?.value !== -1
    ),
    type: Yup.mixed().test(
        'bookTypeIsSelected',
        'Select a book type',
        (value) => value?.value !== -1
    ),
    notes: Yup.string()
        .max(500, 'Max 500 characteres'),
    contentWarning: Yup.string()
        .max(100, 'Max 100 characteres')
});

export const difficultyOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'Y', 'Z'].map(i => ({ value: i, label: i }));
export function getInitDifficulty(difficulty){
    if(!difficulty) return difficultyOptions[0];
    return difficultyOptions.find(d => d.value === difficulty) || { value: -1, label: `Invalid type (${difficulty})` };
}

export const themeOptions = ['AfAmCl', 'AsCl', 'Adv', 'Bio', 'ChrFa', 'Com', 'Emp', 'Fam', 'FR',
    'ICR', 'LatCl', 'LM', 'L', 'M', 'NaCl', 'NonF', 'P', 'RefExp', 'SLID', 'SS'].map(i => ({ value: i, label: i }));
export function getInitTheme(theme){
    if(!theme) return themeOptions[0];
    return themeOptions.find(d => d.value === theme) || { value: -1, label: `Invalid type (${theme})` };
}

export const typeOptions = ['P', 'C', 'GN', 'KD', 'KD-P', 'TP', 'S1'].map(i => ({ value: i, label: i }));
export function getInitType(type){
    if(!type) return typeOptions[0];
    return typeOptions.find(t => t.value === type) || { value: -1, label: `Invalid type (${type})` };
}

export function renderMeta(book){
    let created = book.date_created ? (
        <div>Created on {formatDateTime(book.date_created)}</div>
    ) : null;
    let updated = book.date_updated ? (
        <div>Updated on {formatDateTime(book.date_updated)}</div>
    ) : null;

    if(created || updated){
        return(
            <div className="col">
                <br/>
                {created}
                {updated}
            </div>
        )
    } else return null;
}