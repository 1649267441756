import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button, ModalBodyFooter, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl, Check } from '../../form';
import { TooltipWrapper } from '../../display';
import { validationSchema, getInitStudentOption, typeOptions, getInitStampsAmount,
    renderSelectedAppointment, renderChanges, renderSubmitting } from './helpers';

function StampsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { studentOptions, stampParams, selectedAppointment, loaded,
        submitted, submissionStatus, handleClose, handleSubmit } = props;

    const [recommendedAmount, setRecommendedAmount] = useState(0);

    useEffect(() => {
        if(!selectedAppointment.id) return;
        const sa = selectedAppointment;
        const newRecommendedAmount = sa.participation + 1 + sa.answer_accuracy + 1 + sa.depth_of_thinking + 1;
        setRecommendedAmount(newRecommendedAmount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAppointment]);

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    selectedStudent: getInitStudentOption(selectedAppointment.student, studentOptions),
                    selectedType: { value: 'Give Stamps', label: 'Give Stamps'},
                    amount: getInitStampsAmount(selectedAppointment),
                    stampsGiven: parseInt(selectedAppointment?.stamps_given) === 1 ? true : false,
                    stampsNotes: ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    formik.isSubmitting ? renderSubmitting(submissionStatus, formik.setSubmitting, formik.setFieldValue) : 
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body
                            className="form fv-plugins-bootstrap fv-plugins-framework"
                        >
                            {parseInt(selectedAppointment.stamps_given) === 1 ?
                                <div className="text-mpOrange col">
                                    <h4>Heads up!</h4>
                                    <div>Stamps have already been awarded for this appointment.</div>
                                    <div>Quick give is disabled to avoid accidental duplication.</div>
                                    <br/>
                                </div> : null
                            }
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Student</h4>
                                    <SelectSingle
                                        id="stamps-selectedStudent"
                                        name="selectedStudent"
                                        value={formik.values.selectedStudent}
                                        // Disallow changing if the form is linked to an existing appointment.
                                        // This is needed because the form cannot re-link an appointment if the student
                                        // is changed.
                                        disabled={selectedAppointment.id}
                                        onChange={formik.handleChange}
                                        options={studentOptions}
                                    />
                                    {selectedAppointment.id && 
                                        <div style={{ fontSize: "8pt", marginRight: "2rem" }}>
                                            Student cannot be changed when a linked appointment exists.&nbsp;
                                            To select a different student, open this form using the table header
                                            on the Scheduling page (purple "Stamp Manager" button).
                                        </div>
                                    }
                                    {formik.errors.selectedStudent && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedStudent}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Transaction Type</h4>
                                    <SelectSingle
                                        id="stamps-selectedType"
                                        name="selectedType"
                                        value={formik.values.selectedType}
                                        onChange={formik.handleChange}
                                        options={typeOptions}
                                    />
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Amount</h4>
                                    <FormikControl
                                        id="stamps-amount"
                                        name="amount"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                        placeholder="Min: 1, Max: 1000"
                                    />
                                    {formik.errors.amount && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.amount}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <TooltipWrapper
                                        tooltipText="Notes will be added to the Stamps Log entry"
                                    >
                                        <h4 className="text-mpLBlue">Notes</h4>
                                    </TooltipWrapper>
                                    <FormikControl
                                        id="stamps-notes"
                                        name="stampsNotes"
                                        value={formik.values.stampsNotes}
                                        onChange={formik.handleChange}
                                        placeholder="Max. 100 characters"
                                    />
                                    {formik.errors.stampsNotes && (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.stampsNotes}
                                        </ErrorMessage>
                                    )}
                                </div>
                            </div>

                            {/* Only render this button section if we have a linked appointment! */}
                            {selectedAppointment.id && 
                                <>
                                    <br/>
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-full">
                                            <Check
                                                id="stamps-stamps-given"
                                                name="stampsGiven"
                                                color="mpDBlue"
                                                label={<TooltipWrapper
                                                    tooltipText={
                                                        <>
                                                            <div>
                                                                Have stamps been given for the linked appointment?
                                                            </div>
                                                            <br/>
                                                            <div>
                                                                Will be marked as true if the form is submitted and nonzero amount is given
                                                                ("Give Stamps" and "Give Cards" only).
                                                            </div>
                                                                <br/>
                                                            <div>
                                                                When this is false, the stamps button appears purple on the
                                                                appointments table. When true, it appears light blue.
                                                            </div>
                                                        </>
                                                    }
                                                >
                                                    <div className="text-mpLBlue">Stamps Given?</div>
                                                </TooltipWrapper>}
                                                checked={formik.values.stampsGiven}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <Button
                                            color="lte-mpLBlue"
                                            onClick={(e) => {
                                                if(formik.isSubmitting) return;
                                                const newValues = formik.values;
                                                newValues.amount = recommendedAmount;
                                                newValues.selectedType = { value: 'Give Stamps', label: 'Give Stamps' };
                                                formik.setValues(newValues);
                                                formik.handleSubmit();
                                            }}
                                            style={{ marginLeft: "auto" }}
                                            disabled={selectedAppointment.stamps_given || !parseInt(recommendedAmount)
                                                || formik.isSubmitting || formik.values.selectedType.value !== 'Give Stamps'}
                                        >
                                            Quick Give ({recommendedAmount}) & Save
                                        </Button>
                                    </div>
                                </>
                            }

                            <div className="h-6 clear-both"/>
                            <hr/>
                            <div className="h-4 clear-both"/>

                            {/* Again, only render if we have a linked appointment */}
                            <div className="grid grid-cols-1 gap-y-2 w-full">
                                {renderSelectedAppointment(selectedAppointment, recommendedAmount)}

                                {renderChanges(formik.values.selectedStudent, formik.values.selectedType,
                                    formik.values.amount, !formik.errors.amount, stampParams)}
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(StampsBodyFooter);