import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { BookListModal } from '../../../../components/modal';
import BookListTable from './BookListTable';
import { formatDate } from '../../../../components/functions';
import { SelectSingle, checkResponses } from '../../../../components/form';
import { LoadingOverlay, BrowserTabTitle } from '../../../../components/display';
import { Socket } from '../../../../components/ws';

import {
    fetchRpCentersAll,
    fetchCycleGroupsActive,
    fetchBookListsStudents,
    fetchBooksAll,
    fetchStudentsAll,
} from '../../../../actions';

function BookListManager(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [firstSearch, setFirstSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);

    const [groupOptions, setGroupOptions] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [books, setBooks] = useState([]);
    const [bookListOptions, setBookListOptions] = useState([]);

    const [modalMode, setModalMode] = useState(null);

    const { fetchRpCentersAll, fetchCycleGroupsActive, fetchBookListsStudents, fetchStudentsAll, fetchBooksAll } = props;

    const filterGroups = useCallback((selectedCenter) => {
        const isAll = parseInt(selectedCenter.value) === 0;
        
        const newGroupOptions = groupOptions.filter(g => isAll || parseInt(g.obj?.center) === selectedCenter.value);

        if(!newGroupOptions.length) newGroupOptions.push({ value: -1, label: 'No groups found' })
        
        if(mounted.current){
            setGroupOptions(newGroupOptions);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupOptions]);
    const refreshData = useCallback((selectedGroup, selectedStudent, selectedBookList) => {
        (async function refresh(){
            if(loading) return;
            if(mounted.current){
                setLoading(true);
                setFirstSearch(true);
            }

            const bookListsRes = await fetchBookListsStudents({ studentIds: [selectedStudent.value] });
            const newBookLists = bookListsRes.data?.bookLists || [];
            const newBookListItems = bookListsRes.data?.bookListItems || [];

            const bookMap = {};
            books.forEach(b => bookMap[parseInt(b.id)] = b);

            const bliMap = {};
            newBookListItems.forEach(bli => {
                bli.bookObj = bookMap[parseInt(bli.book_id)] || {};
                const bookListId = parseInt(bli.book_list_id);
                if(!bliMap[bookListId]) bliMap[bookListId] = [];
                bliMap[bookListId].push(bli);
            });

            newBookLists.forEach(bl => bl.items = bliMap[parseInt(bl.id)] || []);

            const additionalBookListOptions = newBookLists.sort((a, b) => {
                return b.date_created - a.date_created;
            }).sort((a, b) => {
                if(parseInt(a.active) === 0) return 1;
                else if(parseInt(b.active) === 0) return -1;
                else return 0;
            }).map(bl => {
                const isInactive = parseInt(bl.active) === 0 ? ' (Inactive)' : '';
                return { value: parseInt(bl.id), label: `${bl.name} (${formatDate(bl.date_created)})${isInactive}`, obj: bl };
            });
            const newBookListOptions = newBookLists.length ? [ { value: -1, label: 'Please select a book list...' },
                ...additionalBookListOptions
            ] : [{ value: -1, label: 'No book lists found' }];

            const currentGroupOption = groupOptions.find(g => g.value === parseInt(selectedGroup?.value)) || groupOptions[0];
            formRef?.current?.setFieldValue('selectedGroup', currentGroupOption);
            const currentBookOption = newBookListOptions.find(o => o.value === selectedBookList?.value) ||
                newBookListOptions[0];
            formRef?.current?.setFieldValue('selectedBookList', currentBookOption);

            if(mounted.current){
                setBookListOptions(newBookListOptions);
                setLoading(false);
            }
        })();
    }, [loading, setLoading, fetchBookListsStudents, books, groupOptions]);
    useEffect(() => {
        async function init(){
            setLoading(true);

            const centersRes = await fetchRpCentersAll();
            const groupsRes = await fetchCycleGroupsActive();
            const studentsRes = await fetchStudentsAll();
            const booksRes = await fetchBooksAll();

            const isApiError = checkResponses(centersRes, groupsRes, booksRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                }
            }

            const newCenters = centersRes.data || [];
            const newCenterOptions = [{ value: 0, label: 'All' }];
            newCenters.forEach(c => newCenterOptions.push({ value: c.id, label: c.name }));

            const newStudents = studentsRes.data || [];
            const studentOptionMap = {};
            newStudents.forEach(s => {
                studentOptionMap[s.user_id] = { value: s.user_id, label: `${s.first_name} ${s.last_name}`};
            });
            const newGroupMembers = groupsRes.data?.groupMembers || [];
            const groupMemberMap = {};
            newGroupMembers.forEach(gm => {
                const groupId = gm.group_id;
                const studentOption = studentOptionMap[gm.student] || { value: -1, label: `Unknown student (UID ${gm.student})`};
                if(!groupMemberMap[groupId]) groupMemberMap[groupId] = [];
                groupMemberMap[groupId].push(studentOption);
            });

            const newGroups = groupsRes.data?.groups || [];
            const newGroupsSorted = newGroups.sort((a, b) => {
                if(a < b) return -1;
                else if(a > b) return 1;
                else return 0;
            }).map(g => {
                g.studentOptions = groupMemberMap[parseInt(g.id)] || [{ value: -1, label: 'No students found' }];
                return g;
            });
            const newGroupOptions = newGroupsSorted.map(g => ({ value: g.id, label: g.name, obj: g }));

            const newBooks = booksRes.data || [];

            if(mounted.current){
                setCenterOptions(newCenterOptions);
                setGroupOptions(newGroupOptions);
                setBooks(newBooks);

                setLoading(false);
            }
        }

        init();
    }, [fetchRpCentersAll, fetchStudentsAll, fetchCycleGroupsActive, fetchBooksAll]);

    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            if(changes) refreshData(formRef?.current?.values?.selectedGroup, formRef?.current?.values?.selectedStudent,
                formRef?.current?.values?.selectedBookList);
        }
    }, [mounted, refreshData, formRef]);

    return (
        <div className="page-box">
            <BrowserTabTitle>Book List Manager</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <Formik
                    enableReinitialize
                    initialValues={{
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                        selectedGroup: groupOptions[0] ? { value: -1, label: 'Please select...' } : { value: -1, label: 'Loading groups...' },
                        selectedStudent: { value: -1, label: 'Please select a student...' },
                        selectedBookList: { value: -1, label: 'Please select a book list...' },
                    }}
                    innerRef={formRef}
                >
                    {formik => (
                        <>
                            { modalMode && 
                                <BookListModal
                                    mode={modalMode}
                                    onSubmitCallback={onSubmitCallback}
                                    selectedGroup={formik.values.selectedGroup}
                                    selectedStudent={formik.values.selectedStudent}
                                    selectedBookList={formik.values.selectedBookList.obj}
                                />
                            }
                            <h3>Book List Manager</h3>
                            <br/>
                            <div className="flex flex-row gap-x-4">
                                <div className="w-1/6">
                                    <SelectSingle
                                        id="book-list-manager-center"
                                        value={formik.values.selectedCenter}
                                        name="selectedCenter"
                                        label="Filter Groups by Center"
                                        onChange={async (e) => {
                                            await formik.handleChange(e);
                                            filterGroups(e.target.value);
                                        }}
                                        options={centerOptions}
                                    />
                                </div>
                                <div className="w-1/4">
                                    <SelectSingle
                                        id="book-list-manager-group"
                                        value={formik.values.selectedGroup}
                                        name="selectedGroup"
                                        label="Group"
                                        onChange={(e) => {
                                            const newValues = { ...formik.values };
                                            newValues.selectedGroup = e.target.value;
                                            newValues.selectedStudent = { value: -1, label: 'Please select a student...' };
                                            newValues.selectedBookList = { value: -1, label: 'Please select a book list...' };
                                            formik.setValues(newValues);
                                        }}
                                        options={groupOptions}
                                    />
                                </div>
                                <div className="w-1/4">
                                    <SelectSingle
                                        id="book-list-manager-student"
                                        value={formik.values.selectedStudent}
                                        name="selectedStudent"
                                        label="Student"
                                        onChange={async (e) => {
                                            await formik.handleChange(e);
                                            await refreshData(formik.values.selectedGroup, e.target.value, formik.values.selectedBookList);
                                        }}
                                        options={formik.values.selectedGroup.obj?.studentOptions || [{ value: -1, label: 'Please select a student...' }]}
                                    />
                                </div>
                                <div className="w-1/3">
                                    <SelectSingle
                                        id="book-list-manager-bl"
                                        value={formik.values.selectedBookList}
                                        name="selectedBookList"
                                        label="Book List"
                                        onChange={formik.handleChange}
                                        options={bookListOptions}
                                    />
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-2">
                                <div className="self-center">
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setModalMode('create')}
                                        disabled={formik.values.selectedStudent.value === -1}
                                    >
                                        + Add Book List
                                    </Button>
                                </div>
                                { formik.values.selectedBookList.obj &&
                                    <>
                                        <div className="self-center ml-8">
                                            <Button
                                                color="lte-mpLBlue"
                                                onClick={() => setModalMode('edit')}
                                                disabled={formik.values.selectedGroup.value === -1}
                                            >
                                                Edit Book List
                                            </Button>
                                        </div>
                                        <div className="self-center ml-8">
                                            <Button
                                                color="lte-mpLRed"
                                                onClick={() => setModalMode('delete')}
                                                disabled={formik.values.selectedGroup.value === -1}
                                            >
                                                Delete Book List
                                            </Button>
                                        </div>
                                    </>
                                }
                            </div>
                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                                <>
                                    <br/>
                                    <hr/>
                                    <br/>

                                    { firstSearch && formik.values.selectedBookList.obj && 
                                        <BookListTable selectedBookList={formik.values.selectedBookList}/>
                                    }
                                </>
                            }
                        </>
                    )}
                </Formik>
            </div>
            {/* <Socket
                refreshData={() => null}
                page="Book Lists"
                setVersion={props.setVersion}
            /> */}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchRpCentersAll,
    fetchCycleGroupsActive,
    fetchBookListsStudents,
    fetchBooksAll,
    fetchStudentsAll
})(BookListManager)