import React from 'react';

import { Button, Label, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper, tableColors, renderPhone } from '../../../../../components/display';
import { formatDate, formatDateFull } from '../../../../../components/functions';

const activeLabel = <Label color="mpGreen">Active</Label>;
const inactiveLabel = <Label color="mpLRed">Inactive</Label>;

const noneTag = <span className="text-mpOrange">None</span>
export function getUserColumns(handleShowMemberModal){
    const permissionsMap = {
        "parent": 1,
        "student": 2,
        "None": 3
    };

    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};
    return(
        [
            {
                dataField: 'id',
                text: 'UUID',
                sort: true,
                formatter: (row) => {
                    return(
                        <TooltipWrapper
                            tooltipText={row.id}
                        >
                            <div className="text-mpLBlue">
                                [ID]
                            </div>
                        </TooltipWrapper>
                    )
                }
            }, {
                dataField: 'name',
                text: 'Name',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const aName = `${rowA.first_name} ${rowA.last_name}`;
                    const bName = `${rowB.first_name} ${rowB.last_name}`;
                    const swap = order === 'asc' ? 1 : -1;
        
                    if(aName < bName) return -1 * swap;
                    else if(aName > bName) return 1 * swap;
                    return 0;
                },
                formatter: (row) => {
                    const name = `${row.first_name} ${row.last_name}`;
                    if(row.mp_permissions === 'Parent' || row.rp_permissions === 'Parent'){
                        return (
                            <TooltipWrapper
                                tooltipText={
                                    <>
                                        <div>Students:</div>
                                        {row.studentList ? 
                                            <div>{row.studentList.join(', ')}</div> :
                                            noneTag
                                        }
                                    </>
                                }
                            >
                                <div className="text-mpLBlue">{name}</div>
                            </TooltipWrapper>
                        );
                    } else if(row.mp_permissions === 'Student' || row.rp_permissions === 'Student'){
                        const parent = row.parentObject;
                        if(!parent.first_name && !parent.last_name) return name;
                        
                        return (
                            <TooltipWrapper
                                tooltipText={
                                    <>
                                        <div>Parent: {parent.first_name} {parent.last_name}</div>
                                    </>
                                }
                            >
                                <div className="text-mpLBlue">{name}</div>
                            </TooltipWrapper>
                        );
                    }
                }
            }, {
                dataField: 'email',
                text: 'Email',
                sort: true,
            }, {
                dataField: 'phone',
                text: 'Phone Number',
                sort: true,
                formatter: (row) => {
                    let number = row.phone.toString();
                    if(!number.length || number === '0' || number === '0000000000'){
                        return noneTag;
                    }
                    return renderPhone(number);
                }
            }, {
                dataField: 'primary_center',
                text: 'Primary Center',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const sortBy = order === 'asc' ? 'mp' : 'rp';
                    if(sortBy === 'mp'){
                        if(a.mpCenterName < b.mpCenterName) return -1;
                        else if(a.mpCenterName > b.mpCenterName) return 1;
                        else return 0;
                    } else if (sortBy === 'rp'){
                        if(a.rpCenterName < b.rpCenterName) return -1;
                        else if(a.rpCenterName > b.rpCenterName) return 1;
                        else return 0;
                    }
                },
                formatter: (row) => {
                    const mpCenter = parseInt(row.mp_primary_center);
                    const rpCenter = parseInt(row.rp_primary_center);
                    let mpCenterColor = 'mpLGrey';
                    let rpCenterColor = 'mpLGrey';
                    if(mpCenter !== -1){
                        if(colorMap[mpCenter]) mpCenterColor = colorMap[mpCenter];
                        else {
                            colorMap[mpCenter] = colors[(colorIndex++) % 5];
                            mpCenterColor = colorMap[mpCenter];
                        }
                    }
                    if(rpCenter !== -1){
                        if(colorMap[rpCenter]) rpCenterColor = colorMap[rpCenter];
                        else {
                            colorMap[rpCenter] = colors[(colorIndex++) % 5];
                            rpCenterColor = colorMap[rpCenter];
                        }
                    }
                    return (
                        <div className="flex-col">
                            <div>MP: <Label color={mpCenterColor}>{row.mpCenterName}</Label></div>

                            <div className="h-3"/>

                            <div>RP: <Label color={rpCenterColor}>{row.rpCenterName}</Label></div>
                        </div>
                    );
                }
            }, {
                dataField: 'permissions',
                text: 'Permissions',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? 1 : -1;
                    const mpPermissionsA = rowA.mp_permissions;
                    const mpPermissionsB = rowB.mp_permissions;
                    const rpPermissionsA = rowA.rp_permissions;
                    const rpPermissionsB = rowB.rp_permissions;

                    const priorityA = permissionsMap[mpPermissionsA.toLowerCase()] || 99 + permissionsMap[rpPermissionsA.toLowerCase()] || 99;
                    const priorityB = permissionsMap[mpPermissionsB.toLowerCase()] || 99 + permissionsMap[rpPermissionsB.toLowerCase()] || 99;
                    return (priorityA - priorityB) * swap;
                },
                formatter: (row) => {
                    const { mp_permissions, rp_permissions } = row;

                    let mpColor = 'mpLGrey';
                    if(mp_permissions === 'Parent') mpColor = 'mpBlue';
                    else if(mp_permissions === 'Student') mpColor = 'mpOrange';
                    const mpPermissions = <Label color={mpColor}>{mp_permissions}</Label>;

                    let rpColor = 'mpLGrey';
                    if(rp_permissions === 'Parent') rpColor = 'mpBlue';
                    else if(rp_permissions === 'Student') rpColor = 'mpOrange';
                    const rpPermissions = <Label color={rpColor}>{rp_permissions}</Label>;

                    return (
                        <div className="col no-pad">
                            <div>MP: {mpPermissions}</div>

                            <div style={{ height: "0.7rem" }}/>

                            <div>RP: {rpPermissions}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'active',
                text: 'Active',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? 1 : -1;

                    const rpSort = rowB.rp_active - rowA.rp_active;
                    const mpSort = rowB.mp_active - rowA.mp_active;
                    return (rpSort || mpSort) * swap;
                },
                formatter: (row) => {
                    const mpActive = parseInt(row.mp_active) === 1 ? activeLabel : inactiveLabel;
                    const rpActive = parseInt(row.rp_active) === 1 ? activeLabel : inactiveLabel;
                    return (
                        <div className="col no-pad">
                            <div>MP: {mpActive}</div>

                            <div style={{ height: "0.7rem" }}/>

                            <div>RP: {rpActive}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'actions',
                text: 'Actions',
                formatter: (row) => {
                    return (
                        <div className="flex flex-row gap-x-2">
                            <TooltipWrapper
                                tooltipText={`Edit ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    className="btn btn-icon btn-sm"
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowMemberModal('edit', row)}
                                    style={{ marginRight: "5px" }}
                                >
                                    <SVGIcon fileName="pencil"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Delete ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    className="btn btn-icon btn-sm"
                                    color="lte-mpLRed"
                                    variant="icon"
                                    onClick={() => handleShowMemberModal('delete', row)}
                                >
                                    <SVGIcon fileName="trash"/>
                                </Button>
                            </TooltipWrapper>
                        </div>
                    );
                }
            }
        ]
    );
}

const gradeColorMap = {
    0: "mpGreen",
    1: "mpGreen",
    2: "mpGreen",
    3: "mpTeal",
    4: "mpTeal",
    5: "mpTeal",
    6: "mpDBlue",
    7: "mpDBlue",
    8: "mpDBlue",
    9: "mpOrange",
    10: "mpOrange",
    11: "mpOrange",
    12: "mpOrange",
    13: "mpPurple",
    "-1": "mpLGrey" // None
};

const gradeNameMap = {
    0: 'Pre-K/Kinder',
    1: '1st',
    2: '2nd',
    3: '3rd',
    4: '4th',
    5: '5th',
    6: '6th',
    7: '7th',
    8: '8th',
    9: '9th',
    10: '10th',
    11: '11th',
    12: '12th',
    13: 'HS Grad',
};

export function getStudentColumns(handleShowStudentModal){
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return [
        {
            dataField: 'id',
            text: 'UUID',
            sort: true,
            formatter: (row) => {
                return(
                    <TooltipWrapper
                        tooltipText={row.user_id}
                    >
                        <div className="text-mpLBlue">
                            [ID]
                        </div>
                    </TooltipWrapper>
                )
            }
        }, {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const aName = `${rowA.first_name} ${rowA.last_name}`;
                const bName = `${rowB.first_name} ${rowB.last_name}`;
                const swap = order === 'asc' ? 1 : -1;
    
                if(aName < bName) return -1 * swap;
                else if(aName > bName) return 1 * swap;
                return 0;
            },
            formatter: (row) => {
                const isLead = parseInt(row.is_lead) === 1 ? ' (Lead)' : '';
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="col">
                                <div>Phone:</div>
                                <div>{renderPhone(row.userObject.phone)}</div>
                                <br/>
                                <div>Email:</div>
                                <div>{row.email || noneTag}</div>
                                <br/>
                                <div>Notes:</div>
                                <div> {row.userObject.user_notes || noneTag}</div>
                            </div>
                        }
                    >
                        <div className="text-mpLBlue">{row.first_name} {row.last_name}{isLead}</div>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'parentObject',
            text: 'Parent',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                if(a.first_name < b.first_name) return -1 * swap;
                else if(a.first_name > b.first_name) return 1 * swap;
                else return 0;
            },
            formatter: (row) => {
                const cell = row.parentObject;
                if(!cell.first_name && !cell.last_name) return noneTag;

                const isLead = cell.isLead ? ' (Lead)' : '';
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="col">
                                <div>Phone:</div>
                                <div>{renderPhone(cell.phone)}</div>
                                <br/>
                                <div>Email:</div>
                                <div>{cell.email || noneTag}</div>
                                <br/>
                                <div>Notes:</div>
                                <div> {cell.user_notes || noneTag}</div>
                            </div>
                        }
                    >
                        <div className="text-mpLBlue">{cell.first_name} {cell.last_name}{isLead}</div>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'grade',
            text: 'Grade',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aVal = parseInt(a);
                const bVal = parseInt(b);
                if(isNaN(aVal)) return 1 * swap;
                else if(isNaN(bVal)) return -1 * swap;
                return (aVal - bVal) * swap;
            },
            formatter: (row) => {
                const cell = row.grade;
                const color = gradeColorMap[parseInt(cell)] || "mpLRed";
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="col">
                                <div>Teacher: {row.mp_teacher || noneTag}</div>
                                <div>School: {row.school || noneTag}</div>
                            </div>
                        }
                    >
                        <span className={`label label-lg label-inline label-light-${color}`}>
                            {(color === "mpLRed" ? cell : gradeNameMap[parseInt(cell)]) || noneTag}
                        </span>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'contract',
            text: 'Contract',
            formatter: (row) => {
                const cell = row.contract;
                if(!cell.id) return <div className="text-mpLRed">None</div>;
                const expiresContent = parseInt(cell.expires) === 1 ?
                    <>
                        <div>Start: {formatDate(cell.start_date)}</div>
                        <div>End: {formatDate(cell.end_date)}</div>
                    </> :
                    <div>Never Expires</div>
                return (
                    <TooltipWrapper
                        tooltipText={
                            <div className="col">
                                <div>Type: {cell.type}</div>
                                {expiresContent}
                                <div>Payment Freq: {cell.payment_frequency}</div>
                                <div>PD Offset: {cell.payment_date_offset} days</div>
                                <div>$ Per Payment: ${cell.amount_per_payment}</div>
                                <div>Show Warnings: {parseInt(cell.show_warnings) === 1 ? 'Yes' : 'No'}</div>
                                <div>Warn Members: {parseInt(cell.warn_members) === 1 ? 'Yes' : 'No'}</div>
                                <br/>
                                <div>Notes: {cell.notes.length > 100 ? `${cell.notes.slice(0, 100)}...` : cell.notes}</div>
                            </div>
                        }
                    >
                        <div className="max-h-6 max-w-6">
                            <SVGIcon fileName="contract"/>
                        </div>
                    </TooltipWrapper>
                );
            }
        }, {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                const swap = order === 'asc' ? 1 : -1;
                if(rowA.rp_active !== rowB.rp_active) return (rowA.rp_active - rowB.rp_active) * swap;
                return 0;
            },
            formatter: (row) => {
                const statusLabel = row.rp_active ? 
                    <TooltipWrapper
                        tooltipText={
                            <div>
                                <div>Inactive Date:</div>
                                <div>
                                    {(new Date(row.mp_inactive_date)).getFullYear() >= 2100 ? 'Never' :
                                    formatDateFull(row.mp_inactive_date)}
                                </div>
                            </div>
                        }
                    >
                        {activeLabel}
                    </TooltipWrapper>
                    : inactiveLabel;
                return <div className="flex flex-row">{statusLabel}</div>
            }
        }, {
            dataField: 'driveLinks',
            text: 'Drive Links',
            formatter: (row) => {
                const name1 = row.rp_drive_folder_link_1_name || 'Unnamed';
                const studentName = `${row.first_name} ${row.last_name}`;
                const driveLink1 = row.rp_drive_folder_link_1 ? (
                    <TooltipWrapper
                        tooltipText={`View ${studentName}'s drive folder (${name1})`}
                    >
                        <Button
                            color="lte-mpEGreen"
                            variant="icon"
                            onClick={() => window.open(row.rp_drive_folder_link_1, '_blank')}
                        >
                            <SVGIcon fileName="external-link"/>
                        </Button>
                    </TooltipWrapper>
                ) : null;
                const name2 = row.rp_drive_folder_link_2_name || 'Unnamed';
                const driveLink2 = row.rp_drive_folder_link_2 ? (
                    <TooltipWrapper
                        tooltipText={`View ${studentName}'s drive folder (${name2})`}
                    >
                        <Button
                            color="lte-mpEGreen"
                            variant="icon"
                            onClick={() => window.open(row.rp_drive_folder_link_2, '_blank')}
                        >
                            <SVGIcon fileName="external-link"/>
                        </Button>
                    </TooltipWrapper>
                ) : null;
                const name3 = row.rp_drive_folder_link_3_name || 'Unnamed';
                const driveLink3 = row.rp_drive_folder_link_3 ? (
                    <TooltipWrapper
                        tooltipText={`View ${studentName}'s drive folder (${name3})`}
                    >
                        <Button
                            color="lte-mpEGreen"
                            variant="icon"
                            onClick={() => window.open(row.rp_drive_folder_link_3, '_blank')}
                        >
                            <SVGIcon fileName="external-link"/>
                        </Button>
                    </TooltipWrapper>
                ) : null;
                const name4 = row.rp_drive_folder_link_4_name || 'Unnamed';
                const driveLink4 = row.rp_drive_folder_link_4 ? (
                    <TooltipWrapper
                        tooltipText={`View ${studentName}'s drive folder (${name4})`}
                    >
                        <Button
                            color="lte-mpEGreen"
                            variant="icon"
                            onClick={() => window.open(row.rp_drive_folder_link_4, '_blank')}
                        >
                            <SVGIcon fileName="external-link"/>
                        </Button>
                    </TooltipWrapper>
                ) : null;
                
                if(driveLink1 || driveLink2 || driveLink3 || driveLink4){
                    return(
                        <div className="flex flex-row gap-x-2">
                            {driveLink1}
                            {driveLink2}
                            {driveLink3}
                            {driveLink4}
                        </div>
                    );
                } else {
                    return(
                        <TooltipWrapper
                            tooltipText="No drive folder path provided"
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => null}
                                disabled
                            >
                                <SVGIcon fileName="unlink"/>
                            </Button>
                        </TooltipWrapper>
                    );
                }
            }
        }, {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit ${row.first_name} ${row.last_name}`}
                        >
                            <Button
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowStudentModal(row)}
                                style={{ marginRight: "5px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            },
        }
    ];
}

export function getLeadColumns(handleShowLeadModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    const permissionsMap = {
        "parent": 1,
        "student": 2,
        "None": 3
    };
    return(
        [
            {
                dataField: 'id',
                text: 'UUID',
                sort: true,
                formatter: (row) => {
                    return(
                        <TooltipWrapper
                            tooltipText={row.id}
                        >
                            <div className="text-mpLBlue">
                                [ID]
                            </div>
                        </TooltipWrapper>
                    )
                }
            }, {
                dataField: 'name',
                text: 'Name',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const aName = `${rowA.first_name} ${rowA.last_name}`;
                    const bName = `${rowB.first_name} ${rowB.last_name}`;
                    const swap = order === 'asc' ? 1 : -1;
        
                    if(aName < bName) return -1 * swap;
                    else if(aName > bName) return 1 * swap;
                    return 0;
                },
                formatter: (row) => {
                    const name = `${row.first_name} ${row.last_name}`;
                    if(row.mp_permissions === 'Parent' || row.rp_permissions === 'Parent'){
                        return (
                            <TooltipWrapper
                                tooltipText={
                                    <>
                                        <div>Students:</div>
                                        {row.studentList ? 
                                            <div>{row.studentList.join(', ')}</div> :
                                            noneTag
                                        }
                                    </>
                                }
                            >
                                <div className="text-mpLBlue">{name}</div>
                            </TooltipWrapper>
                        );
                    } else if(row.mp_permissions === 'Student' || row.rp_permissions === 'Student'){
                        return name;
                    }
                }
            }, {
                dataField: 'email',
                text: 'Email',
                sort: true,
            }, {
                dataField: 'phone',
                text: 'Phone Number',
                sort: true,
                formatter: (row) => {
                    let number = row.phone.toString();
                    if(!number.length || number === '0' || number === '0000000000'){
                        return <Label color="mpLRed">None</Label>;
                    }
                    return renderPhone(number);
                }
            }, {
                dataField: 'primary_center',
                text: 'Primary Center',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const sortBy = order === 'asc' ? 'mp' : 'rp';
                    if(sortBy === 'mp'){
                        if(a.mpCenterName < b.mpCenterName) return -1;
                        else if(a.mpCenterName > b.mpCenterName) return 1;
                        else return 0;
                    } else if (sortBy === 'rp'){
                        if(a.rpCenterName < b.rpCenterName) return -1;
                        else if(a.rpCenterName > b.rpCenterName) return 1;
                        else return 0;
                    }
                },
                formatter: (row) => {
                    const mpCenter = parseInt(row.mp_primary_center);
                    const rpCenter = parseInt(row.rp_primary_center);
                    let mpCenterColor = 'mpLGrey';
                    let rpCenterColor = 'mpLGrey';
                    if(mpCenter !== -1){
                        if(colorMap[mpCenter]) mpCenterColor = colorMap[mpCenter];
                        else {
                            colorMap[mpCenter] = colors[(colorIndex++) % 5];
                            mpCenterColor = colorMap[mpCenter];
                        }
                    }
                    if(rpCenter !== -1){
                        if(colorMap[rpCenter]) rpCenterColor = colorMap[rpCenter];
                        else {
                            colorMap[rpCenter] = colors[(colorIndex++) % 5];
                            rpCenterColor = colorMap[rpCenter];
                        }
                    }
                    return (
                        <div className="flex-col">
                            <div>MP: <Label color={mpCenterColor}>{row.mpCenterName}</Label></div>

                            <div className="h-3"/>

                            <div>RP: <Label color={rpCenterColor}>{row.rpCenterName}</Label></div>
                        </div>
                    );
                }
            }, {
                dataField: 'permissions',
                text: 'Permissions',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? 1 : -1;
                    const mpPermissionsA = rowA.mp_permissions;
                    const mpPermissionsB = rowB.mp_permissions;
                    const rpPermissionsA = rowA.rp_permissions;
                    const rpPermissionsB = rowB.rp_permissions;

                    const priorityA = permissionsMap[mpPermissionsA.toLowerCase()] || 99 + permissionsMap[rpPermissionsA.toLowerCase()] || 99;
                    const priorityB = permissionsMap[mpPermissionsB.toLowerCase()] || 99 + permissionsMap[rpPermissionsB.toLowerCase()] || 99;
                    return (priorityA - priorityB) * swap;
                },
                formatter: (row) => {
                    const { mp_permissions, rp_permissions } = row;

                    let mpColor = 'mpLGrey';
                    if(mp_permissions === 'Parent') mpColor = 'mpBlue';
                    else if(mp_permissions === 'Student') mpColor = 'mpOrange';
                    const mpPermissions = <span className={`label labl-lg label-inline label-light-${mpColor}`}>{mp_permissions}</span>;

                    let rpColor = 'mpLGrey';
                    if(rp_permissions === 'Parent') rpColor = 'mpBlue';
                    else if(rp_permissions === 'Student') rpColor = 'mpOrange';
                    const rpPermissions = <span className={`label labl-lg label-inline label-light-${rpColor}`}>{rp_permissions}</span>;

                    return (
                        <div className="col no-pad">
                            <div>MP: {mpPermissions}</div>

                            <div style={{ height: "0.7rem" }}/>

                            <div>RP: {rpPermissions}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'active',
                text: 'Active',
                sort: true,
                sortFunc: (a, b, order, dataField, rowA, rowB) => {
                    const swap = order === 'asc' ? 1 : -1;

                    const rpSort = rowB.rp_active - rowA.rp_active;
                    const mpSort = rowB.mp_active - rowA.mp_active;
                    return (rpSort || mpSort) * swap;
                },
                formatter: (row) => {
                    const mpActive = parseInt(row.mp_active) === 1 ? activeLabel : inactiveLabel;
                    const rpActive = parseInt(row.rp_active) === 1 ? activeLabel : inactiveLabel;
                    return (
                        <div className="col no-pad">
                            <div>MP: {mpActive}</div>

                            <div style={{ height: "0.7rem" }}/>

                            <div>RP: {rpActive}</div>
                        </div>
                    );
                }
            }, {
                dataField: 'actions',
                text: 'Actions',
                formatter: (row) => {
                    return (
                        <div className="flex flex-row gap-x-2">
                            <TooltipWrapper
                                tooltipText={`Edit ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    className="btn btn-icon btn-sm"
                                    color="lte-mpLBlue"
                                    variant="icon"
                                    onClick={() => handleShowLeadModal('edit', row)}
                                >
                                    <SVGIcon path="new/pencil"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Delete ${row.first_name} ${row.last_name}`}
                            >
                                <Button
                                    className="btn btn-icon btn-sm"
                                    color="lte-mpLRed"
                                    variant="icon"
                                    onClick={() => handleShowLeadModal('delete', row)}
                                >
                                    <SVGIcon path="new/trash"/>
                                </Button>
                            </TooltipWrapper>
                            <TooltipWrapper
                                tooltipText={`Convert ${row.first_name} ${row.last_name} into a Member User`}
                            >
                                <Button
                                    className="btn btn-icon btn-sm"
                                    color="lte-mpYellow"
                                    variant="icon"
                                    onClick={() => handleShowLeadModal('convert', row)}
                                >
                                    <SVGIcon path="new/Random"/>
                                </Button>
                            </TooltipWrapper>
                        </div>
                    );
                }
            }
        ]
    );
}