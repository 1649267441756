import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { Button } from '../../../../components/custom-essentials';
import { DateRangeSelector, SelectSingle, FormikControl, checkResponses } from '../../../../components/form';
import { formatDateApi, getymdhms } from '../../../../components/functions';
import { BrowserTabTitle, LoadingOverlay, TooltipWrapper } from '../../../../components/display';
import { CSVExport } from '../../../../components/export';
import AppointmentsTable from "./AppointmentsTable";
import { Socket } from '../../../../components/ws';

import {
    fetchRpCentersAll,
    fetchStudentsAll,
    fetchCycleGroupsIds,
    fetchMembersAll,
    fetchAdminUsersAll,
    fetchAppointmentsDaterangeCenterStudentName,
} from '../../../../actions';

const start = new Date();
start.setDate(start.getDate() - 7);
const startApi = formatDateApi(start);
const end = new Date();
end.setDate(end.getDate() + 14);
const endApi = formatDateApi(end);

function AppointmentSearch(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    const formRef = useRef();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);
    // Form
    const [drsValid, setDrsValid] = useState(true);
    // Data
    const [students, setStudents] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [members, setMembers] = useState([]);
    const [centers, setCenters] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [appointments, setAppointments] = useState([]);

    const { fetchRpCentersAll, fetchStudentsAll, fetchCycleGroupsIds, fetchMembersAll, fetchAdminUsersAll,
        fetchAppointmentsDaterangeCenterStudentName } = props;

    const hoursInfo = useMemo(() => {
        let scheduled = 0;
        let scheduledH = 0;
        let inProgress = 0;
        let inProgressH = 0;
        let completed = 0;
        let completedH = 0;
        let missed = 0;
        let missedH = 0;
        let cancelled = 0;
        let cancelledH = 0;
        let notCharged = 0;
        let notChargedH = 0;

        appointments.forEach(a => {
            switch(a.status){
                case 'Scheduled':
                    scheduled++;
                    scheduledH += parseInt(a.duration);
                    break;
                case 'In Progress':
                    inProgress++;
                    inProgressH += parseInt(a.duration);
                    break;
                case 'Completed':
                    completed++;
                    completedH += parseInt(a.duration);
                    break;
                case 'Missed':
                    missed++;
                    missedH += parseInt(a.duration);
                    break;
                case 'Cancelled':
                    cancelled++;
                    cancelledH += parseInt(a.duration);
                    break;
                case 'Not Charged':
                    notCharged++;
                    notChargedH += parseInt(a.duration);
                    break;
                default:
                    break;
            }
        });

        scheduledH /= 60;
        inProgressH /= 60;
        completedH /= 60;
        missedH /= 60;
        cancelledH /= 60;
        notChargedH /= 60;

        const total = scheduled + inProgress + completed + missed + notCharged;
        const totalH = scheduledH + inProgressH + completedH + missedH + notChargedH;

        return { scheduled, scheduledH, inProgress, inProgressH, completed, completedH,
            missed, missedH, cancelled, cancelledH, notCharged, notChargedH, total, totalH };
    }, [appointments]);

    const refreshData = useCallback(() => {
        (async function refresh(){
            if(loading || !drsValid || !formRef.current.values) return;
            if(mounted.current) setLoading(true);
    
            const { startDate, endDate, selectedCenter, searchQuery } = formRef.current.values;
    
            const aaRes = await fetchAppointmentsDaterangeCenterStudentName({
                startDate: startDate,
                endDate: endDate,
                center: selectedCenter.value,
                searchQuery
            });
            const isApiError = checkResponses(aaRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please try again later.');
                    setLoading(false);
                }
                return;
            } else setApiError(false);

            const newAppointments = aaRes.data?.appointments || [];
            const newInstructorAssignments = aaRes.data?.assignments || [];

            const groupIds = [];
            newAppointments.forEach(a => {
                const groupId = parseInt(a.group_id);
                if(!groupIds.includes(groupId)) groupIds.push(groupId);
            });
            const cycleGroupsRes = await fetchCycleGroupsIds({ ids: groupIds });
            const newCycleGroups = cycleGroupsRes.data?.groups;

            const appointmentToIaMap = {};
            newInstructorAssignments.forEach(ia => {
                if(!appointmentToIaMap[parseInt(ia.appointment_id)]) appointmentToIaMap[parseInt(ia.appointment_id)] = [];
                appointmentToIaMap[parseInt(ia.appointment_id)].push(ia.instructor);
            });
    
            const studentToNameMap = {};
            students.forEach(s => studentToNameMap[s.user_id] = `${s.first_name} ${s.last_name}`);
            const userToNameMap = {};
            employees.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
            members.forEach(e => userToNameMap[e.id] = `${e.first_name} ${e.last_name}`);
            const centerMap = {};
            centers.forEach(c => centerMap[parseInt(c.id)] = c.name);
            const cycleGroupNameMap = {};
            newCycleGroups.forEach(cg => cycleGroupNameMap[parseInt(cg.id)] = cg.name);

            newAppointments.forEach(a => {
                const [year, month, date, hours, minutes] = getymdhms(new Date(a.date_time));
                a.date = `${year}-${month}-${date} 00:00:00`;
                a.time = hours * 60 + minutes * 1;

                a.studentName = studentToNameMap[a.student] || `Unknown student (UID: ${a.student})`;
                a.groupName = cycleGroupNameMap[parseInt(a.group_id)] || `Unknown group (ID ${a.group_id})`;
                a.centerName = centerMap[parseInt(a.center)] || `Unknown center (ID: ${a.center})`;
    
                const assignmentList = appointmentToIaMap[parseInt(a.id)] || [];
                if(!assignmentList.length) a.instructorNames = <span className="text-mpOrange">None</span>;
                else {
                    let names = '';
                    assignmentList.forEach(a => {
                        if(names.length) names += ', ';
                        const newName = userToNameMap[a];
                        names += newName || '??';
                    });
                    a.instructorNames = names;
                }

                a.createdByName = userToNameMap[a.created_by] || `Unknown user (UUID: ${a.created_by})`;
                a.updatedByName = userToNameMap[a.updated_by] || `Unknown user (UUID: ${a.updated_by})`;
            });
            
            if(mounted.current){
                setAppointments(newAppointments);
                setLoading(false);
            }
        })();
    }, [mounted, loading, centers, employees, members, students, drsValid, fetchCycleGroupsIds,
        fetchAppointmentsDaterangeCenterStudentName]);
    useEffect(() => {
        async function init(){
            if(mounted.current) setLoading(true);

            const centersRes = await fetchRpCentersAll();
            const studentsRes = await fetchStudentsAll();
            const instructorsRes = await fetchAdminUsersAll();
            const membersRes = await fetchMembersAll();
            const isApiError = checkResponses(centersRes, studentsRes, instructorsRes, membersRes);
            if(isApiError){
                if(mounted.current){
                    setApiError('Error fetching data from the server. Please refresh the page or try again later.');
                    setLoading(false);
                    setHasLoaded(true);
                }
                return;
            }

            const newCenters = centersRes.data || [];
            const newStudents = studentsRes.data || [];
            const newEmployees = instructorsRes.data || [];
            const newMembers = membersRes.data || [];

            const newCenterOptions = [
                { value: 'all', label: 'All'},
                ...newCenters.map(c => ({ value: parseInt(c.id), label: c.name })) 
            ];            

            if(mounted.current){
                await setLoading(false);

                await setCenters(newCenters);
                await setCenterOptions(newCenterOptions);
                await setStudents(newStudents);
                await setEmployees(newEmployees);
                await setMembers(newMembers);
            
                setHasLoaded(true);
            }
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="page-box">
            <BrowserTabTitle>Appointment Search</BrowserTabTitle>
            {loading && <LoadingOverlay/>}
            <div className="card">
                <h3>Appointment Search ({appointments.length})</h3>
                <br/>
                <Formik
                    enableReinitialize
                    initialValues={{
                        startDate: startApi,
                        endDate: endApi,
                        selectedCenter: centerOptions[0] || { value: -1, label: 'Loading centers...' },
                        searchQuery: ''
                    }}
                    onSubmit={refreshData}
                    innerRef={formRef}
                >
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div>
                                    <DateRangeSelector
                                        id="appointmentSearch-drs-1"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        onChangeValidation={setDrsValid}
                                    />
                                </div>
                                <div className="w-1/3">
                                    <SelectSingle
                                        id="appointmentSearch-center"
                                        label="Center"
                                        name="selectedCenter"
                                        value={formik.values.selectedCenter}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                </div>
                            </div>

                            <br/>

                            {apiError ? <div className="text-mpLRed">{apiError}</div> :
                                <div className="flex flex-row gap-x-4 items-end">
                                    <div className="w-1/2">
                                        <FormikControl
                                            id="appointmentSearch-query"
                                            name="searchQuery"
                                            label="Search by student name"
                                            placeholder="Enter a search query..."
                                            value={formik.values.searchQuery}
                                            onChange={formik.handleChange}
                                            shouldHandleSubmit={true}
                                            onSubmit={formik.handleSubmit}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            color="lte-mpTeal"
                                            onClick={formik.handleSubmit}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                    <div className="ml-auto">
                                        <TooltipWrapper
                                            tooltipText={
                                                <div>
                                                    <div>
                                                        What gets exported?
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        All appointments that are currently filtered ({appointments.length} items).
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <CSVExport
                                                title="Appointments"
                                                label="Export Appointments to CSV"
                                                data={appointments}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </div>
                            }
                        </form>
                    )}
                </Formik>

                {
                    appointments.length >= 2000 ? 
                    <>
                        <br/>
                        <div className="text-mpLRed">Only the first 2000 results were returned.</div>
                    </>
                    : null
                }
                { apiError ? null : 
                    <div className="text-sm">
                        <br/>

                        <h4>Appointments Summary</h4>
                        <br/>
                        <div className="flex flex-row">
                            <div>
                                <b>Scheduled:</b> {hoursInfo.scheduled} ({hoursInfo.scheduledH} h)
                            </div>&nbsp;&nbsp;||&nbsp;&nbsp;
                            <div>
                                <b>In Progress:</b> {hoursInfo.inProgress} ({hoursInfo.inProgressH} h)
                            </div>&nbsp;&nbsp;||&nbsp;&nbsp;
                            <div>
                                <b>Completed:</b> {hoursInfo.completed} ({hoursInfo.completedH} h)
                            </div>
                        </div>
                        <div className="h-2 clear-both"/>
                        <div className="flex flex-row">
                            <TooltipWrapper tooltipText="Includes no-shows. These count towards a student's used hours.">
                                <div><span className="text-mpLBlue" ><b>Missed:</b></span> {hoursInfo.missed} ({hoursInfo.missedH} h)</div>
                            </TooltipWrapper>&nbsp;&nbsp;||&nbsp;&nbsp;
                            <TooltipWrapper tooltipText="These do not count towards a student's used hours.">
                                <div><span className="text-mpLBlue" ><b>Cancelled:</b></span> {hoursInfo.cancelled} ({hoursInfo.cancelledH} h)</div>
                            </TooltipWrapper>&nbsp;&nbsp;||&nbsp;&nbsp;
                            <TooltipWrapper tooltipText="Appointments where the student attended, but their hours were not charged.">
                                <div><span className="text-mpLBlue" ><b>Not Charged:</b></span> {hoursInfo.notCharged} ({hoursInfo.notChargedH} h)</div>
                            </TooltipWrapper>&nbsp;&nbsp;||&nbsp;&nbsp;
                            <TooltipWrapper tooltipText="Includes everything but cancelled appointments.">
                                <div><span className="text-mpLBlue" ><b>Total:</b></span> {hoursInfo.total} ({hoursInfo.totalH} h)</div>
                            </TooltipWrapper>
                        </div>

                        <br/>

                        {hasLoaded && 
                            <AppointmentsTable
                                appointments={appointments}
                                refreshData={refreshData}
                                permissions={props.auth.permissions}
                            />
                        }
                    </div>
                }
            </div>

            <Socket
                refreshData={refreshData}
                page="Appointment Search"
                setVersion={props.setVersion}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, {
    fetchRpCentersAll,
    fetchStudentsAll,
    fetchCycleGroupsIds,
    fetchMembersAll,
    fetchAdminUsersAll,
    fetchAppointmentsDaterangeCenterStudentName,
})(AppointmentSearch);