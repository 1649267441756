import React, { useRef, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { Modal } from '../../custom-essentials';
import { checkResponse } from '../../form';
import { formatDateApi, processRelInfo, getymdhms, checkLessonDatesValid } from '../../functions';
import { getStampChanges } from '../stamps/helpers';
import DetailsModalBodyFooter from './DetailsModalBF';

import {
    fetchRpCentersAll,
    fetchStampParams,
    fetchAdminUsersActive,
    fetchAppointmentsIds,
    fetchStudentsUserIds,
    fetchBookListsStudents,
    fetchBooksAll,
    fetchRelevantInfoMemberDaterangeStudentName,
    fetchFlagsDaterange,
    updateAppointmentDetails,
    updateBookListItems,
    updateStudentStamps,
    createStampsLog,
    createFlag,
} from '../../../actions';

function DetailsModal(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [attemptingClose, setAttemptingClose] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    // Prevents form from closing while submitting (only forms with accidental closure prevention)
    const [closureSubmitting, setClosureSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState({ errored: false, completed: false });
    const [oneSuccess, setOneSuccess] = useState(false);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const [centers, setCenters] = useState([]);
    const [stampParams, setStampParams] = useState({});
    const [relevantAssignments, setRelevantAssignments] = useState([]);
    const [appointment, setAppointment] = useState({});
    const [bookListOptions, setBookListOptions] = useState([]);
    const [relInfo, setRelInfo] = useState({});
    const [flags, setFlags] = useState([]);
    const [originalBLItemMap, setOriginalBLItemMap] = useState({}); // To check what has changed

    const { selectedAppointment, onSubmitCallback, fetchRpCentersAll, fetchStampParams, fetchBooksAll,
        fetchRelevantInfoMemberDaterangeStudentName, fetchFlagsDaterange, fetchAdminUsersActive, fetchAppointmentsIds,
        fetchStudentsUserIds, fetchBookListsStudents, updateAppointmentDetails, updateBookListItems,
        updateStudentStamps, createStampsLog, createFlag } = props;
    
    useEffect(() => {
        async function init(){
            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 3);
            const endDate = new Date();
            const startApi = formatDateApi(startDate);
            const endApi = formatDateApi(endDate);

            const centerRes = await fetchRpCentersAll();
            const stampParamsRes = await fetchStampParams();
            const appointmentRes = await fetchAppointmentsIds({ ids: [selectedAppointment.id] });
            const instructorsRes = await fetchAdminUsersActive();
            const studentRes = await fetchStudentsUserIds({ userIds: [selectedAppointment.student] });
            const bookListRes = await fetchBookListsStudents({ studentIds: [selectedAppointment.student] });
            const booksRes = await fetchBooksAll();
            const flagsRes = await fetchFlagsDaterange({ startDate: startApi, endDate: endApi });
            const newCenters = centerRes.data || [];
            const newStampParams = stampParamsRes.data?.[0] || {};
            const newAppointment = appointmentRes.data?.appointments?.[0] || {};
            const newRelevantAssignments = appointmentRes.data?.assignments || [];
            const newInstructors = instructorsRes.data || [];
            const newStudent = studentRes.data?.[0] || {};
            const newBookLists = bookListRes.data?.bookLists || [];
            const newBookListItems = bookListRes.data?.bookListItems || [];
            const newBooks = booksRes.data || [];
            const newFlags = flagsRes.data || [];

            const studentName = `${newStudent.first_name} ${newStudent.last_name}`;

            const newInstructorOptions = newInstructors.filter(e => {
                return ['Root', 'Admin', 'Lead Instructor', 'Instructor'].includes(e.rp_permissions);
            }).map(e => ({ value: e.id, label: `${e.first_name} ${e.last_name}`}));
            const instructorNameMap = {};
            newInstructors.forEach(i => instructorNameMap[i.id] = `${i.first_name} ${i.last_name}`);

            newFlags.forEach(f => f.userName = instructorNameMap[f.user] || `Unknown user (${f.user})`);

            const centerMap = {};
            newCenters.forEach(c => centerMap[c.id] = c.name);
            const aptDateTime = new Date(newAppointment.date_time);
            const [year, month, date, hours, minutes] = getymdhms(aptDateTime);
            newAppointment.date = `${year}-${month}-${date} 00:00:00`;
            newAppointment.time = hours * 60 + minutes * 1;
            newAppointment.centerName = centerMap[parseInt(newAppointment.center || -1)] || `Unknown center (ID: ${newAppointment.center})`;
            newAppointment.studentName = studentName;
            newAppointment.studentObject = newStudent;

            const relevantInfoRes = await fetchRelevantInfoMemberDaterangeStudentName({
                startDate: formatDateApi(startDate),
                endDate: formatDateApi(endDate),
                searchTerm: studentName
            });
            
            const newRelInfo = relevantInfoRes.data || {};

            const processedRelInfo = processRelInfo(newRelInfo, newCenters, newInstructors);

            // Book and book list stuff
            const bookMap = {};
            newBooks.forEach(b => bookMap[parseInt(b.id)] = b);

            const newBookListItemMap = {}; // To be used in formik
            const newOriginalBLItemMap = {}; // To check what has changed in handleSubmit
            newBookListItems.forEach(bli => {
                const bookId = parseInt(bli.book_id);
                
                bli.dateAssigned = bli.date_assigned ? formatDateApi(bli.date_assigned) : '';
                bli.dateCompleted = bli.date_completed ? formatDateApi(bli.date_completed) : '';
                const relevantBook = bookMap[bookId] || {};
                bli.name = relevantBook ? `${relevantBook.id} - ${relevantBook.title}` :
                    `Unknown lesbookson (${bli.book_id})`;

                const blId = parseInt(bli.book_list_id);

                if(!newBookListItemMap[blId]) newBookListItemMap[blId] = [];
                bli.orderIndex = newBookListItemMap[blId].length;
                bli.bookObj = relevantBook;
                newBookListItemMap[blId].push(bli);

                newOriginalBLItemMap[parseInt(bli.id)] = { ...bli };
            });
            
            const bookListItemMap = {};
            newBookListItems.filter(bli => {
                return bli.date_assigned;
            }).forEach(bli => {
                bli.dateAssigned = bli.date_assigned ? formatDateApi(bli.date_assigned) : '';
                bli.dateCompleted = bli.date_completed ? formatDateApi(bli.date_completed) : '';

                const relevantBook = bookMap[parseInt(bli.book_id)] || {};
                bli.name = relevantBook ? `${relevantBook.title}` : `Unknown book (${bli.book_id})`;

                const blId = parseInt(bli.book_list_id);
                
                if(!bookListItemMap[parseInt(blId)]) bookListItemMap[parseInt(blId)] = [];
                bli.orderIndex = bookListItemMap[blId].length;
                bookListItemMap[parseInt(blId)].push(bli);
            });

            newBookLists.forEach(bl => {
                bl.items = bookListItemMap[parseInt(bl.id)] || [];
            });
            const newBookListOptions = newBookLists.map(bl => ({ value: bl.id, label: bl.name, obj: bl }));

            if(mounted.current){
                setCenters(newCenters);
                setStampParams(newStampParams);
                setInstructorOptions(newInstructorOptions);
                setRelevantAssignments(newRelevantAssignments);
                setAppointment(newAppointment);
                setBookListOptions(newBookListOptions);
                setRelInfo(processedRelInfo);
                setFlags(newFlags);
                setOriginalBLItemMap(newOriginalBLItemMap);
                setLoaded(true);
            }
        };
        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = useCallback((changes, forceClose = false) => {
        if(!forceClose){
            if(changes !== true) changes = false;
            if(closureSubmitting) return;
            if(!attemptingClose){
                setAttemptingClose(true);
                return;
            }
        }
        (async function close(){
            await onSubmitCallback(changes || oneSuccess);
            if(mounted.current) setShowModal(false);
        })();
    }, [onSubmitCallback, attemptingClose, closureSubmitting, oneSuccess]);

    const handleSubmit = useCallback((values, actions) => {
        async function submit(){
            const { setStatus, setSubmitting } = actions;

            if(mounted.current){
                setSubmitting(true);
                setClosureSubmitting(true);
            }

            // Validate dynamic fields //
            let isValid = true;
            let erroredBookName = '';
            const bookLists = values.bookListOptions;
            const blItems = [];
            bookLists.forEach(bl => bl.obj.items?.forEach(bli => blItems.push(bli)));
            blItems.forEach(bli => {
                if(!checkLessonDatesValid(bli.dateAssigned, bli.dateCompleted)){
                    isValid = false;
                    erroredBookName = bli.name;
                    return;
                }
            });
            if(!isValid){
                setStatus(`The following book has an invalid date assigned or date completed: ${erroredBookName}. You might need to uncheck "Hide Completed" under "Active Learning Plans" in order to find the issue.`);
                setSubmitting(false);
                return;
            }
            // END - Validate dynamic field //

            // PREPARE DATA //
            const detailsParams = {
                id: appointment.id,
                instructors: values.instructors.map(si => si.value),
                vocabPages: values.vocabPages,
                participation: values.participation,
                answerAccuracy: values.answerAccuracy,
                depthOfThinking: values.depthOfThinking,
                stampsGiven: (values.stampsAmount > 0 || values.stampsGiven) ? 1 : 0,
                sessionNotes: values.sessionNotes,
                homeworkNotes: values.homeworkNotes,
                internalNotes: values.internalNotes,
                status: values.status.value,
            };

            // Check what has changed and add them to the array to be processed
            const bookListItemParams = [];
            blItems.forEach(bli => {
                const originalBli = originalBLItemMap[parseInt(bli.id)] || {};

                const datesChanged = (
                    bli.dateAssigned !== originalBli.dateAssigned ||
                    bli.dateCompleted !== originalBli.dateCompleted
                );
                if(datesChanged){
                    bookListItemParams.push({
                        id: parseInt(bli.id),
                        bookId: bli.book_id,
                        dateAssigned: bli.dateAssigned,
                        dateCompleted: bli.dateCompleted,
                        sortOrder: parseInt(bli.sort_order)
                    });
                }
            });

            const {
                newTotalCards,
                newCurrentCards,
                newCurrentStamps,
            } = getStampChanges(appointment.studentObject, { value: 'Give Stamps' }, values.stampsAmount, stampParams);
            let adjustedCurrentStamps = newTotalCards < 0 || newCurrentCards < 0 ? 0 : newCurrentStamps;

            const stampsParams = {
                student: appointment.student,
                currentStamps: adjustedCurrentStamps,
                currentCards: Math.max(newCurrentCards, 0),
                totalCards: Math.max(newTotalCards, 0),
            };

            const stampsLogParams = {
                student: appointment.student ,
                type: 'Give Stamps',
                amount: values.stampsAmount,
                notes: values.stampsNotes
            }

            const flagsParams = {
                user: values.flagUser.value,
                center: values.flagCenter.value,
                notes: values.flagNotes,
                followupNotes: '',
                priority: values.flagPriority.value,
                status: 'Pending'
            };
            // END-PREPARE DATA//

            // SEND DATA //
            const nss = { errored: false, completed: false };
            nss.appointmentDetails = { name: 'Appointment Details', completed: false, message: 'Pending...' };
            if(bookListItemParams.length) nss.bookListItems = { name: 'Book List Items', completed: false, message: 'Pending...' };
            if(values.createFlag) nss.flag = { name: 'Create Flag', completed: false, message: 'Pending...' };
            setSubmissionStatus({...nss});

            const uadResponse = await updateAppointmentDetails(detailsParams);
            nss.appointmentDetails.completed = true;
            nss.errored = !checkResponse(uadResponse, mounted, (resp) => nss.appointmentDetails.message = resp) || nss.errored;
            if(!nss.errored) nss.appointmentDetails.message = 'Success!';
            setSubmissionStatus({...nss});

            nss.upcomingExams = { name: 'Upcoming Exams', completed: false, message: '' };

            if(values.stampsAmount !== 0){
                nss.stamps = { name: 'Give Stamps', completed: false, message: '' };
                nss.stampsLog = { name: 'Create Stamps Log', completed: false, message: '' };
            }

            if(bookListItemParams.length){
                const ubliResponse = await updateBookListItems({ bookListItems: bookListItemParams });
                nss.bookListItems.completed = true;
                nss.errored = !checkResponse(ubliResponse, mounted, (resp) => nss.bookListItems.message = resp) || nss.errored;
                if(!nss.errored) nss.bookListItems.message = 'Success!';
                setSubmissionStatus({...nss});
            }

            if(values.stampsAmount !== 0){
                const ussResponse = await updateStudentStamps(stampsParams);
                nss.stamps.completed = true;
                nss.errored = !checkResponse(ussResponse, mounted, (resp) => nss.stamps.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
                
                const cslResponse = await createStampsLog(stampsLogParams);
                nss.stampsLog.completed = true;
                nss.errored = !checkResponse(cslResponse, mounted, (resp) => nss.stamps.message = resp) || nss.errored;
                setSubmissionStatus({...nss});
            }

            if(values.createFlag){
                const cfResponse = await createFlag(flagsParams);
                nss.flag.completed = true;
                nss.errored = !checkResponse(cfResponse, mounted, (resp) => nss.flag.message = resp) || nss.errored;
                if(!nss.errored) nss.flag.message = 'Success!';
                setSubmissionStatus({...nss});
            }

            // END-SEND DATA //
            setOneSuccess(oneSuccess || Object.values(nss).some(s => s.message === 'Success!'));

            nss.completed = true;

            if(nss.errored && mounted.current){
                setClosureSubmitting(false);
                setStatus(
                    `One or more errors occurred during submission.
                    Please be careful! Resubmitting this form will re-attempt all actions.
                    This includes updating the appointment,
                    giving student stamps, and creating a flag (if applicable).`
                );
                setSubmissionStatus(nss);
                return;
            }
            
            if(mounted.current){
                setClosureSubmitting(false);
                setSubmitted(true);
                setSubmitting(false);
            }
            setTimeout(() => handleClose(true, true), 1000);
        }
        submit();
    }, [handleClose, appointment, updateAppointmentDetails, updateStudentStamps, createStampsLog,
        originalBLItemMap, updateBookListItems, createFlag, oneSuccess, stampParams]);

    return (
        <Modal className="w-9/12" show={showModal} onHide={handleClose}>
            <Modal.Header>
                <h2>Appointment Details Form</h2>
            </Modal.Header>
            <Modal.BodyFooter>
                <DetailsModalBodyFooter
                    loaded={loaded}
                    attemptingClose={attemptingClose}
                    setAttemptingClose={setAttemptingClose}
                    centers={centers}
                    stampParams={stampParams}
                    selectedAppointment={appointment}
                    instructorOptions={instructorOptions}
                    relevantAssignments={relevantAssignments}
                    bookListOptions={bookListOptions}
                    relInfo={relInfo}
                    flags={flags}
                    submitted={submitted}
                    submissionStatus={submissionStatus}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            </Modal.BodyFooter>
        </Modal>
    );
}

export default connect(null, {
    fetchRpCentersAll,
    fetchStampParams,
    fetchAdminUsersActive,
    fetchAppointmentsIds,
    fetchStudentsUserIds,
    fetchBookListsStudents,
    fetchBooksAll,
    fetchRelevantInfoMemberDaterangeStudentName,
    fetchFlagsDaterange,
    updateAppointmentDetails,
    updateBookListItems,
    updateStudentStamps,
    createStampsLog,
    createFlag
})(DetailsModal);