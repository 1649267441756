import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, EllipsisLoader, ModalLoading } from '../../custom-essentials';
import { validationSchema, renderBody } from './helpers';
import { formatDateTime } from '../../functions';

function BookListTemplateBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, bookListTemplate, bookListTemplateItems, bookOptions,
        loaded, submitted, handleClose, handleSubmit, attemptingClose, setAttemptingClose } = props;

    const [deleteFirstConfirm, setDeleteFirstConfirm] = useState(false);

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this book list?</h4>
                            <div className="grid grid-rows-1">
                                <div><b>Book List Name:</b> {bookListTemplate.name}</div>
                                <div><b>Created:</b> {formatDateTime(bookListTemplate.date_created)}</div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <div>
                                        <span className="mr-2"><EllipsisLoader/></span>
                                        Processing...
                                    </div>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed font-xs mr-8">
                                        {formik.status}
                                    </div> : null
                                }
                                { deleteFirstConfirm ?
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={formik.handleSubmit}
                                            >
                                            Confirm Delete
                                        </Button>
                                        <Button
                                            color="lte-mpLBlue"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setDeleteFirstConfirm(false)}
                                        >
                                            Back
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Button
                                            color="lte-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="hol-mpLRed"
                                            disabled={formik.isSubmitting}
                                            onClick={() => setDeleteFirstConfirm(true)}
                                        >
                                            Delete
                                        </Button>
                                    </>
                                }
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    firstConfirm: false,
                    stagedToRemoveIndex: -1,
                    name: bookListTemplate.name || '',
                    notes: bookListTemplate.notes || '',
                    bookListTemplateItems,
                    active: parseInt(bookListTemplate.active) === 0 ? false : true
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    attemptingClose ? (
                        <ModalBodyFooter>
                            <ModalBodyFooter.Body>
                                <h4>
                                    Are you sure you would like to close the form?
                                </h4>
                            </ModalBodyFooter.Body>
                            <ModalBodyFooter.Footer>
                                <div className="flex flex-row gap-x-2 flex-wrap">
                                    <Button
                                        color="lte-mpLRed"
                                        onClick={handleClose}
                                    >
                                        Close Form
                                    </Button>
                                    <Button
                                        color="lte-mpLBlue"
                                        onClick={() => setAttemptingClose(false)}
                                    >
                                        Back to Form
                                    </Button>
                                </div>
                            </ModalBodyFooter.Footer>
                        </ModalBodyFooter>
                    ) : renderBody(formik, handleClose, bookOptions)
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(BookListTemplateBodyFooter);