import React, { useRef, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../components/functions";
import { Wait } from '../../components/wait';
import { EllipsisLoader } from '../custom-essentials';

export function LoadingOverlay(){
    const [timer, setTimer] = useState(0);
  
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
    
        return () => (mounted.current = false);
    });
  
    useEffect(() => {
        async function increaseCounter(){
          await Wait(1000);
          if(mounted.current) setTimer(timer + 1);
          else return;
        }
    
        increaseCounter();
    })
  
    return (
        <>
            <div className="z-[3001] fixed inset-0 bg-mpLGrey-1 bg-opacity-30 flex justify-center items-center">
                <div className="grid grid-cols-1">
                    <div className="flex justify-center">
                        <img
                            src={toAbsoluteUrl("/media/logos/lightshort.png")}
                            alt="ReadingPlex logo"
                        />
                    </div>
                    
                    <br/>

                    <div className="flex flex-row gap-x-12 justify-center">
                        <EllipsisLoader/>

                        <h2>{`Loading... ` + (timer >= 2 ? `(${timer} seconds elapsed)` : '')}</h2>
                        <br/>
                    </div>

                    {timer >= 5 ? <h4>This is taking a while... Either your connection is slow, or something is wrong with this page.</h4> : null}
                </div>
            </div>
        </>
    );
  }
  