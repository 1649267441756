import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, FormikControl, DatePicker } from '../../form';
import { validationSchema, typeOptions, getInitTypeOption, getInitStudentOption } from './helpers';
import { formatDate, formatDateApi } from '../../functions';

const initDate = formatDateApi(new Date());

function FlagsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedLog, studentOptions, loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this stamps log entry?</h4>
                            <div className="col">
                                <div>Student: {selectedLog.studentName}</div>
                                <div>Date: {formatDate(selectedLog.date)}</div>
                                <div>Type: {selectedLog.type}</div>
                                <div>Amount: {selectedLog.amount}</div>
                                <div>Notes: {selectedLog.notes || <span className="text-mpOrange">None</span>}</div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed font-sm">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    selectedDate: selectedLog.date ? formatDateApi(selectedLog.date) : initDate,
                    selectedStudent: getInitStudentOption(selectedLog.student, studentOptions),
                    selectedType: getInitTypeOption(selectedLog.type),
                    amount: selectedLog.amount || 0,
                    notes: selectedLog.notes || ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <div className="flex flex-row gap-x-4 items-start">
                                <div className="grid grid-cols-1 gap-y-2 w-5/12">
                                    <h4>Date</h4>
                                    <DatePicker
                                        id="stamps-log-selectedDate"
                                        name="selectedDate"
                                        value={formik.values.selectedDate}
                                        onChange={formik.handleChange}
                                        label="Select a date"
                                    />
                                    {formik.errors.selectedDate ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedDate}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <h4>Student</h4>
                                    <SelectSingle
                                        id="stamps-log-selectedStudent"
                                        name="selectedStudent"
                                        value={formik.values.selectedStudent}
                                        onChange={formik.handleChange}
                                        options={studentOptions}
                                    />
                                    {formik.errors.selectedStudent ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedStudent}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4 items-start">            
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Type</h4>
                                    <SelectSingle
                                        id="stamps-log-selectedType"
                                        name="selectedType"
                                        value={formik.values.selectedType}
                                        onChange={formik.handleChange}
                                        options={typeOptions}
                                    />
                                    {formik.errors.selectedType ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedType}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                    <h4>Amount</h4>
                                    <FormikControl
                                        id="stamps-log-amount"
                                        name="amount"
                                        placeholder="Min: 0, Max: 1000"
                                        value={formik.values.amount}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.amount ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.amount}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">            
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Notes</h4>
                                    <FormikControl
                                        id="stamps-log-notes"
                                        name="notes"
                                        value={formik.values.notes}
                                        onChange={formik.handleChange}
                                        as="textarea"
                                        placeholder="Notes about the transaction. Max 100 characters."
                                    />
                                    {formik.errors.notes ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.notes}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(FlagsBodyFooter);