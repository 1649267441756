import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Table } from '../../../../components/table';
import { AppointmentModal, DetailsModal } from '../../../../components/modal';

import { getColumns } from './table/getColumns';

const defaultSorted = [{ dataField: 'date', order: 'asc' }]

function AppointmentsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const [appointmentModalMode, setAppointmentModalMode] = useState(null);
    const [detailsModalMode, setDetailsModalMode] = useState(null);
    const [selectedAppointment, setSelectedAppointment] = useState(null);

    const { appointments, refreshData, permissions } = props;

    const handleShowAppointmentModal = useCallback((mode, appointment) => {
        if(mounted.current){
            setAppointmentModalMode(mode);
            setSelectedAppointment(appointment);
        }
    }, [mounted]);
    const handleShowDetailsModal = useCallback((mode, appointment) => {
        if(mounted.current){
            setDetailsModalMode(mode);
            setSelectedAppointment(appointment);
        }
    }, [mounted])
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setAppointmentModalMode(null);
            setDetailsModalMode(null);
            setSelectedAppointment(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    const columns = useMemo(() => getColumns(handleShowAppointmentModal, handleShowDetailsModal, permissions), 
        [handleShowAppointmentModal, handleShowDetailsModal, permissions]);

    return (
        <div>
            {appointmentModalMode &&
                <AppointmentModal
                    mode={appointmentModalMode}
                    selectedAppointment={selectedAppointment}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            {detailsModalMode &&
                <DetailsModal
                    selectedAppointment={selectedAppointment}
                    onSubmitCallback={onSubmitCallback}
                />
            }
            <Table
                tableName="appointmentSerach-appointments"
                data={appointments}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No appointments were found"
            />
        </div>
    );
}

export default connect(null, {

})(AppointmentsTable);