import React from 'react';

import { formatDateFull } from '.';
import { TooltipWrapper } from '../display';
import { SVGIcon } from '../custom-essentials';

export function getAlerts(appointment, hideAlerts = {}){
    let durationIcon = null;
    let specialIcon = null;
    let specialIcon2 = null;
    let pendingFlagIcon = null;

    if(!hideAlerts.duration){
        if(appointment.duration === 30){
            durationIcon = (
                <TooltipWrapper
                    tooltipText={`Appointment Length: ${appointment.duration} minutes`}
                >
                    <div className="flex flex-row mr-0.5">
                        <SVGIcon width="1.25rem" height="1.25rem" fileName="clock-green"/>
                    </div>
                </TooltipWrapper>
            );
        } else if(appointment.duration > 60){
            if(appointment.duration > 90){
                durationIcon = (
                    <TooltipWrapper
                        tooltipText={`Appointment Length: ${appointment.duration} minutes`}
                    >
                        <div className="flex flex-row mr-0.5">
                            <SVGIcon width="1.25rem" height="1.25rem" fileName="clock-red"/>
                            <SVGIcon width="1.25rem" height="1.25rem" fileName="clock-red"/>
                        </div>
                    </TooltipWrapper>
                );
            }
            else durationIcon = (
                <TooltipWrapper
                    tooltipText={`Appointment Length: ${appointment.duration} minutes`}
                >
                    <div className="mr-0.5">
                        <SVGIcon width="1.25rem" height="1.25rem" fileName="clock-red"/>
                    </div>
                </TooltipWrapper>
            );
        }
    }
    if(appointment.special_notes && !hideAlerts.specialNotes){
        specialIcon = (
            <TooltipWrapper
                tooltipText={`${appointment.special_notes}`}
            >
                <div className="mr-0.5">
                    <SVGIcon width="1.25rem" height="1.25rem" fileName="exclamation-red"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(appointment.special_notes_2 && !hideAlerts.specialNotes2){
        specialIcon2 = (
            <TooltipWrapper
                tooltipText={`${appointment.special_notes_2}`}
            >
                <div className="mr-0.5">
                    <SVGIcon width="1.25rem" height="1.25rem" fileName="exclamation-blue"/>
                </div>
            </TooltipWrapper>
        );
    }
    if(Object.keys(appointment.pendingFlag).length !== 0){
        const pf = appointment.pendingFlag;
        pendingFlagIcon = (
            <TooltipWrapper
                tooltipText={
                    <>
                        <div>This student currently has at least one pending flag. Details of the most recent pending flag are shown below.</div>
                        <br/>
                        <div><b>Tag:</b> {pf.tag || <span className="text-mpOrange">None</span>}</div>
                        <div><b>Center:</b> {pf.centerName}</div>
                        <div><b>Notes:</b> {pf.notes || <span className="text-mpOrange">None</span>}</div>
                        <div><b>Admin Notes:</b> {pf.admin_notes || <span className="text-mpOrange">None</span>}</div>
                        <div><b>Created:</b> {formatDateFull(pf.date_created)} by {pf.createdByName}</div>
                        <div>
                            <b>Updated:</b> {
                            pf.updated_by ? `${formatDateFull(pf.date_updated)} by ${pf.updatedByName}`
                            : <span className="text-mpOrange">Never</span>}
                        </div>
                    </>
                }
            >
                <div className="mr-0.5">
                    <SVGIcon width="1.25rem" height="1.25rem" fileName="flag-red"/>
                </div>
            </TooltipWrapper>
        );
    }

    if(durationIcon === null && specialIcon === null &&
        specialIcon2 === null && pendingFlagIcon === null && !hideAlerts.noAlerts) return 'None';
    return(
        <div className="flex flex-row gap-x-2">
            {durationIcon} {specialIcon} {specialIcon2} {pendingFlagIcon}
        </div>
    );
}