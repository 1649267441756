import React from 'react';

import { Button, SVGIcon } from '../../../../../components/custom-essentials';
import { TooltipWrapper, tableColors } from '../../../../../components/display';
import { formatDateFull } from '../../../../../components/functions';

export function getStampsLogColumns(handleShowModal){
    const colors = tableColors;
    let colorIndex = 0;
    const colorMap = {};

    return [
        {
            dataField: 'date',
            text: 'Date',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                const aDate = new Date(a);
                const bDate = new Date(b);
                return (bDate - aDate) * swap;
            },
            formatter: (row) => formatDateFull(row.date)
        },
        {
            dataField: 'studentName',
            text: 'Student',
            sort: true,
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                if(a < b) return -1 * swap;
                else if(a > b) return 1 * swap;
                else return 0;
            },
            formatter: (row) => {
                const type = row.type;
                let typeColor = '';
                if(colorMap[type]) typeColor = colorMap[type];
                else {
                    colorMap[type] = colors[(colorIndex++) % 5];
                    typeColor = colorMap[type];
                }
                return (
                    <div style={{ marginRight: "0.5rem" }}>
                        <span className={`label label-light-${typeColor} label-inline font-weight-bold`}>
                            {type}
                        </span>
                    </div>
                );
            }
        },
        {
            dataField: 'amount',
            text: 'Amount',
            sort: true,
            formatter: (row) => row.amount
        },
        {
            dataField: 'notes',
            text: 'Notes',
            formatter: (row) => row.notes ? row.notes : <div className="text-mpOrange">None</div>
        },
        {
            dataField: 'updatedByName',
            text: 'Updated By',
            sort: true,
            sortFunc: (a, b, order) => {
                const swap = order === 'asc' ? 1 : -1;
                if(a < b) return -1 * swap;
                else if(a > b) return 1 * swap;
                else return 0;
            },
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (row) => {
                return (
                    <div className="flex flex-row gap-x-2">
                        <TooltipWrapper
                            tooltipText={`Edit entry`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLBlue"
                                variant="icon"
                                onClick={() => handleShowModal('edit', row)}
                                style={{ marginRight: "10px" }}
                            >
                                <SVGIcon fileName="pencil"/>
                            </Button>
                        </TooltipWrapper>
                        <TooltipWrapper
                            tooltipText={`Delete entry`}
                        >
                            <Button
                                className="btn btn-icon btn-sm"
                                color="lte-mpLRed"
                                variant="icon"
                                onClick={() => handleShowModal('delete', row)}
                            >
                                <SVGIcon fileName="trash"/>
                            </Button>
                        </TooltipWrapper>
                    </div>
                );
            }
        }
    ];
}