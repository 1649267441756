import React, { useRef, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { formatDateTime, formatDateApi, getTimeObject } from '../../functions';
import { DatePicker, TimePicker, Check } from '../../form';
import { getValidationSchema } from './helpers';

function InstructorAttendanceBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedAttendance, userName, today, loaded,
        submitted, handleClose, handleSubmit } = props;

    const validationSchema = useMemo(() => {
        return getValidationSchema(today);
    }, [today])

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {(formik) => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this attendance entry?</h4>
                            <br/>
                            <div className="grid grid-cols-1">
                                <div>User: {userName}</div>
                                <div>Time In: {formatDateTime(selectedAttendance.time_in)}</div>
                                <div>Time Out: {selectedAttendance.time_out ? formatDateTime(selectedAttendance.time_out) : 'None'}</div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed font-xs mr-4">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    selectedDate: selectedAttendance?.time_in ? formatDateApi(selectedAttendance.time_in) :
                        formatDateApi(new Date()),
                    timeIn: selectedAttendance?.time_in ? getTimeObject(selectedAttendance.time_in, true) :
                        getTimeObject(new Date(), true),
                    timeOut: selectedAttendance?.time_out ? getTimeObject(selectedAttendance.time_out, true) :
                        getTimeObject(new Date(), true),
                    timeOutNull: selectedAttendance?.time_out ? false : true,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h3>Attendance for {userName}</h3>
                            <br/>
                            <div className="flex flex-row gap-x-2">
                                <div className="grid grid-cols-1 gap-y-2 w-1/3">
                                    <h4>Date</h4>
                                    <DatePicker
                                        id="attendance-date"
                                        name="selectedDate"
                                        value={formik.values.selectedDate}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.selectedDate ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedDate}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                                <div className="w-1/12"/>
                                <div className="grid grid-cols-1 gap-y-2 w-7/12">
                                    <div className="flex flex-row gap-x-4">
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>Time In</h4>
                                            <TimePicker
                                                id="attendance-time-in"
                                                name="timeIn"
                                                value={formik.values.timeIn?.formatted24 || ''}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-y-2 w-1/2">
                                            <h4>Time Out</h4>
                                            <TimePicker
                                                id="attendance-time-out"
                                                name="timeOut"
                                                value={formik.values.timeOut?.formatted24 || ''}
                                                onChange={formik.handleChange}
                                                disabled={formik.values.timeOutNull}
                                            />
                                            <Check
                                                id="attendance-time-out-null"
                                                name="timeOutNull"
                                                label="No Out Time"
                                                color="mpDBlue"
                                                checked={formik.values.timeOutNull}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {formik.errors.checkTimesValid ? (
                                <ErrorMessage color="mpLRed">
                                    {formik.errors.checkTimesValid}
                                </ErrorMessage>
                            ) : null}
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-4">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(InstructorAttendanceBodyFooter);