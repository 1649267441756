import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { ModalBodyFooter, Button, ModalLoading, ModalProcessing, ErrorMessage } from '../../custom-essentials';
import { SelectSingle, SelectMulti, FormikControl, Check, DateRangeSelector, TimePicker } from '../../form';
import { validationSchema, getInitCenterOption, getInitSelectedStudents } from './helpers';
import { formatDateApi, getTimeObject } from '../../functions';

const startApi = formatDateApi(new Date());
const end = new Date();
end.setDate(end.getDate() + 27);
const endApi = formatDateApi(end);

function CycleGroupsBodyFooter(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    const { mode, selectedGroup, studentOptions, centerOptions,
        loaded, submitted, handleClose, handleSubmit } = props;

    if(!loaded){
        return(
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <ModalLoading/>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(submitted){
        return (
            <ModalBodyFooter>
                <ModalBodyFooter.Body>
                    <h4>
                        Updated successfully!
                    </h4>
                </ModalBodyFooter.Body>
                <ModalBodyFooter.Footer>
                    <Button
                        color="lte-mpLRed"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </ModalBodyFooter.Footer>
            </ModalBodyFooter>
        );
    } else if(mode === 'delete') {
        return (
            <Formik
                enableReinitialize
                initialValues={{}}
                validateOnChange={true}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <h4>Delete this group?</h4>
                            <div className="grid grid-cols-1">
                                <div>Center: {selectedGroup.centerName}</div>
                                <div>Students: {selectedGroup.memberNames}</div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {formik.isSubmitting &&
                                    <ModalProcessing/>
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed font-[8pt] mr-4">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="hol-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={formik.handleSubmit}
                                >
                                    Delete
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    } else {
        return(
            <Formik
                enableReinitialize
                initialValues={{
                    groupName: selectedGroup.name || '',
                    center: getInitCenterOption(selectedGroup.center, centerOptions),
                    notes: selectedGroup.notes || '',
                    startDate: selectedGroup.start_date ? formatDateApi(selectedGroup.start_date) : startApi,
                    endDate: selectedGroup.end_date ? formatDateApi(selectedGroup.end_date) : endApi,
                    sundays: parseInt(selectedGroup.sundays) === 1,
                    mondays: parseInt(selectedGroup.mondays) === 1,
                    tuesdays: parseInt(selectedGroup.tuesdays) === 1,
                    wednesdays: parseInt(selectedGroup.wednesdays) === 1,
                    thursdays: parseInt(selectedGroup.thursdays) === 1,
                    fridays: parseInt(selectedGroup.fridays) === 1,
                    saturdays: parseInt(selectedGroup.saturdays) === 1,
                    time: getTimeObject(selectedGroup.time || 840),
                    active: selectedGroup.active ? parseInt(selectedGroup.active) === 1 : true,
                    selectedStudents: getInitSelectedStudents(selectedGroup.members, studentOptions)
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {formik => (
                    <ModalBodyFooter>
                        <ModalBodyFooter.Body>
                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Group Name</h4>
                                    <FormikControl
                                        id="cycle-groups-group-name"
                                        name="groupName"
                                        value={formik.values.groupName}
                                        onChange={formik.handleChange}
                                        placeholder="Max 200 characters"
                                    />
                                    {formik.errors.groupName ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.groupName}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Center</h4>
                                    <SelectSingle
                                        id="cycle-groups-center"
                                        name="center"
                                        value={formik.values.center}
                                        onChange={formik.handleChange}
                                        options={centerOptions}
                                    />
                                    {formik.errors.center ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.center}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Students</h4>
                                    <SelectMulti
                                        id="cycle-groups-selected-students"
                                        name="selectedStudents"
                                        value={formik.values.selectedStudents}
                                        onChange={(e) => {
                                            // Length cannot exceed 6
                                            // Do not allow additions if appointments are selected
                                            // But allow removals in all cases
                                            if(e.target.value?.length <= 6 ||
                                                e.target.value?.length < formik.values.selectedStudents.length
                                            ){
                                                formik.handleChange(e);
                                            }
                                        }}
                                        options={studentOptions}
                                    />
                                    {formik.errors.selectedStudents ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.selectedStudents}
                                        </ErrorMessage>
                                    ) : null}
                                    {formik.values.selectedStudents.length === 6 ? 
                                        <div className="text-mpOrange">Maximum 6 students.</div>
                                        : null
                                    }
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Planned Cycle Dates</h4>
                                    
                                    <div className="clear-both h-1"/>

                                    <DateRangeSelector
                                        id="cycle-groups-drs"
                                        startName="startDate"
                                        endName="endDate"
                                        startLabel="Start Date"
                                        endLabel="End Date"
                                        startValue={formik.values.startDate}
                                        endValue={formik.values.endDate}
                                        defaultValid={true}
                                        onStartChange={formik.handleChange}
                                        onEndChange={formik.handleChange}
                                        useCustomValidation={true}
                                    />
                                    {formik.errors.drsValid ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.drsValid}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Meeting Days and Time</h4>

                                    <div className="clear-both h-1"/>

                                    <TimePicker
                                        id="cycle-groups-time"
                                        name="time"
                                        label="Meeting Time"
                                        value={formik.values.time?.formatted24 || ''}
                                        onChange={formik.handleChange}
                                        step={900} // 15 min
                                    />
                                    {formik.errors.time ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.time}
                                        </ErrorMessage>
                                    ) : null}

                                    <div className="clear-both h-2"/>

                                    <div className="flex flex-row">
                                        <Check
                                            id="cycle-groups-sundays"
                                            name="sundays"
                                            color="mpDBlue"
                                            checked={formik.values.sundays}
                                            onChange={formik.handleChange}
                                            label="Su"
                                        />
                                        <Check
                                            id="cycle-groups-mondays"
                                            name="mondays"
                                            color="mpDBlue"
                                            checked={formik.values.mondays}
                                            onChange={formik.handleChange}
                                            label="M"
                                        />
                                        <Check
                                            id="cycle-groups-tuesdays"
                                            name="tuesdays"
                                            color="mpDBlue"
                                            checked={formik.values.tuesdays}
                                            onChange={formik.handleChange}
                                            label="T"
                                        />
                                        <Check
                                            id="cycle-groups-wednesdays"
                                            name="wednesdays"
                                            color="mpDBlue"
                                            checked={formik.values.wednesdays}
                                            onChange={formik.handleChange}
                                            label="W"
                                        />
                                        <Check
                                            id="cycle-groups-thursdays"
                                            name="thursdays"
                                            color="mpDBlue"
                                            checked={formik.values.thursdays}
                                            onChange={formik.handleChange}
                                            label="R"
                                        />
                                        <Check
                                            id="cycle-groups-fridays"
                                            name="fridays"
                                            color="mpDBlue"
                                            checked={formik.values.fridays}
                                            onChange={formik.handleChange}
                                            label="F"
                                        />
                                        <Check
                                            id="cycle-groups-saturdays"
                                            name="saturdays"
                                            color="mpDBlue"
                                            checked={formik.values.saturdays}
                                            onChange={formik.handleChange}
                                            label="Sa"
                                        />
                                    </div>
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Notes</h4>
                                    <FormikControl
                                        id="cycle-groups-notes"
                                        name="notes"
                                        value={formik.values.notes}
                                        onChange={formik.handleChange}
                                        as="textarea"
                                        placeholder="Max 5,000 characters."
                                    />
                                    {formik.errors.notes ? (
                                        <ErrorMessage color="mpLRed">
                                            {formik.errors.notes}
                                        </ErrorMessage>
                                    ) : null}
                                </div>
                            </div>

                            <br/>

                            <div className="flex flex-row gap-x-4">
                                <div className="grid grid-cols-1 gap-y-2 w-full">
                                    <h4>Group Active</h4>
                                    <Check
                                        id="cycle-groups-active"
                                        name="active"
                                        color="mpDBlue"
                                        checked={formik.values.active}
                                        onChange={formik.handleChange}
                                        label="Group Active"
                                    />
                                </div>
                            </div>
                        </ModalBodyFooter.Body>
                        <ModalBodyFooter.Footer>
                            <div className="flex flex-row gap-x-2 flex-wrap">
                                {!formik.isValid && parseInt(formik.submitCount) && !formik.isSubmitting ?
                                    (
                                        <div className="text-mpLRed">
                                            One or more fields needs to be corrected.
                                        </div>
                                    ) : null
                                }
                                {formik.isSubmitting ?
                                    <ModalProcessing/> : null
                                }
                                {formik.status && !formik.isSubmitting ? 
                                    <div className="text-mpLRed mr-4">
                                        {formik.status}
                                    </div> : null
                                }
                                <Button
                                    color="lte-mpLRed"
                                    disabled={formik.isSubmitting}
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                <Button
                                    color="lte-mpLBlue"
                                    onClick={formik.handleSubmit}
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                        </ModalBodyFooter.Footer>
                    </ModalBodyFooter>
                )}
            </Formik>
        );
    }
}

export default connect(null, {

})(CycleGroupsBodyFooter);