import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { getColumns } from './table/getColumns';
import { Table } from '../../../../components/table';
import { CycleGroupModal } from '../../../../components/modal';

const defaultSorted = [{ dataField: 'date_submitted', order: 'desc' }];

function CycleGroupsTable(props){
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });

    // Modal
    const [modalMode, setModalMode] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const { refreshData, groups } = props;
    const handleShowModal = useCallback((mode, group) => {
        if(mounted.current){
            setModalMode(mode);
            setSelectedGroup(group);
        }
    }, [mounted]);
    const onSubmitCallback = useCallback((changes = false) => {
        if(mounted.current){
            setModalMode(null);
            setSelectedGroup(null);
            if(changes) refreshData();
        }
    }, [mounted, refreshData]);

    const columns = useMemo(() => getColumns(handleShowModal), [handleShowModal]);

    return (
        <>
            <Table
                tableName="cycleGroups"
                data={groups}
                columns={columns}
                defaultSorted={defaultSorted}
                emptyMessage="No groups were found"
            />
            { modalMode &&
                <CycleGroupModal
                    mode={modalMode}
                    selectedGroup={selectedGroup}
                    onSubmitCallback={onSubmitCallback}
                />
            }
        </>
    );
}

export default connect(null, {

})(CycleGroupsTable);