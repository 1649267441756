import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './index.css';
import App from './app/App';
import { store } from './app/store';
import { colorPalette } from './components/custom-essentials';

const el = document.getElementById('root');
const root = createRoot(el);

const theme = createTheme({
    palette: colorPalette
})

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </React.StrictMode>
    </Provider>
);